import React, { forwardRef, useState, useRef } from "react";
import MaterialTable from "material-table";
import { TableBody, TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
let ismobile = window.innerWidth < 1600;
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: () => <div />,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const statusPriorityMap = {
  Submitted: 1,
  "User Submitted": 2,
  "Quote Generated": 3,
  "Uploaded Document": 4,
  "UW Questions": 5,
  MQL: 6,
  SQL: 7,
  "Company Profile(Get Quote)": 8,
  "Company Profile": 9,
  "Landing Page": 10,
  "Visited Website": 11,
  "Affinity Logo Clicked": 12,
  "Fourth Logo Clicked": 13,
  "Clicked on Affinity logo": 14,
  "Clicked on Fourth logo": 15,
  "Clicked on the image": 16,
  "Clicked on insure comp logo": 17,
  "Watched Video": 18,
  "Visited LinkedIn": 19,
  "Visited Facebook": 20,
  "Visited Instagram": 21,
  "Accessed Social Media": 22,
  "Opened Email": 23,
  "Not Campaigned": 24,
  "-": 999,
};
function EngagedTabMUI(props) {
  let { isLoading, engagedRowDataList, showDetailPanel, columns } = props;
  const myRefEngagedTab = useRef();
  const [scrollLeft, setScrollLeft] = useState(false);
  const [scrollRight, setScrollRight] = useState(true);

  const fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.87rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.85rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.8rem";
    }
    if (window.innerWidth < 1300) {
      return "0.72rem";
    }
  };

  const scrollLeftHandler = () => {
    const tableContainerDiv =
      myRefEngagedTab.current.children[0].children[2].children[1].children[0]
        .children[0];
    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft -= 150;
      if (tableContainerDiv.scrollLeft == 0) {
        setScrollLeft(false);
        setScrollRight(true);
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft < maxScrollLeft) {
        setScrollRight(true);
      }
    }
  };
  const scrollRightHandler = () => {
    const tableContainerDiv =
      myRefEngagedTab.current.children[0].children[2].children[1].children[0]
        .children[0];
    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft += 150;
      setScrollLeft(true);
    }

    const maxScrollLeft =
      tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    if (tableContainerDiv.scrollLeft >= maxScrollLeft) {
      setScrollRight(false);
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        {scrollLeft && (
          <button className="ChevronLeft" onClick={scrollLeftHandler}>
            &lt;
          </button>
        )}
        {scrollRight && (
          <button className="ChevronRight" onClick={scrollRightHandler}>
            &gt;
          </button>
        )}
        <div ref={myRefEngagedTab}>
          <MaterialTable
            icons={tableIcons}
            data={engagedRowDataList}
            columns={columns}
            options={{
              stickyHeader: true,
              doubleHorizontalScroll: true,
              filtering: true,
              sorting: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 75, 100],
              headerStyle: {
                fontSize: ismobile ? fontfunciton() : "1rem",
                backgroundColor: "#003764",
                color: "#FFF",
                padding: "6px 15px 6px 10px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile ? fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
            }}
            // components={{
            //   Pagination: (props) => (
            //     <TablePagination
            //       {...props}
            //       labelRowsPerPage={
            //         <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
            //       }
            //       labelDisplayedRows={(row) => (
            //         <div style={{ fontSize: 12 }}>
            //           {props.labelDisplayedRows(row)}
            //         </div>
            //       )}
            //       SelectProps={{
            //         style: {
            //           fontSize: 12,
            //         },
            //       }}
            //     />
            //   ),
            // }}
            detailPanel={
              showDetailPanel
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return (
                          <div style={{ padding: "25px" }}>
                            <TableContainer component={Paper}>
                              <Table
                                size="small"
                                className="sub_tab1"
                                id="pre_engaged_subtab"
                              >
                                <TableHead
                                  style={{
                                    background: "#00000066",
                                    color: "whitesmoke",
                                  }}
                                >
                                  <TableRow>
                                    <TableCell>Contact Name</TableCell>
                                    <TableCell>Phone Number</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Job Description</TableCell>
                                    <TableCell>Visit Count</TableCell>
                                    <TableCell>Status</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rowData.email_specific ? (
                                    Object.values(rowData.email_specific).map(
                                      (element, index) => {
                                        let step;
                                        if (element.companyProfile) {
                                          if (
                                            Number(
                                              element.proposalGeneratedCount
                                            ) > 0
                                          ) {
                                            step = "Proposal Generated";
                                          } else if (element.quote_status) {
                                            if (
                                              element.quote_status ===
                                              "uwportal"
                                            ) {
                                              step = "UW Portal";
                                            } else if (
                                              element.quote_status ===
                                              "proceed_uw"
                                            ) {
                                              step = "Approved";
                                            } else if (
                                              element.quote_status === "reject"
                                            ) {
                                              step = "Rejected";
                                            }
                                          } else if (
                                            Number(
                                              element.quoteGeneratedCount
                                            ) > 0
                                          ) {
                                            step = "Quote Generated";
                                          } else if (element.formStage) {
                                            if (element.formStage === "two") {
                                              if (element.nonEligibilityList) {
                                                step = "Not Eligible";
                                              } else {
                                                step = "UW Questions";
                                              }
                                            } else if (
                                              element.formStage === "one"
                                            ) {
                                              step = "Company Profile";
                                            }
                                          } else if (
                                            element.visitCount &&
                                            Number(element.visitCount) > 0
                                          ) {
                                            step = "Landing Page";
                                          } else {
                                            step = "-";
                                          }
                                          if (
                                            element.companyProfile.status &&
                                            element.companyProfile.status.value
                                          ) {
                                            step =
                                              statusPriorityMap[
                                                element.companyProfile.status
                                                  .value
                                              ] < statusPriorityMap[step]
                                                ? element.companyProfile.status
                                                    .value
                                                : step;
                                          }
                                          return (
                                            <TableRow key={index}>
                                              <TableCell scope="row">
                                                {element.companyProfile &&
                                                element.companyProfile
                                                  .contact_name
                                                  ? element.companyProfile
                                                      .contact_name.value
                                                  : ""}
                                              </TableCell>
                                              <TableCell scope="row">
                                                {element?.companyProfile?.phoneNumber?.value?.replace(
                                                  /(\d{3})(\d{3})(\d{4})/,
                                                  "($1) $2-$3"
                                                ) ||
                                                  element?.companyProfile?.contact_number?.value?.replace(
                                                    /(\d{3})(\d{3})(\d{4})/,
                                                    "($1) $2-$3"
                                                  ) ||
                                                  ""}
                                              </TableCell>
                                              <TableCell scope="row">
                                                {
                                                  element?.companyProfile
                                                    ?.emailId?.value
                                                }
                                              </TableCell>
                                              <TableCell scope="row">
                                                {
                                                  element?.companyProfile
                                                    ?.job_title?.value
                                                }
                                              </TableCell>
                                              <TableCell scope="row">
                                                {element.visitCount || 0}
                                              </TableCell>
                                              <TableCell scope="row">
                                                {step}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        }
                                      }
                                    )
                                  ) : (
                                    <TableRow key={0}>
                                      <TableCell scope="row">
                                        {rowData.contactName}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {rowData.phoneNumber
                                          ? rowData.phoneNumber.replace(
                                              /(\d{3})(\d{3})(\d{4})/,
                                              "($1) $2-$3"
                                            )
                                          : "-"}
                                      </TableCell>
                                      <TableCell scope="row">
                                        {rowData.userEmail}
                                      </TableCell>
                                      <TableCell scope="row">{""}</TableCell>
                                      <TableCell scope="row">
                                        {rowData.visitCount}
                                      </TableCell>
                                      {/* <TableCell scope="row">
                                {rowData.latestCampaign || ""}
                              </TableCell> */}
                                      <TableCell scope="row">
                                        {rowData.step || ""}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        );
                      },
                    },
                  ]
                : false
            }
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
}

export default EngagedTabMUI;
