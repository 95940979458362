"use strict";
import React, {
  Component,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from "react";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import MaterialTable from "material-table";
import { TableBody, TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import pdf from "../../images/pdf.svg";
import excel from "../../images/excel.svg";

import uploaddownload from "../../images/uploading.svg";

import DealClosedLostButtons from "../subcompo/DealClosedLostButtons";

import generateExcelData from "../../utils/generate_excel_data";
import xlsx from "json-as-xlsx";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import numeral from "numeral";
import { Auth } from "aws-amplify";

const {
  DownloadDashboardFiles,
} = require("../subcompo/DownloadDashboardFiles.jsx");

let ismobile = window.innerWidth < 1600;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: () => <div />,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const ExportToExcel = (data, fileName) => {
  let settings = {
    fileName: fileName, // Name of the resulting spreadsheet
    extraLength: 1, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    RTL: false, // Display the columns from right-to-left (the default value is false)
  };

  try {
    xlsx(data, settings); // Will download the excel file
  } catch (error) {
    console.log("error while generating excel: ", error);
  }
};

// Fein format and adding 0 precedence
const formatFein = (value) => {
  let fein;
  value = value.split("-").join("");
  var len = value.length,
    dummystr = "000000000";

  if (len === 0) {
    fein = "-";
  } else if (len > 0 && len < 9) {
    value = dummystr.slice(0, 9 - len) + "" + value;
    fein = value[0] + value[1] + "-" + value.slice(2);
  } else {
    fein = value[0] + value[1] + "-" + value.slice(2);
  }

  return fein;
};

// Camel case for companyName
const camelCase = (str) => {
  if (str)
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  return str;
};

const fontfunciton = () => {
  if (window.innerWidth < 1600 && window.innerWidth > 1500) {
    return "0.87rem";
  }
  if (window.innerWidth < 1500 && window.innerWidth > 1410) {
    return "0.85rem";
  }
  if (window.innerWidth < 1410 && window.innerWidth > 1310) {
    return "0.8rem";
  }
  if (window.innerWidth < 1300) {
    return "0.72rem";
  }
};
const updateData = (data) => {
  try {
    axios
      .post(awsUrl2 + "/api/underWriterData/update", data)
      .then((res) => {
        console.log("data saved");
      })
      .catch((err) => {
        console.log("error while saving");
      });
  } catch (error) {}
};

const amtrustResponse = (uuid, apiType) => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let res = await axios.get(
        awsUrl2 + `/api/getAmtrustData/${uuid}/${apiType}`
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });

  return promise;
};

const downloadPolicyDocument = async (rowData) => {
  let policy = rowData?.amtrustAccountDetails?.BindData?.Data?.Policy;
  toastSuccess("Downloading");
  if (policy) {
    let amtrustPolicyDocumentResponse = {};
    try {
      let amtrustPolicyDocumentTriggerResponse = await axios.post(
        awsUrl + `/api/triggerAmtrustPolicyDocument/${policy}`
      );

      let policyDocumentUuid;
      if (amtrustPolicyDocumentTriggerResponse.data.uuid) {
        policyDocumentUuid = amtrustPolicyDocumentTriggerResponse.data.uuid;
      } else {
        throw "error in amtrust policy document trigger";
      }

      do {
        amtrustPolicyDocumentResponse = await amtrustResponse(
          policyDocumentUuid,
          "getPolicyDocument"
        );
        if (amtrustPolicyDocumentResponse === "error") {
          throw "error in amtrust get policy document";
        }
      } while (amtrustPolicyDocumentResponse === "processing");

      try {
        let link = document.createElement("a");
        link.href = amtrustPolicyDocumentResponse;
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (error) {
        toastError("Unable to download");
        console.log("error while downloading the policy document", error);
      }
    } catch (error) {
      toastError("Unable to download");
      console.log("error in fetching amtrust policy document response", error);
    }
  }
};

const toastSuccess = (text) =>
  toast.success(text, {
    duration: 3000,
  });

const toastError = (text) =>
  toast.error(text, {
    duration: 3000,
  });

let Changing_fontvalue = fontfunciton();

const UnderWriterTab = (props) => {
  const myRef = useRef();
  const [rowDataList, setRowDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalData, setModalData] = useState({});
  const [domainButtonMap, setDomainButtonMap] = useState({});
  const [style, setStyle] = useState({});
  const [scrollLeft, setScrollLeft] = useState(false);
  const [scrollRight, setScrollRight] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState("");

  let { selected, tabName, dealMap, updateDealMap } = props;
  const tabsMap = {
    tab1: "peo",
    tab2: "paygo",
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        if (res?.attributes?.email) {
          setLoggedInUser(res.attributes.email);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDisplayHide = () => {
    setStyle(tabName !== selected ? { display: "none", fontSize: "12px" } : {});
  };

  const onClickCompanyName = (rowData) => {
    if (rowData.currProspect) {
      sessionStorage.setItem(
        "currProspect",
        JSON.stringify(rowData.currProspect)
      );
    }
    if (rowData.user_email_id) {
      sessionStorage.setItem("salesID", rowData.user_email_id);
    }

    if (rowData.amtrustAccountDetails) {
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(rowData.amtrustAccountDetails)
      );
    }

    if (rowData.address) {
      sessionStorage.setItem("address", JSON.stringify(rowData.address));
    }

    if (rowData.quoteData) {
      sessionStorage.setItem("quoteData", JSON.stringify(rowData.quoteData));
    }

    if (rowData.uuidCarriers) {
      sessionStorage.setItem(
        "sortKeyList",
        JSON.stringify(rowData.uuidCarriers)
      );
    }

    sessionStorage.setItem("formStage", "three");
    sessionStorage.setItem("showAllFields", true);
  };

  const columnData = {
    tab1: [
      {
        title: "Submitted Date",
        field: "submittedDate",
        filterPlaceholder: "Filter",

        // render: (rowData) =>
        //   rowData.submittedDate
        //     ? moment(Number(rowData.submittedDate), "x").format("M/D/YYYY")
        //     : "",
        customFilterAndSearch: (term, rowData, field) =>
          customFilterAll(term, rowData, field),

        cellStyle: {
          wordBreak: "break-all",
          padding: "6px 0",
          minWidth: "100px",
        },
        headerStyle: {
          padding: "10px 0",
        },
      },
      {
        title: "Company Name",
        field: "companyname",
        filterPlaceholder: "Filter",

        cellStyle: {
          padding: "6px 5px 6px 10px",
          minWidth: "250px",
          wordBreak: "break-word",
        },
        headerStyle: {
          padding: "6px 5px 6px 10px",
        },
        render: (rowData) => {
          return (
            <Clamp lines={2}>
              <span onClick={() => onClickCompanyName(rowData)}>
                <Link to={"/Quote"}>{camelCase(rowData.companyname)}</Link>
              </span>
            </Clamp>
          );
        },
      },
      {
        title: "FEIN",
        field: "fein",
        filterPlaceholder: "Filter",
        customFilterAndSearch: (term, rowData, field) =>
          customFilterAll(term, rowData, field),

        cellStyle: {
          wordBreak: "break-all",
          padding: "6px 0",
          minWidth: "100px",
        },
        headerStyle: {
          padding: "10px 0",
          minWidth: "100px",
        },
        render: (rowData) => formatFein(rowData.fein),
      },
      {
        title: "Tools Submission Status",
        field: "ToolssubmissionStatus",
        filterPlaceholder: "Filter",

        cellStyle: (e, rowData) => {
          let style = {
            padding: "6px 15px 6px 10px",
            fontWeight: "bold",
            // textAlign: "center",
          };
          if (rowData.ToolssubmissionStatus === "Approved") {
            style.color = "#008E1B";
          } else if (rowData.ToolssubmissionStatus === "Declined") {
            style.color = "#CB2E3E";
          } else if (rowData.ToolssubmissionStatus === "Referral") {
            style.color = "#FFBD16";
          } else {
            style.color = "#000";
          }
          return style;
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          // textAlign: "center",
        },
        cellStyle: {
          padding: "6px 0",
          minWidth: "120px",
        },
      },
      {
        title: "Pre-UW Reco",
        field: "pre_Uwreco",
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "130px",
          wordBreak: "break-all",
          // textAlign: "center",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          // textAlign: "center",
        },
      },

      {
        title: "Tools Reco",
        field: "ToolsReco",
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "120px",
          wordBreak: "break-all",
          // textAlign: "center",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          // textAlign: "center",
        },
      },
      {
        title: "Agent",
        field: "agent",
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "130px",
          wordBreak: "break-all",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
        },
        render: (rowData) => <Clamp lines={2}> {rowData.agent || ""}</Clamp>,
      },
      {
        title: "Comments",
        field: "comments",
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 15px 6px 10px",
        },
        render: (rowData) => {
          return (
            <textarea
              defaultValue={rowData.comments || ""}
              rows="2"
              style={{ width: "100%" }}
              onBlur={(e) => {
                let dataToSave = {};
                dataToSave.user_email_id = rowData.user_email_id;
                dataToSave.sortKeyList = rowData.uuidCarriers;
                dataToSave.comments = e.target.value;

                updateData(dataToSave);
              }}
            ></textarea>
          );
        },
      },
      {
        title: "Source",
        field: "source",
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "130px",
          wordBreak: "break-all",
          // textAlign: "center",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          // textAlign: "center",
        },
      },
      {
        title: "Sent to salesforce?",
        field: "sentToSalesforce",
        render: (rowData) => {
          return rowData.sentToSalesforce;
        },
      },
      {
        title: "To Salesforce",
        field: "toSalesforce",
        render: (rowData) => {
          return (
            <button
              className="btn btn-sm btn-warning"
              onClick={(e) => {
                if (rowData.isSalesforce) {
                  sendToSalesforce(
                    rowData.salesforceData,
                    rowData.bindCarrier,
                    rowData.admin_fee,
                    rowData.admin_percent
                  );
                  updateData({
                    user_email_id: rowData.user_email_id,
                    sortKeyList: rowData.uuidCarriers,
                    toSalesforce: "true",
                    toSalesforceDate: moment().format("x"),
                    toSalesforceUser: loggedInUser,
                  });
                }
              }}
              disabled={!rowData.isSalesforce}
            >
              To Salesforce
            </button>
          );
        },
      },
      {
        title: "Deal Won/Lost",
        field: "dealFlagFilter",
        render: (rowData) => {
          let key = rowData.user_email_id;
          let isClosed = false,
            isLost = false;
          if (key in dealMap) {
            isClosed = dealMap[key] === "true" ? true : false;
            isLost = dealMap[key] === "false" ? true : false;
          } else {
            isClosed = rowData.dealFlag === "closed" ? true : false;
            isLost = rowData.dealFlag === "lost" ? true : false;
          }

          return (
            <DealClosedLostButtons
              recordDetails={rowData.recordDetails}
              isClosed={isClosed}
              isLost={isLost}
              updateDealMap={updateDealMap}
              disabled={rowData?.dealConfirmed}
            />
          );
        },
        cellStyle: {
          padding: "6px 15px 6px 10px",
          minWidth: "150px",
        },
      },
    ],
    tab2: [
      {
        title: "Submitted Date",
        field: "submittedDate",
        filterPlaceholder: "Filter",
        filter: true,

        // render: (rowData) =>
        //   rowData.submittedDate
        //     ? moment(Number(rowData.submittedDate), "x").format("M/D/YYYY")
        //     : "",
        customFilterAndSearch: (term, rowData, field) =>
          customFilterAll(term, rowData, field),
        cellStyle: {
          wordBreak: "break-all",
          padding: "6px 0",
          minWidth: "100px",
        },
        headerStyle: {
          padding: "10px 0",
        },
      },
      {
        title: "Company Name",
        field: "companyname",
        filterPlaceholder: "Filter",

        cellStyle: {
          padding: "6px 5px 6px 10px",
          minWidth: "250px",
          wordBreak: "break-word",
        },
        headerStyle: {
          padding: "6px 5px 6px 10px",
        },
        render: (rowData) => {
          return (
            <Clamp lines={2}>
              <span onClick={() => onClickCompanyName(rowData)}>
                <Link to={"/Quote"}>{camelCase(rowData.companyname)}</Link>
              </span>
            </Clamp>
          );
        },
      },
      {
        title: "FEIN",
        field: "fein",
        customFilterAndSearch: (term, rowData, field) =>
          customFilterAll(term, rowData, field),

        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "130px",
          // width: "190px",
          // textAlign: "center",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          // textAlign: "center",
        },
        render: (rowData) => formatFein(rowData.fein),
      },
      {
        title: "Tools Submission Status",
        field: "ToolssubmissionStatus",
        filterPlaceholder: "Filter",

        cellStyle: (e, rowData) => {
          let style = {
            padding: "6px 15px 6px 10px",
            fontWeight: "bold",
            minWidth: "140px",
          };
          if (rowData.ToolssubmissionStatus === "Approved") {
            style.color = "#008E1B";
          } else if (rowData.ToolssubmissionStatus === "Declined") {
            style.color = "#CB2E3E";
          } else if (rowData.ToolssubmissionStatus === "Referral") {
            style.color = "#FFBD16";
          } else {
            style.color = "#000";
          }
          return style;
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          // textAlign: "center",
        },
      },
      {
        title: "Pre-UW Reco",
        field: "pre_Uwreco",
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "140px",
          wordBreak: "break-all",
          // textAlign: "center",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          // textAlign: "center",
        },
      },

      {
        title: "Tools Reco",
        field: "ToolsReco",
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "120px",
          wordBreak: "break-all",
          // textAlign: "center",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
          // textAlign: "center",
        },
      },
      {
        title: "Agent",
        field: "agent",
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "120px",
          wordBreak: "break-all",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
        },
        render: (rowData) => <Clamp lines={2}> {rowData.agent || ""}</Clamp>,
      },
      {
        title: "Amtrust Eligibility",
        field: "amtrustPaygoEligibility",
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "130px",
          wordBreak: "break-all",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
        },
        render: (rowData) => (
          <Clamp lines={1}> {rowData?.amtrustPaygoEligibility || ""}</Clamp>
        ),
      },
      {
        title: "Policy Document",
        field: "amtrustPolicyAvailable",
        filterPlaceholder: "Filter",
        render: (rowData) => {
          return (
            <button
              className="btn pt-0"
              type="button"
              disabled={!rowData?.amtrustPolicyAvailable}
              style={{ border: "none", padding: 0 }}
              onClick={() => downloadPolicyDocument(rowData)}
            >
              <img
                src={require("../../images/file-download-circle-green.svg")}
                alt=""
                style={
                  rowData?.amtrustPolicyAvailable
                    ? { width: "40px", filter: "grayscale(0)" }
                    : {
                        width: "40px",
                        filter: "grayscale(100%)  opacity(0.4)",
                      }
                }
              />
            </button>
          );
        },
        cellStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "120px",
          wordBreak: "break-all",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
        },
      },
      {
        title: "Bound with Amtrust",
        field: "amtrustBound",
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 0 6px 10px",
          minWidth: "130px",
          wordBreak: "break-all",
        },
        headerStyle: {
          padding: "6px 0 6px 10px",
        },
      },
      {
        title: "Comments",
        field: "comments",
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 15px 6px 10px",
        },
        render: (rowData) => {
          return (
            <textarea
              defaultValue={rowData.comments || ""}
              rows="2"
              style={{ width: "100%" }}
              onBlur={(e) => {
                let dataToSave = {};
                dataToSave.user_email_id = rowData.user_email_id;
                dataToSave.sortKeyList = rowData.uuidCarriers;
                dataToSave.comments = e.target.value;

                updateData(dataToSave);
              }}
            ></textarea>
          );
        },
      },
      {
        title: "Source",
        field: "source",
        filterPlaceholder: "Filter",
        cellStyle: {
          padding: "6px 0 6px 10px",
          // minWidth: "90px",
          wordBreak: "break-all",
          // textAlign: "center",
        },
        cellStyle: {
          padding: "6px 15px 6px 10px",
          minWidth: "170px",
        },
        render: (rowData) => <Clamp lines={1}>{rowData.source || "-"}</Clamp>,
      },
      {
        title: "Sent to salesforce?",
        field: "sentToSalesforce",
        render: (rowData) => {
          return rowData.sendtToSalesforce;
        },
      },
      {
        title: "To Salesforce",
        field: "toSalesforce",
        render: (rowData) => {
          return (
            <button
              className="btn btn-sm btn-warning"
              onClick={(e) => {
                if (rowData.isSalesforce) {
                  sendToSalesforce(
                    rowData.salesforceData,
                    rowData.bindCarrier,
                    rowData.admin_fee,
                    rowData.admin_percent
                  );
                  updateData({
                    user_email_id: rowData.user_email_id,
                    sortKeyList: rowData.uuidCarriers,
                    toSalesforce: "true",
                    toSalesforceDate: moment().format("x"),
                    toSalesforceUser: loggedInUser,
                  });
                }
              }}
              disabled={!rowData.isSalesforce}
            >
              To Salesforce
            </button>
          );
        },
      },
      {
        title: "Deal Won/Lost",
        field: "dealFlagFilter",
        render: (rowData) => {
          let key = rowData.user_email_id;
          let isClosed = false,
            isLost = false;
          if (key in dealMap) {
            isClosed = dealMap[key] === "true" ? true : false;
            isLost = dealMap[key] === "false" ? true : false;
          } else {
            isClosed = rowData.dealFlag === "closed" ? true : false;
            isLost = rowData.dealFlag === "lost" ? true : false;
          }

          // console.log("rendering..", isClosed, isLost);

          return (
            <DealClosedLostButtons
              recordDetails={rowData.recordDetails}
              isClosed={isClosed}
              isLost={isLost}
              updateDealMap={updateDealMap}
              disabled={rowData?.dealConfirmed}
            />
          );
        },
        cellStyle: {
          padding: "6px 15px 6px 10px",
          minWidth: "150px",
        },
      },
    ],
  };

  const customFilterfunction = (term, rowData, field) => {
    let customFieldName = field["field"];

    let excludeFirstCharacter = term.substring(1);
    let excludeFirstTwoCharacter = term.substring(2);
    let firstCharacter = term.substring(1, 0);
    let firstTwoCharacter = term.substring(2, 0);
    let operationMap = {
      ">=": ">=",
      "<=": "<=",
      "<": "<",
      ">": ">",
      "=": "===",
    };
    try {
      if (firstTwoCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstTwoCharacter]} ${excludeFirstTwoCharacter}`
        );
      } else if (firstCharacter in operationMap) {
        console.log(
          "firstcharater:",
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
      } else {
        return rowData[customFieldName]?.toString()?.startsWith(term);
      }
    } catch (err) {
      return rowData[customFieldName]?.toString()?.startsWith(term);
    }
  };

  const customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };

  const setTableData = () => {
    // getting the data from backend

    const scrollLeftHandler = () => {
      const tableContainerDiv =
        myRef.current.lastChild.lastChild.children[2].children[1].children[0]
          .children[0];
      if (tableContainerDiv) {
        tableContainerDiv.scrollLeft -= 150;
        if (tableContainerDiv.scrollLeft == 0) {
          setScrollLeft(false);
          setScrollRight(true);
        }

        const maxScrollLeft =
          tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

        if (tableContainerDiv.scrollLeft < maxScrollLeft) {
          setScrollRight(true);
        }
      }
    };
    const scrollRightHandler = () => {
      const tableContainerDiv =
        myRef.current.lastChild.lastChild.children[2].children[1].children[0]
          .children[0];

      if (tableContainerDiv) {
        tableContainerDiv.scrollLeft += 150;
        setScrollLeft(true);
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft >= maxScrollLeft) {
        setScrollRight(false);
      }
    };

    return (
      <>
        <div style={{ position: "relative" }}>
          {scrollLeft && (
            <button className="ChevronLeft" onClick={scrollLeftHandler}>
              &lt;
            </button>
          )}
          {scrollRight && (
            <button className="ChevronRight" onClick={scrollRightHandler}>
              &gt;
            </button>
          )}
          <MaterialTable
            icons={tableIcons}
            data={rowDataList}
            columns={columnData[tabName]}
            options={{
              stickyHeader: true,
              doubleHorizontalScroll: true,
              filtering: true,
              sorting: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 75, 100],
              headerStyle: {
                fontSize: ismobile ? fontfunciton() : "1rem",
                backgroundColor: "#003764",
                color: "#FFF",
                padding: "6px 15px 6px 10px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile ? fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
            }}
            detailPanel={[
              {
                tooltip: "More info",
                icon: () => <KeyboardArrowRightIcon />,
                render: (rowData) => {
                  return (
                    <div style={{ padding: "20px" }}>
                      <TableContainer component={Paper}>
                        <Table className="sub_unberwritter_tab">
                          <TableHead
                            className="sub_unberwritter_header"
                            style={{
                              background: "#00000066",
                              color: "##000000",
                            }}
                          >
                            <TableRow>
                              <TableCell className="sub_underwritter_header_row1 nowrap">
                                Pre-UW <br></br>Snapshot
                              </TableCell>
                              <TableCell className="sub_underwritter_header_row2">
                                Tools <br></br> Snapshot
                              </TableCell>
                              <TableCell className="sub_underwritter_header_row3">
                                Info/Flag Statements
                              </TableCell>
                              <TableCell className="sub_underwritter_header_row4">
                                Documents
                              </TableCell>
                              <TableCell className="sub_underwritter_header_row5">
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody key={rowData.user_email_id}>
                            <TableRow>
                              <TableCell scope="row" key={2}>
                                <div
                                  style={
                                    rowData?.preUwData?.currProspect
                                      ? { filter: "grayscale(0)" }
                                      : {
                                          filter:
                                            "grayscale(100%)  opacity(0.4)",
                                        }
                                  }
                                >
                                  <img
                                    className="sub_underwritter_pdfImage2"
                                    src={excel}
                                    style={
                                      rowData?.preUwData?.currProspect
                                        ? { cursor: "pointer" }
                                        : {}
                                    }
                                    onClick={() => {
                                      if (!rowData?.preUwData?.currProspect)
                                        return;
                                      let response = generateExcelData(
                                        rowData.preUwData?.currProspect
                                      );
                                      console.log("response: ", response);
                                      if (response) {
                                        ExportToExcel(response, "preUW");
                                      } else {
                                        console.log(
                                          "error while generating the data"
                                        );
                                      }
                                    }}
                                    alt="pdf"
                                  ></img>
                                </div>
                              </TableCell>
                              <TableCell scope="row" key={1}>
                                <div
                                  style={
                                    rowData?.currProspect
                                      ? { filter: "grayscale(0)" }
                                      : {
                                          filter:
                                            "grayscale(100%)  opacity(0.4)",
                                        }
                                  }
                                >
                                  <img
                                    className="sub_underwritter_pdfImage1"
                                    src={excel}
                                    style={
                                      rowData?.currProspect
                                        ? { cursor: "pointer" }
                                        : {}
                                    }
                                    onClick={() => {
                                      if (!rowData?.currProspect) return;
                                      let response = generateExcelData(
                                        rowData.currProspect
                                      );
                                      // console.log("response: ", response);
                                      if (response) {
                                        ExportToExcel(response, "preUW");
                                      } else {
                                        console.log(
                                          "error while generating the data"
                                        );
                                      }
                                    }}
                                    alt="pdf"
                                  ></img>
                                </div>
                              </TableCell>
                              <TableCell scope="row" key={3}>
                                <div
                                  style={{
                                    filter: "grayscale(100%)  opacity(0.4)",
                                  }}
                                >
                                  <img
                                    className="sub_underwritter_pdfImage3"
                                    src={pdf}
                                    alt="pdf"
                                  ></img>
                                </div>
                              </TableCell>
                              <TableCell scope="row" key={4}>
                                <button
                                  className="btn pt-0"
                                  type="button"
                                  // title={parse(title)}
                                  data-html="true"
                                  onClick={() => {
                                    setModalData({
                                      showModel: true,
                                      modalTitle: <h1>{"Download"}</h1>,
                                      modalContent: (
                                        <>
                                          <DownloadDashboardFiles
                                            dashboardFiles={
                                              rowData.dashboardFiles || []
                                            }
                                            preUwFiles={
                                              rowData.preUwFiles || []
                                            }
                                            fileListToStore={
                                              rowData.fileListToStore || []
                                            }
                                            domainName={rowData.domain}
                                          />
                                        </>
                                      ),
                                    });
                                  }}
                                >
                                  <img
                                    className="sub_underwritter_pdfImage4"
                                    src={uploaddownload}
                                    alt="uploaddownloadiimage"
                                  ></img>
                                </button>
                              </TableCell>
                              <TableCell
                                scope="row"
                                // className=" sub_underwritter_Grouped_button"
                                key={5}
                              >
                                <ButtonGroup
                                  domainButtonMap={domainButtonMap}
                                  setDomainButtonMap={setDomainButtonMap}
                                  updateData={updateData}
                                  rowData={rowData}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  );
                },
              },
            ]}
            isLoading={isLoading}
          />
        </div>
      </>
    );
  };

  const ButtonGroup = (props) => {
    const [isApproved, setIsApproved] = useState(false);
    const [isRejected, setIsRejected] = useState(false);
    const [isOnHold, setIsOnHold] = useState(false);

    useEffect(() => {
      setIsApproved(
        props?.domainButtonMap?.[props?.rowData?.["user_email_id"] || "test"]
          ?.isApproved || false
      );
      setIsRejected(
        props?.domainButtonMap?.[props?.rowData?.["user_email_id"] || "test"]
          ?.isRejected || false
      );
      setIsOnHold(
        props?.domainButtonMap?.[props?.rowData?.["user_email_id"] || "test"]
          ?.isOnHold || false
      );
    }, [
      props.domainButtonMap?.isApproved,
      props.domainButtonMap?.isRejected,
      props.domainButtonMap?.isOnHold,
    ]);

    const updateButtonStatus = (status) => {
      let row = props.rowData;
      let _domainButtonMap = Object.assign({}, props.domainButtonMap);

      let dataToSave = {};
      dataToSave.user_email_id = row.user_email_id;
      dataToSave.sortKeyList = row.uuidCarriers;
      dataToSave.uw_status = status;

      if (status === "approved") {
        _domainButtonMap[row?.["user_email_id"]].isApproved = true;
      } else if (status === "rejected") {
        _domainButtonMap[row?.["user_email_id"]].isRejected = true;
      } else if (status === "onHold") {
        _domainButtonMap[row?.["user_email_id"]].isOnHold = true;
      }
      props.updateData(dataToSave);
      props.setDomainButtonMap(_domainButtonMap);
    };

    return (
      <div>
        {!isApproved && !isRejected && !isOnHold && (
          <>
            <button
              className="sub_underwritter_approved_button"
              onClick={() => {
                updateButtonStatus("approved");
                setIsApproved(true);
              }}
            >
              Approve
            </button>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <button
              className="sub_underwritter_hold_button"
              onClick={() => {
                updateButtonStatus("onHold");
                setIsOnHold(true);
              }}
            >
              Hold
            </button>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <button
              className="sub_underwritter_rejected_button"
              onClick={() => {
                updateButtonStatus("rejected");
                setIsRejected(true);
              }}
            >
              Reject
            </button>
          </>
        )}
        {isApproved && (
          <button className="sub_underwritter_approved_button" disabled>
            Approved
          </button>
        )}
        {isRejected && (
          <button className="sub_underwritter_rejected_button" disabled>
            Rejected
          </button>
        )}
        {isOnHold && (
          <button className="sub_underwritter_hold_button" disabled>
            On Hold
          </button>
        )}
      </div>
    );
  };

  const sendToSalesforce = async (
    salesforceData,
    selectedCarrier,
    adminPerCk,
    adminPercent
  ) => {
    try {
      toastSuccess("Syncing...");

      let salesforceDataCopy = JSON.parse(JSON.stringify(salesforceData));
      if (selectedCarrier === "peo") {
        salesforceDataCopy.workers_comp = "Master";
      } else if (selectedCarrier === "paygo") {
        salesforceDataCopy.workers_comp = "Yes - WC Covered by Fourth";
      }

      salesforceDataCopy.peo_admin_percent_rate =
        adminPercent && !isNaN(adminPercent)
          ? (Math.round(Number(adminPercent) * 100) / 100).toFixed(2)
          : "";

      salesforceDataCopy.peo_admin_fee =
        adminPerCk && !isNaN(adminPerCk)
          ? (Math.round(Number(adminPerCk) * 100) / 100).toFixed(2)
          : "";
      console.log("Salesfoce data+++");
      console.log(salesforceDataCopy);
      let response;

      try {
        response = await axios.post(
          awsUrl + "/api/sendToSalesforce",
          salesforceDataCopy
        );

        console.log("++resp", response);
        toastSuccess("Synced with salesforce");
      } catch (error) {
        toastError("Error while syncing");
        throw error;
      }
    } catch (error) {
      console.log("error sending data to salesforce", error);
      toastError("Error while syncing");
    }
  };

  const getAllData = (bindCarrier) => {
    try {
      axios
        .post(awsUrl2 + "/api/underWriterData/getAll", {
          bindCarrier,
        })
        .then((res) => {
          let dataFromAPI = res.data;
          let rowDataList = [];
          let domainButtonMap = {};

          try {
            for (let domain in dataFromAPI) {
              let createPreparedData = true;
              let preparedData = {};

              if (!(domain in domainButtonMap))
                domainButtonMap[domain] = {
                  isApproved: false,
                  isRejected: false,
                  isOnHold: false,
                };

              for (let domainWiseQuoteData of dataFromAPI[domain]) {
                if (createPreparedData) {
                  try {
                    preparedData = {
                      user_email_id: domainWiseQuoteData?.user_email_id,
                      amtrustAccountDetails:
                        domainWiseQuoteData?.amtrustAccountDetails,
                      amtrustBound: domainWiseQuoteData?.amtrustBound
                        ? "Yes"
                        : domainWiseQuoteData?.amtrustBound === false
                        ? "No"
                        : "",
                      amtrustPolicyAvailable:
                        domainWiseQuoteData?.amtrustPolicyAvailable,
                      amtrustPaygoEligibility:
                        domainWiseQuoteData?.amtrustPaygoEligibility
                          ?.Eligibility,
                      submittedDate:
                        (domainWiseQuoteData?.submissionDate
                          ? moment(
                              Number(domainWiseQuoteData?.submissionDate),
                              "x"
                            ).format("M/D/YYYY")
                          : "-") || "",
                      companyname:
                        domainWiseQuoteData?.currProspectDetails?.companyProfile
                          ?.companyName?.value || "",
                      fein:
                        domainWiseQuoteData?.currProspectDetails?.companyProfile
                          ?.fein?.value || "",
                      ToolssubmissionStatus: "Approved",
                      pre_Uwreco: domainWiseQuoteData?.preUwData?.preUwStatus
                        ? domainWiseQuoteData?.preUwData?.preUwStatus ===
                          "approved"
                          ? "PEO"
                          : "PAYGO"
                        : "",
                      ToolsReco: domainWiseQuoteData?.clientEligibility
                        ? domainWiseQuoteData?.clientEligibility === "declined"
                          ? "PAYGO"
                          : "PEO"
                        : "",
                      agent:
                        domainWiseQuoteData?.salespersonNameAttr ||
                        domainWiseQuoteData?.salespersonName,
                      comments: domainWiseQuoteData?.comments,
                      currProspect: domainWiseQuoteData?.currProspectDetails,
                      preUwData: domainWiseQuoteData?.preUwData,
                      uuidCarriers: domainWiseQuoteData?.uuid_carrier
                        ? [domainWiseQuoteData.uuid_carrier]
                        : [],
                      address: domainWiseQuoteData?.uuid_carrier
                        ? [domainWiseQuoteData?.uuid_carrier?.split("+").pop()]
                        : [],
                      source:
                        domainWiseQuoteData.source ||
                        (domainWiseQuoteData?.origin === "sales_person"
                          ? "Sales Person"
                          : ""),
                      dealFlag:
                        domainWiseQuoteData?.dealData?.status === "true"
                          ? "closed"
                          : domainWiseQuoteData?.dealData?.status === "false"
                          ? "lost"
                          : "",
                      dealFlagFilter:
                        domainWiseQuoteData?.dealData?.status === "true"
                          ? "won"
                          : domainWiseQuoteData?.dealData?.status === "false"
                          ? "lost"
                          : "",
                      dealConfirmed: domainWiseQuoteData?.dealConfirmed,
                      recordDetails: {
                        salesId:
                          domainWiseQuoteData?.origin === "sales_person"
                            ? domainWiseQuoteData?.user_email_id
                            : "",
                        domain:
                          domainWiseQuoteData?.origin === "email_campaign"
                            ? domainWiseQuoteData?.user_email_id
                            : "",
                        userId: domainWiseQuoteData?.user_email_id,
                      },
                      sentToSalesforce: domainWiseQuoteData.toSalesforce
                        ? "Yes"
                        : "No",
                      toSalesforce: domainWiseQuoteData.toSalesforce
                        ? true
                        : false,
                      isSalesforce: domainWiseQuoteData.isSalesforce
                        ? true
                        : false,
                      bindCarrier: domainWiseQuoteData.bindCarrier,
                      salesId:
                        domainWiseQuoteData?.origin === "sales_person"
                          ? domainWiseQuoteData?.user_email_id
                          : "",
                      domain:
                        domainWiseQuoteData?.origin === "email_campaign"
                          ? domainWiseQuoteData?.user_email_id
                          : "",
                    };

                    if (domainWiseQuoteData.isSalesforce) {
                      try {
                        let adminType = domainWiseQuoteData?.currProspectDetails
                          ?.requestedPricing?.checked
                          ? "percent"
                          : "percheck";

                        let salesforceData = {
                          id: "",
                          fein: "",
                          years_of_operation: "",
                          peo_fee_type: "",
                          peo_admin_fee: "",
                          total_employees: "",
                          gross_wages: "",
                          peo_admin_percent_rate: "",
                          broker_name: "",
                          workers_comp: "",
                          price: "",
                          percent_or_amount: "percent",
                          discount_percent: "",
                          discount_amount: "",
                        };

                        let totalEmployees = 0;
                        let grossWages = 0;
                        for (let locIndex in domainWiseQuoteData.prospect_addresses) {
                          for (let ccIndex in domainWiseQuoteData
                            .prospect_addresses[locIndex].classCodes) {
                            let {
                              ft,
                              pt,
                              payroll,
                            } = domainWiseQuoteData.prospect_addresses[
                              locIndex
                            ].classCodes[ccIndex];
                            totalEmployees += Number(ft) + Number(pt);
                            grossWages += Number(payroll);
                          }
                        }

                        salesforceData = {
                          ...salesforceData,
                          id: domainWiseQuoteData.sfQuoteId?.trim() || "",
                          fein: domainWiseQuoteData.fein?.trim() || "",
                          years_of_operation:
                            domainWiseQuoteData?.currProspectDetails?.companyProfile?.yearsInBusiness?.value?.trim() ||
                            "",
                          total_employees: String(totalEmployees)?.trim(),
                          gross_wages: String(grossWages)?.trim(),
                          broker_name: domainWiseQuoteData?.currProspectDetails
                            ?.brokerDetails?.brokerQuestionValue
                            ? domainWiseQuoteData.currProspectDetails?.brokerDetails?.brokerdropdownSelected?.label?.trim() ||
                              ""
                            : "",
                          price:
                            (domainWiseQuoteData?.carrier_location_data
                              ?.admin_cost &&
                              !isNaN(
                                domainWiseQuoteData?.carrier_location_data
                                  .admin_cost
                              ) &&
                              numeral(
                                domainWiseQuoteData?.carrier_location_data
                                  .admin_cost
                              )
                                .divide(12)
                                .value()
                                .toFixed(2)) ||
                            "",
                          peo_fee_type:
                            adminType === "percent"
                              ? "Percent of Gross"
                              : adminType === "percheck"
                              ? "Per Check"
                              : "",
                          discount_percent:
                            domainWiseQuoteData?.specialAdminData
                              ?.additionalData?.smAdminDiscount || "",
                        };

                        preparedData.salesforceData = salesforceData;
                        preparedData.admin_fee =
                          domainWiseQuoteData?.carrier_location_data.admin_fee;
                        preparedData.admin_percent =
                          domainWiseQuoteData?.carrier_location_data.admin_percent;
                      } catch (error) {
                        console.log("error: ", error);
                      }
                      console.log("prs: ", preparedData.salesforceData);
                    }

                    if (
                      domainWiseQuoteData?.date &&
                      domainWiseQuoteData?.uuid
                    ) {
                      preparedData.quoteData = {
                        uuid: domainWiseQuoteData.uuid,
                        date: domainWiseQuoteData.date,
                      };
                    }

                    domainButtonMap[domain] = {
                      isApproved: domainWiseQuoteData?.uw_status === "approved",
                      isRejected: domainWiseQuoteData?.uw_status === "rejected",
                      isOnHold: domainWiseQuoteData?.uw_status === "onHold",
                    };

                    if (domainWiseQuoteData?.dashboardFiles?.length) {
                      preparedData["dashboardFiles"] =
                        domainWiseQuoteData.dashboardFiles;
                    }
                    if (domainWiseQuoteData?.preUwFiles?.length) {
                      let _preUwFiles = [];
                      domainWiseQuoteData.preUwFiles.forEach((ele) => {
                        _preUwFiles.push(ele.key);
                      });
                      preparedData["preUwFiles"] = _preUwFiles;
                    }

                    if (domainWiseQuoteData?.fileListToStore?.length) {
                      let _fileListToStore = [];
                      domainWiseQuoteData.fileListToStore.forEach((ele) => {
                        _fileListToStore.push(ele.key);
                      });
                      preparedData["fileListToStore"] = _fileListToStore;
                    }

                    createPreparedData = false;
                  } catch (error) {}
                } else {
                  if (domainWiseQuoteData?.uuid_carrier) {
                    preparedData.uuidCarriers.push(
                      domainWiseQuoteData.uuid_carrier
                    );
                    preparedData.address.push(
                      domainWiseQuoteData?.uuid_carrier?.split("+").pop()
                    );
                  }
                }
              }
              if (preparedData?.recordDetails) {
                preparedData.recordDetails.uuidList = preparedData.uuidCarriers;
              }
              rowDataList.push(preparedData);
            }
          } catch (error) {}

          setDomainButtonMap(domainButtonMap);
          setRowDataList(rowDataList);
          setIsLoading(false);
        })
        .catch((err) => {
          setRowDataList([]);
          setIsLoading(false);
        });
    } catch (error) {
      console.log("error in fetching data ", error);
    }
  };

  useEffect(() => {
    getAllData(tabsMap[tabName]);
    window.addEventListener("load", handleDisplayHide);
  }, []);

  useEffect(() => {
    handleDisplayHide();
  }, [selected]);

  let { showModel, modalTitle, modalContent } = modalData;

  return (
    <>
      <div className={"Submission-uw-dashboard"} style={style}>
        <div ref={myRef}>{setTableData()}</div>
        <Modal
          show={showModel}
          onHide={() => {
            setModalData({
              showModel: false,
              modalTitle: null,
              modalContent: null,
            });
          }}
          dialogClassName="model-download modal-dialog-centered"
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalContent}</Modal.Body>
        </Modal>
      </div>
      <Toaster />
    </>
  );
};

export default UnderWriterTab;
