"use strict";
import React, { Component, forwardRef } from "react";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTable from "material-table";
import { TableBody, TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import { Link } from "react-router-dom";

let ismobile = window.innerWidth < 1600;
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: () => <div />,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class PreUnderwriterFlow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      rowDataList: [],
    };
  }

  componentDidMount = () => {
    sessionStorage.clear();
    this.updateEligibilityDisplay();
  };

  camelCase = (str) => {
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };
  fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.87rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.85rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.8rem";
    }
    if (window.innerWidth < 1300) {
      return "0.72rem";
    }
  };

  formatFein = (value) => {
    let fein;
    value = value.split("-").join("");
    var len = value.length,
      dummystr = "000000000";

    if (len === 0) {
      fein = "-";
    } else if (len > 0 && len < 9) {
      value = dummystr.slice(0, 9 - len) + "" + value;
      fein = value[0] + value[1] + "-" + value.slice(2);
    } else {
      fein = value[0] + value[1] + "-" + value.slice(2);
    }

    return fein;
  };

  setQuestions = (quesList) => {
    let response = {};
    for (let question of quesList) {
      response[question.name] = {
        ques: question.ques,
        response: question.response,
      };
    }

    return response;
  };

  onClickCompanyName = (row) => {
    let currProspect = {
      companyProfile: row.companyProfile,
      emodStatesData: row.emodStatesData,
      childrenLoc: row.payrollData,
      preUwStatus: row.preUwStatus,
    };
    sessionStorage.setItem("preUnderwriter", "true");
    sessionStorage.setItem("domain", row.domain);
    sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
    if (
      row.pre_underwriter_status === "uw" ||
      row.pre_underwriter_status === "completed"
    ) {
      sessionStorage.setItem("formStage", "two");
    }
    if (row.quesList) {
      let quesList = this.setQuestions(row.quesList);
      sessionStorage.setItem("quesList", JSON.stringify(quesList));
    }
    if (row.amtrustQuesList) {
      let amtrustQuesList = this.setQuestions(row.amtrustQuesList);
      sessionStorage.setItem(
        "amtrustQuesList",
        JSON.stringify(amtrustQuesList)
      );
    }
    window.location.href = "/Quote";
  };

  setTableData = () => {
    let { rowDataList, isLoading } = JSON.parse(JSON.stringify(this.state));

    for (let rowData of rowDataList) {
      if (rowData.pre_underwriter_status === "completed") {
        rowData.status = "completed";
      } else {
        rowData.status = "in progress";
      }
    }

    return (
      <MaterialTable
        isLoading={isLoading}
        icons={tableIcons}
        data={rowDataList}
        columns={[
          {
            title: "Company Name",
            field: "companyName",
            cellStyle: {
              padding: "6px 15px 6px 10px",
              width: "250px",
            },
            render: (rowData) => (
              <div
                id="company-name-link"
                onClick={() => this.onClickCompanyName(rowData)}
                style={{
                  cursor: "pointer",
                  color: "blue",
                }}
              >
                {this.camelCase(rowData.companyProfile.companyName.value)}
              </div>
            ),
          },
          {
            title: "Payroll",
            field: "payroll",
            render: (rowData) => numeral(rowData.payroll).format("$0,0"),
            cellStyle: {
              padding: "6px 0 6px 10px",
              minWidth: "136px",
              wordBreak: "break-all",
            },
            headerStyle: {
              padding: "6px 0 6px 10px",
              minWidth: "136px",
            },
          },
          {
            title: "Effective Date",
            field: "effectiveDate",
            render: (rowData) =>
              moment(Number(rowData.timestamp)).format("M/D/YYYY"),
            cellStyle: {
              padding: "6px 15px 6px 10px",
            },
          },
          {
            title: "EMOD",
            field: "emod",
            headerStyle: {
              padding: 0,
            },
            cellStyle: {
              padding: 0,
            },
          },
          {
            title: "Status",
            field: "status",
            cellStyle: {
              wordBreak: "break-all",
              padding: "6px 0px",
              width: "150px",
            },
            headerStyle: {
              padding: "6px 0px",
            },
            render: (rowData) => (
              <Clamp lines={1}>{this.camelCase(rowData.status)}</Clamp>
            ),
          },
        ]}
        options={{
          filtering: true,
          sorting: true,
          paginationPosition: "both",
          padding: "dense",
          showTitle: false,
          thirdSortClick: false,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 75, 100],
          headerStyle: {
            fontSize: ismobile ? this.fontfunciton() : "1rem",
            backgroundColor: "#003764",
            color: "#FFF",
            padding: "6px 15px 6px 10px",
            fontFamily: "Montserrat",
          },
          rowStyle: {
            fontSize: ismobile ? this.fontfunciton() : "0.95rem",
            color: "#000",
            fontFamily: "Montserrat",
          },
          searchFieldStyle: {
            fontSize: "0.95rem",
          },
        }}
        detailPanel={[
          {
            tooltip: "Client info",
            icon: () => <KeyboardArrowRightIcon />,
            render: (rowData) => {
              return (
                <div style={{ padding: "25px" }}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead
                        style={{
                          background: "#00000066",
                          color: "whitesmoke",
                        }}
                      >
                        <TableRow>
                          <TableCell>Contact Name</TableCell>
                          <TableCell>Phone Number</TableCell>
                          <TableCell>Email</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rowData.email_specific ? (
                          Object.keys(rowData.email_specific).map(
                            (element, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell scope="row">
                                    {rowData.email_specific[element]
                                      .companyProfile.firstName.value +
                                      " " +
                                      rowData.email_specific[element]
                                        .companyProfile.lastName.value}
                                  </TableCell>
                                  <TableCell scope="row">
                                    {
                                      rowData.email_specific[element]
                                        .companyProfile.phoneNumber.value
                                    }
                                  </TableCell>
                                  <TableCell scope="row">
                                    {
                                      rowData.email_specific[element]
                                        .companyProfile.emailId.value
                                    }
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )
                        ) : (
                          <TableRow key={0}>
                            <TableCell scope="row">
                              {rowData.contactName}
                            </TableCell>
                            <TableCell scope="row">
                              {rowData.phoneNumber}
                            </TableCell>
                            <TableCell scope="row">
                              {rowData.userEmail}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              );
            },
          },
        ]}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              labelRowsPerPage={
                <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
              }
              labelDisplayedRows={(row) => (
                <div style={{ fontSize: 12 }}>
                  {props.labelDisplayedRows(row)}
                </div>
              )}
              SelectProps={{
                style: {
                  fontSize: 12,
                },
              }}
            />
          ),
        }}
      />
    );
  };

  updateEligibilityDisplay = async () => {
    await axios
      .get(awsUrl2 + "/api/getPreUnderwriter")
      .then((res) => {
        let data = res.data;
        let payroll = 0,
          emod = 1;

        for (let row in data) {
          if (
            data[row].emodStatesData &&
            data[row].emodStatesData[Object.keys(data[row].emodStatesData)[0]]
              .rate1
          ) {
            emod =
              data[row].emodStatesData[Object.keys(data[row].emodStatesData)[0]]
                .rate1.value;
          }

          if (data[row].payrollData) {
            for (var state in data[row].payrollData) {
              for (var pay in data[row].payrollData[state].classCodesInfo) {
                var pyrll = numeral(
                  data[row].payrollData[state].classCodesInfo[pay].payroll.value
                ).value();

                payroll += parseInt(pyrll);
              }
            }
          }
          data[row].payroll = payroll;
          data[row].emod = emod;
        }

        this.setState({ rowDataList: data });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    let { selected, tabName } = this.props;
    return (
      <div className={"pre-uw-dashboard"} style={{ width: "100%" }}>
        <div>{this.setTableData()}</div>
      </div>
    );
  }
}

export default PreUnderwriterFlow;
