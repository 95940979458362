import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Container, Nav, Form, Button } from "react-bootstrap";
import styles from "./Header.module.scss";
import { checkMailCampaignUser } from "../../utils/check_mail_campaign_user";
import { startNewQuote, logout } from "../../utils/common";
import { Auth } from "aws-amplify";
import HeaderSubPart from "./HeaderSubPart";

let fourthlogo = require("../../images/fourth.png");
let insureComplogo = require("../../images/new-logo.png");
let fourthpdflogo = require("../../images/fourthpdflogo.png");
let maskLogo = require("../../images/uw.webp");
let isMobile = window.innerWidth < 992;
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      userName: "user",
      showDashboardLink: false,
      showSalesDashboardLink: false,
    };
  }

  // componentDidMount = async () => {
  //   try {
  //     const currentAuthUser = await Auth.currentAuthenticatedUser();
  //     if (currentAuthUser) {
  //       let userName =
  //         currentAuthUser?.attributes?.name ||
  //         currentAuthUser.attributes.email.split("@")[0];
  //       let groups =
  //         currentAuthUser?.signInUserSession?.accessToken?.payload[
  //           "cognito:groups"
  //         ];
  //       if (groups.includes(process.env.REACT_APP_ADMIN_GROUP)) {
  //         this.setState({ showDashboardLink: true });
  //       } else if (groups.includes(process.env.REACT_APP_SALES_GROUP)) {
  //         this.setState({ showSalesDashboardLink: true });
  //       }
  //       this.setState({ isLoggedIn: true, userName });
  //     }
  //   } catch (error) {}
  // };

  render() {
    const {
      isLoggedIn,
      userName,
      showDashboardLink,
      showSalesDashboardLink,
    } = this.state;
    return (
      <>
        <nav
          id="navbarMain"
          className="new-navbar container-fluid"
          style={{ backgroundColor: "white", height: "auto", fontSize: "16px" }}
        >
          <div style={{ width: "100vw" }}>
            <div className="row justify-content-between " id="brand-container">
              <Link
                to={"/"}
                className="navbar-brand d-flex align-items-center hotscheduleslogo"
              >
                {(process.env.REACT_APP_MODE === "dev" ||
                  process.env.REACT_APP_MODE === "prod") && (
                  <img
                    className="fourthpdflogo-style"
                    src={fourthpdflogo}
                    alt="Fuba_logo"
                  />
                )}
                {process.env.REACT_APP_MODE === "beta" && (
                  <img
                    style={{ height: "150px" }}
                    src={maskLogo}
                    alt="UW_logo"
                  />
                )}
              </Link>
              <Link
                to={"/"}
                className="navbar-brand d-flex align-items-center  affinitylogo"
                style={{ margin: "20px" }}
              >
                {
                  <a
                    className="navbar-brand pride-logo d-flex align-items-center"
                    href="/"
                  >
                    <img
                      src={insureComplogo}
                      alt="insureComp_logo"
                      className="insureComplogo-style"
                    />
                    {/* <span className="peo-header">
                      Professional
                      <br /> Employer
                      <br /> Organisation
                    </span> */}
                  </a>
                }
              </Link>
            </div>
            <div className="pb-2">
              <hr className="mt-0" style={{ borderWidth: "2px" }} />
              <ul
                className="text-right nav h-100 justify-content-end text-dark dash-nav"
                style={{ fontFamily: "Montserrat" }}
              >
                {isMobile &&
                window.location.pathname.toLowerCase().includes("quote") ? (
                  " "
                ) : (
                  <HeaderSubPart />
                )}
              </ul>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default Header;
