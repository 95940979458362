export const acordQues = [
  {
    name: "q1",
    statementCode: "eligibility_check_4_1",
    ques: "Does applicant operate or lease aircraft / watercraft?",
    response: false,
    ifYes: "",
    ifNo: "approved",
    childrenQues: {
      ifYes: ["q1_1", "q1_2"],
    },
  },
  {
    name: "q2",
    statementCode: "eligibility_check_4_4",
    ques:
      "Do/have past, present or discontinued operations involve(d) storing, treating, discharging, applying, disposing, or transporting of hazardous material? (e.g. landfills, wastes, fuel tanks, etc)",
    response: false,
    ifYes: "referred",
    ifNo: "approved",
  },
  {
    name: "q3",
    statementCode: "eligibility_check_4_5",
    ques: "Any work performed underground or above 15 feet?",
    response: false,
    ifYes: "",
    ifNo: "approved",
    childrenQues: {
      ifYes: ["q3_1", "q3_2"],
    },
  },
  {
    name: "q4",
    statementCode: "eligibility_check_4_8",
    ques: "Any work performed on barges, vessels, docks, bridges over water?",
    response: false,
    ifYes: "declined",
    ifNo: "approved",
  },
  {
    name: "q5",
    statementCode: "eligibility_check_4_9",
    ques: "Is applicant engaged in any other type of business?",
    response: false,
    ifYes: "referred",
    ifNo: "approved",
  },
  {
    name: "q6",
    statementCode: "eligibility_check_4_10",
    ques:
      'Are sub-contractors and/or independent contractors used? (if "Yes", give % of work subcontracted)',
    response: false,
    ifYes: "",
    ifNo: "approved",
    type: "percent",
    childrenQues: {
      ifYes: ["q6_1", "q6_2"],
    },
  },
  {
    name: "q7",
    statementCode: "eligibility_check_4_12",
    ques: "Any work sublet without certificates of insurance?",
    response: false,
    ifYes: "declined",
    ifNo: "approved",
  },
  {
    name: "q8",
    statementCode: "eligibility_check_4_13",
    ques: "Is a formal safety program in operation?",
    response: false,
    ifYes: "approved",
    ifNo: "approved",
  },
  {
    name: "q9",
    statementCode: "eligibility_check_4_14",
    ques: "Any group transportation provided?",
    response: false,
    ifYes: "declined",
    ifNo: "approved",
  },
  {
    name: "q10",
    statementCode: "eligibility_check_4_15",
    ques: "Any employees under 16 or over 60 years of age?",
    response: false,
    ifYes: "approved",
    ifNo: "approved",
  },
  {
    name: "q11",
    statementCode: "eligibility_check_4_16",
    ques: "Any part time or seasonal employees?",
    response: false,
    ifYes: "approved",
    ifNo: "approved",
  },
  {
    name: "q12",
    statementCode: "eligibility_check_4_17",
    ques: "Is there any volunteer or donated labor?",
    response: false,
    ifYes: "approved",
    ifNo: "approved",
  },
  {
    name: "q13",
    statementCode: "eligibility_check_4_18",
    ques: "Any employees with physical handicaps?",
    response: false,
    ifYes: "approved",
    ifNo: "approved",
  },
  {
    name: "q14",
    statementCode: "eligibility_check_4_19",
    ques: "Do employees travel out of state?",
    response: false,
    ifYes: "referred",
    ifNo: "approved",
    childrenQues: {
      ifYes: ["q14_1", "q14_2"],
    },
  },
  {
    name: "q15",
    statementCode: "eligibility_check_4_20",
    ques: "Are athletic teams sponsored?",
    response: false,
    ifYes: "approved",
    ifNo: "approved",
  },
  {
    name: "q16",
    statementCode: "eligibility_check_4_21",
    ques: "Are physicals required after offers of employment are made?",
    response: false,
    ifYes: "approved",
    ifNo: "approved",
  },
  {
    name: "q17",
    statementCode: "eligibility_check_4_22",
    ques: "Any other insurance with this insurer?",
    response: false,
    ifYes: "approved",
    ifNo: "approved",
  },
  {
    name: "q18",
    statementCode: "eligibility_check_4_23",
    ques:
      "Any prior coverage declined / cancelled / non-renewed (last 3 years)?",
    response: false,
    ifYes: "referred",
    ifNo: "approved",
    childrenQues: {
      ifYes: ["q18_1"],
    },
  },
  {
    name: "q19",
    statementCode: "eligibility_check_4_24",
    ques: "Are employee health plans provided?",
    response: false,
    ifYes: "approved",
    ifNo: "approved",
  },
  {
    name: "q20",
    statementCode: "eligibility_check_4_25",
    ques: "Is there a labor interchange with any other business/subsidiary?",
    response: false,
    ifYes: "declined",
    ifNo: "approved",
  },
  {
    name: "q21",
    statementCode: "eligibility_check_4_26",
    ques: "Do you lease employees to or from other employers?",
    response: false,
    ifYes: "referred",
    ifNo: "approved",
  },
  {
    name: "q22",
    statementCode: "eligibility_check_4_27",
    ques: "Do any employees predominantly work at home?",
    response: false,
    ifYes: "approved",
    ifNo: "approved",
  },
  {
    name: "q23",
    ques: "What are your estimated annual revenues?",
    response: "",
    type: "input",
  },
  {
    name: "q24",
    statementCode: "eligibility_check_4_28",
    ques:
      "Is there any current or anticipated debt for unpaid premiums owed to any previous workers' compensation provider?",
    response: false,
    ifYes: "referred",
    ifNo: "approved",
  },
  {
    name: "q25",
    statementCode: "eligibility_check_4_29",
    ques: "Deductible?",
    response: false,
    ifYes: "referred",
    ifNo: "approved",
  },
  {
    name: "q26",
    ques:
      "Does the company have a single Workers' Compensation claim over $15,000?",
    statementCode: "eligibility_check_4_30",
    response: false,
    ifYes: "referred",
    ifNo: "approved",
  },
  {
    name: "q27",
    ques:
      "Will company be submitting an application for the Drug-Free Workplace Premium Credit?",
    response: false,
  },
  {
    name: "q28",
    ques:
      "Will company be submitting an application for the Employer Workplace Safety Premium Credit?",
    response: false,
  },
  {
    name: "q29",
    ques: "Is Blanket waiver of subrogation endorsement needed?",
    response: false,
  },
  {
    name: "q30",
    ques: "Would you like a payroll company reporting payment plan?",
    response: false,
    ifYes: "approved",
    ifNo: "approved",
  },
  {
    name: "q31",
    ques: "Does this business or any of the owners of this business, either individually or in combination with other owners of this business, own more than 50% of any other business, which operated at any time during the five years prior to this application?",
    response: false,
    ifYes: "",
    ifNo: "",
  },
  {
    name: "q32",
    ques: "Does this business own a majority interest in another entity, which in turn owns a majority interest in any entity that operated at any time in the five years prior to this application?",
    response: false,
    ifYes: "",
    ifNo: "",
  },
];

export const extraQues = {
  q1_1: {
    name: "q1_1",
    statementCode: "eligibility_check_4_2",
    ques: "Aircraft",
    response: "",
    ifYes: "declined",
    ifNo: "approved",
  },
  q1_2: {
    name: "q1_2",
    statementCode: "eligibility_check_4_3",
    ques: "Watercraft",
    response: "",
    ifYes: "referred",
    ifNo: "approved",
  },
  q3_1: {
    name: "q3_1",
    statementCode: "eligibility_check_4_6",
    ques: "Any exterior work above 3 stories?",
    response: "",
    ifYes: "declined",
    ifNo: "approved",
  },
  q3_2: {
    name: "q3_2",
    statementCode: "eligibility_check_4_7",
    ques: "Any underground work below 4 feet?",
    response: "",
    ifYes: "declined",
    ifNo: "approved",
  },
  q6_1: {
    name: "q6_1",
    statementCode: "eligibility_check_4_11",
    ques:
      "Does the company subcontract trades not described in the governing class code of this submission?",
    response: "",
    ifYes: "referred",
    ifNo: "approved",
  },
  q6_2: {
    name: "q6_2",
    ques: "Projected cost of subcontractors / independent contractors?",
    response: "",
    type: "input",
  },
  q14_1: {
    name: "q14_1",
    ques:
      "For employees that travel out of state, what state(s) do they travel to?",
    response: "",
    type: "input",
  },
  q14_2: {
    name: "q14_2",
    ques:
      "For employees that travel out of state, what is the duration of travel per year?",
    response: "",
    type: "input",
  },
  q18_1: {
    name: "q18_1",
    ques: "Reason for prior coverage cancellation?",
    response: "",
    type: "input",
  },
};

export var fubaGoverningCcQues = [
  {
    name: "q1",
    id: 1,
    ques: "Is coverage in states other than Florida needed?",
    ifYes: "declined",
    ifNo: "approved",
  },
  {
    name: "q2",
    id: 2,
    ques: "Is USL&H coverage needed?",
    ifYes: "declined",
    ifNo: "approved",
  },
  {
    name: "q3",
    id: 3,
    ques: "Is voluntary compensation coverage needed?",
    ifYes: "declined",
    ifNo: "approved",
  },
];
