import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";
import Newcode from "./Newcode";
import StateList from "../../common/StateList";
import { deleteAddress } from "../../../utils/session_data";
import axios from "axios";
import { awsUrl } from "../../../config";
import { checkMailCampaignUser } from "../../../utils/check_mail_campaign_user";
import { Auth } from "aws-amplify";
let monoStates = require("../../../utils/mono_states.json");
let ismobile = window.innerWidth < 992;
class CompLoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationInfo: {
        state: { value: "fl" },
        zipCode: {},
        cityName: {},
        street: {},
        suta: {},
      },
      PreUWLoginCheck: true,
    };
  }

  componentDidMount = async () => {
    this.setState({ locationInfo: this.props.locationInfo });
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      if (currentAuthUser) {
        let userName =
          currentAuthUser?.attributes?.name ||
          currentAuthUser.attributes.email.split("@")[0];
        let groups =
          currentAuthUser?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ];
        if (groups.includes(process.env.REACT_APP_PREUW_GROUP)) {
          this.setState({ PreUWLoginCheck: false });
        }
      }
    } catch (error) {}
  };

  componentDidUpdate() {
    if (this.props.locationInfo !== this.state.locationInfo) {
      this.setState({ locationInfo: this.props.locationInfo });
    }
  }

  addClassCode = (number, classCodesInfo, updateLocationInfo) => {
    let codeCount = Object.keys(classCodesInfo).reduce((prev, val) => {
      return Math.max(Number(val), Number(prev));
    });
    updateLocationInfo(null, number, null, Number(codeCount) + 1);
  };

  getMinMax = (addressBlockNo, addressChildNo) => {
    const { locationInfo } = this.state;
    const { effectiveDate, updateLocationError } = this.props;
    const {
      ft,
      payroll,
      classCodeDescription,
      govClassCodeCheck,
    } = locationInfo.classCodesInfo[addressChildNo];
    const state = "FL".toLowerCase();
    // const zip = locationInfo.zipCode.value;

    let classCode = classCodeDescription.value.split(":")[0];

    let data = {
      code: classCode,
      pt: "0",
      ft: ft.value.trim(),
      payroll: payroll.value
        .split("$")[1]
        .split(",")
        .join(""),
    };

    let bodyData = {
      employees: [data],
      state: state,
      date_from: effectiveDate.value.format("YYYY-MM-DD"),
      location: "",
    };

    if (!monoStates.includes(state)) {
      axios
        .post(awsUrl + "/api/getMinMaxPremium", JSON.stringify(bodyData))
        .then((response) => {
          if (govClassCodeCheck?.["value"]) {
            this.props.govCCPremiumHandler(response.data.man_prem_values);
            console.log(response.data.man_prem_values);
          }

          if (response.data === "Not eligible") {
            updateLocationError(
              addressBlockNo,
              addressChildNo,
              "classCodeDescription",
              true
            );
          } else {
            updateLocationError(
              addressBlockNo,
              addressChildNo,
              "classCodeDescription",
              false
            );
          }
        })
        .catch((error) => {
          console.log(error);
          updateLocationError(
            addressBlockNo,
            addressChildNo,
            "classCodeDescription",
            true
          );
        });
    }
  };

  handleMinMax = (addressBlockNo, addressChildNo) => {
    const { locationInfo } = this.state;
    const keyList = ["ft", "payroll", "classCodeDescription"];
    let allClassCodeVals;
    if (locationInfo && locationInfo.classCodesInfo[addressChildNo]) {
      allClassCodeVals = keyList.every((e) => {
        return (
          e in locationInfo.classCodesInfo[addressChildNo] &&
          locationInfo.classCodesInfo[addressChildNo][e].value &&
          locationInfo.classCodesInfo[addressChildNo][e].value.trim() !== ""
        );
      });
    }

    if (allClassCodeVals) {
      this.getMinMax(addressBlockNo, addressChildNo);
    }
  };

  conditional_Render_street = (mailCampaignUserCheck) => {
    let style = {};
    // this.setState({ screenWidth: window.screen.width });
    if (mailCampaignUserCheck) {
      if (window.screen.width > 992) {
        style = {
          width: "48%",
          paddingInline: "15px",
        };
      }

      if (window.screen.width < 993) {
        style = {
          maxWidth: "100%",
          paddingInline: "15px",
        };
      }
      return style;
    } else {
      if (window.screen.width < 993) {
        style = {
          maxWidth: "100%",
          paddingInline: "15px",
        };
      }
      if (window.screen.width > 992) {
        style = {
          width: "28%",
          paddingInline: "15px",
        };
      }
      return style;
    }
  };
  conditional_Render_city = (mailCampaignUserCheck) => {
    let style = {};
    if (mailCampaignUserCheck && window.screen.width > 992) {
      style = {
        width: "19%",
        paddingInline: "15px",
      };
      return style;
    }
    if (mailCampaignUserCheck && window.screen.width < 993) {
      style = {
        maxWidth: "100%",
        paddingInline: "15px",
      };
      return style;
    }

    if (!mailCampaignUserCheck && window.screen.width < 993) {
      style = {
        maxWidth: "100%",
        paddingInline: "15px",
      };
      return style;
    }
    if (!mailCampaignUserCheck && window.screen.width > 993) {
      style = {
        width: "14%",
        paddingInline: "15px",
      };
      return style;
    }
  };
  conditional_Render_suta = (mailCampaignUserCheck) => {
    let style = {};
    if (!mailCampaignUserCheck && window.screen.width < 993) {
      style = {
        maxWidth: "100%",
        paddingInline: "15px",
      };
      return style;
    }
    if (!mailCampaignUserCheck && window.screen.width > 993) {
      style = {
        width: "12.5%",
        paddingInline: "15px",
      };
      return style;
    }
  };

  conditional_Render_state_zip = (value) => {
    let style = {};
    if (window.screen.width < 993) {
      style = {
        maxWidth: "100%",
        paddingInline: "15px",
      };

      return style;
    }
    if (value === "zip" && window.screen.width > 993) {
      style = {
        width: "10%",
        paddingInline: "15px",
      };
      return style;
    }
    if (value === "state" && window.screen.width > 993) {
      style = {
        width: "11%",
        paddingInline: "15px",
      };
      return style;
    }
  };

  render() {
    const {
      setStateAndCity,
      updateLocationInfo,
      removeLocation,
      removeClassCode,
      updateClassCodesInfo,
      resetClassCodeDescriptionData,
      index,
      updateLocationError,
      locationCount,
      isLoggedIn,
    } = this.props;

    let isSalespersonMailCampaign = sessionStorage.getItem(
      "salespersonMailCampaign"
    )
      ? true
      : false;
    let showAllFields = sessionStorage.getItem("showAllFields") ? true : false;
    let mailCampaignUserCheck =
      checkMailCampaignUser() && !isSalespersonMailCampaign;

    const { locationInfo } = this.state;
    const number = locationInfo ? locationInfo.number : null;

    return (
      <div id={"officeLoc" + number} className="mb-2">
        {/* <div className="row mb-3">
          <h6 className="col-sm-12">Loc {index} </h6>
        </div> */}
        {/*<div className={`row grey  ${ismobile ? " " : "align-items-end"}`}>
          <div
            className="d-flex justify-content-center align-items-center locationbreakcss"
            style={{ width: "8%", paddingInline: "15px" }}
          >
            <p>{`Loc ${index}`} </p>
          </div>
          <div
            style={this.conditional_Render_street(
              mailCampaignUserCheck ||
                !isLoggedIn ||
                !(this.state.PreUWLoginCheck || showAllFields)
            )}
            id="street"
          >
            <Form.Group>
              <label
                for={"street-" + number}
                className="mandatory"
                title="Street Address"
              >
                <b className="font-family-montserrat-semi-bold">
                  Street Address
                </b>
              </label>
              <input
                id={"street-" + number}
                name="street"
                type="text"
                onChange={(event) =>
                  updateLocationInfo(event.target.value, number, "street")
                }
                value={
                  locationInfo && locationInfo.street
                    ? locationInfo.street.value
                    : locationInfo && locationInfo.locationAddress
                    ? locationInfo.locationAddress.value
                    : ""
                }
                style={
                  locationInfo &&
                  locationInfo.street &&
                  locationInfo.street.error
                    ? { borderColor: "red" }
                    : {}
                }
                placeholder="Street Address"
                autoComplete="none"
              />
              <p className="errMsg addressErrs" />
            </Form.Group>
          </div>
          <div style={this.conditional_Render_state_zip("zip")} id="zip">
            <Form.Group>
              <label
                for={"zipCode-" + number}
                className="mandatory"
                title="Zip Code"
              >
                <b className="font-family-montserrat-semi-bold">Zip</b>
              </label>
              <NumberFormat
                name="zip"
                id={"zipCode" + number}
                format="#####"
                type="text"
                onChange={(event) => {
                  updateLocationInfo(event.target.value, number, "zipCode");
                  resetClassCodeDescriptionData(number);
                }}
                value={
                  locationInfo &&
                  locationInfo.zipCode &&
                  locationInfo.zipCode.value
                    ? locationInfo.zipCode.value
                    : ""
                }
                style={
                  locationInfo &&
                  locationInfo.zipCode &&
                  locationInfo.zipCode.error
                    ? { borderColor: "red" }
                    : {}
                }
                onBlur={(event) => setStateAndCity(event.target.value, number)}
                placeholder="Zip"
                autoComplete="none"
              />
              <p className="errMsg addressErrs" />
            </Form.Group>
          </div>
          <div
            style={this.conditional_Render_city(
              mailCampaignUserCheck || !isLoggedIn
            )}
            id="city"
          >
            <Form.Group>
              <label
                for={"cityName-" + number}
                className="mandatory"
                title="City"
              >
                <b className="font-family-montserrat-semi-bold">City</b>
              </label>
              <input
                id={"cityName-" + number}
                name="cityName"
                type="text"
                onChange={(event) =>
                  updateLocationInfo(event.target.value, number, "cityName")
                }
                value={
                  locationInfo &&
                  locationInfo.cityName &&
                  locationInfo.cityName.value
                    ? locationInfo.cityName.value
                    : ""
                }
                style={
                  locationInfo &&
                  locationInfo.cityName &&
                  locationInfo.cityName.error
                    ? { borderColor: "red" }
                    : {}
                }
                placeholder="City Name"
                autoComplete="none"
              />
              <p className="errMsg addressErrs" />
            </Form.Group>
          </div>
          <div style={this.conditional_Render_state_zip("state")} id="state">
            <Form.Group>
              <label className="mandatory" title="State">
                <b className="font-family-montserrat-semi-bold">State</b>
              </label>
              <StateList
                state={
                  locationInfo && locationInfo.state && locationInfo.state.value
                    ? locationInfo.state.value
                    : null
                }
                addressBlockNo={number}
                locationInfo={locationInfo}
                updateLocationInfo={updateLocationInfo}
                resetClassCodeDescriptionData={resetClassCodeDescriptionData}
              />
              <p className="errMsg addressErrs" />
            </Form.Group>
              </div> */}

        {/*!mailCampaignUserCheck &&
            isLoggedIn &&
            (this.state.PreUWLoginCheck || showAllFields) && (
              <div
                style={this.conditional_Render_suta(mailCampaignUserCheck)}
                id="clientsuta"
              >
                <Form.Group>
                  <label
                    for={"clientsuta-" + number}
                    title="Client SUTA %"
                    style={{ display: "flex", marginTop: "-1.5rem" }}
                  >
                    <b className="font-family-montserrat-semi-bold">
                      Client SUTA%
                    </b>
                  </label>
                  <NumberFormat
                    id={"clientsuta-" + number}
                    name="clientsuta"
                    type="text"
                    disabled={
                      locationInfo &&
                      locationInfo.clientsuta &&
                      locationInfo.clientsuta.disabled
                        ? true
                        : false
                    }
                    fixedDecimalScale={true}
                    decimalScale={2}
                    onChange={(event) =>
                      updateLocationInfo(
                        event.target.value,
                        number,
                        "clientsuta"
                      )
                    }
                    value={
                      locationInfo && locationInfo.clientsuta
                        ? locationInfo.clientsuta.value
                        : ""
                    }
                    style={
                      locationInfo &&
                      locationInfo.clientsuta &&
                      locationInfo.clientsuta.error
                        ? { borderColor: "red", textAlign: "center" }
                        : locationInfo &&
                          locationInfo.clientsuta &&
                          locationInfo.clientsuta.disabled
                        ? { backgroundColor: "#c2c4c5", textAlign: "center" }
                        : { textAlign: "center" }
                    }
                    placeholder="SUTA %"
                    suffix="%"
                    autoComplete="none"
                  />
                  <p className="errMsg addressErrs" />
                </Form.Group>
              </div>
                  ) */}
        {/*!mailCampaignUserCheck &&
            isLoggedIn &&
            (this.state.PreUWLoginCheck || showAllFields) && (
              <div
                style={this.conditional_Render_suta(mailCampaignUserCheck)}
                id="requestedsuta"
              >
                <Form.Group>
                  <label
                    for={"suta-" + number}
                    title="Requested SUTA %"
                    style={{ display: "flex", marginTop: "-1.5rem" }}
                  >
                    <b className="font-family-montserrat-semi-bold">
                      Requested SUTA%
                    </b>
                  </label>
                  <NumberFormat
                    id={"suta-" + number}
                    name="suta"
                    type="text"
                    disabled={
                      locationInfo &&
                      locationInfo.suta &&
                      locationInfo.suta.disabled
                        ? true
                        : false
                    }
                    fixedDecimalScale={true}
                    decimalScale={2}
                    onChange={(event) =>
                      updateLocationInfo(event.target.value, number, "suta")
                    }
                    value={
                      locationInfo && locationInfo.suta
                        ? locationInfo.suta.value
                        : ""
                    }
                    style={
                      locationInfo &&
                      locationInfo.suta &&
                      locationInfo.suta.error
                        ? { borderColor: "red", textAlign: "center" }
                        : locationInfo &&
                          locationInfo.suta &&
                          locationInfo.suta.disabled
                        ? { backgroundColor: "#c2c4c5", textAlign: "center" }
                        : { textAlign: "center" }
                    }
                    placeholder="SUTA %"
                    suffix="%"
                    autoComplete="none"
                  />
                  <p className="errMsg addressErrs" />
                </Form.Group>
              </div>
                  )
          {locationCount > 1 && (
            <div
              className="address-delete"
              style={{
                width: "4%",
                display: "flex",
                alignItems: "center",
                marginTop: "-1.2rem",
              }}
            >
              <button
                type="button"
                onClick={(event) => {
                  removeLocation(number);
                }}
                className="rmvLoc btnInvisible transpButton"
              >
                <img
                  onClick={(event) => deleteAddress(number)}
                  src={require("../../../images/delete4.png")}
                  alt=""
                />
              </button>
            </div>
          )}
              </div>*/}

        <div className={`row grey  ${ismobile ? "align-items" : "align-items-end"}`}>
          <Form.Group className="col-1"></Form.Group>
          <Form.Group className="col-3">
            <label title="State">
              <b className="font-family-montserrat-semi-bold">State</b>
            </label>
            <input
              className="font-family-montserrat-semi-bold"
              value={"FL"}
              style={{
                width: "60px",
                textAlign: "center",
                border: "1px solid black",
              }}
            />
          </Form.Group>
        </div>
        <div className="">
          {false &&
            locationInfo &&
            locationInfo.state.value &&
            locationInfo.classCodesInfo && (
              <div className="row mb-3">
                <h6 className="col-sm-12">
                  <b>Class Code Information</b>
                </h6>
              </div>
            )}
          {locationInfo &&
            locationInfo.classCodesInfo &&
            Object.keys(locationInfo.classCodesInfo).map((e) => {
              return (
                <Newcode
                  key={e}
                  state={locationInfo.state.value}
                  addressChildNo={e}
                  addressBlockNo={number}
                  removeClassCode={removeClassCode}
                  classCodesInfo={locationInfo.classCodesInfo}
                  updateLocationError={updateLocationError}
                  updateClassCodesInfo={updateClassCodesInfo}
                  handleMinMax={this.handleMinMax}
                  isLoggedIn={isLoggedIn}
                />
              );
            })}
          {locationInfo && locationInfo.classCodesInfo && (
            <div className="row">
              <div style={{ width: "6%", paddingInline: "15px" }}></div>
              <div className="calculator">
                <div>
                  <button
                    type="button"
                    className="btnInvisible addLoc transpButton p-0"
                    onClick={() =>
                      this.addClassCode(
                        number,
                        locationInfo.classCodesInfo,
                        updateLocationInfo
                      )
                    }
                  >
                    <img src={require("../../../images/plus4.png")} />{" "}
                    <b>Add class code</b>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CompLoc;
