import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import { Auth } from "aws-amplify";
import { isDeepEquals } from "../../utils/isDeepEquals";
import style from "./DealClosedLostButtons.module.css";
import { awsUrl } from "../../config";

function ConfirmDealButton(props) {
  const [isLoading, setIsLoading] = useState(false);

  let handleButtonClicked = async () => {
    props.updatePopUpData({
      show: true,
      title: "Do you wish to confirm this deal?",
      children: (
        <>
          <br></br>
          <span style={{ fontWeight: "bold" }}>
            Once confirmed, cannot be reverted.
          </span>
        </>
      ),
      acceptBtn: "Yes",
      acceptBtnCallback: async () => {
        try {
          let api_body = {
            salesId: props?.rowData?.recordDetails?.salesId || "",
            domain: props?.rowData?.recordDetails?.domain || "",
            userId: props?.rowData?.recordDetails?.userId || "",
            isMailCmpn: props?.rowData?.recordDetails.isMailCmpn || false,
            uuidList: props?.rowData?.recordDetails.uuidList || [],
            confirmDeal: "true",
          };

          setIsLoading(true);

          //api call here
          axios
            .post(awsUrl + "/api/setDealConfirmed", JSON.stringify(api_body))
            .then((res) => {
              // console.log("response from API: ", res);
              if (props?.updateConfirmDealMap) {
                props.updateConfirmDealMap(
                  api_body.salesId || api_body.domain,
                  api_body.confirmDeal
                );
              }
              if (props?.handleDealConfirm) {
                let dealFlag =
                  props.isClosed === true
                    ? "closed"
                    : props.isLost === true
                    ? "lost"
                    : "";
                props.handleDealConfirm(
                  props.tabName,
                  props.rowData.tableData.id,
                  dealFlag,
                  props.rowData
                );
              }
              setIsLoading(false);
            })
            .catch((err) => {
              console.log("error in API: ", err);
            });
        } catch (error) {
          console.log("error: ", error);
        }
      },
    });
  };

  return (
    <LoadingOverlay active={isLoading}>
      <div className={style.deal_closed_lost_container}>
        {(props.isClosed || props.isLost) && !props.disabled ? (
          <button
            className="btnFormFinal button_color"
            onClick={(e) => handleButtonClicked()}
          >
            Confirm
          </button>
        ) : (
          <button className="btnFormFinal" disabled>
            Confirm
          </button>
        )}
      </div>
    </LoadingOverlay>
  );
}

export default ConfirmDealButton;
