import React, { useEffect, useState, useRef } from "react";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTableContainer from "../../common/MaterialTableContainer/MaterialTableContainer";
import parse from "html-react-parser";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../../config";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Auth } from "aws-amplify";
import Header from "../../common/Header";
import EngagedTabMUI from "../../common/MaterialTableContainer/EngagedTabMUI";

const {
  DownloadDashboardFiles,
} = require("../../subcompo/DownloadDashboardFiles.jsx");

const dateSort = (a, b, field) => {
  let momentA = moment(a[field], "M/D/YYYY - hh:mm A");
  let momentB = moment(b[field], "M/D/YYYY - hh:mm A");
  if ((!momentA.isValid() && momentB.isValid()) || momentA.isBefore(momentB)) {
    return -1;
  } else if (
    (momentA.isValid() && !momentB.isValid()) ||
    momentA.isAfter(momentB)
  ) {
    return 1;
  } else if (
    (!momentA.isValid() && !momentB.isValid()) ||
    momentA.isSame(momentB)
  ) {
    return 0;
  }
};

const camelCase = (str) => {
  return str
    .trim()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

const appendRemaining = (str) => {
  let len = str.length;
  for (let i = 1; i <= 10 - len; i++) {
    str = "0" + str;
  }
  return str;
};

const updateDiscountData = (rowData) => {
  let requestBody = {
    user_email_id: rowData.userEmail,
    uuid_carrier: rowData.uuid_carrier,
    discountData: rowData.discountData,
  };
  axios
    .post(awsUrl2 + "/api/updateUserStatusTable", requestBody)
    .then((res) => { })
    .catch((err) => console.log("error: ", err));
};

const formatFein = (value) => {
  let fein;
  value = value.split("-").join("");
  var len = value.length,
    dummystr = "000000000";

  if (len === 0) {
    fein = "-";
  } else if (len > 0 && len < 9) {
    value = dummystr.slice(0, 9 - len) + "" + value;
    fein = value[0] + value[1] + "-" + value.slice(2);
  } else {
    fein = value[0] + value[1] + "-" + value.slice(2);
  }

  return fein;
};

const onClickCompanyName = (row) => {
  try {
    sessionStorage.clear();
    sessionStorage.setItem("preUnderwriter", "true");

    if (row.disableContinue) sessionStorage.setItem("disableContinue", "true");
    if (row.disableGetQuote) sessionStorage.setItem("disableGetQuote", "true");

    if (row.disableContinue || row.disableGetQuote) {
      sessionStorage.setItem("formStage", "two");
    } else {
      if (row.formStage) {
        sessionStorage.setItem("formStage", row.formStage);
      }
    }

    if (row.dealLostOrClosed) {
      sessionStorage.setItem("disableGetQuote", "true");
    }

    if (row.isEngaged) {
      sessionStorage.setItem("preUwEngagedFlag", "true");
    }

    if (row.clientEligibility) {
      sessionStorage.setItem("clientEligibility", row.clientEligibility);
    }

    if (row.preUwFiles) {
      sessionStorage.setItem("preUwFiles", JSON.stringify(row.preUwFiles));
    }

    if (row.currProspectDetails) {
      let {
        companyProfile,
        childrenLoc,
        emodStatesData,
      } = row.currProspectDetails;

      let zywaveCompanyProfile = row.zywaveCompanyProfile;

      let campaignMap;

      for (let loc in childrenLoc) {
        if (childrenLoc[loc].classCodesInfo) {
          for (let cc in childrenLoc[loc].classCodesInfo) {
            if (
              !childrenLoc[loc].classCodesInfo[cc].ft ||
              (childrenLoc[loc].classCodesInfo[cc].ft &&
                !Number(childrenLoc[loc].classCodesInfo[cc].ft.value))
            ) {
              childrenLoc[loc].classCodesInfo[cc] = {
                ...childrenLoc[loc].classCodesInfo[cc],
                ft: {
                  value:
                    zywaveCompanyProfile.employees &&
                      zywaveCompanyProfile.employees.value
                      ? zywaveCompanyProfile.employees.value
                      : 0,
                },
              };
            }
          }
        }
      }

      let compName;

      if (
        companyProfile &&
        companyProfile.company_name &&
        companyProfile.company_name.value
      ) {
        compName = companyProfile.company_name.value;
      }

      if (companyProfile.state.value) {
        companyProfile.state1 = {
          value: companyProfile.state1?.value || companyProfile.state.value,
        };
        companyProfile.state2 = {
          value: companyProfile.state2?.value || companyProfile.state.value,
        };
      }
      if (companyProfile.zip.value) {
        companyProfile.zipCode1 = {
          value: companyProfile.zipCode1?.value || companyProfile.zip.value,
        };
        companyProfile.zipCode2 = {
          value: companyProfile.zipCode2?.value || companyProfile.zip.value,
        };
      }
      if (companyProfile.city.value) {
        companyProfile.cityName1 = {
          value: companyProfile.cityName1?.value || companyProfile.city.value,
        };
        companyProfile.cityName2 = {
          value: companyProfile.cityName2?.value || companyProfile.city.value,
        };
      }
      if (companyProfile.address.value) {
        companyProfile.street1 = {
          value: companyProfile.street1?.value || companyProfile.address.value,
        };
        companyProfile.street2 = {
          value: companyProfile.street2?.value || companyProfile.address.value,
        };
      }

      if (compName) {
        companyProfile = {
          ...companyProfile,
          companyName: {
            value: compName,
          },
          company_name: {
            value: compName,
          },
        };
      }

      companyProfile = {
        ...companyProfile,
      };

      if (
        companyProfile &&
        (!companyProfile.companyName || !companyProfile.companyName.value) &&
        companyProfile.company_name &&
        companyProfile.company_name.value
      ) {
        companyProfile.companyName = companyProfile.company_name;
      }

      if (
        companyProfile &&
        (!companyProfile.phoneNumber || !companyProfile.phoneNumber.value) &&
        companyProfile.contact_number &&
        companyProfile.contact_number.value
      ) {
        companyProfile.phoneNumber = companyProfile.contact_number;
      }

      if (
        companyProfile &&
        companyProfile.contact_name &&
        companyProfile.contact_name.value
      ) {
        let nameList = companyProfile.contact_name.value.split(" ");
        let lastName = nameList.pop();
        let firstName = nameList.join(" ");
        if (lastName) {
          companyProfile.lastName = {
            value: lastName,
          };
        }
        if (firstName) {
          companyProfile.firstName = {
            value: firstName,
          };
        }
      }

      if (
        companyProfile &&
        (!companyProfile.descriptionOfOperations ||
          !companyProfile.descriptionOfOperations.value) &&
        companyProfile.description_of_operations &&
        companyProfile.description_of_operations.value
      ) {
        companyProfile.descriptionOfOperations =
          companyProfile.description_of_operations;
      }

      if (companyProfile && companyProfile.fein && companyProfile.fein.value) {
        let value = companyProfile.fein.value + "";
        value = value.split("-").join("");
        var len = value.length,
          dummystr = "000000000";

        if (len > 0 && len < 9) {
          value = dummystr.slice(0, 9 - len) + "" + value;
          companyProfile.fein.value =
            value[0] + value[1] + "-" + value.slice(2);
          companyProfile.fein.errMsg = "";
        } else if (len > 9) {
          companyProfile.fein.errMsg = "FEIN needs to be 9 digits";
          companyProfile.fein.error = true;
        } else if (len === 0) {
          companyProfile.fein.value = undefined;
          companyProfile.fein.errMsg = "";
        } else {
          companyProfile.fein.value =
            value[0] + value[1] + "-" + value.slice(2);
          companyProfile.fein.errMsg = "";
        }
      }

      if (
        companyProfile &&
        (!companyProfile.effectiveDate ||
          (companyProfile.effectiveDate && !companyProfile.effectiveDate.value))
      ) {
        companyProfile = {
          ...companyProfile,
          effectiveDate: {
            value:
              moment().date() === 1
                ? moment()
                : moment(moment().subtract(moment().date() - 1, "days")).add(
                  1,
                  "month"
                ),
          },
        };
      }

      if (
        companyProfile &&
        (!companyProfile.effectiveDate ||
          (companyProfile.effectiveDate &&
            !companyProfile.effectiveDate.value))
      ) {
        companyProfile = {
          ...companyProfile,
          effectiveDate: {
            value:
              moment().date() === 1
                ? moment()
                : moment(moment().subtract(moment().date() - 1, "days")).add(
                  1,
                  "month"
                ),
          },
        };
      }

      let currProspect = {
        childrenLoc,
        companyProfile,
        emodStatesData,
      };

      if (row.uwAmtrustQuesList) {
        currProspect.amtrustQuesList = row.uwAmtrustQuesList;
      }

      if (row.uwQuesList) {
        currProspect.uwQues = row.uwQuesList;
      }

      if (row.uwExpectedResponses) {
        currProspect.uwExpectedResponses = row.uwExpectedResponses;
      }

      if (!("healthQues" in companyProfile)) {
        companyProfile.healthQues = { value: true };
      }

      if (row.preUwStatus) {
        currProspect.preUwStatus = row.preUwStatus;
      }

      sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
    }
    sessionStorage.setItem("domain", row.domain);
    if (row.salesID) {
      sessionStorage.setItem("salesID", row.salesID);
    }

    if (row.address) {
      sessionStorage.setItem("address", JSON.stringify(row.address));
    }
    if (row.quoteData) {
      sessionStorage.setItem("quoteData", JSON.stringify(row.quoteData));
    }
    if (
      row.pre_underwriter_status === "uw" ||
      row.pre_underwriter_status === "completed"
    ) {
      sessionStorage.setItem("formStage", "two");
    }
  } catch (error) {
    console.log("++error", error);
  }
};

const formatInCamel = (str) => {
  if (str) {
    return str[0].toUpperCase() + "" + str.slice(1);
  }
  return str;
};

const source = (Source) => {
  if (Source == "hotschedules") {
    return "HotSchedules";
  } else if (Source == "affinity") {
    return "Affinity";
  } else {
    return Source;
  }
};
const tabsMap = {
  tab1: "Engaged",
  tab2: "Campaigned",
};

const getUploadDateFlag = async () => {
  const currentAuthUser = await Auth.currentAuthenticatedUser();
  if (currentAuthUser) {
    let groups =
      currentAuthUser?.signInUserSession?.accessToken?.payload[
      "cognito:groups"
      ];
    if (groups.includes(process.env.REACT_APP_DATA_TEAM)) return true;
    else return false;
  }
};
function PreUnderWriterDashboard() {
  const [domain_messageid_files, setDomain_messageid_files] = useState({});
  const [rowDataList, setRowDataList] = useState([]);
  const [engagedRowDataList, setEngagedRowDataList] = useState([]);
  const [
    engagedDomain_messageid_file,
    setEngagedDomain_messageid_files,
  ] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCampaigned, setIsLoadingCampaigned] = useState(true);
  const [selected, setSelected] = useState("tab2");
  const [uploadDateFlag, setUploadDateFlag] = useState(false);

  useEffect(() => {
    const PreUwCampaignedTab = () => {
      let userDataList = [];

      try {
        axios
          .post(awsUrl2 + "/api/preUwData/getAllRows", {})

          .then((res) => {
            let tempObj;
            let rowData;

            let rowDataList = [];
            let domain_messageid_files = {};

            let dataFromApi = res.data;
            // console.log(dataFromApi, "+===============");

            for (let key in dataFromApi) {
              tempObj = {};
              for (let dataKey in dataFromApi[key]) {
                tempObj[dataKey] = dataFromApi[key][dataKey];
              }
              userDataList.push(tempObj);
            }

            for (let userData of userDataList) {
              // console.log("userData: ", userData);
              let quote_status;
              let step;
              let excluded_status;

              let detailPanelData = {};

              detailPanelData.header = [
                "Contact Name",
                "Phone Number",
                "Email",
                "Job Description",
                "Visit Count",
                "Status",
              ];
              detailPanelData.data = [];

              let email_specific_status;
              let landing_email_status, landing_email_get_quote_clicked;

              let emailCount = 0;
              let statusPriorityMap = {
                Submitted: 1,
                "User Submitted": 2,
                "Quote Generated": 3,
                "Uploaded Document": 4,
                "UW Questions": 5,
                MQL: 6,
                SQL: 7,
                "Company Profile(Get Quote)": 8,
                "Company Profile": 9,
                "Landing Page": 10,
                "Visited Website": 11,
                "Affinity Logo Clicked": 12,
                "Fourth Logo Clicked": 13,
                "Clicked on Affinity logo": 14,
                "Clicked on Fourth logo": 15,
                "Clicked on the image": 16,
                "Clicked on insure comp logo": 17,
                "Watched Video": 18,
                "Visited LinkedIn": 19,
                "Visited Facebook": 20,
                "Visited Instagram": 21,
                "Accessed Social Media": 22,
                "Opened Email": 23,
                "Not Campaigned": 24,
                "-": 999,
              };

              for (let email in userData.email_specific) {
                emailCount++;
                let element = userData.email_specific[email];
                let db_quote_status =
                  userData.email_specific[email].quote_status;
                let db_excluded =
                  userData.email_specific[email].nonEligibilityList;
                if (db_excluded && db_excluded.length > 0) {
                  excluded_status = true;
                }

                if (
                  userData.email_specific[email].companyProfile &&
                  userData.email_specific[email].companyProfile.status &&
                  userData.email_specific[email].companyProfile.status.value
                ) {
                  let emailStatus =
                    userData.email_specific[email].companyProfile.status.value;
                  if (!email_specific_status)
                    email_specific_status = emailStatus;
                  else if (
                    statusPriorityMap[emailStatus] <
                    statusPriorityMap[email_specific_status]
                  ) {
                    email_specific_status = emailStatus;
                  }
                }
                if (!landing_email_status) {
                  landing_email_status = userData.email_specific[email]
                    .landingPage
                    ? userData.email_specific[email].landingPage
                    : null;
                }

                if (!landing_email_get_quote_clicked) {
                  landing_email_get_quote_clicked = userData.email_specific[
                    email
                  ].homeGetQuote
                    ? userData.email_specific[email].homeGetQuote
                    : null;
                }

                if (
                  (!quote_status && db_quote_status) ||
                  (quote_status === "reject" && db_quote_status) ||
                  db_quote_status === "proceed_uw"
                ) {
                  quote_status = db_quote_status;
                }

                //-=-=-=-=-=-=-=-=-=-=
                let step;
                if (element.companyProfile) {
                  if (Number(element.proposalGeneratedCount) > 0) {
                    step = "Proposal Generated";
                  } else if (element.quote_status) {
                    if (element.quote_status === "uwportal") {
                      step = "UW Portal";
                    } else if (element.quote_status === "proceed_uw") {
                      step = "Approved";
                    } else if (element.quote_status === "reject") {
                      step = "Rejected";
                    }
                  } else if (Number(element.quoteGeneratedCount) > 0) {
                    step = "Quote Generated";
                  } else if (element.formStage) {
                    if (element.formStage === "two") {
                      if (element.nonEligibilityList) {
                        step = "Not Eligible";
                      } else {
                        step = "UW Questions";
                      }
                    } else if (element.formStage === "one") {
                      step = "Company Profile";
                      if (landing_email_get_quote_clicked) {
                        step += " (Get Quote)";
                      }
                    }
                  } else if (
                    element.visitCount &&
                    Number(element.visitCount) > 0
                  ) {
                    step = "Landing Page";
                  } else if (landing_email_status) {
                    step =
                      landing_email_status === "affinityLogo"
                        ? "Affinity Logo Clicked"
                        : "Fourth Logo Clicked";
                  } else {
                    step = "-";
                  }
                  if (
                    element.companyProfile.status &&
                    element.companyProfile.status.value
                  ) {
                    step =
                      statusPriorityMap[
                        element.companyProfile.status
                          .value
                      ] < statusPriorityMap[step]
                        ? element.companyProfile.status
                          .value
                        : step;
                  }
                  detailPanelData.data.push({
                    contactName:
                      element.companyProfile &&
                        element.companyProfile.contact_name
                        ? element.companyProfile.contact_name.value
                        : "",
                    contactNumber:
                      element?.companyProfile?.phoneNumber?.value?.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "($1) $2-$3"
                      ) ||
                      element?.companyProfile?.contact_number?.value?.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "($1) $2-$3"
                      ) ||
                      "",
                    email: element?.companyProfile?.emailId?.value || "",
                    jobTitle: element?.companyProfile?.job_title?.value || "",
                    count: element.visitCount || 0,
                    step: step || "-",
                  });
                }

                //-=-=-=-=-=-=-=--=-=-
              }

              if (Number(userData.proposalGeneratedCount) > 0) {
                step = "Proposal Generated";
              } else if (quote_status) {
                if (quote_status === "uwportal") {
                  step = "UW Portal";
                } else if (quote_status === "proceed_uw") {
                  step = "Approved";
                } else if (quote_status === "reject") {
                  step = "Rejected";
                }
              } else if (Number(userData.quoteGeneratedCount) > 0) {
                step = "Quote Generated";
              } else if (userData.formStage) {
                if (userData.formStage === "one") {
                  step = "Company Profile";
                } else {
                  if (excluded_status) {
                    step = "Not Eligible";
                  } else {
                    step = "UW Questions";
                  }
                }
              } else if (
                userData.visitCount &&
                (Number(userData.visitCount) > 0)
              ) {
                step = "Landing Page";
              } else {
                step = "-";
              }
              if (email_specific_status) {
                step =
                  statusPriorityMap[email_specific_status] <
                    statusPriorityMap[step]
                    ? email_specific_status
                    : step;
              }

              let currProspectDetails = {
                companyProfile: userData.companyProfile,
                childrenLoc: userData.payrollData,
                emodStatesData: userData.emodStatesData,
              };

              // if (
              //   (!currProspectDetails.companyProfile &&
              //     !currProspectDetails.companyProfile.fein &&
              //     !currProspectDetails.companyProfile.fein.value) ||
              //   userData?.fein
              // ) {
              //   currProspectDetails.companyProfile.fein = {
              //     value: userData.fein,
              //   };
              // }

              if (
                !currProspectDetails?.companyProfile?.fein &&
                !currProspectDetails?.companyProfile?.fein?.value
              ) {
                if (userData?.fein) {
                  currProspectDetails.companyProfile.fein = {
                    value: userData.fein,
                  };
                }
              }

              rowData = {
                visitDate:
                  userData.timestamp &&
                  moment(Number(userData.timestamp))
                    .utcOffset("-0500")
                    .format("M/D/YYYY - hh:mm A"),
                userEmail: userData.email,
                quoteGeneratedCount: userData.quoteGeneratedCount || 0,
                proposalGeneratedCount: userData.proposalGeneratedCount || 0,
                uuid: userData.uuid,
                phoneNumber: userData.phoneNumber,
                payroll: userData.payroll,
                indicationCost: userData.indicationCost,
                emod:
                  userData.emod && Number(userData.emod)
                    ? parseFloat(userData.emod).toFixed(2)
                    : "-",
                uwEmail: userData.uwEmail,
                effectiveDate: userData.effectiveDate
                  ? moment(userData.effectiveDate).format("x")
                  : "",
                contactName: userData.contactName || "",
                companyName: userData.companyName || "",
                disableCompanyName: emailCount ? false : true,
                comment: userData.comment || "",
                domain: userData.domain,
                visitCount: userData.visitCount || 0,
                step,
                latestCampaign: userData.latestCampaign,
                email_specific: userData.email_specific,
                messageID: userData.messageID,
                dashboardFiles: userData.dashboardFiles,
                uploadedDate: userData.uploadedDate,
                uploadedTimeStamp: userData.uploadedTimeStamp
                  ? moment(userData.uploadedTimeStamp, "x").format("M/D/YYYY")
                  : userData.zywave_uploaded_date
                    ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                      "M/D/YYYY"
                    )
                    : "-",
                currProspectDetails,
                salesID: userData.domain,
                formStage: userData.uwFormStage,
                address: userData.address,
                quoteData: userData.quoteData,
                state: userData?.state || "-",
                total_emp: userData?.total_emp || 0,
                salespersonName:
                  userData.salespersonNameAttr || userData.salespersonName,
                uwExpectedResponses: userData.uwExpectedResponses,
                quesList: userData.quesList,
                amtrustQuesList: userData.amtrustQuesList,
                uwQuesList: userData.uwQuesList,
                uwAmtrustQuesList: userData.uwAmtrustQuesList,
                preUwStatus: userData.preUwStatus || "approved",
                preUwName: userData.preUwName,
                isPreUnderwritten: userData.preUwStatus ? true : false,
                clientEligibility: userData.clientEligibility,
                zywaveCompanyProfile: userData.zywaveCompanyProfile,
                website: userData.companyProfile.website.value,
                fein: userData.fein,
                preUwFiles: userData.preUwFiles,
                source: userData.source,
                isEngaged: false,
                dealLostOrClosed:
                  userData?.dealData?.status === "true" ||
                    userData?.dealData?.status === "false"
                    ? true
                    : false,
              };

              if (rowData.email_specific) {
                Object.values(rowData.email_specific).map((email) => {
                  if (
                    email.companyProfile &&
                    email.companyProfile.contact_number &&
                    email.companyProfile.contact_number.value
                  ) {
                    email.companyProfile.contact_number.value = appendRemaining(
                      email.companyProfile.contact_number.value.toString()
                    );
                  }
                });
              } else {
                if (rowData.phoneNumber)
                  rowData.phoneNumber = appendRemaining(
                    rowData.phoneNumber.toString()
                  );
              }

              if (!rowData.email_specific) {
                detailPanelData.data.push({
                  contactName: rowData.contactName,
                  contactNumber: rowData.phoneNumber
                    ? rowData.phoneNumber.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "($1) $2-$3"
                    )
                    : "-",
                  email: rowData.userEmail,
                  jobTitle: "",
                  count: rowData.visitCount || 0,
                  step: step || "-",
                });
              }

              rowData.detailPanelData = detailPanelData;

              rowDataList.push(rowData);
              domain_messageid_files[rowData.domain] = {};
              if (rowData.messageID)
                domain_messageid_files[rowData.domain]["messageID"] =
                  rowData.messageID;
              if (rowData.dashboardFiles)
                domain_messageid_files[rowData.domain]["dashboardFiles"] =
                  rowData.dashboardFiles;
            }

            setRowDataList(rowDataList);
            setDomain_messageid_files(domain_messageid_files);
          })
          .catch((error) => {
            setIsLoadingCampaigned(false);
            console.log("error", error);
          })
          .finally(() => {
            rowDataList && setIsLoadingCampaigned(false);
          });
      } catch (error) {
        console.log("error in update data: ", error);
      }
    };

    const PreUwEngagedTab = () => {
      let requestBody = {
        tab: "tab1",
      };
      axios
        .post(awsUrl2 + "/api/getDashboardTableData", requestBody)
        .then((res) => {
          let userDataList = [];
          let tempObj;
          let rowData;
          // let rowDataList = {
          //   PEO: {
          //     Affinity: [],
          //     Hotschedules: [],
          //   },
          //   PAYGO: {
          //     Affinity: [],
          //     Hotschedules: [],
          //   },
          // };

          let rowDataList = [];
          let domain_messageid_files = {};

          let dataFromApi = res.data.data;
          // console.log(dataFromApi, "frontedn");
          for (let key in dataFromApi) {
            tempObj = {};
            for (let dataKey in dataFromApi[key]) {
              tempObj[dataKey] = dataFromApi[key][dataKey];
            }
            userDataList.push(tempObj);
          }

          for (let userData of userDataList) {
            let quote_status;
            let step;
            let excluded_status;

            let firstFilter = userData.onlyPaygo === "true" ? "PAYGO" : "PEO";
            let secondFilter =
              userData.client_source === "Hotschedules"
                ? "Hotschedules"
                : "Affinity";

            let email_specific_status;
            let landing_email_status, landing_email_get_quote_clicked;
            let statusPriorityMap = {
              Submitted: 1,
              "User Submitted": 2,
              "Quote Generated": 3,
              "Uploaded Document": 4,
              "UW Questions": 5,
              MQL: 6,
              SQL: 7,
              "Company Profile(Get Quote)": 8,
              "Company Profile": 9,
              "Landing Page": 10,
              "Visited Website": 11,
              "Affinity Logo Clicked": 12,
              "Fourth Logo Clicked": 13,
              "Clicked on Affinity logo": 14,
              "Clicked on Fourth logo": 15,
              "Clicked on the image": 16,
              "Clicked on insure comp logo": 17,
              "Watched Video": 18,
              "Visited LinkedIn": 19,
              "Visited Facebook": 20,
              "Visited Instagram": 21,
              "Accessed Social Media": 22,
              "Opened Email": 23,
              "Not Campaigned": 24,
              "-": 999,
            };

            for (let email in userData.email_specific) {
              let db_quote_status = userData.email_specific[email].quote_status;
              let db_excluded =
                userData.email_specific[email].nonEligibilityList;
              if (db_excluded && db_excluded.length > 0) {
                excluded_status = true;
              }

              if (
                userData.email_specific[email].companyProfile &&
                userData.email_specific[email].companyProfile.status &&
                userData.email_specific[email].companyProfile.status.value
              ) {
                let emailStatus =
                  userData.email_specific[email].companyProfile.status.value;
                if (!email_specific_status) email_specific_status = emailStatus;
                else if (
                  statusPriorityMap[emailStatus] <
                  statusPriorityMap[email_specific_status]
                ) {
                  email_specific_status = emailStatus;
                }
              }

              if (!landing_email_status) {
                landing_email_status = userData.email_specific[email]
                  .landingPage
                  ? userData.email_specific[email].landingPage
                  : null;
              }

              if (!landing_email_get_quote_clicked) {
                landing_email_get_quote_clicked = userData.email_specific[email]
                  .homeGetQuote
                  ? userData.email_specific[email].homeGetQuote
                  : null;
              }

              if (
                (!quote_status && db_quote_status) ||
                (quote_status === "reject" && db_quote_status) ||
                db_quote_status === "proceed_uw"
              ) {
                quote_status = db_quote_status;
              }
            }

            if (userData.isSalespersonSubmitted) {
              step = "Submitted";
            } else if (userData.isUserSubmitted) {
              step = "User Submitted";
            } else if (userData.isSubmitted) {
              step = "Submitted";
            } else if (Number(userData.proposalGeneratedCount) > 0) {
              step = "Proposal Generated";
            } else if (quote_status) {
              if (quote_status === "uwportal") {
                step = "UW Portal";
              } else if (quote_status === "proceed_uw") {
                step = "Approved";
              } else if (quote_status === "reject") {
                step = "Rejected";
              }
            } else if (Number(userData.quoteGeneratedCount) > 0) {
              step = "Quote Generated";
            } else if (userData.formStage) {
              if (userData.formStage === "one") {
                step = "Company Profile";
                if (landing_email_get_quote_clicked) {
                  step += " (Get Quote)";
                }
              } else {
                if (excluded_status) {
                  step = "Not Eligible";
                } else {
                  step = "UW Questions";
                }
              }
            } else if (
              userData.visitCount &&
              (Number(userData.visitCount) > 0)
            ) {
              step = "Landing Page";
            } else if (landing_email_status) {
              step =
                landing_email_status === "affinityLogo"
                  ? "Affinity Logo Clicked"
                  : "Fourth Logo Clicked";
            } else {
              step = "-";
            }
            if (email_specific_status) {
              step =
                statusPriorityMap[email_specific_status] <
                  statusPriorityMap[step]
                  ? email_specific_status
                  : step;
            }

            let currProspectDetails = {
              companyProfile: userData.companyProfile,
              childrenLoc: userData.payrollData,
              emodStatesData: userData.emodStatesData,
            };

            // let total_emp = 0;
            // if (userData.payrollData) {
            //   let childrenLoc = userData.payrollData;
            //   for (let locIndex in childrenLoc) {
            //     let loc = childrenLoc[locIndex];
            //     for (let ccIndex in loc.classCodesInfo) {
            //       let cc = loc.classCodesInfo[ccIndex];
            //       total_emp =
            //         total_emp +
            //         Number(cc?.ft?.value || 0) +
            //         Number(cc?.pt?.value || 0);
            //     }
            //   }
            // }

            if (!currProspectDetails?.companyProfile?.fein?.value) {
              if (userData?.fein) {
                currProspectDetails.companyProfile.fein = {
                  value: userData.fein,
                };
              }
            }

            rowData = {
              visitDate:
                userData.timestamp &&
                moment(Number(userData.timestamp))
                  .utcOffset("-0500")
                  .format("M/D/YYYY - hh:mm A"),
              userEmail: userData.email,
              quoteGeneratedCount: userData.quoteGeneratedCount || 0,
              proposalGeneratedCount: userData.proposalGeneratedCount || 0,
              uuid: userData.uuid,
              phoneNumber: userData.phoneNumber,
              payroll: userData.payroll,
              indicationCost: userData.indicationCost,
              emod:
                userData.emod && Number(userData.emod)
                  ? parseFloat(userData.emod).toFixed(2)
                  : "-",
              uwEmail: userData.uwEmail,
              effectiveDate: userData.effectiveDate
                ? moment(userData.effectiveDate).format("x")
                : "",
              contactName: userData.contactName || "",
              companyName: userData.companyName || "",
              comment: userData.comment || "",
              domain: userData.domain,
              visitCount: userData.visitCount || 0,
              step,
              latestCampaign: userData.latestCampaign,
              email_specific: userData.email_specific,
              messageID: userData.messageID,
              dashboardFiles: userData.dashboardFiles,
              uploadedDate: userData.uploadedDate,
              uploadedTimeStamp: userData.uploadedTimeStamp
                ? moment(userData.uploadedTimeStamp, "x").format("M/D/YYYY")
                : userData.zywave_uploaded_date
                  ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                    "M/D/YYYY"
                  )
                  : "-",
              currProspectDetails,
              salesID: userData.domain,
              formStage: userData.uwFormStage,
              address: userData.address,
              quoteData: userData.quoteData,
              state: userData?.state || "-",
              total_emp: userData?.total_emp || 0,
              salespersonName:
                userData.salespersonNameAttr || userData.salespersonName,
              uwExpectedResponses: userData.uwExpectedResponses,
              uwQuesList: userData.uwQuesList,
              uwAmtrustQuesList: userData.uwAmtrustQuesList,
              preUwStatus: userData.preUwStatus || "approved",
              preUwName: userData.preUwName,
              isPreUnderwritten: userData.preUwStatus ? true : false,
              clientEligibility: userData.clientEligibility,
              zywaveCompanyProfile: userData.zywaveCompanyProfile,
              website: userData.companyProfile.website.value,
              fein: userData.fein,
              preUwFiles: userData.preUwFiles,
              source: userData.source,
              isEngaged: true,
              isSubmitted: userData.isSubmitted,
              fileListToStore: userData.fileListToStore,
              preUwDate:
                userData.preUwDate &&
                moment(Number(userData.preUwDate))
                  .utcOffset("-0500")
                  .format("M/D/YYYY - hh:mm A"),
            };

            let enableContinueSteps = [
              "-",
              "Landing Page",
              "Company Profile",
              "Opened Email",
              "Accessed Social Media",
              "Watched Video",
              "Visited Website",
              "Company Profile",
              "UW Questions",
              "Generated Quote",
              "Uploaded Document",
              "Declined Ineligible",
              "Landed on Quote Page",
              "Visited LinkedIn",
              "Visited Facebook",
              "Visited Instagram",
              "Clicked on Affinity logo",
              "Clicked on Fourth logo",
              "Clicked on the image",
              "Clicked on insure comp logo",
              "Not Campaigned",
              "MQL",
              "SQL",
            ];

            if (!enableContinueSteps.includes(step)) {
              rowData.disableContinue = true;
              if (step !== "UW Questions") rowData.disableGetQuote = true;
            }

            if (rowData.email_specific) {
              Object.values(rowData.email_specific).map((email) => {
                if (
                  email.companyProfile &&
                  email.companyProfile.contact_number &&
                  email.companyProfile.contact_number.value
                ) {
                  email.companyProfile.contact_number.value = appendRemaining(
                    email.companyProfile.contact_number.value.toString()
                  );
                }
              });
            } else {
              if (rowData.phoneNumber)
                rowData.phoneNumber = appendRemaining(
                  rowData.phoneNumber.toString()
                );
            }

            // rowDataList[firstFilter][secondFilter].push(rowData);
            rowDataList.push(rowData);
            domain_messageid_files[rowData.domain] = {};
            if (rowData.messageID)
              domain_messageid_files[rowData.domain]["messageID"] =
                rowData.messageID;
            if (rowData.dashboardFiles)
              domain_messageid_files[rowData.domain]["dashboardFiles"] =
                rowData.dashboardFiles;
            if (rowData.preUwFiles) {
              let _preUwFiles = [];
              rowData.preUwFiles.forEach((ele) => {
                _preUwFiles.push(ele.key);
              });
              domain_messageid_files[rowData.domain][
                "preUwFiles"
              ] = _preUwFiles;
            }

            if (rowData.fileListToStore) {
              let _fileListToStore = [];
              rowData.fileListToStore.forEach((ele) => {
                _fileListToStore.push(ele.key);
              });
              domain_messageid_files[rowData.domain][
                "fileListToStore"
              ] = _fileListToStore;
            }
          }

          setEngagedRowDataList(rowDataList);
          setEngagedDomain_messageid_files(domain_messageid_files);
        })
        .catch((error) => {
          console.log("error", error);
          setIsLoading(false);
        })
        .finally(() => {
          rowDataList && setIsLoading(false);
        });
    };
    PreUwCampaignedTab();
    PreUwEngagedTab();
  }, []);

  const updateComment = (e, rowData) => {
    let index = rowData.tableData.id;
    let requestBody = {};

    requestBody.domain =
      selected == "tab2"
        ? rowDataList[index].domain
        : selected == "tab1"
          ? engagedRowDataList[index].domain
          : "";
    requestBody.comment = e.target.value;
    requestBody.tableName = selected;

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => { })
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  useEffect(() => {
    async function updateUploadFlag() {
      let flag = await getUploadDateFlag();
      setUploadDateFlag(flag);
    }
    updateUploadFlag();
  }, []);

  const customFilterfunction = (term, rowData, field) => {
    let customFieldName = field["field"];

    let excludeFirstCharacter = term.substring(1);
    let excludeFirstTwoCharacter = term.substring(2);
    let firstCharacter = term.substring(1, 0);
    let firstTwoCharacter = term.substring(2, 0);
    let operationMap = {
      ">=": ">=",
      "<=": "<=",
      "<": "<",
      ">": ">",
      "=": "===",
    };
    try {
      if (firstTwoCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstTwoCharacter]} ${excludeFirstTwoCharacter}`
        );
      } else if (firstCharacter in operationMap) {
        console.log(
          "firstcharater:",
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
      } else {
        return rowData[customFieldName]?.toString()?.startsWith(term);
      }
    } catch (err) {
      return rowData[customFieldName]?.toString()?.startsWith(term);
    }
  };

  const customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];
    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };

  let columns = [
    {
      title: "Visit Date (ET)",
      field: "visitDate",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      defaultSort: "desc",
      customSort: (a, b) => dateSort(a, b, "visitDate"),
      render: (rowData) => {
        if (rowData.visitDate) {
          let date = rowData.visitDate.split("-")[0];
          let time = rowData.visitDate.split("-")[1];
          return (
            <span>
              {date}
              <br></br>
              {time}
            </span>
          );
        } else {
          return "-";
        }
      },
      cellStyle: {
        wordBreak: "break-all",
        padding: "6px 0",
        minWidth: "100px",
      },
      headerStyle: {
        padding: "6px 0",
        minWidth: "100px",
      },
    },
    {
      title: "Company Name",
      field: "companyName",
      filterPlaceholder: "Filter",

      cellStyle: {
        padding: "6px 5px 6px 10px",
        minWidth: "230px",
        wordBreak: "break-word",
      },
      render: (rowData) => {
        return (
          <Clamp lines={2}>
            <span onClick={() => onClickCompanyName(rowData)}>
              {rowData.disableCompanyName ? (
                <div>{camelCase(rowData.companyName)}</div>
              ) : (
                <Link to={"/Quote"}>{camelCase(rowData.companyName)}</Link>
              )}
            </span>
          </Clamp>
        );
      },
    },
    {
      title: "Payroll",
      field: "payroll",
      filterPlaceholder: "Filter",
      // customSort: (a, b) => a.payroll.length - b.payroll.length,
      render: (rowData) => numeral(rowData.payroll).format("$0,0"),
      customFilterAndSearch: (term, rowData, field) =>
        customFilterfunction(term, rowData, field),
      cellStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "136px",
        wordBreak: "break-all",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "136px",
      },
    },
    {
      title: "State",
      field: "state",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      cellStyle: {
        textTransform: "uppercase",
        padding: "6px 0 6px 10px",
        minWidth: "50px",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "50px",
      },
    },
    {
      title: "# Emp",
      field: "total_emp",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterfunction(term, rowData, field),
      cellStyle: {
        padding: "6px 0",
        minWidth: "65px",
      },
      headerStyle: {
        padding: "6px 0",
        whiteSpace: "nowrap",
        minWidth: "65px",
      },
    },
    {
      title: "Status",
      field: "step",
      filterPlaceholder: "Filter",

      cellStyle: (e, rowData) => {
        let style = { padding: "6px 15px 6px 10px" };
        if (rowData.step === "Submitted") {
          style.backgroundColor = "#5ADA38";
        } else if (rowData.step === "User Submitted") {
          style.backgroundColor = "#5ADA38";
        } else if (rowData.step === "UW Portal") {
          style.backgroundColor = "#ffd737ac";
        } else if (rowData.step === "Approved") {
          style.backgroundColor = "#87FA4D";
        } else if (rowData.step === "Rejected") {
          style.backgroundColor = "#fa6d3e";
        } else if (rowData.step === "Quote Generated") {
          style.backgroundColor = "#87FA20";
        } else if (rowData.step === "UW Questions") {
          style.backgroundColor = "#FFD737";
        } else if (Number(rowData.visitCount) > 0) {
          style.backgroundColor = "#FFD737";
        } else {
          style.backgroundColor = "#FFF";
        }
        return style;
      },
    },
    {
      title: "Comments",
      field: "comment",
      filterPlaceholder: "Filter",
      render: (rowData) => {
        return (
          <textarea
            defaultValue={rowData.comment || ""}
            onBlur={(e) => updateComment(e, rowData)}
            rows="2"
            style={{ width: "100%" }}
          ></textarea>
        );
      },
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "160px",
      },
    },
    {
      title: "Visit Count",
      field: "visitCount",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterfunction(term, rowData, field),
      cellStyle: {
        padding: "6px 0",
        minWidth: "70px",
      },
      headerStyle: {
        padding: "6px 0",
      },
    },
    {
      title: "Campaign Name",
      field: "latestCampaign",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "6px 15px 6px 10px",
        wordBreak: "break-word",
        minWidth: "70px",
      },
      render: (rowData) => (
        <Clamp lines={2}>{camelCase(rowData?.latestCampaign) || ""}</Clamp>
      ),
    },
    {
      title: "Pre UW",
      field: "preUwName",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "90px",
        wordBreak: "break-all",
      },
      render: (rowData) => <Clamp lines={2}>{rowData.preUwName || ""}</Clamp>,
    },
    // {
    //   title: "PreUnderwritten?",
    //   field: "isPreUnderwritten",
    //   headerStyle: {
    //     // padding: "6px 0 6px 0px",
    //     width: "30px",
    //     wordBreak: "break-all",
    //   },
    //   render: (rowData) => rowData.isPreUnderwritten ? "YES" : "NO",
    // },
    {
      title: "Salesperson",
      field: "salespersonName",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "170px",
      },
      render: (rowData) => {
        let name = "";
        try {
          if (rowData.salespersonName.length > 15) {
            name = `${rowData.salespersonName.slice(0, 15)}...`;
          } else {
            name = rowData.salespersonName;
          }
        } catch (error) { }
        return <Clamp lines={2}>{name || ""}</Clamp>;
      },
    },
    {
      title: "Source",
      field: "source",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "170px",
      },
      render: (rowData) => (
        <Clamp lines={1}>{source(rowData?.source) || ""}</Clamp>
      ),
    },
  ];
  let EngagedColumn = [
    {
      title: "Submitted Date (ET)",
      field: "preUwDate",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),

      customSort: (a, b) => dateSort(a, b, "preUwDate"),
      render: (rowData) => {
        if (rowData.preUwDate) {
          let date = rowData.preUwDate.split("-")[0];
          let time = rowData.preUwDate.split("-")[1];
          return (
            <span>
              {date}
              <br></br>
              {time}
            </span>
          );
        } else {
          return "-";
        }
      },
      cellStyle: {
        wordBreak: "break-all",
        padding: "6px 0",
        minWidth: "100px",
      },
      headerStyle: {
        padding: "6px 0",
        minWidth: "100px",
      },
    },
    {
      title: "Visit Date (ET)",
      field: "visitDate",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),

      customSort: (a, b) => dateSort(a, b, "visitDate"),
      render: (rowData) => {
        if (rowData.visitDate) {
          let date = rowData.visitDate.split("-")[0];
          let time = rowData.visitDate.split("-")[1];
          return (
            <span>
              {date}
              <br></br>
              {time}
            </span>
          );
        } else {
          return "-";
        }
      },
      cellStyle: {
        wordBreak: "break-all",
        padding: "6px 0",
        minWidth: "100px",
      },
      headerStyle: {
        padding: "6px 0",
        minWidth: "100px",
      },
    },
    {
      title: "Company Name",
      field: "companyName",
      filterPlaceholder: "Filter",

      cellStyle: {
        padding: "6px 5px 6px 10px",
        minWidth: "230px",
        wordBreak: "break-word",
      },
      render: (rowData) => {
        let random_user = Object.keys(rowData.email_specific)[0];

        return (
          <Clamp lines={2}>
            <span onClick={() => onClickCompanyName(rowData)}>
              <Link to={"/Quote"}>{camelCase(rowData.companyName)}</Link>
            </span>
          </Clamp>
        );
      },
    },
    {
      title: "Payroll",
      field: "payroll",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterfunction(term, rowData, field),

      render: (rowData) => numeral(rowData.payroll).format("$0,0"),
      cellStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "136px",
        wordBreak: "break-all",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "136px",
      },
    },
    {
      title: "State",
      field: "state",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      cellStyle: {
        textTransform: "uppercase",
        padding: "6px 0 6px 10px",
        minWidth: "50px",
      },
      headerStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "50px",
      },
    },
    {
      title: "# Emp",
      field: "total_emp",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterfunction(term, rowData, field),
      cellStyle: {
        padding: "6px 0",
        minWidth: "65px",
      },
      headerStyle: {
        padding: "6px 0",
        whiteSpace: "nowrap",
        minWidth: "65px",
      },
    },
    {
      title: "Status",
      field: "step",
      filterPlaceholder: "Filter",

      cellStyle: (e, rowData) => {
        let style = { padding: "6px 15px 6px 10px" };
        if (rowData.step === "Submitted") {
          style.background = "#5ADA38";
        } else if (rowData.step === "User Submitted") {
          style.background = "#5ADA38";
        } else if (rowData.step === "Proposal Generated") {
          style.backgroundColor = "#5ADA38";
        } else if (rowData.step === "UW Portal") {
          style.backgroundColor = "#ffd737ac";
        } else if (rowData.step === "Approved") {
          style.backgroundColor = "#87FA4D";
        } else if (rowData.step === "Rejected") {
          style.backgroundColor = "#fa6d3e";
        } else if (rowData.step === "Quote Generated") {
          style.backgroundColor = "#87FA20";
        } else if (rowData.step === "UW Questions") {
          style.backgroundColor = "#FFD737";
        } else if (Number(rowData.visitCount) > 0) {
          style.backgroundColor = "#FFD737";
        } else {
          style.backgroundColor = "#FFF";
        }
        return style;
      },
    },
    {
      title: "Comments",
      field: "comment",
      filterPlaceholder: "Filter",
      // defaultSort: "desc",
      defaultSort: "asc",
      render: (rowData) => {
        let index = rowData.tableData.id;
        // let { rowDataList } = JSON.parse(JSON.stringify(this.state));
        return (
          <textarea
            defaultValue={rowData.comment || ""}
            onBlur={(e) => updateComment(e, rowData)}
            rows="2"
            style={{ width: "100%" }}
          ></textarea>
        );
      },
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "140px",
      },
    },
    {
      title: "Visit Count",
      field: "visitCount",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterfunction(term, rowData, field),
      cellStyle: {
        padding: "6px 0",
        minWidth: "70px",
      },
      headerStyle: {
        padding: "6px 0",
      },
    },
    {
      title: "Campaign Name",
      field: "latestCampaign",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "6px 15px 6px 10px",
        wordBreak: "break-word",
        minWidth: "70px",
      },
      render: (rowData) => (
        <Clamp lines={2}>{camelCase(rowData?.latestCampaign) || ""}</Clamp>
      ),
    },
    {
      title: "Pre UW",
      field: "preUwName",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "6px 0 6px 10px",
        minWidth: "90px",
        wordBreak: "break-all",
      },
      render: (rowData) => <Clamp lines={2}>{rowData?.preUwName || ""}</Clamp>,
    },
    {
      title: "Salesperson",
      field: "salespersonName",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "6px 0px 6px 10px",
        minWidth: "120px",
      },
      render: (rowData) => {
        let name = "";
        try {
          if (rowData.salespersonName.length > 15) {
            name = `${rowData.salespersonName.slice(0, 15)}...`;
          } else {
            name = rowData.salespersonName;
          }
        } catch (error) { }
        return <Clamp lines={2}>{name || ""}</Clamp>;
      },
    },
    {
      title: "Source",
      field: "source",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "6px 15px 6px 10px",
        minWidth: "170px",
      },
      render: (rowData) => (
        <Clamp lines={1}>{source(rowData?.source) || ""}</Clamp>
      ),
    },
  ];

  if (uploadDateFlag) {
    columns.unshift({
      title: "Upload Date",
      field: "uploadedTimeStamp",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      customSort: (a, b) => dateSort(a, b, "uploadedTimeStamp"),
      cellStyle: {
        wordBreak: "break-all",
        padding: "6px 0",
        minWidth: "80px",
        textAlign: "center",
      },
      headerStyle: {
        padding: "6px 0",
        textAlign: "center",
      },
      render: (rowData) => <Clamp lines={1}>{rowData.uploadedTimeStamp}</Clamp>,
    });
    EngagedColumn.unshift({
      title: "Upload Date",
      field: "uploadedTimeStamp",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),
      customSort: (a, b) => dateSort(a, b, "uploadedTimeStamp"),
      cellStyle: {
        wordBreak: "break-all",
        padding: "6px 0",
        minWidth: "70px",
      },
      headerStyle: {
        padding: "6px 0",
      },
      render: (rowData) => <Clamp lines={1}>{rowData.uploadedTimeStamp}</Clamp>,
    });
  }

  return (
    <div className="preUWDashboard m-3 w-100">
      {/* <Header /> */}

      <Nav
        variant="pills"
        defaultActiveKey={selected}
        style={{
          fontSize: "16px",
          margin: "5px",
          fontFamily: "Montserrat",
          marginTop: "30px",
        }}
      >
        {Object.keys(tabsMap).map((tab) => (
          <Nav.Item>
            <Nav.Link eventKey={tab} onClick={() => setSelected(tab)}>
              {tabsMap[tab]}
            </Nav.Link>
          </Nav.Item>
        ))}
        <h1
          className="mt-0 mb-0 d-flex"
          style={{
            textAlign: "center",
            fontFamily: "Montserrat",
            margin: "0px auto",
          }}
        >
          Pre-Underwriter Dashboard
        </h1>
      </Nav>

      {selected === "tab1" ? (
        <EngagedTabMUI
          columns={EngagedColumn}
          isLoading={isLoading}
          showDetailPanel={true}
          engagedRowDataList={engagedRowDataList}
        />
      ) : (
        " "
      )}
      {selected === "tab2" ? (
        <MaterialTableContainer
          isLoadingCampaigned={isLoadingCampaigned}
          rowDataList={rowDataList}
          columns={columns}
          showDetailPanel={true}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default PreUnderWriterDashboard;
