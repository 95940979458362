import axios from "axios";
import { awsUrl2, awsUrl } from "../config";

export const getFinalEligibility = (eligibility_check, componentKey) => {
  let eligibilityRankingMap = {
    approved: 1,
    referred: 2,
    declined: 3,
  };
  let finalEligibility = "approved";
  try {
    if (eligibility_check["quote_data"]["eligibility_check_5_2"]["eligibility"] === "referred")
      return "referred"

    for (let component in eligibility_check) {
      if (!componentKey || componentKey === component) {
        for (let key in eligibility_check[component]) {
          let eligibility = eligibility_check[component][key].eligibility;
          if (
            eligibility in eligibilityRankingMap &&
            eligibilityRankingMap[eligibility] >
            eligibilityRankingMap[finalEligibility]
          ) {
            finalEligibility = eligibility;
          }
        }
      }
    }
  } catch (error) { }

  return finalEligibility;
};

export let getDeclinedStatementList = async (
  eligibility_check,
  componentKey
) => {
  let statementKeyList = [];
  let statementList = [];
  for (let component in eligibility_check) {
    if (!componentKey || componentKey === component) {
      for (let key in eligibility_check[component]) {
        let eligibility = eligibility_check[component][key].eligibility;
        if (eligibility === "declined") {
          statementKeyList.push(key);
        }
      }
    }
  }

  try {
    let response = await axios
      .post(awsUrl2 + `/api/getEligibilityInfoStatements`, statementKeyList)
      .catch((e) => console.log("error in getting gov cc statements", e));

    statementList = response.data;
  } catch (error) {
    console.log("Error:", error);
  }

  return statementList;
};

export const resetEligibility = (eligibility_check, componentList) => {
  if (componentList.length === 0) {
    for (let component in eligibility_check) {
      for (let key in eligibility_check[component]) {
        eligibility_check[component][key].eligibility = "";
      }
    }
  }
  else {
    for (let compKey of componentList) {
      for (let component in eligibility_check) {
        if (compKey === component) {
          for (let key in eligibility_check[component]) {
            eligibility_check[component][key].eligibility = "";
          }
        }
      }
    }
  }
}
