import React, { Component, forwardRef } from "react";
import axios from "axios";
import { awsUrl2 } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTable from "material-table";
import { TableBody, TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Loader from "../common/Loader";
import Header from "../common/Header";
import Stats2 from "../subcompo/sections-home/Stats2";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { logout } from "../../utils/common";
import { Tab, Col, Row, Nav } from "react-bootstrap";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import clipboard from "../../images/clipboard.svg";
import toast, { Toaster } from "react-hot-toast";
import { getFinalEligibility } from "../../utils/eligibility_helpers";
import { Modal } from "react-bootstrap";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";

import parse from "html-react-parser";

import DealClosedLostButtons from "../subcompo/DealClosedLostButtons";
import { UploadDashboardFile } from "../subcompo/UploadDashboardFile";

const {
  DownloadDashboardFiles,
} = require("../subcompo/DownloadDashboardFiles.jsx");
let ismobile = window.innerWidth < 1600;
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: () => <></>,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const statusPriorityMap = {
  Submitted: 1,
  "User Submitted": 2,
  "Quote Generated": 3,
  "Uploaded Document": 4,
  "UW Questions": 5,
  MQL: 6,
  SQL: 7,
  "Company Profile(Get Quote)": 8,
  "Company Profile": 9,
  "Landing Page": 10,
  "Visited Website": 11,
  "Affinity Logo Clicked": 12,
  "Fourth Logo Clicked": 13,
  "Clicked on Affinity logo": 14,
  "Clicked on Fourth logo": 15,
  "Clicked on the image": 16,
  "Clicked on insure comp logo": 17,
  "Watched Video": 18,
  "Visited LinkedIn": 19,
  "Visited Facebook": 20,
  "Visited Instagram": 21,
  "Accessed Social Media": 22,
  "Opened Email": 23,
  "Not Campaigned": 24,
  "-": 999,
};

class DashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "tab1",
      dataToDisplay: {
        tab1: [],
        tab2: [],
        // tab3: [],
        // tab4: [],
        // tab5: [],
        // tab6: [],
      },
      isLoading: { tab1: true, tab2: true },
      // dealMap: {},
    };
  }

  async componentDidMount() {
    await this.fetchData();
    sessionStorage.clear();
  }

  // updateDealMap = (key, value) => {
  //   let { dealMap } = this.state;
  //   dealMap[key] = value;
  //   this.setState({ dealMap });
  // };

  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      console.log(tab, "tabs");
      this.setState({ selected: tab });
    }
  };

  appendRemaining = (str) => {
    let len = str.length;
    for (let i = 1; i <= 10 - len; i++) {
      str = "0" + str;
    }
    return str;
  };

  processSalesData = (data) => {
    let list = [];
    let wonList = [];
    let lostList = [];
    let domain_messageid_files = {};
    for (let userDataRow in data) {
      try {
        let userData = data[userDataRow];
        let step;

        if (userData.formStage) {
          switch (userData.formStage) {
            case "gov_cc":
              step = " Eligibility page";
              break;
            case "one":
              step = "Company Profile";
              break;
            case "two":
              step = "UW Questions";
              break;
            case "three":
              let eligibility_check = getFinalEligibility(
                userData?.eligibilityCheck
              );
              let eligibilityMapping = {
                approved: "Quoted",
                declined: "Declined",
                referred: "Submitted to UW",
              };
              step =
                userData?.UWeligibilityCheckStatus ||
                eligibilityMapping[eligibility_check] ||
                "";
              break;
            default:
              break;
          }
        }
        let currProspectDetails = {};
        if (userData.currProspect) {
          currProspectDetails = userData.currProspect;
        } else {
          currProspectDetails = {
            companyProfile: userData.companyProfile,
            childrenLoc: userData.payrollData,
            emodStatesData: userData.emodStatesData,
            companyProfileAddQuestions: userData.companyProfileAddQuestions,
            uwQues: userData.uwQues,
            requestedPricing: userData.requestedPricing,
          };
        }

        currProspectDetails = {
          ...currProspectDetails,
          brokerDetails: userData.brokerDetails,
        };

        let total_emp = 0,
          payroll = 0;

        if (userData.payrollData) {
          let childrenLoc = userData.payrollData;
          for (let locIndex in childrenLoc) {
            let loc = childrenLoc[locIndex];
            for (let ccIndex in loc.classCodesInfo) {
              let cc = loc.classCodesInfo[ccIndex];
              total_emp =
                total_emp +
                Number(cc?.ft?.value || 0) +
                Number(cc?.pt?.value || 0);
              let payrollVlue = cc?.payroll?.value?.replace(/\D/g, "") || 0;
              payroll = payroll + Number(payrollVlue || 0);
            }
          }
        }

        let rowData = {
          uploadedTimeStamp: userData.created_timestamp
            ? moment(userData.created_timestamp, "x").format("M/D/YY")
            : "-",
          modifiedTimeStamp: userData.updated_timestamp
            ? moment(userData.updated_timestamp, "x").format("M/D/YY")
            : "-",
          visitDate:
            userData.updated_timestamp &&
            moment(Number(userData.updated_timestamp))
              .utcOffset("-0500")
              .format("M/D/YY - hh:mm A"),
          phoneNumber:
            userData.companyProfile && userData.companyProfile.phoneNumber
              ? userData.companyProfile.phoneNumber.value
              : "",
          effectiveDate: userData?.companyProfile?.effectiveDate?.value
            ? moment(userData?.companyProfile?.effectiveDate?.value).format(
                "M/D/YY"
              )
            : "-",

          contactName:
            userData.companyProfile && userData.companyProfile.firstName
              ? userData.companyProfile.firstName.value
              : "",
          companyName:
            userData.companyProfile && userData.companyProfile.companyName
              ? userData.companyProfile.companyName.value
              : "",
          step: userData.isSubmitted ? "Submitted" : step,
          currProspectDetails,
          agentID: userData.id,
          formStage: userData.formStage,
          amtrustAccountDetails: userData.amtrustAccountDetails,
          amtrustAccountDetailsCopy: userData.amtrustAccountDetailsCopy,
          agentName: userData.agentEmail || userData.agentName,
          total_emp,
          state: userData?.companyProfile?.state1?.value || "",
          quoteData: userData.quoteData,
          address: userData.address,
          payroll,
          visitCount: "0",
          latestCampaign: "-",
          sales: "true",
          dashboardFiles: userData.dashboardFiles,
          fileListToStore: userData?.fileListToStore,
          // salescomment: userData.comment,
          sortKeyList: userData.sortKeyList,
          companyProfile: userData.companyProfile,
          quoteGeneratedTimestamp: userData.quoteGeneratedTimestamp
            ? moment(userData.quoteGeneratedTimestamp, "x")
                .add(1, "months")
                .format("M/D/YY")
            : "-",
          totalPremium: userData?.totalPremium || 0,
        };
        domain_messageid_files[rowData.agentID] = {};

        if (rowData?.dashboardFiles) {
          domain_messageid_files[rowData.agentID]["dashboardFiles"] =
            rowData.dashboardFiles;
        }
        if (rowData?.fileListToStore) {
          domain_messageid_files[rowData.agentID]["fileListToStore"] =
            rowData?.fileListToStore;
        }

        // rowData["domain_messageid_files"] = domain_messageid_files;
        // this.setState({ domain_messageid_files });
        if (rowData.dealConfirmed === "true") {
          if (rowData.dealFlag === "closed") {
            wonList.push(rowData);
          } else {
            lostList.push(rowData);
          }
        } else {
          list.push(rowData);
        }
      } catch (error) {
        console.log("error in processing: ", data[userDataRow].id);
      }
    }

    return {
      value: list,
      won: wonList,
      lost: lostList,
      domain_messageid_files: domain_messageid_files,
    };
  };

  processDeclinedAdminDashboard = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(awsUrl2 + "/api/getDeclinedDashboardData")
        .then((res) => {
          let apiData = res.data;
          let rowDataList = [];
          let domain_messageid_files = {};

          for (let userData of apiData) {
            try {
              let rowData = {
                userEmail: userData?.userEmail,
                year: userData?.year,
                month: userData?.month,
                count: userData?.count,
              };
              rowDataList.push(rowData);
            } catch (error) {
              console.log("error in processEngagedData: ", error);
            }
          }

          resolve({
            value: rowDataList,
            domain_messageid_files,
          });
        })
        .catch((error) => {
          console.log(error, "error");
          reject(error);
        });
    });
  };

  fetchData = async () => {
    let { isLoading } = JSON.parse(JSON.stringify(this.state));
    let sessionData = await Auth.currentAuthenticatedUser();
    if (sessionData) {
      let requestBody = {
        salesPersonEmail: sessionData.attributes.email,
        firstTimeLoading: "true",
      };
      let last_key = "";
      let dataToDisplay = {};
      let {
        value: eligibilityValue,
        domain_messageid_files: eligibilityValue_domain_messageid_files,
      } = await this.processDeclinedAdminDashboard();

      await new Promise((resolve, reject) => {
        axios
          .post(awsUrl2 + "/api/getAgentDashboardDataAdmin", requestBody)
          .then(async (salesDataResponse) => {
            let salesData = salesDataResponse.data;
            last_key = salesData.old_key;

            let {
              value: agentValue,
              domain_messageid_files: sales_domain_messageid_files,
            } = this.processSalesData(salesData["salesforce"]);

            dataToDisplay = {
              tab1: {
                value: agentValue,
                domain_messageid_files: sales_domain_messageid_files,
              },
              tab2: {
                value: eligibilityValue,
                domain_messageid_files: eligibilityValue_domain_messageid_files,
              },
            };

            isLoading["tab2"] = false;
            isLoading["tab1"] = false;
            this.setState({ dataToDisplay, isLoading });
          })
          .catch((error) => {
            console.log("error", error);
          })
          .finally(() => {
            isLoading["tab2"] = false;
            isLoading["tab1"] = false;
            this.setState({ isLoading });
            resolve();
          });
      });
      do {
        delete requestBody["firstTimeLoading"];
        if (last_key) {
          requestBody["lastEvaluatedKey"] = last_key;
        }
        await new Promise((resolve, reject) => {
          axios
            .post(awsUrl2 + "/api/getAgentDashboardDataAdmin", requestBody)
            .then(async (salesDataResponse) => {
              let salesData = salesDataResponse.data;
              last_key = salesData.old_key;

              let {
                value: agentValue,
                domain_messageid_files: sales_domain_messageid_files,
              } = this.processSalesData(salesData["salesforce"]);

              // let {
              //   value: eligibilityValue,
              //   domain_messageid_files: eligibilityValue_domain_messageid_files,
              // } = await this.processDeclinedAdminDashboard();

              dataToDisplay = {
                tab1: {
                  value: [...agentValue, ...dataToDisplay?.["tab1"]?.["value"]],
                  domain_messageid_files: {
                    ...sales_domain_messageid_files,
                    ...dataToDisplay?.["tab1"]?.["domain_messageid_files"],
                  },
                },
                tab2: {
                  value: eligibilityValue,
                  domain_messageid_files: {
                    ...eligibilityValue_domain_messageid_files,
                    ...dataToDisplay?.["tab2"]?.["domain_messageid_files"],
                  },
                },
              };

              isLoading["tab2"] = false;
              isLoading["tab1"] = false;
              this.setState({ dataToDisplay, isLoading });
            })
            .catch((error) => {
              console.log("error", error);
            })
            .finally(() => {
              isLoading["tab2"] = false;
              isLoading["tab1"] = false;
              this.setState({ isLoading });
              resolve();
            });
        });
      } while (last_key);
    }
  };

  render() {
    let { selected, dataToDisplay, dealMap, isLoading } = this.state;
    const tabsMap = {
      tab1: { value: "Admin" },
      tab2: {
        value: "Governing CC Check",
      },
    };

    return (
      <div className="dashboard-container">
        <div
          className="d-flex bg-light"
          style={{ borderBottomStyle: "groove" }}
        >
          <Header />
        </div>
        <div className={`tabs p-4`}>
          <Nav
            variant="pills"
            defaultActiveKey={selected}
            style={{ fontSize: "16px" }}
          >
            <span>
              {" "}
              <div className="${selected_table}">
                {Object.keys(tabsMap).map((tab, index) => (
                  <button
                    key={index}
                    value={tab}
                    onClick={(e) => this.setSelected(e.target.value)}
                    className={`pl-2 pr-2 ml-5 mr-3 btn  font-family-montserrat-semi-bold ${
                      selected === tab ? "btn-info" : "btn-default"
                    }`}
                  >
                    {tabsMap[tab].value}
                  </button>
                ))}
              </div>
            </span>
          </Nav>
          {Object.keys(tabsMap).map((tab) => (
            <div key={tab} className="Admin__Dashboard">
              {
                <AdminDashboard
                  tabName={tab}
                  selected={selected}
                  list={dataToDisplay[tab]?.value}
                  domain_messageid_files={
                    dataToDisplay[tab].domain_messageid_files
                  }
                  isLoading={isLoading}
                />
              }
            </div>
          ))}
        </div>
        <Loader />
      </div>
    );
  }
}

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      // isLoading: true,
      rowDataList: [],
      domain_messageid_files: {},
      style: {},
      salesPersonLogin: false,
      salesManagerLogin: false,
      uploadDateFlag: false,
      scrollLeft: false,
      scrollRight: true,
    };

    this.handleDisplayHide = this.handleDisplayHide.bind();
  }

  handleDisplayHide = () => {
    let { tabName, selected } = this.props;

    this.setState({
      style: tabName !== selected ? { display: "none", fontSize: "12px" } : {},
    });
  };

  componentDidMount = async () => {
    try {
      const currentAuthUser = await Auth.currentAuthenticatedUser();
      if (currentAuthUser) {
        let groups =
          currentAuthUser?.signInUserSession?.accessToken?.payload[
            "cognito:groups"
          ];
        if (groups) {
          if (groups.includes(process.env.REACT_APP_AGENT_GROUP)) {
            this.setState({ agentLogin: true });
          }
          if (groups.includes(process.env.REACT_APP_ADMIN_GROUP)) {
            this.setState({ adminLogin: true });
          }
          // else if (groups.includes(process.env.REACT_APP_SALES_GROUP)) {
          //   this.setState({ salesPersonLogin: true });
          // } else {
          //   this.setState({ uploadDateFlag: false });
          // }
        }
      }
    } catch (error) {}
    window.addEventListener("load", this.handleDisplayHide);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.selected !== prevProps.selected) {
      this.handleDisplayHide();
    }
    if (
      this.props.selected !== prevProps.selected ||
      this.props?.list?.length !== prevProps?.list?.length
    ) {
      this.setState({ rowDataList: this.props.list, isLoading: false });
    }
    let len1 = Object.keys(this.props.domain_messageid_files || {}).length,
      len2 = Object.keys(this.state.domain_messageid_files || {}).length;
    if (
      len1 &&
      // len2 &&
      (len1 !== len2 ||
        JSON.stringify(this.props.domain_messageid_files) !==
          JSON.stringify(this.state.domain_messageid_files))
    ) {
      this.setState({
        domain_messageid_files: this.props.domain_messageid_files,
      });
    }
  };

  camelCase = (str) => {
    if (str)
      return str
        .trim()
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");

    return str;
  };

  fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.9rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.88rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.86rem";
    }
    if (window.innerWidth < 1300) {
      return "0.85rem";
    }
  };
  // updateEngagedComment = (e, rowData) => {
  //   let { rowDataList, program, source } = JSON.parse(
  //     JSON.stringify(this.state)
  //   );
  //   let index = rowData.tableData.id;
  //   let requestBody = {};

  //   requestBody.domain = rowDataList[index].domain;
  //   requestBody.salescomment = e.target.value;
  //   requestBody.tableName = "tab1";

  //   axios
  //     .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
  //     .then((res) => {})
  //     .catch((error) => {
  //       console.log("error in posting data to user tracking", error);
  //     });
  // };

  formatInCamel = (str) => {
    if (str) {
      return str[0].toUpperCase() + "" + str.slice(1);
    }
    return str;
  };

  // updateAdminComments = (e, rowData) => {
  //   let { rowDataList, program, source } = JSON.parse(
  //     JSON.stringify(this.state)
  //   );
  //   let index = rowData.tableData.id;
  //   let requestBody = {};

  //   requestBody.domain = rowDataList[index].domain;
  //   requestBody.admincomment = e.target.value;
  //   requestBody.tableName = "tab1";

  //   axios
  //     .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
  //     .then((res) => {})
  //     .catch((error) => {
  //       console.log("error in posting data to user tracking", error);
  //     });
  // };

  // updateSalesComment = (e, rowData, str) => {
  //   let { rowDataList, program, source } = JSON.parse(
  //     JSON.stringify(this.state)
  //   );
  //   let index = rowData.tableData.id;
  //   let requestBody = {};

  //   requestBody.domain =
  //     rowDataList[index].salesID || rowDataList[index].domain;

  //   if (str === "salesManager") {
  //     requestBody.smcomment = e.target.value;
  //   } else {
  //     requestBody.comment = e.target.value;
  //   }
  //   requestBody.tableName = "tab3";

  //   axios
  //     .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
  //     .then((res) => {})
  //     .catch((error) => {
  //       console.log("error in posting data to user tracking", error);
  //     });
  // };

  // updateSalesManagerComment = (e, rowData) => {
  //   let { rowDataList, program, source } = JSON.parse(
  //     JSON.stringify(this.state)
  //   );
  //   let index = rowData.tableData.id;
  //   let requestBody = {};

  //   requestBody.domain =
  //     rowDataList[index].salesID || rowDataList[index].domain;
  //   requestBody.smcomment = e.target.value;
  //   requestBody.tableName = "tab1";

  //   axios
  //     .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
  //     .then((res) => {})
  //     .catch((error) => {
  //       console.log("error in posting data to user tracking", error);
  //     });
  // };

  // updateSalesManagerComment = (e, rowData) => {
  //   let { rowDataList, program, source } = JSON.parse(
  //     JSON.stringify(this.state)
  //   );
  //   let index = rowData.tableData.id;
  //   let requestBody = {};

  //   requestBody.domain =
  //     rowDataList[index].salesID || rowDataList[index].domain;
  //   requestBody.smcomment = e.target.value;
  //   requestBody.tableName = "tab1";

  //   axios
  //     .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
  //     .then((res) => {})
  //     .catch((error) => {
  //       console.log("error in posting data to user tracking", error);
  //     });
  // };

  onClickCompanyName = (rowData) => {
    if (rowData.currProspectDetails) {
      sessionStorage.setItem(
        "currProspect",
        JSON.stringify(rowData.currProspectDetails)
      );
    }
    if (rowData.dealConfirmed === "true") {
      sessionStorage.setItem("hideButtons", "true");
    }
    if (rowData.agentID) {
      sessionStorage.setItem("agentID", rowData.agentID);
    }
    if (rowData.formStage) {
      sessionStorage.setItem("formStage", rowData.formStage);
    }
    if (rowData.amtrustAccountDetails) {
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(rowData.amtrustAccountDetails)
      );
    }
    if (rowData.amtrustAccountDetailsCopy) {
      sessionStorage.setItem(
        "amtrustAccountDetailsCopy",
        JSON.stringify(rowData.amtrustAccountDetailsCopy)
      );
    }
    if (rowData.address) {
      sessionStorage.setItem("address", JSON.stringify(rowData.address));
    }
    if (rowData.quoteData) {
      sessionStorage.setItem("quoteData", JSON.stringify(rowData.quoteData));
    }
    if (rowData.sortKeyList) {
      sessionStorage.setItem(
        "sortKeyList",
        JSON.stringify(rowData.sortKeyList)
      );
    }
    if (rowData.isSalesforce) {
      sessionStorage.setItem("isSalesforce", true);
      sessionStorage.setItem("sfQuoteId", rowData.sfQuoteId);
    }
    if (
      this.state.domain_messageid_files?.[rowData.agentID]?.fileListToStore
        ?.length > 0
    ) {
      sessionStorage.setItem(
        "fileListToStore",
        JSON.stringify(
          this.state.domain_messageid_files[rowData.agentID]["fileListToStore"]
        )
      );
    }
  };

  dateSort = (a, b, field) => {
    let momentA = moment(a[field], "M/D/YY - hh:mm A");
    let momentB = moment(b[field], "M/D/YY - hh:mm A");
    if (
      (!momentA.isValid() && momentB.isValid()) ||
      momentA.isBefore(momentB)
    ) {
      return -1;
    } else if (
      (momentA.isValid() && !momentB.isValid()) ||
      momentA.isAfter(momentB)
    ) {
      return 1;
    } else if (
      (!momentA.isValid() && !momentB.isValid()) ||
      momentA.isSame(momentB)
    ) {
      return 0;
    }
  };

  copySuccess = (text) =>
    toast.success(text, {
      duration: 3000,
    });

  source = (Source) => {
    if (Source == "hotschedules") {
      return "HotSchedules";
    } else if (Source == "affinity") {
      return "Affinity";
    } else {
      return Source;
    }
  };
  copyError = (text) =>
    toast.error(text, {
      duration: 3000,
    });

  copyId = (id) => {
    try {
      navigator.clipboard.writeText(id);
      this.copySuccess("Copied");
    } catch (error) {
      console.log(error);
      this.copyError("Error while copying");
    }
  };

  updateDomainMessageidFiles = (domain, key, val) => {
    console.log(domain, key, val, "i am  here as well ");
    let { domain_messageid_files } = this.state;
    if (!domain_messageid_files.hasOwnProperty(domain)) {
      domain_messageid_files[domain] = {};
    }
    domain_messageid_files[domain][key] = val;
    this.setState({ domain_messageid_files });
  };
  customFilterfunction = (term, rowData, field) => {
    let customFieldName = field["field"];

    let excludeFirstCharacter = term.substring(1);
    let excludeFirstTwoCharacter = term.substring(2);
    let firstCharacter = term.substring(1, 0);
    let firstTwoCharacter = term.substring(2, 0);
    let operationMap = {
      ">=": ">=",
      "<=": "<=",
      "<": "<",
      ">": ">",
      "=": "===",
    };
    try {
      if (firstTwoCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstTwoCharacter]} ${excludeFirstTwoCharacter}`
        );
      } else if (firstCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
      } else {
        return rowData[customFieldName]?.toString()?.startsWith(term);
      }
    } catch (err) {
      return rowData[customFieldName]?.toString()?.startsWith(term);
    }

    // if (term[0] == ">" && term[1] == "=") {
    //   return firstTwoCharacter <= rowData[customFieldName];
    // } else if (term[0] == "<" && term[1] == "=") {
    //   return firstTwoCharacter >= rowData[customFieldName];
    // } else if (term[0] == ">") {
    //   return firstCharacter < rowData[customFieldName];
    // } else if (term[0] == "<") {
    //   return firstCharacter > rowData[customFieldName];
    // } else if (term[0] == "=") {
    //   return firstCharacter == rowData[customFieldName];
    // } else {
    //   return rowData[customFieldName]?.toString()?.startsWith(term);
    // }
  };

  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };
  scrollLeft = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));
    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft -= 150;
      if (tableContainerDiv.scrollLeft == 0) {
        this.setState({ scrollLeft: false });
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft < maxScrollLeft) {
        this.setState({ scrollRight: true });
      }
    }
  };
  scrollRight = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));

    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft += 150;
      this.setState({ scrollLeft: true });
    }

    const maxScrollLeft =
      tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    if (tableContainerDiv.scrollLeft >= maxScrollLeft) {
      this.setState({ scrollRight: false });
    }
  };

  // tab2DetailPanel = (rowData) => {
  //   return (
  //     <div style={{ padding: "25px" }}>
  //       <TableContainer component={Paper}>
  //         <Table size="small" className="sub_tab2">
  //           <TableHead
  //             style={{
  //               background: "#00000066",
  //               color: "whitesmoke",
  //             }}
  //           >
  //             <TableRow>
  //               <TableCell>Contact Name</TableCell>
  //               <TableCell>Phone Number</TableCell>
  //               <TableCell>Email</TableCell>
  //               <TableCell>Job Description</TableCell>
  //               <TableCell>Visit Count</TableCell>
  //               <TableCell>Status</TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //             {rowData.email_specific ? (
  //               Object.values(rowData.email_specific).map((element, index) => {
  //                 let step;
  //                 if (element.companyProfile) {
  //                   if (Number(element.proposalGeneratedCount) > 0) {
  //                     step = "Proposal Generated";
  //                   } else if (element.quote_status) {
  //                     if (element.quote_status === "uwportal") {
  //                       step = "UW Portal";
  //                     } else if (element.quote_status === "proceed_uw") {
  //                       step = "Approved";
  //                     } else if (element.quote_status === "reject") {
  //                       step = "Rejected";
  //                     }
  //                   } else if (Number(element.quoteGeneratedCount) > 0) {
  //                     step = "Quote Generated";
  //                   } else if (element.formStage) {
  //                     if (element.formStage === "two") {
  //                       if (element.nonEligibilityList) {
  //                         step = "Not Eligible";
  //                       } else {
  //                         step = "UW Questions";
  //                       }
  //                     } else if (element.formStage === "one") {
  //                       step = "Company Profile";
  //                     }
  //                   } else if (
  //                     element.visitCount &&
  //                     Number(element.visitCount) > 0
  //                   ) {
  //                     step = "Landing Page";
  //                   } else {
  //                     step = "-";
  //                   }
  //                   if (
  //                     element.companyProfile.status &&
  //                     element.companyProfile.status.value
  //                   ) {
  //                     step =
  //                       statusPriorityMap[element.companyProfile.status.value] <
  //                       statusPriorityMap[step]
  //                         ? element.companyProfile.status.value
  //                         : step;
  //                   }
  //                   return (
  //                     <TableRow key={index}>
  //                       <TableCell scope="row">
  //                         {element.companyProfile &&
  //                         element.companyProfile.contact_name
  //                           ? element.companyProfile.contact_name.value
  //                           : ""}
  //                       </TableCell>
  //                       <TableCell scope="row">
  //                         {element?.companyProfile?.phoneNumber?.value?.replace(
  //                           /(\d{3})(\d{3})(\d{4})/,
  //                           "($1) $2-$3"
  //                         ) ||
  //                           element?.companyProfile?.contact_number?.value?.replace(
  //                             /(\d{3})(\d{3})(\d{4})/,
  //                             "($1) $2-$3"
  //                           ) ||
  //                           ""}
  //                       </TableCell>
  //                       <TableCell scope="row">
  //                         {element?.companyProfile?.emailId?.value}
  //                       </TableCell>
  //                       <TableCell scope="row">
  //                         {element?.companyProfile?.job_title?.value}
  //                       </TableCell>
  //                       <TableCell scope="row">
  //                         {element.visitCount || 0}
  //                       </TableCell>
  //                       <TableCell scope="row">{step}</TableCell>
  //                     </TableRow>
  //                   );
  //                 }
  //               })
  //             ) : (
  //               <TableRow key={0}>
  //                 <TableCell scope="row">{rowData.contactName}</TableCell>
  //                 <TableCell scope="row">
  //                   {rowData.phoneNumber
  //                     ? rowData.phoneNumber.replace(
  //                         /(\d{3})(\d{3})(\d{4})/,
  //                         "($1) $2-$3"
  //                       )
  //                     : "-"}
  //                 </TableCell>
  //                 <TableCell scope="row">{rowData.userEmail}</TableCell>
  //                 <TableCell scope="row">{""}</TableCell>
  //                 <TableCell scope="row">{rowData.visitCount}</TableCell>
  //                 {/* <TableCell scope="row">
  //             {rowData.latestCampaign || ""}
  //           </TableCell> */}
  //                 <TableCell scope="row">{rowData.step || ""}</TableCell>
  //               </TableRow>
  //             )}
  //           </TableBody>
  //         </Table>
  //       </TableContainer>
  //     </div>
  //   );
  // };

  // tab1DetailPanel = (rowData) => {
  //   return (
  //     <div style={{ padding: "25px" }}>
  //       <TableContainer component={Paper}>
  //         <Table size="small" className="sub_tab1">
  //           <TableHead
  //             style={{
  //               background: "#00000066",
  //               color: "whitesmoke",
  //             }}
  //           >
  //             <TableRow>
  //               <TableCell>Contact Name</TableCell>
  //               <TableCell>Phone Number</TableCell>
  //               <TableCell>Email</TableCell>
  //               <TableCell>Job Description</TableCell>
  //               <TableCell>Visit Count</TableCell>
  //               <TableCell>Status</TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //             {
  //               <TableRow key={0}>
  //                 <TableCell scope="row">
  //                   {(this.formatInCamel(
  //                     rowData?.companyProfile?.firstName?.value
  //                   ) || "") +
  //                     " " +
  //                     (this.formatInCamel(
  //                       rowData?.companyProfile?.lastName?.value
  //                     ) || "") || "-"}
  //                 </TableCell>
  //                 <TableCell scope="row">
  //                   {rowData?.companyProfile?.phoneNumber?.value?.replace(
  //                     /(\d{3})(\d{3})(\d{4})/,
  //                     "($1) $2-$3"
  //                   ) || "-"}
  //                 </TableCell>
  //                 <TableCell scope="row">{"-"}</TableCell>
  //                 <TableCell scope="row">{"-"}</TableCell>
  //                 <TableCell scope="row">{"0"}</TableCell>
  //                 <TableCell scope="row">{rowData.step || ""}</TableCell>
  //               </TableRow>
  //             }
  //           </TableBody>
  //         </Table>
  //       </TableContainer>
  //     </div>
  //   );
  // };

  setTableData = () => {
    let {
      rowDataList,
      agentLogin,
      adminLogin,
      scrollLeft,
      scrollRight,
    } = JSON.parse(JSON.stringify(this.state));
    // console.log(rowDataList, "chekcing ky hara hai");

    let {
      domain_messageid_files,
      tabName,
      selected,
      list,
      isLoading,
      // updateDealMap,
      // dealMap,
    } = this.props;
    let columns = [];

    let colorMapping = {
      "In Progress": "#FFC000",
      "User Submitted": "#5ADA38",
      "Proposal Generated": "#5ADA38",
      "Submitted to UW": "#FFFF00",
      approved: "#00B050",
      Declined: "#C00000",
      "UW Questions": "#FFD737",
      Quoted: "#00B050",
      "Quote Generated": "#87FA20",
    };
    if (selected === "tab1") {
      columns = [
        {
          title: "Upload Date",
          field: "uploadedTimeStamp",
          filtering: true,
          defaultSort: "desc",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),

          customSort: (a, b) => this.dateSort(a, b, "uploadedTimeStamp"),
          cellStyle: {
            minWidth: "80px",
            padding: "0px 15px 0px 25px",
          },
          headerStyle: {
            padding: "0px 6px 0px 25px",

            minWidth: "80px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.uploadedTimeStamp}</Clamp>
          ),
        },
        {
          title: "Modified Date",
          field: "modifiedTimeStamp",
          filtering: true,
          defaultSort: "desc",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),

          customSort: (a, b) => this.dateSort(a, b, "modifiedTimeStamp"),
          cellStyle: {
            minWidth: "80px",
            padding: "0px 15px 0px 25px",
          },
          headerStyle: {
            padding: "0px 6px 0px 25px",

            minWidth: "80px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData.modifiedTimeStamp}</Clamp>
          ),
        },
        {
          title: "Company Name",
          field: "companyName",

          cellStyle: {
            padding: "0px 15px 0px 10px",
            minWidth: "250px",
            wordBreak: "break-word",
          },
          headerStyle: {
            padding: " 0px 15px 0px 10px",
            minWidth: "250px",
          },
          filtering: true,
          filterPlaceholder: "Filter",
          render: (rowData) => {
            if (rowData.sales === "true") {
              //copy here
              return (
                <span className="d-flex">
                  {/* <img
                    style={{ width: "12px", cursor: "pointer" }}
                    className="mr-2"
                    src={clipboard}
                    title={rowData.salesID}
                    onClick={() => {
                      this.copyId(rowData.salesID);
                    }}
                  /> */}
                  <Clamp lines={2}>
                    <span onClick={() => this.onClickCompanyName(rowData)}>
                      <Link to={"/Quote"}>
                        {this.camelCase(rowData.companyName)}
                      </Link>
                    </span>
                  </Clamp>
                </span>
              );
            } else {
              let random_user = Object.keys(rowData.email_specific)[0];

              return (
                <Clamp lines={2}>
                  <span
                    onClick={() => {
                      sessionStorage.setItem("landingEmail", random_user);
                      // sessionStorage.setItem("domain", rowData.agentID);
                      sessionStorage.setItem("salespersonMailCampaign", "true");
                    }}
                  >
                    <Link to={`/Quote/?user=${random_user}&viewButton=true`}>
                      {this.camelCase(rowData.companyName)}
                    </Link>
                  </span>
                </Clamp>
              );
            }
          },
        },
        {
          title: "Premium",
          field: "totalPremium",
          filterPlaceholder: "Filter",
          filtering: true,

          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),

          render: (rowData) => numeral(rowData?.totalPremium).format("$0,0"),
          cellStyle: {
            padding: "0px 6px 0px 10px",
            minWidth: "136px",
            wordBreak: "break-all",
            textAlign: "right",
          },
          headerStyle: {
            padding: "0px 6px 0px 10px",
            minWidth: "136px",
            textAlign: "left",
          },
        },

        {
          title: "Policy Eff. Date",
          field: "effectiveDate",
          filtering: true,
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),

          customSort: (a, b) => this.dateSort(a, b, "effectiveDate"),
          cellStyle: {
            wordBreak: "break-all",
            minWidth: "90px",
            padding: "0px 15px 0px 20px",
          },
          headerStyle: {
            padding: "0px 6px 0px 10px",
            minWidth: "90px",
            textAlign: "left",
          },
          render: (rowData) => (
            <Clamp lines={1}>{rowData?.effectiveDate}</Clamp>
          ),
        },
        {
          title: "Quote Exp. Date",
          field: "quoteGeneratedTimestamp",
          filtering: true,
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),

          customSort: (a, b) => this.dateSort(a, b, "quoteGeneratedTimestamp"),
          cellStyle: {
            wordBreak: "break-all",
            minWidth: "120px",
            padding: "0px 15px 0px 20px",
          },
          headerStyle: {
            padding: "0px 6px 0px 10px",
            minWidth: "136px",
            textAlign: "left",
          },
          render: (rowData) => (
            <Clamp lines={1}>
              {rowData.formStage == "one" || rowData.formStage == "two"
                ? ""
                : rowData?.quoteGeneratedTimestamp || ""}
            </Clamp>
          ),
        },
        {
          title: "Status",
          field: "step",
          filtering: true,
          filterPlaceholder: "Filter",
          cellStyle: { minWidth: "180px" },
          // cellStyle: (e, rowData) => {
          //   let style = { padding: "0px 15px 0px 10px", width: "20px" };
          //   if (rowData.step === "In Progress") {
          //     style.backgroundColor = "#FFC000";
          //   } else if (rowData.step === "User Submitted") {
          //     style.backgroundColor = "#5ADA38";
          //   } else if (rowData.step === "Proposal Generated") {
          //     style.backgroundColor = "#5ADA38";
          //   } else if (rowData.step === "Submitted to UW") {
          //     style.backgroundColor = "#FFFF00";
          //   } else if (rowData.step === "approved") {
          //     style.backgroundColor = "#00B050";
          //   } else if (rowData.step === "Declined") {
          //     style.backgroundColor = "#C00000";
          //     style.color = "white";
          //   } else if (rowData.step === "Quoted") {
          //     style.backgroundColor = "#00B050";
          //   } else if (rowData.step === "UW Questions") {
          //     style.backgroundColor = "#FFD737";
          //   } else if (Number(rowData.visitCount) > 0) {
          //     style.backgroundColor = "#FFD737";
          //   } else {
          //     style.backgroundColor = "#FFF";
          //   }
          //   return style;
          // },
          render: (rowData) => (
            <Clamp lines={2}>
              <div className="d-flex align-items-center">
                <span
                  className="mr-2"
                  style={{
                    height: "1rem",
                    width: "1rem",
                    backgroundColor: colorMapping[rowData.step],
                    color: "black",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                ></span>
                {rowData?.step || ""}
              </div>
            </Clamp>
          ),
        },
        {
          title: "Agent Name",
          field: "agentName",
          filterPlaceholder: "Filter",
          filtering: true,
          cellStyle: {
            padding: "0px 15px 0px 20px",
            minWidth: "170px",
          },
          headerStyle: {
            padding: "0px 15px 0px 20px",
            minWidth: "170px",
          },
          render: (rowData) => {
            let name = "";
            try {
              let nameMap = {
                "fubaadmin@insurepro20.com": "Fuba Admin",
                "fubaagent1@insurepro20.com": "Fuba Agent 1",
                "uwmsageer@insurepro20.com": "UW Sageer",
                "fubaagent@insurepro20.com": "Fuba Agent",
                "fubaagent2@insurepro20.com": "Fuba Agent 2",
              };
              if (rowData.agentName in nameMap) {
                name = nameMap[rowData.agentName];
              } else {
                rowData.agentName = rowData.agentName.split("@")[0];
                if (rowData.agentName.length > 28) {
                  name = `${rowData.agentName.slice(0, 28)}...`;
                } else {
                  name = rowData.agentName;
                }
              }
            } catch (error) {}
            return name;
          },
        },
        {
          title: "Upload / Download",
          disableSortBy: true,
          sorting: false,
          cellStyle: {
            minWidth: "30px",
            width: "20px",
            maxWidth: "20px",
            textAlign: "center",
            padding: " 0px 15px 0px 10px",
          },
          headerStyle: {
            padding: "0px 15px 0px 10px",
            width: "120px",
            textAlign: "center",
          },
          render: (rowData) => (
            <button
              className="btn pt-0 "
              type="button"
              onClick={() => {
                let totalFiles =
                  (domain_messageid_files[rowData.agentID]?.dashboardFiles
                    ?.length || 0) +
                  (domain_messageid_files[rowData.agentID]?.fileListToStore
                    ?.length || 0);
                this.setState({
                  showModel: true,
                  modalTitle: <h1>{"Upload/Download"}</h1>,
                  modalContent: (
                    <>
                      <UploadDashboardFile
                        tabName={rowData.agentName}
                        domainName={rowData.agentID}
                        dashboardFiles={
                          domain_messageid_files[rowData.agentID]
                            .dashboardFiles || []
                        }
                        // companyName={rowData.companyName}
                        // messageID={rowData.messageID}
                        updateDomainMessageidFiles={
                          this.updateDomainMessageidFiles
                        }
                        // instance={rowData.instance}
                      />
                      <hr></hr>
                      <DownloadDashboardFiles
                        domainName={rowData.agentID}
                        dashboardFiles={
                          domain_messageid_files[rowData?.agentID]
                            ?.dashboardFiles || []
                        }
                        fileListToStore={
                          domain_messageid_files[rowData?.agentID]
                            ?.fileListToStore || []
                        }
                        updateDomainMessageidFiles={
                          this.updateDomainMessageidFiles
                        }
                      />
                    </>
                  ),
                });
              }}
            >
              <img
                src={require("../../images/uploadDownload.png")}
                alt=""
                style={{ width: "35px" }}
              />
            </button>
          ),
        },
        // {
        //   title: "Visit Date (ET)",
        //   field: "visitDate",
        //   defaultSort: "desc",
        //   filtering: true,
        //   filterPlaceholder: "Filter",
        //   customFilterAndSearch: (term, rowData, field) =>
        //     this.customFilterAll(term, rowData, field),
        //   customSort: (a, b) => this.dateSort(a, b, "visitDate"),

        //   render: (rowData) => {
        //     if (rowData.visitDate) {
        //       let date = rowData.visitDate.split("-")[0];
        //       let time = rowData.visitDate.split("-")[1];
        //       return (
        //         <span>
        //           {date}
        //           <br></br>
        //           {time}
        //         </span>
        //       );
        //     } else {
        //       return "-";
        //     }
        //   },
        //   cellStyle: {
        //     wordBreak: "break-all",
        //      padding :" 0px 15px 0px 10px",
        //     minWidth: "100px",
        //   },
        //   headerStyle: {
        //      padding :" 0px 15px 0px 10px",
        //     minWidth: "100px",
        //   },
        // },
        // {
        //   title: "# Emp",
        //   field: "total_emp",
        //   filterPlaceholder: "Filter",
        //   customFilterAndSearch: (term, rowData, field) =>
        //     this.customFilterfunction(term, rowData, field),

        //   filtering: true,
        //   cellStyle: {
        //     textAlign: "center",
        //     padding: " 0px 15px 0px 10px",
        //     minWidth: "75px",
        //   },
        //   headerStyle: {
        //     textAlign: "left",
        //     padding: " 0px 15px 0px 10px",
        //     whiteSpace: "nowrap",
        //     minWidth: "5px",
        //   },
        // },
        // {
        //   title: "State",
        //   field: "state",
        //   filterPlaceholder: "Filter",
        //   filtering: true,
        //   customFilterAndSearch: (term, rowData, field) =>
        //     this.customFilterAll(term, rowData, field),

        //   render: (rowData) => {
        //     return rowData.state.toUpperCase();
        //   },
        //   cellStyle: {
        //     textTransform: "uppercase",
        //     padding: "0px 6px 0px 10px",
        //     minWidth: "90px",
        //   },
        //   headerStyle: {
        //     padding: "0px 6px 0px 10px",
        //     minWidth: "90px",
        //   },
        // },

        // {
        //   title: "Comments",
        //   field: "agentComment",
        //   filterPlaceholder: "Filter",
        //   filtering: true,
        //   render: (rowData) => {
        //     return (
        //       <textarea
        //         defaultValue={rowData.agentComment || ""}
        //         // onBlur={(e) => {
        //         //   if (rowData.agent === "true")
        //         //     this.updateSalesComment(e, rowData, "salesManager");
        //         //   else this.updateSalesManagerComment(e, rowData);
        //         // }}
        //         rows="2"
        //         style={{ width: "100%" }}
        //       ></textarea>
        //     );
        //   },
        //   cellStyle: {
        //     padding: "0px 15px 0px 10px",
        //     minWidth: "160px",
        //   },
        // },
      ];
    }

    if (selected === "tab2") {
      columns = [
        {
          title: "Agent Email Id",
          field: "userEmail",
          filterPlaceholder: "Filter",
          filtering: true,
          cellStyle: {
            padding: "0px 15px 0px 25px",
            minWidth: "100px",
          },
          headerStyle: {
            padding: "0px 15px 0px 25px",
            whiteSpace: "nowrap",
            minWidth: "100px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData.userEmail.length > 15) {
                name = `${rowData.userEmail.slice(0, 30)}`;
              } else {
                name = rowData.userEmail;
              }
            } catch (error) {}
            return name;
          },
        },

        {
          title: "Year",
          field: "year",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),

          filtering: true,
          cellStyle: {
            padding: " 0px 15px 0px 10px",
            minWidth: "75px",
          },
          headerStyle: {
            padding: " 0px 15px 0px 10px",
            whiteSpace: "nowrap",
            minWidth: "5px",
          },
        },
        {
          title: "Month",
          field: "month",
          filterPlaceholder: "Filter",
          filtering: true,
          cellStyle: {
            padding: "0px 15px 0px 10px",
            wordBreak: "break-word",
            minWidth: "80px",
          },
          render: (rowData) => (
            <Clamp lines={2}>{this.camelCase(rowData?.month) || ""}</Clamp>
          ),
        },
        {
          title: "Generated Entry ",
          field: "count",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),

          filtering: true,
          cellStyle: {
            padding: " 0px 15px 0px 10px",
            minWidth: "75px",
          },
          headerStyle: {
            padding: " 0px 15px 0px 10px",
            whiteSpace: "nowrap",
            minWidth: "5px",
          },
        },
      ];
    }
    // if (selected === "tab1" || selected === "tab4") {
    //   columns.unshift({
    //     title: "Upload Date",
    //     field: "uploadedTimeStamp",
    //     filterPlaceholder: "Filter",
    //     filtering: true,
    //     customFilterAndSearch: (term, rowData, field) =>
    //       this.customFilterAll(term, rowData, field),

    //     customSort: (a, b) => this.dateSort(a, b, "uploadedTimeStamp"),
    //     cellStyle: {
    //       wordBreak: "break-all",
    //       minWidth: "80px",
    //       padding: "0px 15px 0px 10px",
    //     },
    //     render: (rowData) => (
    //       <Clamp lines={1}>{rowData.uploadedTimeStamp}</Clamp>
    //     ),
    //   });
    // }
    // // if (selected === "tab1" || selected === "tab4") {
    // if (salesManagerLogin) {
    //   columns.push({
    //     title: "Comments",
    //     field: "smcomment",
    //     filterPlaceholder: "Filter",
    //     filtering: true,
    //     render: (rowData) => {
    //       return (
    //         <textarea
    //           defaultValue={rowData.smcomment || ""}
    //           onBlur={(e) => {
    //             if (rowData.sales === "true")
    //               this.updateSalesComment(e, rowData, "salesManager");
    //             else this.updateSalesManagerComment(e, rowData);
    //           }}
    //           rows="2"
    //           style={{ width: "100%" }}
    //         ></textarea>
    //       );
    //     },
    //     cellStyle: {
    //       padding: "0px 15px 0px 10px",
    //       minWidth: "160px",
    //     },
    //   });
    // } else {
    //   columns.push({
    //     title: "Comments",
    //     field: "salescomment",
    //     filterPlaceholder: "Filter",
    //     filtering: true,
    //     render: (rowData) => {
    //       return (
    //         <textarea
    //           defaultValue={rowData.salescomment || ""}
    //           onBlur={(e) => {
    //             if (rowData.sales === "true") {
    //               this.updateSalesComment(e, rowData);
    //             } else {
    //               this.updateEngagedComment(e, rowData);
    //             }
    //           }}
    //           rows="2"
    //           style={{ width: "100%" }}
    //         ></textarea>
    //       );
    //     },
    //     cellStyle: {
    //       padding: "0px 15px 0px 10px",
    //       minWidth: "160px",
    //     },
    //   });
    // }

    // columns.push({
    //   title: "Comments",
    //   field: "salescomment",
    //   render: (rowData) => {
    //     return (
    //       <textarea
    //         defaultValue={rowData.salescomment || ""}
    //         onBlur={(e) => {
    //           if (salesManagerLogin && rowData.sales !== "true") return;
    //           if (rowData.sales !== "true")
    //             this.updateEngagedComment(e, rowData);
    //           else this.updateSalesComment(e, rowData);
    //         }}
    //         rows="2"
    //         disabled={salesManagerLogin && rowData.sales !== "true" ? true : false}
    //         style={{ width: "100%" }}
    //       ></textarea>
    //     );
    //   },
    //   cellStyle: {
    //     padding: "0px 15px 0px 10px",
    //     minWidth: "160px",
    //   },
    // });

    // }

    // if (selected === "tab2" || selected === "tab3") {
    //   console.log("selected tabb: ", selected);

    //   columns.push({
    //     title: "Comments",
    //     field: "salescomment",
    //     render: (rowData) => {
    //       return (
    //         <textarea
    //           defaultValue={rowData.salescomment || ""}
    //           onBlur={(e) => {
    //             this.updateEngagedComment(e, rowData);
    //           }}
    //           rows="2"
    //           style={{ width: "100%" }}
    //         ></textarea>
    //       );
    //     },
    //     cellStyle: {
    //       padding: "0px 15px 0px 10px",
    //       minWidth: "160px",
    //     },
    //   });

    // }

    // columns.push(
    //   {
    //     title: "Visit Count",
    //     field: "visitCount",
    //     filterPlaceholder: "Filter",

    //     customFilterAndSearch: (term, rowData, field) =>
    //       this.customFilterfunction(term, rowData, field),
    //     filtering: true,
    //     cellStyle: {
    //       textAlign: "center",
    //        padding :" 0px 15px 0px 10px",
    //       minWidth: "70px",
    //     },
    //     headerStyle: {
    //       textAlign: "left",
    //        padding :" 0px 15px 0px 10px",
    //     },
    //   },
    //   {
    //     title: (
    //       <img
    //         src={require("../../images/file-download-circle-green.svg")}
    //         alt=""
    //         style={{
    //           width: "40px",
    //           // filter: "grayscale(100%)  opacity(0.4)",
    //         }}
    //       />
    //     ),
    //     disableSortBy: true,
    //     filtering: true,
    //     cellStyle: {
    //       minWidth: "20px",
    //       width: "20px",
    //       maxWidth: "20px",
    //       padding: "0 0",
    //     },
    //     render: (rowData) => {
    //       if (rowData.sales === "true") {
    //         return (
    //           <button className="btn pt-0" type="button">
    //             <img
    //               src={require("../../images/file-download-circle-green.svg")}
    //               alt=""
    //               style={{
    //                 width: "40px",
    //                 filter: "grayscale(100%)  opacity(0.4)",
    //               }}
    //             />
    //           </button>
    //         );
    //       }

    //       let title = "";

    //       if (
    //         domain_messageid_files &&
    //         domain_messageid_files[rowData.agentID]?.dashboardFiles?.length
    //       ) {
    //         for (let file of domain_messageid_files[rowData.agentID]
    //           .dashboardFiles) {
    //           let fileToDisplay = file.split("/");
    //           title =
    //             title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
    //         }
    //       }
    //       if (domain_messageid_files[rowData.agentID]?.fileListToStore?.length) {
    //         for (let file of domain_messageid_files[rowData.agentID]
    //           .fileListToStore) {
    //           let fileToDisplay = file.split("/");
    //           title =
    //             title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
    //         }
    //       }
    //       if (domain_messageid_files[rowData.agentID]?.preUwFiles?.length) {
    //         for (let file of domain_messageid_files[rowData.agentID]
    //           .preUwFiles) {
    //           let fileToDisplay = file.split("/");
    //           title =
    //             title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
    //         }
    //       }

    //       let totalFiles =
    //         (domain_messageid_files[rowData.agentID]?.dashboardFiles?.length ||
    //           0) +
    //         (domain_messageid_files[rowData.agentID]?.preUwFiles?.length || 0) +
    //         (domain_messageid_files[rowData.agentID]?.fileListToStore?.length ||
    //           0);

    //       return (
    //         <button
    //           className="btn pt-0"
    //           type="button"
    //           title={parse(title)}
    //           data-html="true"
    //           onClick={() => {
    //             this.setState({
    //               showModel: true,
    //               modalTitle: <h1>{"Download"}</h1>,
    //               modalContent: (
    //                 <>
    //                   <DownloadDashboardFiles
    //                     dashboardFiles={
    //                       domain_messageid_files[rowData.agentID]
    //                         .dashboardFiles || []
    //                     }
    //                     preUwFiles={
    //                       domain_messageid_files[rowData.agentID].preUwFiles ||
    //                       []
    //                     }
    //                     fileListToStore={
    //                       domain_messageid_files[rowData.agentID]
    //                         .fileListToStore || []
    //                     }
    //                     domainName={rowData.agentID}
    //                     updateDomainMessageidFiles={
    //                       this.updateDomainMessageidFiles
    //                     }
    //                   />
    //                 </>
    //               ),
    //             });
    //           }}
    //         >
    //           <img
    //             src={require("../../images/file-download-circle-green.svg")}
    //             alt=""
    //             style={
    //               totalFiles > 0
    //                 ? { width: "40px", filter: "grayscale(0)" }
    //                 : { width: "40px", filter: "grayscale(100%)  opacity(0.4)" }
    //             }
    //           />
    //         </button>
    //       );
    //     },
    //   },
    //   {
    //     title: "Campaign Name",
    //     field: "latestCampaign",
    //     filterPlaceholder: "Filter",
    //     filtering: true,
    //     cellStyle: {
    //       padding: "0px 15px 0px 10px",
    //       wordBreak: "break-word",
    //       minWidth: "80px",
    //     },
    //     render: (rowData) => (
    //       <Clamp lines={2}>
    //         {this.camelCase(rowData?.latestCampaign) || ""}
    //       </Clamp>
    //     ),
    //   }
    // );

    // columns.push({
    //   title: "Last Modified By",
    //   field: "salespersonName",
    //   filterPlaceholder: "Filter",
    //   filtering: true,
    //   cellStyle: {
    //     padding: "0px 15px 0px 10px",
    //     minWidth: "170px",
    //   },
    //   render: (rowData) => {
    //     let name = "";
    //     try {
    //       if (rowData.salespersonName.length > 15) {
    //         name = `${rowData.salespersonName.slice(0, 15)}...`;
    //       } else {
    //         name = rowData.salespersonName;
    //       }
    //     } catch (error) {}
    //     return name;
    //   },
    // });

    // if (selected === "tab4" || selected === "tab1") {
    //   columns.push({
    //     title: "Quote Owner",
    //     field: "quoteOwner",
    //     filtering: true,
    //     filterPlaceholder: "Filter",
    //     cellStyle: {
    //       padding: "0px 15px 0px 10px",
    //       minWidth: "150px",
    //     },
    //     render: (rowData) => {
    //       let name = "";
    //       try {
    //         if (rowData?.quoteOwner?.length > 15) {
    //           name = `${rowData?.quoteOwner.slice(0, 15)}...`;
    //         } else {
    //           name = rowData.quoteOwner;
    //         }
    //       } catch (error) {}
    //       return <Clamp lines={2}>{name || ""}</Clamp>;
    //     },
    //   });
    // }

    // columns.push({
    //   title: "Source",
    //   field: "source",
    //   filtering: true,
    //   filterPlaceholder: "Filter",
    //   // customSort: (a, b) => a.Source.length - b.Source.length,
    //   cellStyle: {
    //     padding: "0px 15px 0px 10px",
    //     minWidth: "130px",
    //   },
    //   render: (rowData) => (
    //     <Clamp lines={1}>{this.source(rowData.source) || "-"}</Clamp>
    //   ),
    // });

    // if (selected === "tab2" || selected === "tab3") {
    //   columns.push({
    //     title: "Pre UW?",
    //     filterPlaceholder: "Filter",
    //     field: "isPreUnderwritten",
    //     lookup: { true: "Yes", false: "No" },

    //     headerStyle: {
    //       padding: "6px 0 6px 0px",
    //       // width: "10px",
    //       // wordBreak: "break-all",
    //     },
    //     cellStyle: {
    //       minWidth: "50px",
    //       padding: "6px 0 6px 0px",
    //       textAlign: "center",
    //     },
    //     render: (rowData) => (rowData.isPreUnderwritten ? "YES" : "NO"),
    //   });
    // }

    // if (selected !== "tab1") {
    //   columns.push({
    //     title: "PreUW Comments",
    //     filterPlaceholder: "Filter",
    //     filtering: true,
    //     field: "comment",
    //     render: (rowData) => {
    //       // let index = rowData.tableData.id;
    //       // let { rowDataList } = JSON.parse(JSON.stringify(this.state));
    //       return (
    //         <textarea
    //           defaultValue={rowData.comment || ""}
    //           // onBlur={(e) => {
    //           //   if (rowData.sales !== "true")
    //           //     this.updateEngagedComment(e, rowData);
    //           //   else this.updateSalesComment(e, rowData);
    //           // }}
    //           rows="2"
    //           disabled
    //           style={{ width: "100%" }}
    //         ></textarea>
    //       );
    //     },
    //     cellStyle: {
    //       padding: "0px 15px 0px 10px",
    //       minWidth: "160px",
    //     },
    //   });
    // }

    // columns.push({
    //   title: "Admin Comments",
    //   field: "admincomment",
    //   filterPlaceholder: "Filter",
    //   filtering: true,
    //   render: (rowData) => {
    //     return (
    //       <textarea
    //         defaultValue={rowData.admincomment || ""}
    //         rows="2"
    //         disabled
    //         style={{ width: "100%" }}
    //       ></textarea>
    //     );
    //   },
    //   cellStyle: {
    //     padding: "0px 15px 0px 10px",
    //     minWidth: "160px",
    //   },
    // });

    // if (!salesManagerLogin) {
    //   columns.push({
    //     title: "SalesManager Comments",
    //     filterPlaceholder: "Filter",
    //     filtering: true,
    //     field: "smcomment",
    //     render: (rowData) => {
    //       return (
    //         <textarea
    //           defaultValue={rowData.smcomment || ""}
    //           disabled
    //           rows="2"
    //           style={{ width: "100%" }}
    //         ></textarea>
    //       );
    //     },
    //     cellStyle: {
    //       padding: "0px 15px 0px 10px",
    //       minWidth: "160px",
    //     },
    //   });
    // } else {
    //   columns.push({
    //     title: "SalesPerson Comments",
    //     filterPlaceholder: "Filter",
    //     filtering: true,
    //     field: "salescomment",
    //     render: (rowData) => {
    //       return (
    //         <textarea
    //           defaultValue={rowData.salescomment || ""}
    //           disabled
    //           rows="2"
    //           style={{ width: "100%" }}
    //         ></textarea>
    //       );
    //     },
    //     cellStyle: {
    //       padding: "0px 15px 0px 10px",
    //       minWidth: "160px",
    //     },
    //   });
    // }

    // if (selected !== "tab5" && selected !== "tab6") {
    //   columns.push({
    //     title: "Deal Won/Lost",
    //     field: "dealFlagFilter",
    //     render: (rowData) => {
    //       let key = rowData.agentID || rowData.salesID;
    //       let isClosed = false,
    //         isLost = false;
    //       if (key in dealMap) {
    //         isClosed = dealMap[key] === "true" ? true : false;
    //         isLost = dealMap[key] === "false" ? true : false;
    //       } else {
    //         isClosed = rowData.dealFlag === "closed" ? true : false;
    //         isLost = rowData.dealFlag === "lost" ? true : false;
    //       }
    //       return (
    //         <DealClosedLostButtons
    //           recordDetails={rowData.recordDetails}
    //           isClosed={isClosed}
    //           isLost={isLost}
    //           updateDealMap={updateDealMap}
    //           disabled={rowData.dealConfirmed === "true"}
    //         />
    //       );
    //     },
    //     cellStyle: {
    //       padding: "0px 15px 0px 10px",
    //       minWidth: "150px",
    //     },
    //   });
    // }

    return (
      <>
        <div style={{ position: "relative" }}>
          {/*{scrollLeft && (
            <button className="ChevronLeft" onClick={this.scrollLeft}>
              &lt;
            </button>
          )}
          {scrollRight && (
            <button className="ChevronRight" onClick={this.scrollRight}>
              &gt;
            </button>
          )}*/}

          <MaterialTable
            isLoading={isLoading[selected]}
            icons={tableIcons}
            data={rowDataList}
            columns={columns}
            options={{
              stickyHeader: true,
              // doubleHorizontalScroll: true,
              exportButton: {
                csv: true,
                pdf: false,
              },
              exportFileName: "Admin Table",
              filtering: true,
              filter: true,
              search: true,
              sorting: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [
                10,
                25,
                50,
                75,
                100,
                rowDataList ? rowDataList.length : "500",
              ],
              headerStyle: {
                fontSize: ismobile ? this.fontfunciton() : "1rem",
                backgroundColor: "#006699",
                color: "#FFF",
                padding: "0px 15px 0px 10px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile ? this.fontfunciton() : "0.98rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
              filterCellStyle: {
                padding: "5px 15px 5px 4px",
              },
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  labelRowsPerPage={
                    <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 12 }}>
                      {props.labelDisplayedRows(row)}
                    </div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                />
              ),
            }}
            // detailPanel={
            //   tabName === "tab2" || tabName === "tab3"
            //     ? [
            //         {
            //           tooltip: "Client info",
            //           icon: () => <KeyboardArrowRightIcon />,
            //           render: (rowData) => {
            //             return this.tab2DetailPanel(rowData);
            //           },
            //         },
            //       ]
            //     : tabName === "tab1"
            //     ? [
            //         {
            //           tooltip: "Client info",
            //           icon: () => <KeyboardArrowRightIcon />,
            //           render: (rowData) => {
            //             return this.tab1DetailPanel(rowData);
            //           },
            //         },
            //       ]
            //     : tabName === "tab4"
            //     ? [
            //         {
            //           tooltip: "Client info",
            //           icon: () => <KeyboardArrowRightIcon />,
            //           render: (rowData) => {
            //             if ("true" === rowData?.sales) {
            //               return this.tab1DetailPanel(rowData);
            //             } else {
            //               return this.tab2DetailPanel(rowData);
            //             }
            //           },
            //         },
            //       ]
            //     : tabName === "tab5" || tabName === "tab6"
            //     ? [
            //         {
            //           tooltip: "Client info",
            //           icon: () => <KeyboardArrowRightIcon />,
            //           render: (rowData) => {
            //             if ("true" === rowData?.sales) {
            //               return this.tab1DetailPanel(rowData);
            //             } else {
            //               return this.tab2DetailPanel(rowData);
            //             }
            //           },
            //         },
            //       ]
            //     : false
            // }
            // detailPanel={(rowData) => {}} // to show the all tab details disable this and enable aboove code
          />
        </div>
      </>
    );
  };

  render() {
    let { showModel, modalTitle, modalContent } = this.state;
    let { tabName, selected } = this.props;
    return (
      <>
        {selected === tabName ? (
          <div
            className={`sales-dashboard-${tabName}`}
            style={this.state.style}
          >
            <div ref={this.myRef}>{this.setTableData()}</div>
            <Modal
              show={showModel}
              onHide={() => {
                this.setState({
                  showModel: false,
                  modalTitle: null,
                  modalContent: null,
                });
              }}
              dialogClassName="model-download modal-dialog-centered"
            >
              <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{modalContent}</Modal.Body>
            </Modal>
          </div>
        ) : (
          ""
        )}
        <Toaster />
      </>
    );
  }
}

export default DashboardAdmin;
