import { awsUrl, awsUrl2 } from "../config";
import React, { Component } from "react";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import { acordQues, extraQues } from "../utils/ques_list";
import { mergePdf } from "../component/common/MergePdf";
import {
  deleteAllCookies,
  showErrAlert,
  getHeader,
  blobPdfFromBase64String,
} from "./common";
import { Auth } from "aws-amplify";
import { checkMailCampaignUser } from "../utils/check_mail_campaign_user";

const uuidv4 = require("uuid/v4");
const numeral = require("numeral");
var sizeof = require("object-sizeof");
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");

export let salesPersonLoginCheck = () => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      let groups;
      if (sessionData) {
        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.includes(process.env.REACT_APP_SALES_GROUP)) {
          resolve({
            salespersonName: sessionData.attributes.email,
            salespersonNameAttr: sessionData.attributes.name,
            salespersonCheck: true,
          });
        } else {
          resolve({
            salespersonName: "",
            salespersonCheck: false,
          });
        }
      } else {
        resolve({
          salespersonName: "",
          salespersonCheck: false,
        });
      }
    } catch (error) {
      resolve({
        salespersonName: "",
        salespersonCheck: false,
      });
    }
  });

  return promise;
};

export let agentPersonLoginCheck = () => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      let username =
        sessionData?.attributes?.name ||
        sessionData.attributes.email.split("@")[0];
      let groups;
      if (sessionData) {
        // console.log("sessionData: ", sessionData);

        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.includes(process.env.REACT_APP_AGENT_GROUP)) {
          resolve({
            agentpersonName: sessionData.attributes.email,
            agentpersonNameAttr: sessionData.attributes.name,
            agentpersonCheck: true,
            agentusername: username,
          });
        } else {
          resolve({
            agentpersonName: "",
            agentpersonCheck: false,
            agentusername: username,
          });
        }
      } else {
        resolve({
          agentpersonName: "",
          agentpersonCheck: false,
          agentusername: username,
        });
      }
    } catch (error) {
      resolve({
        agentpersonName: "",
        agentpersonCheck: false,
      });
    }
  });

  return promise;
};

export let adminPersonLoginCheck = () => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let username =
        sessionData?.attributes?.name ||
        sessionData.attributes.email.split("@")[0];
      let sessionData = await Auth.currentAuthenticatedUser();

      let groups;
      if (sessionData) {
        // console.log("sessionData: ", sessionData);
        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.includes(process.env.REACT_APP_ADMIN_GROUP)) {
          resolve({
            adminName: sessionData.attributes.email,
            adminNameAttr: sessionData.attributes.name,
            adminpersonCheck: true,
            username: username,
          });
        } else {
          resolve({
            adminName: "",
            adminpersonCheck: false,
            username: username,
          });
        }
      } else {
        resolve({
          adminName: "",
          adminpersonCheck: false,
          username: username,
        });
      }
    } catch (error) {
      resolve({
        adminpersonName: "",
        adminpersonCheck: false,
      });
    }
  });

  return promise;
};

export let preUwPersonLoginCheck = () => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      let groups;
      if (sessionData) {
        // console.log("sessionData: ", sessionData);
        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.includes(process.env.REACT_APP_PREUW_GROUP)) {
          resolve({
            PreUwPersonName: sessionData.attributes.email,
            PreUwPersonNameAttr: sessionData.attributes.name,
            PreUwCheck: true,
          });
        } else {
          resolve({
            PreUwPersonName: "",
            PreUwCheck: false,
          });
        }
      } else {
        resolve({
          PreUwPersonName: "",
          PreUwCheck: false,
        });
      }
    } catch (error) {
      resolve({
        PreUwPersonName: "",
        PreUwCheck: false,
      });
    }
  });
  return promise;
};

export let salesManagerLoginCheck = () => {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let sessionData = await Auth.currentAuthenticatedUser();
      let groups;
      if (sessionData) {
        // console.log("sessionData: ", sessionData);
        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups.includes(process.env.REACT_APP_SALES_MANAGER_GROUP)) {
          resolve({
            salesManagerPersonName: sessionData.attributes.email,
            salesManagerPersonNameAttr: sessionData.attributes.name,
            salesManagerCheck: true,
          });
        } else {
          resolve({
            salesManagerPersonName: "",
            salesManagerCheck: false,
          });
        }
      } else {
        resolve({
          salesManagerPersonName: "",
          salesManagerCheck: false,
        });
      }
    } catch (error) {
      resolve({
        salesManagerPersonName: "",
        salesManagerCheck: false,
      });
    }
  });
  return promise;
};

export let checkQuestionsValidity = (quesList) => {
  let validQuestions = true;
  console.log("All Questions :", quesList);
  for (let question of [...quesList]) {
    if (question.response !== true && question.response !== false) {
      console.log("Invalid question here+++", question.ques);
      validQuestions = false;
      break;
    }
    if (question?.name === "q23" && question?.response === false) {
      console.log(question.ques);
      validQuestions = false;
      break;
    }
  }

  return validQuestions;
};

export function amtrustResponse(uuid, apiType) {
  let promise = new Promise(async (resolve, reject) => {
    try {
      let res = await axios.get(
        awsUrl2 + `/api/getAmtrustData/${uuid}/${apiType}`
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });

  return promise;
}

function getAmtrustEligibility(amtrustQuesList, uwExpectedResponses) {
  let eligibility = "approved";
  try {
    let uwExpectedMap = {};
    for (let question of uwExpectedResponses) {
      uwExpectedMap[question.paygoName] = question;
    }

    console.log(amtrustQuesList, uwExpectedMap);
    for (let question of amtrustQuesList) {
      if (
        question.name in uwExpectedMap &&
        uwExpectedMap[question.name]?.type === "hospitality"
      ) {
        let questionEligibility = question.response
          ? uwExpectedMap[question.name]?.ifYes
          : uwExpectedMap[question.name]?.ifNo;
        if (
          (questionEligibility === "referred" && eligibility !== "declined") ||
          questionEligibility === "declined"
        ) {
          eligibility = questionEligibility;
        }
      }
    }
  } catch (error) {
    console.log("error in uw amtrust eligibility");
  }

  return eligibility;
}

function getFourthQuestionsEligibility(uwQuesData) {
  let eligibility = "approved";
  try {
    for (let question of uwQuesData) {
      let questionEligibility =
        question.response === "Y" ? question.ifYes : question.ifNo;
      if (
        (questionEligibility === "referred" && eligibility !== "declined") ||
        questionEligibility === "declined"
      ) {
        eligibility = questionEligibility;
      }
    }
  } catch (error) {
    console.log("error in uw fourth eligibility");
  }

  return eligibility;
}

export function getUwQuestionsEligibility({
  uwQuesData,
  amtrustQuesList,
  uwExpectedResponses,
  acordQuesAvailable,
}) {
  let amtrustEligibility = "approved";
  let fourthQuestionsEligibility = "approved";
  let eligibility = "approved";
  if (amtrustQuesList && amtrustQuesList?.length > 0) {
    amtrustEligibility = getAmtrustEligibility(
      amtrustQuesList,
      uwExpectedResponses
    );
  }

  if (uwQuesData && uwQuesData?.length > 0 && !acordQuesAvailable) {
    fourthQuestionsEligibility = getFourthQuestionsEligibility(uwQuesData);
  }

  for (let questionEligibility of [
    amtrustEligibility,
    fourthQuestionsEligibility,
  ]) {
    if (
      (questionEligibility === "referred" && eligibility !== "declined") ||
      questionEligibility === "declined"
    ) {
      eligibility = questionEligibility;
    }
  }

  return eligibility;
}

export function getClassCodeEligibility(childrenLoc) {
  let eligibility = "approved";
  try {
    let classCodeExceptions = [9084];
    let stateCcExceptions = { ny: [8006], nj: [8006] };
    let stateExceptions = ["ca"];

    for (let locIndex in childrenLoc) {
      let state = childrenLoc[locIndex].state.value.toLowerCase();
      if (stateExceptions.includes(state)) {
        eligibility = "declined";
      }
      let classCodeExceptionList = [...classCodeExceptions];
      if (stateCcExceptions?.[state]?.length > 0) {
        classCodeExceptionList.push(...stateCcExceptions[state]);
      }

      for (let classCodeIndex in childrenLoc[locIndex].classCodesInfo) {
        let classCode = childrenLoc[locIndex].classCodesInfo[
          classCodeIndex
        ].classCodeDescription.value
          .split(":")[0]
          .trim();

        if (classCodeExceptionList.includes(Number(classCode))) {
          eligibility = "declined";
        }
      }
    }
  } catch (error) {
    console.log("error in class code eligibility", error);
  }

  return eligibility;
}

export function getHospitalityEligibility(dataBody) {
  return new Promise(async (resolve, reject) => {
    let eligibility = "approved";
    let hospitalityExists, nonHospitalityExists;
    try {
      let classCodeTypeData, isHospitalityClient;

      let classCodeTypeDataPromise = axios.post(
        awsUrl2 + "/api/getClassCodesIndustry",
        dataBody.offices
      );

      let isHospitalityClientPromise = axios.post(
        awsUrl2 + `/api/getHospitalityClassCodes`,
        dataBody
      );

      classCodeTypeData = await classCodeTypeDataPromise;
      isHospitalityClient = await isHospitalityClientPromise;

      for (let state in classCodeTypeData.data) {
        for (let classCode in classCodeTypeData.data[state]) {
          let type = classCodeTypeData.data[state][classCode];
          if (type !== "hospitality") {
            nonHospitalityExists = true;
          }
        }
      }

      hospitalityExists = isHospitalityClient?.data?.value || false;

      if (!hospitalityExists) {
        eligibility = "declined";
      } else if (nonHospitalityExists) {
        eligibility = "referred";
      }
    } catch (error) {
      console.log(error);
    } finally {
      resolve(eligibility);
    }
  });
}

export function sendFailedQuoteMail(userDetails) {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(awsUrl2 + "/api/sendQuoteFailedMail", userDetails)
        .then((res) => resolve())
        .catch((err) => reject());
    } catch (error) {
      reject();
    }
  });
}

export function sendAcordQuoteEmail(
  userDetails,
  etQuoteDetails,
  fileListToStore,
  dashboardFiles,
  quotePdfUuid,
  finalEligibility
) {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(awsUrl2 + "/api/sendAcordQuoteEmail", {
          userDetails,
          etQuoteDetails,
          fileListToStore,
          dashboardFiles,
          quotePdfUuid,
          finalEligibility,
        })
        .then((res) => resolve())
        .catch((err) => reject());
    } catch (error) {
      reject();
    }
  });
}

export async function convertToBuffer(pdfBlob) {
  const reader = new FileReader();
  const bufferPromise = new Promise((resolve, reject) => {
    reader.onloadend = () => {
      const buffer = Buffer.from(reader.result);
      resolve(buffer);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(pdfBlob);
  });

  try {
    const buffer = await bufferPromise;
    return buffer;
  } catch (error) {
    throw error;
  }
}

export function fubaQuotePdfGenerator(quoteGenerate) {
  return new Promise(async (resolve, reject) => {
    try {
      $("#loader").css("display", "block");

      let currProspect = sessionStorage.getItem("currProspect");
      let agentID = sessionStorage.getItem("agentID");
      let quoteData = sessionStorage.getItem("quoteData");
      let parCurrPro = JSON.parse(currProspect);
      let agency_name = parCurrPro.agency_name || "";
      let agent_name = parCurrPro.agent_name || "";
      let uw_name = parCurrPro.uw_name || "";

      if (!currProspect || !agentID || !quoteData)
        throw "Error: One or two values do not exist.";

      let { companyProfile, childrenLoc } = JSON.parse(currProspect);
      let { date } = JSON.parse(quoteData);

      let totalEmployees = 0,
        stateList = [];

      for (let stateKey in childrenLoc) {
        if (childrenLoc[stateKey]?.state?.value?.toLowerCase()) {
          stateList.push(childrenLoc[stateKey].state.value.toLowerCase());

          for (let ccKey in childrenLoc[stateKey].classCodesInfo) {
            totalEmployees = numeral(totalEmployees)
              .add(
                Number(
                  childrenLoc?.[stateKey]?.classCodesInfo?.[ccKey]?.ft?.value
                )
              )
              .add(
                Number(
                  childrenLoc?.[stateKey]?.classCodesInfo?.[ccKey]?.pt?.value ||
                  0
                )
              )
              .value();
          }
        }
      }

      axios
        .post(
          awsUrl2 + "/api/generateFQuotePdf",
          JSON.stringify({
            firstPageDetails: {
              email: agentID,
              timestamp: date,
              stateList: stateList,
              agency_name: agency_name,
              agent_name: agent_name,
              uw_name: uw_name,
            },
            secondPageDetails: {
              name_of_business: companyProfile?.companyName?.value || "",
              doing_business_as:
                companyProfile?.descriptionOfOperations?.value || "",
              mailing_address: companyProfile?.street2?.value,
              city: companyProfile?.cityName1?.value || "",
              state: companyProfile?.state1?.value || "",
              zip_code: companyProfile?.zipCode1?.value || "",
              contact_person:
                (companyProfile?.firstName?.value || "") +
                (companyProfile?.lastName?.value || ""),
              physical_address: companyProfile?.street1?.value,
              city2: companyProfile?.cityName2?.value || "",
              state2: companyProfile?.state2?.value || "",
              zip_code2: companyProfile?.zipCode2?.value || "",
              phone_number: companyProfile?.phoneNumber?.value || "",
              fax: "",
              email: "",
              number_of_employees: totalEmployees,
              signature: "",
              date: "",
              office_use_date: "",
              office_use_policy: "",
              office_use_check: "",
              office_use_agent: "",
              agency_name: agency_name,
              agent_name: agent_name,
              uw_name: uw_name,
            },
          })
        )
        .then(async (res) => {
          console.log(res?.data);
          let fileList = [];

          if (res?.data?.firstPageStream) {
            let _blobObj = blobPdfFromBase64String(
              res.data.firstPageStream.data
            );
            fileList.push(_blobObj);
          }
          if (res?.data?.streamData) {
            let _blobObj2 = blobPdfFromBase64String(res.data.streamData.data);
            fileList.push(_blobObj2);
          }

          let finalPdf = await mergePdf(fileList);
          const buffer = await convertToBuffer(finalPdf);
          resolve(buffer);

          // else {
          //   console.log("Clicked inside else")
          //   let url = URL.createObjectURL(finalPdf);
          //   const link = document.createElement("a");

          //   // create a blobURI pointing to our Blob
          //   link.href = url;
          //   link.download = `Quote.pdf`;

          //   // some browser needs the anchor to be in the doc
          //   document.body.append(link);
          //   link.click();
          //   link.remove();

          //   // in case the Blob uses a lot of memory
          //   setTimeout(() => URL.revokeObjectURL(link.href), 7000);
          //   $("#loader").css("display", "none");
          //   resolve();
          // }
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      console.log(error);
      $("#loader").css("display", "none");
      reject(error);
    }
  });
}

export function downloadFubaDeclinationQuotePdf(
  declineEligibilityStmtList,
  quoteData1,
  quoteGenerate
) {
  $("#loader").css("display", "block");
  return new Promise(async (resolve, reject) => {
    try {
      // let { declineEligibilityStmtList } = this.state;
      let currProspect = sessionStorage.getItem("currProspect");
      let date;
      let quoteData;
      if (quoteData1) {
        quoteData = quoteData1;
        date = quoteData1.date;
      } else {
        quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
        date = quoteData.date;
      }
      if (!date) {
        let sessionData = JSON.parse(sessionStorage.getItem("quoteData"));
        date = sessionData.date;
      }

      if (!quoteData) throw new Error("Error: One or two values do not exist.");

      let req_body_date = moment(date, "x").format("MMMM DD, YYYY");

      let agentID = sessionStorage.getItem("agentID");
      let totalEmployees = 0,
        stateList = [];
      let { companyProfile, childrenLoc } = JSON.parse(currProspect);
      for (let stateKey in childrenLoc) {
        if (childrenLoc[stateKey]?.state?.value?.toLowerCase()) {
          stateList.push(childrenLoc[stateKey].state.value.toLowerCase());

          for (let ccKey in childrenLoc[stateKey].classCodesInfo) {
            totalEmployees = numeral(totalEmployees)
              .add(
                Number(
                  childrenLoc?.[stateKey]?.classCodesInfo?.[ccKey]?.ft?.value
                )
              )
              .add(
                Number(
                  childrenLoc?.[stateKey]?.classCodesInfo?.[ccKey]?.pt?.value ||
                  0
                )
              )
              .value();
          }
        }
      }
      let subNo = agentID.split("_");
      subNo.reverse();
      let sub_digit = subNo[0].substring(subNo[0].length - 6);

      const res = await axios.post(
        awsUrl2 + "/api/generateFQuoteDeclinationPdf",
        JSON.stringify({
          email: agentID,
          timestamp: date,
          date: req_body_date,
          stateList: stateList,
          statements: declineEligibilityStmtList,
          sub_num: sub_digit,
        })
      );

      let _blobObj = blobPdfFromBase64String(res.data.streamData.data);
      const buffer = await convertToBuffer(_blobObj);
      resolve(buffer);

      //  else {
      //   let url = URL.createObjectURL(_blobObj);
      //   const link = document.createElement("a");

      //   link.href = url;
      //   link.download = `DeclinationQuote.pdf`;

      //   document.body.append(link);
      //   link.click();
      //   link.remove();

      //   setTimeout(() => URL.revokeObjectURL(link.href), 7000);

      //   $("#loader").css("display", "none");
      //   resolve();
      // }
    } catch (error) {
      console.log(error);
      $("#loader").css("display", "none");
      reject(error);
    }
  });
}

export function updateQuesList(e, key, value, currIndex) {
  let { quesList } = JSON.parse(JSON.stringify(this.state));
  let index = e.target.id.split("-")[1];
  quesList[index][key] = value;
  if (key === "remarks" && quesList[index]["type"] === "input") {
    console.log({ value });
    quesList[index]["response"] = value.length > 0 ? true : "";
  }
  if (
    key === "response" &&
    quesList[currIndex]["childrenQues"] &&
    quesList[currIndex]?.type !== "input"
  ) {
    const extraQuestionsToRemove = value
      ? quesList[currIndex]["childrenQues"]["ifNo"] || []
      : quesList[currIndex]["childrenQues"]["ifYes"] || [];
    const extraQuestionsToAdd = value
      ? quesList[currIndex]["childrenQues"]["ifYes"] || []
      : quesList[currIndex]["childrenQues"]["ifNo"] || [];
    const indexToRemove = [];
    for (let i = 0; i < quesList.length; i++) {
      if (extraQuestionsToRemove.includes(quesList[i].name))
        indexToRemove.push(i);
    }

    indexToRemove.reverse();
    for (let deleteIndex of indexToRemove) {
      quesList.splice(deleteIndex, 1);
    }
    for (let ques of extraQuestionsToAdd) {
      quesList.splice(currIndex + 1, 0, extraQues[ques]);
    }
  }
  this.setState({ quesList });
}

export function updateAmtrustQuesList(e, key, value) {
  let { amtrustQuesList } = this.state;
  let index = e.target.id.split("-")[1];
  amtrustQuesList[index][key] = value;
  this.setState({ amtrustQuesList });
}

export function updateAddQuesList(e, key, value) {
  let { quesListAdd } = this.state;
  let index = Number(e.target.id.split("-")[1]) - 25;
  quesListAdd[index][key] = value;
  this.setState({ quesListAdd });
}

export function appendAddQuesList(index, questionsList) {
  let { quesListAdd } = this.state;
  questionsList.forEach((question) => {
    quesListAdd[index] = question;
    index++;
  });
  this.setState({ quesListAdd });
}

export function updateEmodRates(i, value, key) {
  let { quesListAdd } = this.state;
  if (!quesListAdd[i].rates) {
    quesListAdd[i].rates = {};
  }
  quesListAdd[i].rates[key] = value;
  this.setState({ quesListAdd });
}

// export async function submitQues(
//   quesList,
//   amtrustQuesList,
//   updatePopUpData,
//   acordQuesAvailable,
//   uwExpectedResponses
// ) {
//   $("#loader").css("display", "block");
//   let validQuestions = checkQuestionsValidity(quesList, amtrustQuesList);
//   if (!validQuestions) {
//     $("#loader").css("display", "none");
//     this.setState({ incompleteQuestions: true });
//     return;
//   }
//   try {
//     let currProspectDetails = JSON.parse(
//       sessionStorage.getItem("currProspect")
//     );

//     let isSalesforce = sessionStorage.getItem("isSalesforce");
//     let sfQuoteId = sessionStorage.getItem("sfQuoteId");

//     let uwAmtrustQuesList = sessionStorage.getItem("uwAmtrustQuesList");
//     if (uwAmtrustQuesList) {
//       uwAmtrustQuesList = JSON.parse(uwAmtrustQuesList);
//     }

//     let uwQuesList = sessionStorage.getItem("uwQuesList");
//     if (uwQuesList) {
//       uwQuesList = JSON.parse(uwQuesList);
//     }

//     let preUwData;
//     try {
//       preUwData = JSON.parse(sessionStorage.getItem("preUwData"));
//     } catch (error) {}

//     let salespersonMailCampaign = sessionStorage.getItem(
//       "salespersonMailCampaign"
//     );

//     let amtrustAccountDetails = sessionStorage.getItem("amtrustAccountDetails");
//     try {
//       amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
//     } catch (error) {
//       amtrustAccountDetails = {};
//     }

//     let amtrustAccountDetailsCopy = sessionStorage.getItem(
//       "amtrustAccountDetailsCopy"
//     );
//     try {
//       amtrustAccountDetailsCopy = JSON.parse(amtrustAccountDetailsCopy);
//     } catch (error) {}

//     let {
//       agentpersonName,
//       agentpersonCheck,
//       agentpersonNameAttr,
//     } = await agentPersonLoginCheck();
//     let {
//       salespersonName,
//       salespersonCheck,
//       salespersonNameAttr,
//     } = await salesPersonLoginCheck();

//     let agentID;
//     try {
//       agentID = sessionStorage.getItem("agentID");
//     } catch (error) {
//       console.log(error);
//     }

//     let uwQuesData = [];
//     let acordQuesData = [];
//     let carrierUserData = [];

//     for (let question of quesList) {
//       let dataQues = {
//         response: question.response ? "Y" : "N",
//         name: question.name,
//         ques: question.ques,
//         ifYes: question.ifYes,
//         ifNo: question.ifNo,
//       };
//       uwQuesData.push(dataQues);
//     }

//     for (let question of acordQues) {
//       let acordDataQues = {
//         response: "",
//         name: question.name,
//         ques: question.ques,
//       };
//       let answerObj = uwQuesData?.find((e) => e.name === question.name);
//       if (answerObj?.response) {
//         acordDataQues.response = answerObj.response;
//       }
//       acordQuesData.push(acordDataQues);
//     }

//     currProspectDetails.uwQues = uwQuesData;
//     if (amtrustQuesList && amtrustQuesList?.length > 0) {
//       currProspectDetails.amtrustQuesList = amtrustQuesList;
//     }
//     if (uwExpectedResponses) {
//       currProspectDetails.uwExpectedResponses = uwExpectedResponses;
//     }

//     sessionStorage.setItem("currProspect", JSON.stringify(currProspectDetails));

//     let admin_coefficient = {
//       a: 52,
//       b: 26,
//       c: 24,
//       d: 12,
//     };

//     const {
//       childrenLoc,
//       companyProfile,
//       emodStatesData,
//       uwQues,
//       requestedPricing,
//       brokerDetails,
//     } = currProspectDetails;
//     if (childrenLoc && companyProfile) {
//       // let { salespersonName, salespersonCheck } = await salesPersonLoginCheck();

//       let requested_admin = {};
//       if (requestedPricing?.checked && requestedPricing?.adminPercent) {
//         requested_admin = {
//           value: requestedPricing.adminPercent,
//           type: "percent",
//         };
//       } else if (!requestedPricing?.checked && requestedPricing?.perCKdollar) {
//         requested_admin = {
//           value: requestedPricing.perCKdollar.split("$")[1],
//           type: "percheck",
//         };
//       }

//       let dataBody = {
//         common: {
//           el_limits: companyProfile?.empLiabilityLimit?.value,
//           date_from: moment(companyProfile.effectiveDate.value).format(
//             "YYYY-MM-DD"
//           ),
//           date_to: moment(companyProfile.effectiveDate.value)
//             .add(1, "y")
//             .format("YYYY-MM-DD"),
//           emod: [],
//           questions: [],
//           sr_carriers: {},
//           requested_admin,
//         },
//         offices: [],
//       };

//       if (requestedPricing?.checked === false) {
//         dataBody.common.coefficient =
//           admin_coefficient[requestedPricing["WeeklySelect"]];
//       }

//       try {
//         if (
//           brokerDetails &&
//           brokerDetails?.brokerQuestionValue &&
//           brokerDetails?.brokerPercentage
//         ) {
//           dataBody.common.broker_fee = brokerDetails.brokerPercentage.split(
//             "%"
//           )[0];
//         }
//       } catch (error) {
//         console.log(error);
//       }

//       let emodForMail;

//       for (let state in emodStatesData) {
//         let emodObj = {};
//         if (emodStatesData[state].response) {
//           emodObj = {
//             value_1: emodStatesData[state].rate1.value,
//             effective_date_1: moment(emodStatesData[state].date1.value).format(
//               "YYYY-MM-DD"
//             ),
//           };

//           if (emodForMail == null && emodStatesData[state].rate1.value)
//             emodForMail = emodStatesData[state].rate1.value;

//           emodObj.stateList =
//             state === "ncci" ? emodStatesData[state].stateList : [state];
//           if (emodStatesData[state].displayMore) {
//             emodObj.value_2 = emodStatesData[state].rate2.value;
//             emodObj.effective_date_2 = moment(
//               emodStatesData[state].date2.value
//             ).format("YYYY-MM-DD");
//           }

//           dataBody.common.emod.push(emodObj);
//         }
//       }

//       let payrollMap = {};
//       for (let addressBlockNo in childrenLoc) {
//         let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${
//           childrenLoc[addressBlockNo].state.value
//         }_${
//           (childrenLoc[addressBlockNo].zipCode ||
//             childrenLoc[addressBlockNo].zipcode)["value"]
//         }`;
//         let officesObj = {
//           name,
//           state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
//           requested_suta: "",
//           client_suta: "",
//           employees: [],
//           carriers: ["carrier_ax"],
//         };

//         for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
//           let classCodeDescArray = childrenLoc[addressBlockNo].classCodesInfo[
//             addressChildNo
//           ].classCodeDescription.value.split(":");

//           let payroll =
//             childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
//               .value;

//           let code = classCodeDescArray.shift();
//           let desc = classCodeDescArray.join(":").trim();
//           let state = childrenLoc[addressBlockNo].state.value.toLowerCase();
//           officesObj.requested_suta =
//             childrenLoc[addressBlockNo].suta &&
//             childrenLoc[addressBlockNo].suta.value
//               ? childrenLoc[addressBlockNo].suta.value.split("%")[0]
//               : "";
//           officesObj.client_suta =
//             childrenLoc[addressBlockNo].clientsuta &&
//             childrenLoc[addressBlockNo].clientsuta.value
//               ? childrenLoc[addressBlockNo].clientsuta.value.split("%")[0]
//               : "";

//           if (!payrollMap[state]) {
//             payrollMap[state] = {};
//           }

//           payrollMap[state][code] = payroll;

//           let classCodeObj = {
//             code,
//             payroll: childrenLoc[addressBlockNo].classCodesInfo[
//               addressChildNo
//             ].payroll.value
//               .split("$")[1]
//               .split(",")
//               .join(""),
//             ft: childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].ft
//               .value
//               ? String(
//                   childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].ft
//                     .value
//                 ).trim()
//               : "0",
//             pt: childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pt
//               .value
//               ? String(
//                   childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pt
//                     .value
//                 ).trim()
//               : "0",
//             desc: desc.toLowerCase(),
//             pricing:
//               childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
//                 .pricing &&
//               childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pricing
//                 .value
//                 ? childrenLoc[addressBlockNo].classCodesInfo[
//                     addressChildNo
//                   ].pricing.value.split("%")[0]
//                 : "",
//           };
//           officesObj.employees.push(classCodeObj);
//         }
//         dataBody.offices.push(officesObj);
//       }

//       let quoteFactors = {
//         teap: 0,
//         total: 0,
//         futaCharge: 0,
//         ficaCharge: 0,
//         sutaCharge: 0,
//         payroll: 0,
//         adminFee: 0,
//       };

//       let eligibilityMap = {
//         approved: 1,
//         referred: 2,
//         declined: 3,
//       };

//       let uwQuestionsEligibility = getUwQuestionsEligibility({
//         uwQuesData,
//         amtrustQuesList,
//         uwExpectedResponses,
//         acordQuesAvailable,
//       });

//       let classCodeEligibility = getClassCodeEligibility(
//         currProspectDetails.childrenLoc
//       );

//       let hospitalityEligibility = await getHospitalityEligibility(dataBody);

//       let clientEligibility;
//       let eligibilityList = [
//         uwQuestionsEligibility || "approved",
//         classCodeEligibility || "approved",
//         hospitalityEligibility || "approved",
//       ];
//       try {
//         clientEligibility = eligibilityList.reduce((a, b) => {
//           if (eligibilityMap[a] > eligibilityMap[b]) {
//             return a;
//           }
//           return b;
//         });
//       } catch (error) {
//         clientEligibility = "approved";
//       }

//       let res;
//       try {
//         res = await axios.post(
//           awsUrl + "/api/getQuote",
//           JSON.stringify(dataBody)
//         );
//       } catch (error) {
//         console.log("error", error);
//         sessionStorage.setItem("quoteFactors", JSON.stringify(quoteFactors));

//         //mail for failed quote generation
//         // let sessionData;
//         // try {
//         //   sessionData = await Auth.currentAuthenticatedUser();
//         // } catch (error) {}

//         // let userDetails = {
//         //   companyName: companyProfile.companyName.value,
//         //   loggedInUser: sessionData
//         //     ? `${sessionData.attributes.name} (${sessionData.attributes.email})`
//         //     : "",
//         // };
//         // await sendFailedQuoteMail(userDetails);

//         $("#loader").css("display", "none");
//         window.location.reload();
//       }
//       let dropped = true;
//       let data = res.data;
//       for (let location in data) {
//         if (data[location]["carrier_ax"] !== "Dropped") {
//           dropped = false;
//           quoteFactors.adminFee = data[location]["carrier_ax"].admin_fee;
//           quoteFactors.teap += Number(
//             data[location]["carrier_ax"].total_estimated_annual_premium
//           );
//           quoteFactors.total += Number(data[location]["carrier_ax"].total);
//           quoteFactors.futaCharge = Number(data[location]["carrier_ax"].futa);
//           quoteFactors.ficaCharge = Number(data[location]["carrier_ax"].fica);
//           quoteFactors.sutaCharge = Number(data[location]["carrier_ax"].suta);
//         }
//       }

//       if (!dropped) {
//         let prospectAddresses = {};

//         // create the prospect address object
//         for (let stateIndex in childrenLoc) {
//           prospectAddresses[stateIndex] = {
//             stateCode: childrenLoc[stateIndex].state.value,
//             addressId: childrenLoc[stateIndex].number,
//             classCodes: [],
//           };
//           for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
//             let classCodeDescArray = childrenLoc[stateIndex].classCodesInfo[
//               classCodeIndex
//             ].classCodeDescription.value.split(":");

//             let code = classCodeDescArray.shift();
//             let desc = classCodeDescArray.join(":").trim();

//             prospectAddresses[stateIndex].classCodes.push({
//               code,
//               payroll: numeral(
//                 childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll
//                   .value
//               )
//                 .value()
//                 .toString(),
//               pt: childrenLoc?.[stateIndex]?.classCodesInfo?.[classCodeIndex]
//                 ?.pt?.value
//                 ? String(
//                     childrenLoc[stateIndex].classCodesInfo[classCodeIndex].pt
//                       .value
//                   ).trim()
//                 : "0",
//               ft: childrenLoc?.[stateIndex]?.classCodesInfo?.[classCodeIndex]
//                 ?.ft?.value
//                 ? String(
//                     childrenLoc[stateIndex].classCodesInfo[classCodeIndex].ft
//                       .value
//                   ).trim()
//                 : "0",
//               description: desc,
//             });
//             quoteFactors.payroll += numeral(
//               childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll
//                 .value
//             ).value();
//           }
//         }

//         let currentDate = Math.floor(Date.now()).toString();
//         let visitTimestamp = sessionStorage.getItem("visitTimestamp");
//         let uuid = sessionStorage.getItem("uuid");
//         if (!uuid) {
//           uuid = uuidv4();
//         }
//         let sortKeyList = [];
//         let address = [];
//         let quoteData;

//         let domain = sessionStorage.getItem("domain");
//         let source = sessionStorage.getItem("source") || "";

//         let primay_key = domain
//           ? domain
//           : salespersonCheck && agentID
//           ? agentID.toLowerCase()
//           : companyProfile.companyName && companyProfile.companyName.value
//           ? companyProfile.companyName.value.toLowerCase()
//           : "";

//         let dashboardFiles = sessionStorage.getItem("dashboardFiles");
//         try {
//           if (dashboardFiles) dashboardFiles = JSON.parse(dashboardFiles);
//           else dashboardFiles = [];
//         } catch (err) {}

//         let preUwFiles = sessionStorage.getItem("preUwFiles");
//         try {
//           if (preUwFiles) preUwFiles = JSON.parse(preUwFiles);
//           else preUwFiles = [];
//         } catch (err) {}

//         for (let place in data) {
//           address.push(place);
//           for (let carrier in data[place]) {
//             if (data[place][carrier] !== "Dropped") {
//               let tempData = {
//                 user_email_id: `${primay_key}`,
//                 uuid_carrier: `${currentDate}@${uuid}_${carrier}+${place}`,
//                 date: currentDate,
//                 visitDate: visitTimestamp,
//                 effective_date: moment(
//                   companyProfile.effectiveDate.value
//                 ).format("YYYY-MM-DD"),
//                 expiration_date: moment(companyProfile.effectiveDate.value)
//                   .add(1, "y")
//                   .format("YYYY-MM-DD"),
//                 prospect_addresses: prospectAddresses,
//                 phone_number: companyProfile.phoneNumber.value,
//                 company_name: companyProfile.companyName.value,
//                 fein: companyProfile.fein.value,
//                 liability_limitId: companyProfile?.empLiabilityLimit?.value,
//                 uuid: uuid,
//                 carrier: `${carrier}+${place}`,
//                 carrier_email_id: data[place][carrier].carrier_email,
//                 carrier_location_data: data[place][carrier],
//                 doc_status: "not_uploaded",
//                 currProspectDetails,
//                 uw_ques: uwQues,
//                 acord_ques: acordQuesData,
//                 uwQuestionsEligibility,
//                 classCodeEligibility,
//                 clientEligibility,
//               };

//               if (isSalesforce) {
//                 tempData.isSalesforce = true;
//                 tempData.sfQuoteId = sfQuoteId;
//               }

//               if (uwQuesList) tempData.uwQuesList = uwQuesList;
//               if (uwAmtrustQuesList)
//                 tempData.uwAmtrustQuesList = uwAmtrustQuesList;

//               if (dashboardFiles?.length > 0) {
//                 tempData.dashboardFiles = dashboardFiles;
//               }

//               if (preUwFiles?.length > 0) {
//                 tempData.preUwFiles = preUwFiles;
//               }

//               if (salespersonName) tempData.salespersonName = salespersonName;

//               if (salespersonNameAttr)
//                 tempData.salespersonNameAttr = salespersonNameAttr;

//               if (preUwData) {
//                 tempData.preUwData = preUwData;
//               }

//               if (amtrustAccountDetails && amtrustAccountDetails.QuoteId) {
//                 tempData.amtrustAccountDetails = amtrustAccountDetails;
//               }

//               if (
//                 amtrustAccountDetailsCopy &&
//                 amtrustAccountDetailsCopy.QuoteId
//               ) {
//                 tempData.amtrustAccountDetailsCopy = amtrustAccountDetailsCopy;
//               }

//               if (salespersonCheck && !salespersonMailCampaign) {
//                 tempData.origin = "sales_person";
//               } else if (
//                 checkMailCampaignUser() ||
//                 (salespersonCheck && salespersonMailCampaign)
//               ) {
//                 tempData.origin = "email_campaign";
//                 tempData.source = source;
//               } else {
//                 tempData.origin = "direct_user";
//               }

//               if (acordQuesAvailable) {
//                 tempData.acordQuesAvailable = acordQuesAvailable;
//               }

//               sortKeyList.push(tempData.uuid_carrier);
//               carrierUserData.push(tempData);
//             }
//           }
//         }

//         quoteData = {
//           uuid,
//           date: currentDate,
//         };

//         sessionStorage.setItem("sortKeyList", JSON.stringify(sortKeyList));
//         sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
//         sessionStorage.setItem("address", JSON.stringify(address));

//         if (amtrustAccountDetails && amtrustAccountDetails.QuoteId) {
//           try {
//             let amtrustSaveQuestionsRequestBody = [];
//             for (let question of amtrustQuesList) {
//               amtrustSaveQuestionsRequestBody.push({
//                 QuestionId: question.name,
//                 AnswerValue: question.response ? "yes" : "no",
//               });
//             }

//             let amtrustSaveQuestionsTriggerResponse = await axios.post(
//               awsUrl2 +
//                 `/api/triggerAmtrustSaveQuestions/${amtrustAccountDetails.QuoteId}`,
//               amtrustSaveQuestionsRequestBody
//             );

//             let saveQuestionsUuid, savePaymentPlansUuid;
//             if (
//               amtrustSaveQuestionsTriggerResponse.data.uuid
//               // &&amtrustSavePaymentPlansTriggerResponse.data.uuid
//             ) {
//               saveQuestionsUuid = amtrustSaveQuestionsTriggerResponse.data.uuid;
//               // savePaymentPlansUuid =
//               //   amtrustSavePaymentPlansTriggerResponse.data.uuid;
//             } else {
//               throw "error in amtrust create quote trigger";
//             }

//             let amtrustQuestionsResponse, amtrustSavePaymentPlansResponse;
//             do {
//               let promiseList = [
//                 amtrustResponse(saveQuestionsUuid, "saveQuestions"),
//                 // amtrustResponse(savePaymentPlansUuid, "savePaymentPlans"),
//               ];

//               await Promise.all(promiseList)
//                 .then((res) => {
//                   console.log("++res", res);
//                   amtrustQuestionsResponse = res[0];
//                   // amtrustSavePaymentPlansResponse = res[1];
//                 })
//                 .catch((err) => {
//                   console.log("error", err);
//                   throw "error in amtrust quote creation";
//                 });
//               if (
//                 amtrustQuestionsResponse === "error"
//                 // ||amtrustSavePaymentPlansResponse === "error"
//               ) {
//                 throw "error in amtrust quote generation";
//               }
//             } while (
//               amtrustQuestionsResponse === "processing"
//               // ||amtrustSavePaymentPlansResponse === "processing"
//             );

//             let amtrustSavePaymentPlansTriggerResponse = await axios.get(
//               awsUrl2 +
//                 `/api/triggerAmtrustSavePaymentPlans/${amtrustAccountDetails.QuoteId}`
//             );

//             if (
//               // amtrustSaveQuestionsTriggerResponse.data.uuid &&
//               amtrustSavePaymentPlansTriggerResponse.data.uuid
//             ) {
//               // saveQuestionsUuid = amtrustSaveQuestionsTriggerResponse.data.uuid;
//               savePaymentPlansUuid =
//                 amtrustSavePaymentPlansTriggerResponse.data.uuid;
//             } else {
//               throw "error in amtrust create quote trigger";
//             }

//             do {
//               let promiseList = [
//                 // amtrustResponse(saveQuestionsUuid, "saveQuestions"),
//                 amtrustResponse(savePaymentPlansUuid, "savePaymentPlans"),
//               ];

//               await Promise.all(promiseList)
//                 .then((res) => {
//                   console.log("++res", res);
//                   // amtrustQuestionsResponse = res[0];
//                   amtrustSavePaymentPlansResponse = res[0];
//                 })
//                 .catch((err) => {
//                   console.log("error", err);
//                   throw "error in amtrust quote creation";
//                 });
//               if (
//                 // amtrustQuestionsResponse === "error" ||
//                 amtrustSavePaymentPlansResponse === "error"
//               ) {
//                 throw "error in amtrust quote generation";
//               }
//             } while (
//               // amtrustQuestionsResponse === "processing" ||
//               amtrustSavePaymentPlansResponse === "processing"
//             );
//           } catch (error) {
//             console.log("error in amtrust quote generation", error);
//           }
//         }

//         // if (sessionStorage.getItem("landingEmail")) {
//         //   let uploadDataa = [];
//         //   let userTrackingData = {};
//         //   userTrackingData.email = sessionStorage.getItem("landingEmail");
//         //   let currProspct = JSON.parse(sessionStorage.getItem("currProspect"));
//         //   userTrackingData.timestamp = visitTimestamp;
//         //   userTrackingData.domain = sessionStorage.getItem("domain");
//         //   userTrackingData.companyProfile = currProspct.companyProfile;
//         //   userTrackingData.emodStatesData = currProspct.emodStatesData;
//         //   userTrackingData.indicationCost = quoteFactors.total;
//         //   userTrackingData.payrollData = currProspct.childrenLoc;
//         //   userTrackingData.uuid = sessionStorage.getItem("uuid");
//         //   userTrackingData.address = address;
//         //   userTrackingData.quoteData = quoteData;
//         //   userTrackingData.uwQues = uwQues;

//         //   if (salespersonMailCampaign) {
//         //     userTrackingData.salespersonName = salespersonName;
//         //     userTrackingData.salespersonNameAttr = salespersonNameAttr;
//         //     userTrackingData.salesEngaged = "true";
//         //   }

//         //   uploadDataa.push(userTrackingData);
//         //   // console.log("*************************");
//         //   // console.log("********uploadDataa******");
//         //   // console.log(uploadDataa);
//         //   await axios
//         //     .post(awsUrl2 + "/api/updateUsersTrackingData", uploadDataa)
//         //     .then((res) => {
//         //       console.log("posted data", res);
//         //     })
//         //     .catch((error) => {
//         //       console.log("error in posting data to user tracking", error);
//         //     });
//         // }

//         try {
//           if (agentpersonCheck) {
//             let agentPersonData = {};
//             let type = "update";
//             let dateNow = Math.floor(Date.now()).toString();

//             agentPersonData.id = agentID;
//             agentPersonData.updated_timestamp = dateNow;
//             agentPersonData.formStage = "three";
//             agentPersonData.agentName = agentpersonName;
//             agentPersonData.uwQues = uwQues;
//             agentPersonData.address = address;
//             agentPersonData.quoteData = quoteData;
//             agentPersonData.sortKeyList = sortKeyList;
//             agentPersonData.currProspect = currProspectDetails;

//             await axios
//               .post(
//                 awsUrl2 + `/api/agentPersonData/${type}`,
//                 JSON.stringify(agentPersonData)
//               )
//               .then(() => {})
//               .catch((error) => {
//                 console.log("error", error);
//               });
//           }
//         } catch (error) {
//           console.log("sales person flow error: ", error);
//         }

//         let prevIndex = 0;
//         let nextIndex = 100;
//         let uploadData;
//         for (let i = 0; i < Math.floor(carrierUserData.length / 100) + 1; i++) {
//           uploadData = carrierUserData.slice(prevIndex, nextIndex);
//           axios
//             .post(awsUrl + "/api/postUsersData", uploadData)
//             .then((res) => {
//               sendQuote;
//               console.log("posted data", res);
//             })
//             .catch((error) => {
//               console.log("error in posting data", error);
//             })
//             .finally(() => {
//               sessionStorage.setItem("formStage", "three");
//               sessionStorage.setItem(
//                 "quoteFactors",
//                 JSON.stringify(quoteFactors)
//               );
//               $("#loader").css("display", "none");
//               window.location.reload();
//             });
//         }
//       } else {
//         sessionStorage.setItem("formStage", "two");
//         $("#loader").css("display", "none");
//         window.location.reload();
//       }
//     }
//   } catch (error) {
//     console.log("error", error);
//     $("#loader").css("display", "none");
//   }
// }
