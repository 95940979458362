import React, { Component } from "react";
import Context from "./Context";

export default class ContextProvider extends Component {
  state = {
    quoteSubmitted: false,
    salesManagerData: { rows: [], additionalData: {} },
    reCalculateData: false,
    carrierSubmittedWith: null,
    amtrustBound: false,
    eligibilityCheck: {
      governing_cc: {
        eligibility_check_1_1: {
          eligibility: "",
        },
        eligibility_check_1_2: {
          eligibility: "",
        },
        eligibility_check_1_3: {
          eligibility: "",
        },
        eligibility_check_1_4: {
          eligibility: "",
        },
      },
      company_profile: {
        eligibility_check_2_1: {
          eligibility: "",
        },
        eligibility_check_2_2: {
          eligibility: "",
        },
        eligibility_check_2_3: {
          eligibility: "",
        },
        eligibility_check_2_4: {
          eligibility: "",
        },
        eligibility_check_2_5: {
          eligibility: "",
        },
        eligibility_check_2_6: {
          eligibility: "",
        },
        eligibility_check_2_7: {
          eligibility: "",
        },
        eligibility_check_2_8: {
          eligibility: "",
        },
        eligibility_check_2_9: {
          eligibility: "",
        },
        eligibility_check_2_10: {
          eligibility: "",
        },
      },
      loss_history: {
        eligibility_check_3_1: {
          eligibility: "",
        },
        eligibility_check_3_2: {
          eligibility: "",
        },
        eligibility_check_3_3: {
          eligibility: "",
        },
        eligibility_check_3_4: {
          eligibility: "",
        },
        eligibility_check_3_5: {
          eligibility: "",
        },
      },
      uw_questions: {
        eligibility_check_4_1: {
          eligibility: "",
        },
        eligibility_check_4_2: {
          eligibility: "",
        },
        eligibility_check_4_3: {
          eligibility: "",
        },
        eligibility_check_4_4: {
          eligibility: "",
        },
        eligibility_check_4_5: {
          eligibility: "",
        },
        eligibility_check_4_6: {
          eligibility: "",
        },
        eligibility_check_4_7: {
          eligibility: "",
        },
        eligibility_check_4_8: {
          eligibility: "",
        },
        eligibility_check_4_9: {
          eligibility: "",
        },
        eligibility_check_4_10: {
          eligibility: "",
        },
        eligibility_check_4_11: {
          eligibility: "",
        },
        eligibility_check_4_12: {
          eligibility: "",
        },
        eligibility_check_4_13: {
          eligibility: "",
        },
        eligibility_check_4_14: {
          eligibility: "",
        },
        eligibility_check_4_15: {
          eligibility: "",
        },
        eligibility_check_4_16: {
          eligibility: "",
        },
        eligibility_check_4_17: {
          eligibility: "",
        },
        eligibility_check_4_18: {
          eligibility: "",
        },
        eligibility_check_4_19: {
          eligibility: "",
        },
        eligibility_check_4_20: {
          eligibility: "",
        },
        eligibility_check_4_21: {
          eligibility: "",
        },
        eligibility_check_4_22: {
          eligibility: "",
        },
        eligibility_check_4_23: {
          eligibility: "",
        },
        eligibility_check_4_24: {
          eligibility: "",
        },
        eligibility_check_4_25: {
          eligibility: "",
        },
        eligibility_check_4_26: {
          eligibility: "",
        },
        eligibility_check_4_27: {
          eligibility: "",
        },
        eligibility_check_4_28: {
          eligibility: "",
        },
        eligibility_check_4_29: {
          eligibility: "",
        },
        eligibility_check_4_30: {
          eligibility: "",
        },
      },
      quote_data: {
        eligibility_check_5_1: {
          eligibility: "",
        },
        eligibility_check_5_2: {
          eligibility: "",
        },
        eligibility_check_5_3: {
          eligibility: "",
        },
      },
    },
    feinAgnecyMap: {}
  };

  render() {
    let {
      quoteSubmitted,
      salesManagerData,
      reCalculateData,
      carrierSubmittedWith,
      amtrustBound,
      eligibilityCheck,
      feinAgnecyMap
    } = this.state;

    return (
      <Context.Provider
        value={{
          quoteSubmitted: quoteSubmitted,
          setQuoteSubmittedStatus: (status) => {
            this.setState({ quoteSubmitted: status });
          },
          carrierSubmittedWith: carrierSubmittedWith,
          setCarrierSubmittedWith: (status) => {
            this.setState({ carrierSubmittedWith: status });
          },
          amtrustBound: amtrustBound,
          setAmtrustBoundStatus: (status) => {
            this.setState({ amtrustBound: status });
          },
          salesManagerData: salesManagerData,
          setSalesManagerData: (data) => {
            this.setState({ salesManagerData: data });
          },
          reCalculateData: reCalculateData,
          setReCalculateData: (value) => {
            this.setState({ reCalculateData: value });
          },
          eligibilityCheck: eligibilityCheck,
          setEligibilityCheck: (value) => {
            this.setState({ eligibilityCheck: value });
          },
          feinAgnecyMap: feinAgnecyMap,
          setFeinAgencyMap: (value) => {
            this.setState({ feinAgnecyMap: value })
          }
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}
