import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { awsUrl, awsUrl2 } from "../../config";
import axios from "axios";

const TEXT_DETAILS = {
  approved: {
    header: "Congratulations!",
    body:
      process.env.REACT_APP_MODE === "beta"
        ? `To bind coverage, please email documentation to underwriting@insurecomp.com.`
        : `To bind coverage, please email documentation to underwriting@fubaworks.com.`,
    button: "Download Quote",
  },
  referred: {
    header: "Thank you for your submission",
    body:
      "This risk will need further review by one of our underwriters. Our underwriting team will respond within the next 24-48 hours.",
  },
  declined: {
    header: "Thank you for your submission",
    body: "",
    button: "Download Declination",
  },
};

function FubaQuoteDetails(props) {
  let listToDisplay = [];
  let indx = 0;
  const [loadingEAcord, setLoadingEAcord] = useState(false);
  for (let statement of props.statements) {
    listToDisplay.push(<li key={indx++}>{statement}</li>);
  }

  const downloadEAcord = async () => {
    try {
      let eAcordPdf;
      let currProspect = sessionStorage.getItem("currProspect")
        ? JSON.parse(sessionStorage.getItem("currProspect"))
        : undefined;
      let address = sessionStorage.getItem("address")
        ? JSON.parse(sessionStorage.getItem("address"))
        : undefined;
      let quoteData = sessionStorage.getItem("quoteData")
        ? JSON.parse(sessionStorage.getItem("quoteData"))
        : undefined;

      let agentID = sessionStorage.getItem("agentID");

      let childrenLoc = currProspect.childrenLoc;
      let stateList = [];
      for (let childrenLocIndx in childrenLoc) {
        let childrenLocObj = childrenLoc[childrenLocIndx];
        if (childrenLocObj.state && childrenLocObj.state.value) {
          stateList.push(childrenLocObj.state.value.toLowerCase());
        }
      }
      let isLoggedIn;
      try {
        isLoggedIn = await Auth.currentAuthenticatedUser();
      } catch (error) {
        console.log("Authentication error here", error);
      }

      if (currProspect && address && quoteData) {
        let etQuoteDetails = {
          email: agentID,
          timestamp: quoteData.date,
          carrier: `carrier_ax`,
          mail_address: "",
          address: address,
          state: stateList,
          netRateMap: {},
          agent_name: isLoggedIn?.attributes.name || " ",
          agent_email: isLoggedIn?.attributes.email || " ",
          agent_agency: isLoggedIn?.attributes["custom:agency"] || " ",
          uw_name: isLoggedIn?.attributes["custom:uw"] || " ",
          agency_code: isLoggedIn?.attributes["custom:agency_code"] || "",
        };
        axios
          .post(
            awsUrl2 + "/api/generateEAcord/fl",
            JSON.stringify(etQuoteDetails)
          )
          .then((res) => {
            eAcordPdf = res.data;
            const uint8Array = new Uint8Array(Object.values(eAcordPdf));
            const blob = new Blob([uint8Array], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "editable_acord.pdf";

            // Append link to body and trigger download
            document.body.appendChild(link);
            link.click();

            // Cleanup
            document.body.removeChild(link);
            setLoadingEAcord(false);
          });
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to download editable Acord. Please try again later.");
    }
  };

  return (
    props.eligibility && (
      <div className="fuba-quote-details">
        <div className="text-center mb-3 fuba-quote-details-header">
          <b>{TEXT_DETAILS[props.eligibility]["header"]}</b>
        </div>
        <div className="fuba-quote-details-body">
          <div className="text-center">
            {TEXT_DETAILS[props.eligibility]["body"]}
          </div>
          {props.statements && (
            <div className="statements">
              <ul>{listToDisplay}</ul>
            </div>
          )}
        </div>
        <div className="downloadEAcord">
          {TEXT_DETAILS[props.eligibility]["button"] && (
            <div className="text-center">
              <button
                className="btn btn-warning"
                onClick={(e) => props.callback()}
              >
                {TEXT_DETAILS[props.eligibility]["button"]}
              </button>
            </div>
          )}
          <button
            className="btn btn-primary"
            onClick={() => {
              downloadEAcord();
              setLoadingEAcord(true);
            }}
          >
            {loadingEAcord ? "Downloading..." : "Download EAcord"}
          </button>
        </div>
      </div>
    )
  );
}

export default FubaQuoteDetails;
