import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import $, { event } from "jquery";
import axios from "axios";
import { awsUrl2 } from "../../config";
var validator = require("email-validator");
window.jQuery = $;
window.$ = $;
global.jQuery = $;

function FubaLoginQuote() {
  useEffect(() => {
    const keyValues = window.location.search;
    const urlParams = new URLSearchParams(keyValues);

    (async () => {
      await Auth.signOut();
      const uuid = urlParams.get("uuid");
      const username = urlParams.get("email");
      const agent = urlParams.get("agent");
      const agency = urlParams.get("agency");
      const uw = urlParams.get("uw");
      const agency_code = urlParams.get("agency_code")
      let VaildEmail = validator.validate(username);

      if (VaildEmail) {
        await axios
          .post(awsUrl2 + "/api/getAuthorization", {
            email: username,
            token: uuid,
            agent: agent,
            agency: agency,
            uw: uw,
            agency_code: agency_code
          })
          .then(async (res) => {
            var userTemp = await Auth.signIn(username.toLowerCase());
            console.log(userTemp, "userTemp");
            try {
              const challengeResult = await Auth.sendCustomChallengeAnswer(
                userTemp,
                uuid
              );
              if (challengeResult) {
                if (userTemp.signInUserSession !== null) {
                  const userData = {
                    email_id: username.toLowerCase(),
                    stage: process.env.REACT_APP_MODE,
                    client: "Fuba"
                  }
                  try {
                    await axios.post(awsUrl2 + "/api/trackUserSession", userData)
                    console.log("UserSessionManagement table is updated")
                  } catch (error) {
                    console.log("Error in updating the UserSessionManagement table")
                  }
                }
                sessionStorage.setItem("isLoggedIn", true);
                window.location.href = "./AgentDashboard";
                $("#loader").css("display", "none");
              } else {
                alert("Sorry, you are not authorized to access the tool.");
                window.location.href = "/";
              }
            } catch (error) {
              alert("Sorry, we ran into some issues. Please try again.");
              window.location.href = "/";
            }
          })
          .catch((err) => {
            console.log("ERROR:", err);
            alert("Sorry, you are not authorized to access the tool.");
            window.location.href = "/";
          });
      } else {
        alert("Invaild Email");
      }
    })();
  }, []);

  return <></>;
}

export default FubaLoginQuote;
