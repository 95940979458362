import React from "react";
import { Form } from "react-bootstrap";

let isMobile = window.innerWidth < 993;
const AdditonalEntity = ({
  additionalEntity,
  setAdditionalEntityHandler,
  addAdditionalEntityHandler,
  removeAdditionalEntityHandler,
  setAdditionalEntityvalueHandler,
  setAdditionalEntityFein,
}) => {
  return (
    <>
      <div id="additionalEntityContainer">
        <div
          id="Entity_Additional_Question"
          className="font-family-montserrat-semi-bold d-flex align-items-center"
        >
          <p className="pr-4"> Are there additional entities to be covered?</p>
          <span className="d-flex align-items-baseline pr-4 pl-4">
            <input
              type="radio"
              id="additional-entities_yes"
              className="pl-3"
              name="additional-entities-radio_Yes"
              checked={additionalEntity.checked === true}
              onChange={(e) => setAdditionalEntityHandler(e, true)}
            />
            <p className="pl-2 d-flex align-self-center"> Yes</p>
          </span>
          <span className="d-flex align-items-baseline pr-4 pl-4">
            <input
              type="radio"
              id="additional-entities_No"
              className="pl-3 "
              name="additional-entities-radio_No"
              checked={additionalEntity.checked === false}
              onChange={(e) => setAdditionalEntityHandler(e, false)}
            />
            <p className="pl-2 d-flex align-self-center"> No</p>
          </span>
        </div>

        <div>
          {additionalEntity.checked && (
            <>
              {additionalEntity?.entityList?.map((value, index) => {
                return (
                  <div className="row no-gutter mt-4 mb-4 d-flex align-items-center">
                    <div className="col-md-1 ">
                      <p style={{ whiteSpace: "nowrap" }}>
                        <b>{`Entity  ${index + 2}`}</b>
                      </p>
                    </div>
                    <div className="col-md-3">
                      <Form.Group>
                        <label htmlFor="companyName" className="mandatory">
                          <b className="font-family-montserrat-semi-bold">
                            Company Name
                          </b>
                        </label>
                        <input
                          id="Eligibilty_companyName"
                          onChange={(e) =>
                            setAdditionalEntityvalueHandler(
                              e,
                              "companyName",
                              index
                            )
                          }
                          style={
                            additionalEntity.entityList[index]["companyName"] &&
                            additionalEntity.entityList[index]["companyName"][
                              "error"
                            ]
                              ? { borderColor: "red" }
                              : {}
                          }
                          type="text"
                          placeholder="Company Name"
                          autoComplete="off"
                          value={`${value?.companyName?.["value"]}`}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-3">
                      <Form.Group>
                        <label htmlFor="fein" className="mandatory">
                          <b className="font-family-montserrat-semi-bold">
                            FEIN
                          </b>
                        </label>
                        <input
                          placeholder="FEIN"
                          value={`${value?.fein?.["value"]}`}
                          id="fein"
                          type="text"
                          style={
                            additionalEntity.entityList[index]["fein"] &&
                            additionalEntity.entityList[index]["fein"]["error"]
                              ? { borderColor: "red" }
                              : {}
                          }
                          autoComplete="off"
                          onInput={(event) => {
                            event.target.value = event.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*)\./g, "$1");
                          }}
                          onChange={(e) =>
                            setAdditionalEntityvalueHandler(e, "fein", index)
                          }
                          onBlur={(event) => {
                            var value = event.target.value.toString().trim();
                            value = value.split("-").join("");
                            var len = value.length,
                              dummystr = "000000000";

                            if (len > 0 && len < 9) {
                              value = dummystr.slice(0, 9 - len) + "" + value;
                              additionalEntity.entityList[index]["fein"][
                                "value"
                              ] = value[0] + value[1] + "-" + value.slice(2);
                              additionalEntity.entityList[index]["fein"][
                                "errMsg"
                              ] = "";
                            } else if (len > 9) {
                              additionalEntity.entityList[index]["fein"][
                                "errMsg"
                              ] = "FEIN needs to be 9 digits";
                              additionalEntity.entityList[index]["fein"][
                                "error"
                              ] = true;
                            } else if (len === 0) {
                              additionalEntity.entityList[index]["fein"][
                                "value"
                              ] = "";
                              additionalEntity.entityList[index]["fein"][
                                "errMsg"
                              ] = "";
                            } else {
                              additionalEntity.entityList[index]["fein"][
                                "value"
                              ] = value[0] + value[1] + "-" + value.slice(2);
                              additionalEntity.entityList[index]["fein"][
                                "errMsg"
                              ] = "";
                            }
                            setAdditionalEntityFein();
                          }}
                        />
                        <p className="errMsg" style={{ fontSize: "12px" }}>
                          {additionalEntity.entityList[index]["fein"] &&
                          additionalEntity.entityList[index]["fein"][
                            "errMsg"
                          ] &&
                          additionalEntity.entityList[index]["fein"][
                            "errMsg"
                          ] !== ""
                            ? additionalEntity.entityList[index]["fein"][
                                "errMsg"
                              ]
                            : ""}
                        </p>
                      </Form.Group>
                    </div>
                    <div className="col-md-1 d-flex">
                      <button
                        type="button"
                        className="rmvLoc btnInvisible transpButton d-flex align-self-center"
                        onClick={() => removeAdditionalEntityHandler(index)}
                      >
                        <img src={require("../../../images/delete4.png")} className={isMobile ? "add-entity-del-button-mobile" : "add-entity-del-button"}/>
                      </button>
                    </div>
                  </div>
                );
              })}
              <div>
                <button
                  type="button"
                  className="btnInvisible addLoc transpButton p-0"
                  onClick={addAdditionalEntityHandler}
                >
                  <img src={require("../../../images/plus4.png")} />{" "}
                  <b>Add Entity</b>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdditonalEntity;
