import React, { Component, forwardRef } from "react";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import numeral from "numeral";
import MaterialTable from "material-table";
import { TableBody, TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import { Auth } from "aws-amplify";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Modal } from "react-bootstrap";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SaveAlt from "@material-ui/icons/SaveAlt";
import LoadingOverlay from "react-loading-overlay";
import Popup from "../common/Popup";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@material-ui/core";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

import DealClosedLostButtons from "../subcompo/DealClosedLostButtons";
import ConfirmDealButton from "../subcompo/ConfirmDealButton";
import "./DashboardAdmin.scss";

let ismobile = window.innerWidth < 1600;

const {
  DownloadDashboardFiles,
} = require("../subcompo/DownloadDashboardFiles.jsx");

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: () => <div />,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const statusPriorityMap = {
  Submitted: 1,
  "User Submitted": 2,
  "Quote Generated": 3,
  "Uploaded Document": 4,
  "UW Questions": 5,
  MQL: 6,
  SQL: 7,
  "Company Profile(Get Quote)": 8,
  "Company Profile": 9,
  "Landing Page": 10,
  "Visited Website": 11,
  "Affinity Logo Clicked": 12,
  "Fourth Logo Clicked": 13,
  "Clicked on Affinity logo": 14,
  "Clicked on Fourth logo": 15,
  "Clicked on the image": 16,
  "Clicked on insure comp logo": 17,
  "Watched Video": 18,
  "Visited LinkedIn": 19,
  "Visited Facebook": 20,
  "Visited Instagram": 21,
  "Accessed Social Media": 22,
  "Opened Email": 23,
  "Not Campaigned": 24,
  "-": 999,
};

let selectedRows = [];

class DashboardAdmin extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isLoading: true,
      program: "PEO",
      source: "Affinity",
      style: {},
      uploadDateFlag: false,
      sourceSelected: "All",
      confirmAllLoading: false,
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => {},
        rejectBtnCallback: () => {},
        show: false,
      },

      scrollLeft: false,
      scrollRight: true,
    };
    this.handleDisplayHide = this.handleDisplayHide.bind(this);
    console.log(this.props);
  }

  fetchDashboardData = () => {
    if (this.props.tabName === "tab3") {
      this.updateSalesDisplay();
    } else if (this.props.tabName === "tab1") {
      this.updateDisplayTab1();
    } else if (this.props.tabName === "tab2") {
      this.updateDisplayTab2();
    } else if (this.props.tabName === "tab4" || this.props.tabName === "tab5") {
      this.setState({ isLoading: false });
    }
  };

  updatePopUpData = (popUp) => {
    this.setState({ popupData: popUp });
  };

  handleSelection = (rows) => {
    selectedRows = rows;
    console.log(selectedRows.length);
  };

  handleConfirmAll = (tabName) => {
    let { dealMap } = this.props;
    if (selectedRows.length > 0) {
      this.updatePopUpData({
        show: true,
        title: "Do you wish to confirm the deals?",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              Once confirmed, cannot be reverted.
            </span>
          </>
        ),
        acceptBtn: "Yes",
        acceptBtnCallback: async () => {
          try {
            let promiseList = [];
            let confirmAllDataList = [];
            let i = 1;
            this.setState({ confirmAllLoading: true });
            for (let row of selectedRows) {
              let key = row.domain || row.salesID;

              let dealFlag;
              if (dealMap[key] === "true") {
                dealFlag = "closed";
              } else if (dealMap[key] === "false") {
                dealFlag = "lost";
              } else {
                dealFlag = row.dealFlag;
              }

              if (
                (row.dealFlag === "lost" ||
                  row.dealFlag === "closed" ||
                  dealMap[key] === "true" ||
                  dealMap[key] === "false") &&
                (!row.dealConfirmed || row?.dealConfirmed !== "true")
              ) {
                let api_body = {
                  salesId: row?.recordDetails?.salesId || "",
                  domain: row?.recordDetails?.domain || "",
                  userId: row?.recordDetails?.userId || "",
                  isMailCmpn: row?.recordDetails?.isMailCmpn || false,
                  uuidList: row?.recordDetails?.uuidList || [],
                  confirmDeal: "true",
                };

                confirmAllDataList.push({
                  tab: tabName,
                  index: Number(row.tableData.id),
                  dealFlag,
                  rowData: row,
                  key:
                    row?.recordDetails?.domain || row?.recordDetails?.salesId,
                  confirmDeal: "true",
                });

                promiseList.push(
                  axios
                    .post(
                      awsUrl + "/api/setDealConfirmed",
                      JSON.stringify(api_body)
                    )
                    .catch((err) => console.log(err))
                );

                if (i % 10 === 0) {
                  await Promise.all(promiseList);
                  promiseList = [];
                }
                i++;
              }
            }

            if (promiseList.length > 0) {
              await Promise.all(promiseList);
            }

            this.setState({ confirmAllLoading: false });
            this.props.handleDealConfirmAll(confirmAllDataList);
            for (let data of confirmAllDataList) {
              this.props.updateConfirmDealMap(data.key, data.confirmDeal);
            }
          } catch (error) {
            console.log("error: ", error);
          }
        },
      });
    } else {
      this.updatePopUpData({
        show: true,
        title: "Nothing to confirm.",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              Please select rows to confirm deals.
            </span>
          </>
        ),
        acceptBtn: "Okay",
        acceptBtnCallback: async () => {},
        disableNoButton: true,
      });
    }
  };

  handleDisplayHide = () => {
    let { tabName, selected } = this.props;
    this.setState({
      style: tabName !== selected ? { display: "none", fontSize: "12px" } : {},
    });
  };

  getUploadDateFlag = async () => {
    const currentAuthUser = await Auth.currentAuthenticatedUser();
    if (currentAuthUser) {
      let groups =
        currentAuthUser?.signInUserSession?.accessToken?.payload[
          "cognito:groups"
        ];
      if (groups.includes(process.env.REACT_APP_DATA_TEAM)) return true;
      else return false;
    }
  };

  updateUploadFlag = async () => {
    let flag = await this.getUploadDateFlag();
    this.setState({ uploadDateFlag: flag });
  };

  componentDidMount() {
    this.fetchDashboardData();
    this.updateUploadFlag();
    window.addEventListener("load", this.handleDisplayHide);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.handleDisplayHide();
    }
  }

  updateDomainMessageidFiles = (domain, key, val) => {
    let { domain_messageid_files } = JSON.parse(JSON.stringify(this.state));
    domain_messageid_files[domain][key] = val;
    this.setState({ domain_messageid_files });
  };

  appendRemaining = (str) => {
    let len = str.length;
    for (let i = 1; i <= 10 - len; i++) {
      str = "0" + str;
    }
    return str;
  };

  updateAdminComment = (e, rowData) => {
    let { rowDataList } = JSON.parse(JSON.stringify(this.props));
    let index = rowData.tableData.id;
    let requestBody = {};

    requestBody.domain =
      rowDataList[index].domain || rowDataList[index].salesID;
    requestBody.admincomment = e.target.value;
    if (this.props.tabName === "tab2") {
      if (rowDataList[index].tableName === "userTrackingTable")
        requestBody.tableName = "tab1";
      else requestBody.tableName = "tab2";
    } else {
      requestBody.tableName = this.props.tabName;
    }

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting comment", error);
      });
  };

  updateComment = (e, rowData) => {
    let { program, source } = JSON.parse(JSON.stringify(this.state));
    let { rowDataList } = JSON.parse(JSON.stringify(this.props));
    let index = rowData.tableData.id;
    let requestBody = {};

    requestBody.domain = rowDataList[index].domain;
    requestBody.comment = e.target.value;
    if (this.props.tabName === "tab2") {
      if (rowDataList[index].tableName === "campaignTable")
        requestBody.tableName = "tab1";
      else requestBody.tableName = "tab2";
    } else {
      requestBody.tableName = this.props.tabName;
    }

    axios
      .post(awsUrl2 + "/api/uploadDashboardComments", requestBody)
      .then((res) => {})
      .catch((error) => {
        console.log("error in posting data to user tracking", error);
      });
  };

  updateDiscountData = (rowData) => {
    let requestBody = {
      user_email_id: rowData.userEmail,
      uuid_carrier: rowData.uuid_carrier,
      discountData: rowData.discountData,
    };
    axios
      .post(awsUrl2 + "/api/updateUserStatusTable", requestBody)
      .then((res) => {})
      .catch((err) => console.log("error: ", err));
  };

  camelCase = (str) => {
    if (str)
      return str
        .trim()
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(" ");
    return "";
  };

  formatFein = (value) => {
    let fein;
    value = value.split("-").join("");
    var len = value.length,
      dummystr = "000000000";

    if (len === 0) {
      fein = "-";
    } else if (len > 0 && len < 9) {
      value = dummystr.slice(0, 9 - len) + "" + value;
      fein = value[0] + value[1] + "-" + value.slice(2);
    } else {
      fein = value[0] + value[1] + "-" + value.slice(2);
    }

    return fein;
  };

  onClickCompanyName = (rowData) => {
    sessionStorage.clear();
    if (rowData.currProspectDetails) {
      sessionStorage.setItem(
        "currProspect",
        JSON.stringify(rowData.currProspectDetails)
      );
    }
    sessionStorage.setItem("hideButtons", "true");
    if (rowData.salesID) {
      sessionStorage.setItem("salesID", rowData.salesID);
    }
    if (rowData.formStage) {
      if (rowData.formStage !== "one" && rowData.formStage !== "two")
        sessionStorage.setItem("formStage", "three");
      else sessionStorage.setItem("formStage", rowData.formStage);
    }
    if (rowData.amtrustAccountDetails) {
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(rowData.amtrustAccountDetails)
      );
    }
    if (rowData.address) {
      sessionStorage.setItem("address", JSON.stringify(rowData.address));
    }
    if (rowData.quoteData) {
      sessionStorage.setItem("quoteData", JSON.stringify(rowData.quoteData));
    }
    if (rowData.sortKeyList) {
      sessionStorage.setItem(
        "sortKeyList",
        JSON.stringify(rowData.sortKeyList)
      );
    }
    sessionStorage.setItem("showAllFields", true);
  };

  dateSort = (a, b, field) => {
    let momentA = moment(a[field], "M/D/YYYY - hh:mm A");
    let momentB = moment(b[field], "M/D/YYYY - hh:mm A");
    if (
      (!momentA.isValid() && momentB.isValid()) ||
      momentA.isBefore(momentB)
    ) {
      return -1;
    } else if (
      (momentA.isValid() && !momentB.isValid()) ||
      momentA.isAfter(momentB)
    ) {
      return 1;
    } else if (
      (!momentA.isValid() && !momentB.isValid()) ||
      momentA.isSame(momentB)
    ) {
      return 0;
    }
  };

  formatInCamel = (str) => {
    if (str) {
      return str[0].toUpperCase() + "" + str.slice(1);
    }
    return str;
  };

  fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.87rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.85rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.8rem";
    }
    if (window.innerWidth < 1300) {
      return "0.72rem";
    }
  };

  customFilterfunction = (term, rowData, field) => {
    let customFieldName = field["field"];

    let excludeFirstCharacter = term.substring(1);
    let excludeFirstTwoCharacter = term.substring(2);
    let firstCharacter = term.substring(1, 0);
    let firstTwoCharacter = term.substring(2, 0);
    let operationMap = {
      ">=": ">=",
      "<=": "<=",
      "<": "<",
      ">": ">",
      "=": "===",
    };
    try {
      if (firstTwoCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstTwoCharacter]} ${excludeFirstTwoCharacter}`
        );
      } else if (firstCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
      } else {
        return rowData[customFieldName]?.toString()?.startsWith(term);
      }
    } catch (err) {
      return rowData[customFieldName]?.toString()?.startsWith(term);
    }
  };

  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };
  scrollLeft = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));
    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft -= 150;
      if (tableContainerDiv.scrollLeft == 0) {
        this.setState({ scrollLeft: false });
        this.setState({ scrollRight: true });
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft < maxScrollLeft) {
        this.setState({ scrollRight: true });
      }
    }
  };
  scrollRight = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));

    const tableContainerDiv = this.myRef.current.lastChild.lastChild.children[2]
      .children[1].children[0].children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft += 150;
      this.setState({ scrollLeft: true });
    }

    const maxScrollLeft =
      tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    if (tableContainerDiv.scrollLeft > maxScrollLeft) {
      this.setState({ scrollRight: false });
    }
  };

  tab1DetailPanel = (rowData) => {
    return (
      <div style={{ padding: "25px" }}>
        <TableContainer component={Paper}>
          <Table size="small" className="sub_tab1">
            <TableHead
              style={{
                background: "#00000066",
                color: "whitesmoke",
              }}
            >
              <TableRow>
                <TableCell>Contact Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Job Description</TableCell>
                <TableCell>Visit Count</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.email_specific ? (
                Object.values(rowData.email_specific).map((element, index) => {
                  let step;
                  if (element.companyProfile) {
                    if (Number(element.proposalGeneratedCount) > 0) {
                      step = "Proposal Generated";
                    } else if (element.quote_status) {
                      if (element.quote_status === "uwportal") {
                        step = "UW Portal";
                      } else if (element.quote_status === "proceed_uw") {
                        step = "Approved";
                      } else if (element.quote_status === "reject") {
                        step = "Rejected";
                      }
                    } else if (Number(element.quoteGeneratedCount) > 0) {
                      step = "Quote Generated";
                    } else if (element.formStage) {
                      if (element.formStage === "two") {
                        if (element.nonEligibilityList) {
                          step = "Not Eligible";
                        } else {
                          step = "UW Questions";
                        }
                      } else if (element.formStage === "one") {
                        step = "Company Profile";
                      }
                    } else if (
                      element.visitCount &&
                      Number(element.visitCount) > 0
                    ) {
                      step = "Landing Page";
                    } else {
                      step = "-";
                    }
                    if (
                      element.companyProfile.status &&
                      element.companyProfile.status.value
                    ) {
                      step =
                        statusPriorityMap[element.companyProfile.status.value] <
                        statusPriorityMap[step]
                          ? element.companyProfile.status.value
                          : step;
                    }
                    return (
                      <TableRow key={index}>
                        <TableCell scope="row">
                          {element.companyProfile &&
                          element.companyProfile.contact_name
                            ? element.companyProfile.contact_name.value
                            : ""}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.phoneNumber?.value?.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "($1) $2-$3"
                          ) ||
                            element?.companyProfile?.contact_number?.value?.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              "($1) $2-$3"
                            ) ||
                            ""}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.emailId?.value}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.job_title?.value}
                        </TableCell>
                        <TableCell scope="row">
                          {element.visitCount || 0}
                        </TableCell>
                        <TableCell scope="row">{step}</TableCell>
                      </TableRow>
                    );
                  }
                })
              ) : (
                <TableRow key={0}>
                  <TableCell scope="row">{rowData.contactName}</TableCell>
                  <TableCell scope="row">
                    {rowData.phoneNumber
                      ? rowData.phoneNumber.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          "($1) $2-$3"
                        )
                      : "-"}
                  </TableCell>
                  <TableCell scope="row">{rowData.userEmail}</TableCell>
                  <TableCell scope="row">{""}</TableCell>
                  <TableCell scope="row">{rowData.visitCount}</TableCell>
                  {/* <TableCell scope="row">
                {rowData.latestCampaign || ""}
              </TableCell> */}
                  <TableCell scope="row">{rowData.step || ""}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  tab2DetailPanel = (rowData) => {
    return (
      <div style={{ padding: "25px" }}>
        <TableContainer component={Paper}>
          <Table size="small" className="sub_tab2">
            <TableHead
              style={{
                background: "#00000066",
                color: "whitesmoke",
              }}
            >
              <TableRow>
                <TableCell>Contact Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Job Description</TableCell>
                <TableCell>Visit Count</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.email_specific ? (
                Object.values(rowData.email_specific).map((element, index) => {
                  let step;
                  if (element.companyProfile) {
                    if (Number(element.proposalGeneratedCount) > 0) {
                      step = "Proposal Generated";
                    } else if (element.quote_status) {
                      if (element.quote_status === "uwportal") {
                        step = "UW Portal";
                      } else if (element.quote_status === "proceed_uw") {
                        step = "Approved";
                      } else if (element.quote_status === "reject") {
                        step = "Rejected";
                      }
                    } else if (Number(element.quoteGeneratedCount) > 0) {
                      step = "Quote Generated";
                    } else if (element.formStage) {
                      if (element.formStage === "two") {
                        if (element.nonEligibilityList) {
                          step = "Not Eligible";
                        } else {
                          step = "UW Questions";
                        }
                      } else if (element.formStage === "one") {
                        step = "Company Profile";
                      }
                    } else if (
                      element.visitCount &&
                      Number(element.visitCount) > 0
                    ) {
                      step = "Landing Page";
                    } else {
                      step = "-";
                    }
                    if (
                      element.companyProfile.status &&
                      element.companyProfile.status.value
                    ) {
                      step =
                        statusPriorityMap[element.companyProfile.status.value] <
                        statusPriorityMap[step]
                          ? element.companyProfile.status.value
                          : step;
                    }

                    return (
                      <TableRow key={index}>
                        <TableCell scope="row">
                          {element.companyProfile &&
                          element.companyProfile.contact_name
                            ? element.companyProfile.contact_name.value
                            : ""}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.phoneNumber?.value?.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "($1) $2-$3"
                          ) ||
                            element?.companyProfile?.contact_number?.value?.replace(
                              /(\d{3})(\d{3})(\d{4})/,
                              "($1) $2-$3"
                            ) ||
                            ""}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.emailId?.value}
                        </TableCell>
                        <TableCell scope="row">
                          {element?.companyProfile?.job_title?.value}
                        </TableCell>
                        <TableCell scope="row">
                          {element.visitCount || 0}
                        </TableCell>
                        <TableCell scope="row">{step}</TableCell>
                      </TableRow>
                    );
                  }
                })
              ) : (
                <TableRow key={0}>
                  <TableCell scope="row">{rowData.contactName}</TableCell>
                  <TableCell scope="row">
                    {rowData.phoneNumber
                      ? rowData.phoneNumber.replace(
                          /(\d{3})(\d{3})(\d{4})/,
                          "($1) $2-$3"
                        )
                      : "-"}
                  </TableCell>
                  <TableCell scope="row">{rowData.userEmail}</TableCell>
                  <TableCell scope="row">{""}</TableCell>
                  <TableCell scope="row">{rowData.visitCount}</TableCell>
                  {/* <TableCell scope="row">
                {rowData.latestCampaign || ""}
              </TableCell> */}
                  <TableCell scope="row">{rowData.step || ""}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  tab3DetailPanel = (rowData) => {
    return (
      <div style={{ padding: "25px" }}>
        <TableContainer component={Paper}>
          <Table size="small" className="sub_tab3">
            <TableHead
              style={{
                background: "#00000066",
                color: "whitesmoke",
              }}
            >
              <TableRow>
                <TableCell>Contact Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Job Description</TableCell>
                <TableCell>Visit Count</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                <TableRow key={0}>
                  <TableCell scope="row">
                    {(this.formatInCamel(
                      rowData?.companyProfile?.firstName?.value
                    ) || "") +
                      " " +
                      (this.formatInCamel(
                        rowData?.companyProfile?.lastName?.value
                      ) || "")}
                  </TableCell>
                  <TableCell scope="row">
                    {rowData?.companyProfile?.phoneNumber?.value?.replace(
                      /(\d{3})(\d{3})(\d{4})/,
                      "($1) $2-$3"
                    ) || "-"}
                  </TableCell>
                  <TableCell scope="row">{"-"}</TableCell>
                  <TableCell scope="row">{"-"}</TableCell>
                  <TableCell scope="row">{"0"}</TableCell>
                  <TableCell scope="row">{rowData.step || ""}</TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  setTableData = () => {
    let {
      isLoading,
      domain_messageid_files,
      discountDataObject,
      program,
      source,
      uploadDateFlag,
      sourceSelected,
      scrollLeft,
      scrollRight,
      popupData,
    } = JSON.parse(JSON.stringify(this.state));
    let {
      rowDataList,
      tabName,
      dealMap,
      updateDealMap,
      confirmDealMap,
      updateConfirmDealMap,
      handleDealConfirm,
    } = this.props;
    const putSource = (Source) => {
      if (Source == "hotschedules") {
        return "HotSchedules";
      } else if (Source == "affinity") {
        return "Affinity";
      } else {
        return Source;
      }
    };

    let columns = {
      tab1: [
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date}
                  <br></br>
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 0",
            minWidth: "100px",
          },
          headerStyle: {
            padding: "6px 0",
            minWidth: "100px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "230px",
            wordBreak: "break-word",
          },

          render: (rowData) => {
            let random_user = Object.keys(rowData?.email_specific || {})[0];

            return (
              <Clamp lines={2}>
                <span
                  onClick={() => {
                    sessionStorage.setItem("hideButtons", "true");
                    sessionStorage.setItem("showAllFields", true);
                    sessionStorage.setItem("salespersonMailCampaign", true);
                  }}
                >
                  <Link to={`/Quote/?user=${random_user}&viewButton=true`}>
                    {this.camelCase(rowData?.companyName || "-")}
                  </Link>
                </span>
              </Clamp>
            );
          },
        },
        {
          title: "Payroll",
          field: "payroll",
          filterPlaceholder: "Filter",
          render: (rowData) => numeral(rowData?.payroll).format("$0,0"),
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "136px",
            wordBreak: "break-all",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "136px",
          },
        },
        {
          title: "State",
          field: "state",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "50px",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "50px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0",
            minWidth: "65px",
          },
          headerStyle: {
            padding: "6px 0",
            whiteSpace: "nowrap",
            minWidth: "65px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px" };
            if (rowData.step === "Submitted") {
              style.background = "#5ADA38";
            } else if (rowData.step === "User Submitted") {
              style.background = "#5ADA38";
            } else if (rowData.step === "Proposal Generated") {
              style.backgroundColor = "#5ADA38";
            } else if (rowData.step === "UW Portal") {
              style.backgroundColor = "#ffd737ac";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Quote Generated") {
              style.backgroundColor = "#87FA20";
            } else if (rowData.step === "UW Questions") {
              style.backgroundColor = "#FFD737";
            } else if (Number(rowData.visitCount) > 0) {
              style.backgroundColor = "#FFD737";
            } else {
              style.backgroundColor = "#FFF";
            }
            return style;
          },
        },
        {
          title: "Comments",
          field: "admincomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.admincomment || ""}
                onBlur={(e) => this.updateAdminComment(e, rowData)}
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "70px",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: "PreUW?",
          field: "isPreUnderwritten",
          filterPlaceholder: "Filter",
          lookup: { true: "Yes", false: "No" },
          cellStyle: {
            minWidth: "50px",
            padding: "6px 0 6px 0px",
          },
          headerStyle: {
            padding: "6px 0 6px 0px",
            // wordBreak: "break-all",
          },
          render: (rowData) => (rowData?.isPreUnderwritten ? "YES" : "NO"),
        },
        {
          title: (
            <img
              src={require("../../images/file-download-circle-green.svg")}
              alt=""
              style={{
                width: "40px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          cellStyle: {
            minWidth: "20px",
            width: "20px",
            maxWidth: "20px",
            padding: "0 0",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files[rowData.domain]
                .dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }
            if (
              domain_messageid_files[rowData.domain]?.fileListToStore?.length
            ) {
              for (let file of domain_messageid_files[rowData.domain]
                .fileListToStore) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }
            if (domain_messageid_files[rowData.domain]?.preUwFiles?.length) {
              for (let file of domain_messageid_files[rowData.domain]
                .preUwFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            let totalFiles =
              (domain_messageid_files[rowData.domain]?.dashboardFiles?.length ||
                0) +
              (domain_messageid_files[rowData.domain]?.preUwFiles?.length ||
                0) +
              (domain_messageid_files[rowData.domain]?.fileListToStore
                ?.length || 0);

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files[rowData.domain]
                              .dashboardFiles || []
                          }
                          preUwFiles={
                            domain_messageid_files[rowData.domain].preUwFiles ||
                            []
                          }
                          fileListToStore={
                            domain_messageid_files[rowData.domain]
                              .fileListToStore || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/file-download-circle-green.svg")}
                  alt=""
                  style={
                    totalFiles > 0
                      ? {
                          width: "40px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "40px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "80px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "170px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData.salespersonName?.slice(0, 15)}...`;
              } else {
                name = rowData?.salespersonName;
              }
            } catch (error) {}
            return <Clamp lines={2}>{rowData?.name || " "}</Clamp>;
          },
        },
        {
          title: "Source",
          field: "source",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "170px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{putSource(rowData?.source) || ""}</Clamp>
          ),
        },
        {
          title: "SalesPerson Comments",
          field: "salescomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.salescomment || ""}
                disabled
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "PreUW Comments",
          field: "comment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.comment || ""}
                disabled
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "SM Comments",
          field: "smcomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.smcomment || ""}
                rows="2"
                disabled
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "Deal Won/Lost",
          field: "dealFlagFilter",
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;
            return (
              <DealClosedLostButtons
                recordDetails={rowData.recordDetails}
                isClosed={isClosed}
                isLost={isLost}
                updateDealMap={updateDealMap}
                disabled={confirm}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
          },
        },
        {
          title: "Confirm Deal",
          field: "dealConfirmed",
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;

            return (
              <ConfirmDealButton
                updatePopUpData={this.updatePopUpData}
                rowData={rowData}
                isClosed={isClosed}
                isLost={isLost}
                updateConfirmDealMap={updateConfirmDealMap}
                disabled={confirm}
                handleDealConfirm={handleDealConfirm}
                tabName={tabName}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
          },
        },
      ],
      tab2: [
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),

          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date}
                  <br></br>
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 0",
            minWidth: "100px",
          },
          headerStyle: {
            padding: "6px 0",
            minWidth: "100px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "230px",
            wordBreak: "break-word",
          },

          render: (rowData) => {
            return (
              <Clamp lines={2}>{this.camelCase(rowData.companyName)}</Clamp>
            );
          },
        },
        {
          title: "Payroll",
          field: "payroll",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          render: (rowData) => numeral(rowData.payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "136px",
            wordBreak: "break-all",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "136px",
          },
        },
        {
          title: "State",
          field: "state",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          filterPlaceholder: "Filter",
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "50px",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "50px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0",
            minWidth: "65px",
          },
          headerStyle: {
            padding: "6px 0",
            whiteSpace: "nowrap",
            minWidth: "65px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px" };
            if (rowData.step === "Submitted") {
              style.background = "#5ADA38";
            } else if (rowData.step === "Proposal Generated") {
              style.backgroundColor = "#5ADA38";
            } else if (rowData.step === "UW Portal") {
              style.backgroundColor = "#ffd737ac";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Quote Generated") {
              style.backgroundColor = "#87FA20";
            } else if (rowData.step === "UW Questions") {
              style.backgroundColor = "#FFD737";
            } else if (Number(rowData.visitCount) > 0) {
              style.backgroundColor = "#FFD737";
            } else {
              style.backgroundColor = "#FFF";
            }
            return style;
          },
        },
        {
          title: "Comments",
          field: "admincomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.admincomment || ""}
                onBlur={(e) => this.updateAdminComment(e, rowData)}
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0",
            minWidth: "70px",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: "Pre UW?",
          field: "isPreUnderwritten",
          filterPlaceholder: "Filter",
          lookup: { true: "Yes", false: "No" },
          cellStyle: {
            minWidth: "50px",
            padding: "6px 0 6px 0px",
            textAlign: "center",
          },
          headerStyle: {
            padding: "6px 0 6px 0px",
            // width: "30px",
            // wordBreak: "break-all",
          },

          render: (rowData) => (rowData.isPreUnderwritten ? "YES" : "NO"),
        },
        {
          title: (
            <img
              src={require("../../images/file-download-circle-green.svg")}
              alt=""
              style={{
                width: "40px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          cellStyle: {
            minWidth: "20px",
            width: "20px",
            maxWidth: "20px",
            padding: "0 0",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files[rowData.domain]
                .dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }
            if (
              domain_messageid_files[rowData.domain]?.fileListToStore?.length
            ) {
              for (let file of domain_messageid_files[rowData.domain]
                .fileListToStore) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }
            if (domain_messageid_files[rowData.domain]?.preUwFiles?.length) {
              for (let file of domain_messageid_files[rowData.domain]
                .preUwFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            let totalFiles =
              (domain_messageid_files[rowData.domain]?.dashboardFiles?.length ||
                0) +
              (domain_messageid_files[rowData.domain]?.preUwFiles?.length ||
                0) +
              (domain_messageid_files[rowData.domain]?.fileListToStore
                ?.length || 0);

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files[rowData.domain]
                              .dashboardFiles || []
                          }
                          preUwFiles={
                            domain_messageid_files[rowData.domain].preUwFiles ||
                            []
                          }
                          fileListToStore={
                            domain_messageid_files[rowData.domain]
                              .fileListToStore || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/file-download-circle-green.svg")}
                  alt=""
                  style={
                    totalFiles > 0
                      ? {
                          width: "40px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "40px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "90px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "120px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData?.salespersonName?.slice(0, 15)}...`;
              } else {
                name = rowData?.salespersonName;
              }
            } catch (error) {}
            return <Clamp lines={2}>{name || ""}</Clamp>;
          },
        },
        {
          title: "Source",
          field: "source",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "170px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{putSource(rowData?.source) || ""}</Clamp>
          ),
        },
        {
          title: "SalesPerson Comments",
          field: "salescomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.salescomment || ""}
                disabled
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "PreUW Comments",
          field: "comment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.comment || ""}
                disabled
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "SM Comments",
          field: "smcomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.smcomment || ""}
                rows="2"
                disabled
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "Deal Won/Lost",
          field: "dealFlagFilter",
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;
            return (
              <DealClosedLostButtons
                recordDetails={rowData.recordDetails}
                isClosed={isClosed}
                isLost={isLost}
                updateDealMap={updateDealMap}
                disabled={confirm}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
          },
        },
        {
          title: "Confirm Deal",
          field: "dealConfirmed",
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;

            return (
              <ConfirmDealButton
                updatePopUpData={this.updatePopUpData}
                rowData={rowData}
                isClosed={isClosed}
                isLost={isLost}
                updateConfirmDealMap={updateConfirmDealMap}
                disabled={confirm}
                handleDealConfirm={handleDealConfirm}
                tabName={tabName}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
          },
        },
      ],
      tab3: [
        {
          title: "Upload Date",
          field: "createdDate",
          filterPlaceholder: "Filter",
          customSort: (a, b) => this.dateSort(a, b, "createdDate"),
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            wordBreak: "break-all",
            minWidth: "70px",
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => <Clamp lines={1}>{rowData.createdDate}</Clamp>,
        },
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date}
                  <br></br>
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 0",
            minWidth: "100px",
          },
          headerStyle: {
            padding: "6px 0",
            minWidth: "100px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "230px",
            wordBreak: "break-word",
          },
          headerStyle: { minWidth: "230px" },
          render: (rowData) => (
            <Clamp lines={2}>
              <span onClick={() => this.onClickCompanyName(rowData)}>
                <Link to={"/Quote"}>{this.camelCase(rowData.companyName)}</Link>
              </span>
            </Clamp>
          ),
        },
        {
          title: "Payroll",
          field: "total_payroll",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          render: (rowData) => numeral(rowData.total_payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "136px",
            wordBreak: "break-all",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "136px",
          },
        },
        {
          title: "State",
          field: "state",

          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "50px",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "50px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0",
            minWidth: "65px",
          },
          headerStyle: {
            padding: "6px 0",
            whiteSpace: "nowrap",
            minWidth: "65px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px" };
            if (rowData.step === "Submitted") {
              style.background = "#5ADA38";
            } else if (rowData.step === "Proposal Generated") {
              style.backgroundColor = "#5ADA38";
            } else if (rowData.step === "UW Portal") {
              style.backgroundColor = "#ffd737ac";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Quote Generated") {
              style.backgroundColor = "#87FA20";
            } else if (rowData.step === "UW Questions") {
              style.backgroundColor = "#FFD737";
            } else if (Number(rowData.visitCount) > 0) {
              style.backgroundColor = "#FFD737";
            } else {
              style.backgroundColor = "#FFF";
            }
            return style;
          },
        },
        {
          title: "Comments",
          field: "admincomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.admincomment || ""}
                onBlur={(e) => this.updateAdminComment(e, rowData)}
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0",
            minWidth: "70px",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: (
            <img
              src={require("../../images/file-download-circle-green.svg")}
              alt=""
              style={{
                width: "40px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          cellStyle: {
            minWidth: "20px",
            width: "20px",
            maxWidth: "20px",
            padding: "0 0",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files?.[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files?.[rowData.domain]
                ?.dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files?.[rowData.domain]
                              ?.dashboardFiles || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/file-download-circle-green.svg")}
                  alt=""
                  style={
                    domain_messageid_files?.[rowData.domain]?.dashboardFiles
                      ?.length
                      ? {
                          width: "40px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "40px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "80px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "120px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData?.salespersonName.slice(0, 15)}...`;
              } else {
                name = rowData?.salespersonName;
              }
            } catch (error) {}
            return <Clamp lines={2}>{name || ""}</Clamp>;
          },
        },
        {
          title: "SalesPerson Comments",
          field: "salescomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.salescomment || ""}
                disabled
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "SM Comments",
          field: "smcomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.smcomment || ""}
                rows="2"
                disabled
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "Deal Won/Lost",
          field: "dealFlagFilter",
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;

            return (
              <DealClosedLostButtons
                recordDetails={rowData.recordDetails}
                isClosed={isClosed}
                isLost={isLost}
                updateDealMap={updateDealMap}
                disabled={confirm}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
          },
        },
        {
          title: "Confirm Deal",
          field: "dealConfirmed",
          render: (rowData) => {
            let key = rowData.domain || rowData.salesID;
            let isClosed = false,
              isLost = false;
            if (key in dealMap) {
              isClosed = dealMap[key] === "true" ? true : false;
              isLost = dealMap[key] === "false" ? true : false;
            } else {
              isClosed = rowData.dealFlag === "closed" ? true : false;
              isLost = rowData.dealFlag === "lost" ? true : false;
            }
            let confirm = false;
            if (confirmDealMap?.[key] === "true") confirm = true;
            else if (rowData.dealConfirmed === "true") confirm = true;

            return (
              <ConfirmDealButton
                updatePopUpData={this.updatePopUpData}
                rowData={rowData}
                isClosed={isClosed}
                isLost={isLost}
                updateConfirmDealMap={updateConfirmDealMap}
                disabled={confirm}
                handleDealConfirm={handleDealConfirm}
                tabName={tabName}
              />
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "150px",
          },
        },
      ],
      tab4: [
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date}
                  <br></br>
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 0",
            minWidth: "100px",
          },
          headerStyle: {
            padding: "6px 0",
            minWidth: "100px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "230px",
            wordBreak: "break-word",
          },
          headerStyle: { minWidth: "230px" },
          render: (rowData) => {
            if (rowData.tab === "tab3") {
              return (
                <Clamp lines={2}>
                  <span onClick={() => this.onClickCompanyName(rowData)}>
                    <Link to={"/Quote"}>
                      {this.camelCase(rowData.companyName)}
                    </Link>
                  </span>
                </Clamp>
              );
            } else if (rowData.tab === "tab1") {
              let random_user = Object.keys(rowData?.email_specific || {})[0];
              return (
                <Clamp lines={2}>
                  <span
                    onClick={() => {
                      sessionStorage.setItem("hideButtons", "true");
                      sessionStorage.setItem("showAllFields", true);
                      sessionStorage.setItem("salespersonMailCampaign", true);
                    }}
                  >
                    <Link to={`/Quote/?user=${random_user}&viewButton=true`}>
                      {this.camelCase(rowData?.companyName || "-")}
                    </Link>
                  </span>
                </Clamp>
              );
            }
            return (
              <Clamp lines={2}>
                <span>{this.camelCase(rowData.companyName)}</span>
              </Clamp>
            );
          },
        },
        {
          title: "Payroll",
          field: "total_payroll",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          render: (rowData) =>
            numeral(rowData.total_payroll || rowData.payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "136px",
            wordBreak: "break-all",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "136px",
          },
        },
        {
          title: "State",
          field: "state",

          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "50px",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "50px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0",
            minWidth: "65px",
          },
          headerStyle: {
            padding: "6px 0",
            whiteSpace: "nowrap",
            minWidth: "65px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px" };
            if (rowData.step === "Submitted") {
              style.background = "#5ADA38";
            } else if (rowData.step === "Proposal Generated") {
              style.backgroundColor = "#5ADA38";
            } else if (rowData.step === "UW Portal") {
              style.backgroundColor = "#ffd737ac";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Quote Generated") {
              style.backgroundColor = "#87FA20";
            } else if (rowData.step === "UW Questions") {
              style.backgroundColor = "#FFD737";
            } else if (Number(rowData.visitCount) > 0) {
              style.backgroundColor = "#FFD737";
            } else {
              style.backgroundColor = "#FFF";
            }
            return style;
          },
        },
        {
          title: "Comments",
          field: "admincomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.admincomment || ""}
                onBlur={(e) => this.updateAdminComment(e, rowData)}
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0",
            minWidth: "70px",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: (
            <img
              src={require("../../images/file-download-circle-green.svg")}
              alt=""
              style={{
                width: "40px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          cellStyle: {
            minWidth: "20px",
            width: "20px",
            maxWidth: "20px",
            padding: "0 0",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files?.[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files?.[rowData.domain]
                ?.dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files?.[rowData.domain]
                              ?.dashboardFiles || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/file-download-circle-green.svg")}
                  alt=""
                  style={
                    domain_messageid_files?.[rowData.domain]?.dashboardFiles
                      ?.length
                      ? {
                          width: "40px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "40px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "80px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "120px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData?.salespersonName.slice(0, 15)}...`;
              } else {
                name = rowData?.salespersonName;
              }
            } catch (error) {}
            return <Clamp lines={2}>{name || ""}</Clamp>;
          },
        },
        {
          title: "Source",
          field: "source",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "170px",
          },
          render: (rowData) => {
            if (rowData.tab === "tab3") {
              return <Clamp lines={1}>Salesforce</Clamp>;
            }
            return <Clamp lines={1}>{putSource(rowData?.source) || ""}</Clamp>;
          },
        },
        {
          title: "SalesPerson Comments",
          field: "salescomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.salescomment || ""}
                disabled
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "PreUW Comments",
          field: "comment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.comment || ""}
                disabled
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "SM Comments",
          field: "smcomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.smcomment || ""}
                rows="2"
                disabled
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
      ],
      tab5: [
        {
          title: "Visit Date (ET)",
          field: "visitDate",
          filterPlaceholder: "Filter",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          customSort: (a, b) => this.dateSort(a, b, "visitDate"),
          render: (rowData) => {
            if (rowData.visitDate) {
              let date = rowData.visitDate.split("-")[0];
              let time = rowData.visitDate.split("-")[1];
              return (
                <span>
                  {date}
                  <br></br>
                  {time}
                </span>
              );
            } else {
              return "-";
            }
          },
          cellStyle: {
            wordBreak: "break-all",
            padding: "6px 0",
            minWidth: "100px",
          },
          headerStyle: {
            padding: "6px 0",
            minWidth: "100px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          filterPlaceholder: "Filter",

          cellStyle: {
            padding: "6px 5px 6px 10px",
            minWidth: "230px",
            wordBreak: "break-word",
          },
          headerStyle: { minWidth: "230px" },
          render: (rowData) => {
            if (rowData.tab === "tab3") {
              return (
                <Clamp lines={2}>
                  <span onClick={() => this.onClickCompanyName(rowData)}>
                    <Link to={"/Quote"}>
                      {this.camelCase(rowData.companyName)}
                    </Link>
                  </span>
                </Clamp>
              );
            } else if (rowData.tab === "tab1") {
              let random_user = Object.keys(rowData?.email_specific || {})[0];
              return (
                <Clamp lines={2}>
                  <span
                    onClick={() => {
                      sessionStorage.setItem("hideButtons", "true");
                      sessionStorage.setItem("showAllFields", true);
                      sessionStorage.setItem("salespersonMailCampaign", true);
                    }}
                  >
                    <Link to={`/Quote/?user=${random_user}&viewButton=true`}>
                      {this.camelCase(rowData?.companyName || "-")}
                    </Link>
                  </span>
                </Clamp>
              );
            }
            return (
              <Clamp lines={2}>
                <span>{this.camelCase(rowData.companyName)}</span>
              </Clamp>
            );
          },
        },
        {
          title: "Payroll",
          field: "total_payroll",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          render: (rowData) =>
            numeral(rowData.total_payroll || rowData.payroll).format("$0,0"),
          cellStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "136px",
            wordBreak: "break-all",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "136px",
          },
        },
        {
          title: "State",
          field: "state",

          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            textTransform: "uppercase",
            padding: "6px 0 6px 10px",
            minWidth: "50px",
          },
          headerStyle: {
            padding: "6px 0 6px 10px",
            minWidth: "50px",
          },
        },
        {
          title: "# Emp",
          field: "total_emp",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0",
            minWidth: "65px",
          },
          headerStyle: {
            padding: "6px 0",
            whiteSpace: "nowrap",
            minWidth: "65px",
          },
        },
        {
          title: "Status",
          field: "step",
          filterPlaceholder: "Filter",

          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px" };
            if (rowData.step === "Submitted") {
              style.background = "#5ADA38";
            } else if (rowData.step === "Proposal Generated") {
              style.backgroundColor = "#5ADA38";
            } else if (rowData.step === "UW Portal") {
              style.backgroundColor = "#ffd737ac";
            } else if (rowData.step === "Approved") {
              style.backgroundColor = "#87FA4D";
            } else if (rowData.step === "Rejected") {
              style.backgroundColor = "#fa6d3e";
            } else if (rowData.step === "Quote Generated") {
              style.backgroundColor = "#87FA20";
            } else if (rowData.step === "UW Questions") {
              style.backgroundColor = "#FFD737";
            } else if (Number(rowData.visitCount) > 0) {
              style.backgroundColor = "#FFD737";
            } else {
              style.backgroundColor = "#FFF";
            }
            return style;
          },
        },
        {
          title: "Comments",
          field: "admincomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.admincomment || ""}
                onBlur={(e) => this.updateAdminComment(e, rowData)}
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "Visit Count",
          field: "visitCount",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
          cellStyle: {
            padding: "6px 0",
            minWidth: "70px",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: (
            <img
              src={require("../../images/file-download-circle-green.svg")}
              alt=""
              style={{
                width: "40px",
                // filter: "grayscale(100%)  opacity(0.4)",
              }}
            />
          ),
          disableSortBy: true,
          cellStyle: {
            minWidth: "20px",
            width: "20px",
            maxWidth: "20px",
            padding: "0 0",
          },
          render: (rowData) => {
            let title = "";

            if (
              domain_messageid_files?.[rowData.domain]?.dashboardFiles?.length
            ) {
              for (let file of domain_messageid_files?.[rowData.domain]
                ?.dashboardFiles) {
                let fileToDisplay = file.split("/");
                title =
                  title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
              }
            }

            return (
              <button
                className="btn pt-0"
                type="button"
                title={parse(title)}
                data-html="true"
                onClick={() => {
                  this.setState({
                    showModel: true,
                    modalTitle: <h1>{"Download"}</h1>,
                    modalContent: (
                      <>
                        <DownloadDashboardFiles
                          dashboardFiles={
                            domain_messageid_files?.[rowData.domain]
                              ?.dashboardFiles || []
                          }
                          domainName={rowData.domain}
                          updateDomainMessageidFiles={
                            this.updateDomainMessageidFiles
                          }
                        />
                      </>
                    ),
                  });
                }}
              >
                <img
                  src={require("../../images/file-download-circle-green.svg")}
                  alt=""
                  style={
                    domain_messageid_files?.[rowData.domain]?.dashboardFiles
                      ?.length
                      ? {
                          width: "40px",
                          filter: "grayscale(0)",
                        }
                      : {
                          width: "40px",
                          filter: "grayscale(100%)  opacity(0.4)",
                        }
                  }
                />
              </button>
            );
          },
        },
        {
          title: "Campaign Name",
          field: "latestCampaign",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            wordBreak: "break-word",
            minWidth: "80px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {this.camelCase(rowData?.latestCampaign) || ""}
            </Clamp>
          ),
        },
        {
          title: "Salesperson",
          field: "salespersonName",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "120px",
          },
          render: (rowData) => {
            let name = "";
            try {
              if (rowData?.salespersonName?.length > 15) {
                name = `${rowData?.salespersonName.slice(0, 15)}...`;
              } else {
                name = rowData?.salespersonName;
              }
            } catch (error) {}
            return <Clamp lines={2}>{name || ""}</Clamp>;
          },
        },
        {
          title: "Source",
          field: "source",
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "170px",
          },
          render: (rowData) => {
            if (rowData.tab === "tab3") {
              return <Clamp lines={1}>Salesforce</Clamp>;
            }
            return <Clamp lines={1}>{putSource(rowData?.source) || ""}</Clamp>;
          },
        },
        {
          title: "SalesPerson Comments",
          field: "salescomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.salescomment || ""}
                disabled
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "PreUW Comments",
          field: "comment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.comment || ""}
                disabled
                rows="2"
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
        {
          title: "SM Comments",
          field: "smcomment",
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                defaultValue={rowData.smcomment || ""}
                rows="2"
                disabled
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "140px",
          },
        },
      ],
    };

    if (uploadDateFlag) {
      let uploadDate = {
        title: "Upload Date",
        field: "uploadedTimeStamp",
        filterPlaceholder: "Filter",
        customSort: (a, b) => this.dateSort(a, b, "uploadedTimeStamp"),
        cellStyle: {
          wordBreak: "break-all",
          padding: "6px 0",
          minWidth: "70px",
        },
        headerStyle: {
          padding: "6px 0",
        },
        render: (rowData) => (
          <Clamp lines={1}>{rowData.uploadedTimeStamp}</Clamp>
        ),
      };
      columns.tab1.unshift(uploadDate);
      columns.tab2.unshift(uploadDate);
    }

    return (
      <>
        {(tabName === "tab1" || tabName === "tab2" || tabName === "tab3") && (
          <LoadingOverlay active={this.state.confirmAllLoading}>
            <button
              className="btn btn-primary btnConfirmAll"
              onClick={() => {
                this.handleConfirmAll(tabName);
              }}
            >
              Confirm All
            </button>
          </LoadingOverlay>
        )}

        <div style={{ position: "relative" }}>
          {scrollLeft && (
            <button className="ChevronLeft" onClick={this.scrollLeft}>
              &lt;
            </button>
          )}
          {scrollRight && (
            <button className="ChevronRight" onClick={this.scrollRight}>
              &gt;
            </button>
          )}

          <MaterialTable
            isLoading={isLoading}
            icons={tableIcons}
            data={
              tabName === "tab1" ? rowDataList[sourceSelected] : rowDataList
            }
            columns={columns[tabName]}
            onSelectionChange={(rows) => this.handleSelection(rows)}
            options={{
              selection:
                tabName === "tab4" || tabName === "tab5" ? false : true,
              selectionProps: (rowData) => {
                let key = rowData.domain || rowData.salesID;
                return {
                  disabled:
                    rowData.dealConfirmed === "true" ||
                    (dealMap[key] !== "true" &&
                      dealMap[key] !== "false" &&
                      rowData.dealFlag !== "lost" &&
                      rowData.dealFlag !== "closed"),
                  color: "primary",
                };
              },
              stickyHeader: true,
              doubleHorizontalScroll: true,
              filtering: true,
              filter: true,
              search: true,
              sorting: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 75, 100],
              headerStyle: {
                fontSize: ismobile ? this.fontfunciton() : "1rem",
                backgroundColor: "#003764",
                color: "#FFF",
                padding: "6px 15px 6px 10px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile ? this.fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
            }}
            detailPanel={
              tabName === "tab2"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return this.tab2DetailPanel(rowData);
                      },
                    },
                  ]
                : tabName === "tab1"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return this.tab1DetailPanel(rowData);
                      },
                    },
                  ]
                : tabName === "tab3"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        return this.tab3DetailPanel(rowData);
                      },
                    },
                  ]
                : tabName === "tab4" || tabName === "tab5"
                ? [
                    {
                      tooltip: "Client info",
                      icon: () => <KeyboardArrowRightIcon />,
                      render: (rowData) => {
                        if (rowData.tab === "tab1") {
                          return this.tab1DetailPanel(rowData);
                        } else if (rowData.tab === "tab2") {
                          return this.tab2DetailPanel(rowData);
                        } else if (rowData.tab === "tab3") {
                          return this.tab3DetailPanel(rowData);
                        }
                      },
                    },
                  ]
                : false
            }
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  labelRowsPerPage={
                    <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 12 }}>
                      {props.labelDisplayedRows(row)}
                    </div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                />
              ),
            }}
          />
        </div>
      </>
    );
  };

  updateSalesDisplay = async () => {
    let sessionData = await Auth.currentAuthenticatedUser();
    if (sessionData) {
      let requestBody = {};
      requestBody.salesPersonEmail = sessionData.attributes.email;
      axios
        .post(awsUrl2 + "/api/getSalesDashboardDataAdmin", requestBody)
        .then((res) => {
          let userDataList = [];
          let rowData;
          let rowDataList = [];

          userDataList = res.data.salesforce;

          for (let userDataRow in userDataList) {
            let step;
            let userData = userDataList[userDataRow];

            if (userData.formStage) {
              switch (userData.formStage) {
                case "two":
                  step = "UW Questions";
                  break;
                case "three":
                  step = "Quote Generated";
                  break;
                default:
                  break;
              }
            }

            let currProspectDetails = {
              companyProfile: userData.companyProfile,
              childrenLoc: userData.payrollData,
              emodStatesData: userData.emodStatesData,
              companyProfileAddQuestions: userData.companyProfileAddQuestions,
              uwQues: userData.uwQues,
              requestedPricing: userData.requestedPricing,
            };

            if (userData?.brokerDetails) {
              currProspectDetails.brokerDetails = userData.brokerDetails;
            }

            let total_emp = 0;
            let total_payroll = 0;

            if (userData.payrollData) {
              let childrenLoc = userData.payrollData;
              for (let locIndex in childrenLoc) {
                let loc = childrenLoc[locIndex];
                for (let ccIndex in loc.classCodesInfo) {
                  let cc = loc.classCodesInfo[ccIndex];
                  total_emp =
                    total_emp +
                    Number(cc?.ft?.value || 0) +
                    Number(cc?.pt?.value || 0);

                  total_payroll =
                    total_payroll + (numeral(cc?.payroll?.value).value() || 0);
                }
              }
            }

            rowData = {
              visitDate:
                userData.updated_timestamp &&
                moment(Number(userData.updated_timestamp))
                  .utcOffset("-0500")
                  .format("M/D/YYYY - hh:mm A"),
              phoneNumber:
                userData.companyProfile && userData.companyProfile.phoneNumber
                  ? userData.companyProfile.phoneNumber.value
                  : "",
              effectiveDate:
                userData.companyProfile &&
                userData.companyProfile.effectiveDate &&
                userData.companyProfile.effectiveDate.value
                  ? moment(userData.companyProfile.effectiveDate.value).format(
                      "x"
                    )
                  : "",
              contactName:
                userData.companyProfile && userData.companyProfile.firstName
                  ? userData.companyProfile.firstName.value
                  : "",
              companyName:
                userData.companyProfile && userData.companyProfile.companyName
                  ? userData.companyProfile.companyName.value
                  : "",
              step: userData.isSubmitted ? "Submitted" : step,
              currProspectDetails,
              salesID: userData.id,
              formStage: userData.formStage,
              amtrustAccountDetails: userData.amtrustAccountDetails,
              salespersonName:
                userData.salespersonNameAttr || userData.salespersonName,
              total_emp,
              state: userData?.companyProfile?.state1?.value,
              quoteData: userData.quoteData,
              address: userData.address,
              total_payroll,
              createdDate: userData.created_timestamp
                ? moment(userData.created_timestamp, "x").format("M/D/YYYY")
                : "-",
              companyProfile: userData.companyProfile,
              sortKeyList: userData.sortKeyList,
              salescomment: userData.comment,
              smcomment: userData.smcomment,
              admincomment: userData.admincomment,
              preUwFiles: userData.preUwFiles || [],
              dashboardFiles: userData.dashboardFiles || [],
              fileListToStore: userData.fileListToStore || [],
              source: userData.source,
              dealFlag:
                userData?.dealData?.status === "true"
                  ? "closed"
                  : userData?.dealData?.status === "false"
                  ? "lost"
                  : "",
              dealFlagFilter:
                userData?.dealData?.status === "true"
                  ? "won"
                  : userData?.dealData?.status === "false"
                  ? "lost"
                  : "",
              dealConfirmed: userData?.dealConfirmed,
              recordDetails: {
                salesId: userData.id,
              },
              tab: this.props.tabName,
            };
            rowDataList.push(rowData);
          }

          this.props.setRowDataList("tab3", rowDataList);
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  updateEligibilityDisplay = async () => {
    await axios
      .get(awsUrl2 + "/api/getNonEligibleData")
      .then((res) => {
        let data = res.data;
        this.setState({ rowDataList: data });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updatePaygoData = () => {
    axios
      .post(awsUrl2 + "/api/peoCampaignData/getAll", {})
      .then((res) => {
        let rowData;
        let rowDataList = [];

        let data = res.data;

        let payroll = 0,
          emod = 1;

        for (let row in data) {
          if (
            data[row].emodStatesData &&
            data[row].emodStatesData[Object.keys(data[row].emodStatesData)[0]]
              .rate1
          ) {
            emod =
              data[row].emodStatesData[Object.keys(data[row].emodStatesData)[0]]
                .rate1.value;
          }

          if (data[row].payrollData) {
            for (var state in data[row].payrollData) {
              for (var pay in data[row].payrollData[state].classCodesInfo) {
                var pyrll = numeral(
                  data[row].payrollData[state].classCodesInfo[pay].payroll.value
                ).value();

                payroll += parseInt(pyrll);
              }
            }
          }
          data[row].payroll = payroll;
          data[row].emod = emod;
        }

        this.setState({ rowDataList: data });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateDisplayTab1 = () => {
    let { tabName } = this.props;
    let requestBody = {
      tab: tabName,
    };
    axios
      .post(awsUrl2 + "/api/getDashboardTableData", requestBody)
      .then((res) => {
        let userDataList = [];
        let tempObj;
        let rowData;
        // let rowDataList = {
        //   PEO: {
        //     Affinity: [],
        //     Hotschedules: [],
        //   },
        //   PAYGO: {
        //     Affinity: [],
        //     Hotschedules: [],
        //   },
        // };

        let rowDataList = [];
        let domain_messageid_files = {};

        let dataFromApi = res.data.data;
        for (let key in dataFromApi) {
          tempObj = {};
          for (let dataKey in dataFromApi[key]) {
            tempObj[dataKey] = dataFromApi[key][dataKey];
          }
          userDataList.push(tempObj);
        }

        for (let userData of userDataList) {
          let quote_status;
          let step;
          let excluded_status;

          let firstFilter = userData.onlyPaygo === "true" ? "PAYGO" : "PEO";
          let secondFilter =
            userData.client_source === "Hotschedules"
              ? "Hotschedules"
              : "Affinity";

          let email_specific_status;
          let landing_email_status, landing_email_get_quote_clicked;

          for (let email in userData.email_specific) {
            let db_quote_status = userData.email_specific[email].quote_status;
            let db_excluded = userData.email_specific[email].nonEligibilityList;
            if (db_excluded && db_excluded.length > 0) {
              excluded_status = true;
            }

            if (
              userData.email_specific[email].companyProfile &&
              userData.email_specific[email].companyProfile.status &&
              userData.email_specific[email].companyProfile.status.value
            ) {
              let emailStatus =
                userData.email_specific[email].companyProfile.status.value;
              if (!email_specific_status) email_specific_status = emailStatus;
              else if (
                statusPriorityMap[emailStatus] <
                statusPriorityMap[email_specific_status]
              ) {
                email_specific_status = emailStatus;
              }
            }

            if (!landing_email_status) {
              landing_email_status = userData.email_specific[email].landingPage
                ? userData.email_specific[email].landingPage
                : null;
            }

            if (!landing_email_get_quote_clicked) {
              landing_email_get_quote_clicked = userData.email_specific[email]
                .homeGetQuote
                ? userData.email_specific[email].homeGetQuote
                : null;
            }

            if (
              (!quote_status && db_quote_status) ||
              (quote_status === "reject" && db_quote_status) ||
              db_quote_status === "proceed_uw"
            ) {
              quote_status = db_quote_status;
            }
          }

          if (userData.isSalespersonSubmitted) {
            step = "Submitted";
          } else if (userData.isUserSubmitted) {
            step = "User Submitted";
          } else if (userData.isSubmitted) {
            step = "Submitted";
          } else if (Number(userData.proposalGeneratedCount) > 0) {
            step = "Proposal Generated";
          } else if (quote_status) {
            if (quote_status === "uwportal") {
              step = "UW Portal";
            } else if (quote_status === "proceed_uw") {
              step = "Approved";
            } else if (quote_status === "reject") {
              step = "Rejected";
            }
          } else if (Number(userData.quoteGeneratedCount) > 0) {
            step = "Quote Generated";
          } else if (userData.formStage) {
            if (userData.formStage === "one") {
              step = "Company Profile";
              if (landing_email_get_quote_clicked) {
                step += " (Get Quote)";
              }
            } else {
              if (excluded_status) {
                step = "Not Eligible";
              } else {
                step = "UW Questions";
              }
            }
          } else if (userData.visitCount && Number(userData.visitCount) > 0) {
            step = "Landing Page";
          } else if (landing_email_status) {
            step =
              landing_email_status === "affinityLogo"
                ? "Affinity Logo Clicked"
                : "Fourth Logo Clicked";
          } else {
            step = "-";
          }
          if (email_specific_status) {
            step =
              statusPriorityMap[email_specific_status] < statusPriorityMap[step]
                ? email_specific_status
                : step;
          }

          let currProspectDetails = {
            companyProfile: userData.companyProfile,
            childrenLoc: userData.payrollData,
            emodStatesData: userData.emodStatesData,
          };

          if (userData?.brokerDetails) {
            currProspectDetails.brokerDetails = userData.brokerDetails;
          }

          rowData = {
            visitDate:
              userData?.timestamp &&
              moment(Number(userData?.timestamp))
                .utcOffset("-0500")
                .format("M/D/YYYY - hh:mm A"),
            userEmail: userData.email,
            quoteGeneratedCount: userData.quoteGeneratedCount || 0,
            proposalGeneratedCount: userData.proposalGeneratedCount || 0,
            uuid: userData.uuid,
            phoneNumber: userData.phoneNumber,
            payroll: userData.payroll,
            indicationCost: userData.indicationCost,
            emod:
              userData.emod && Number(userData.emod)
                ? parseFloat(userData.emod).toFixed(2)
                : "-",
            uwEmail: userData.uwEmail,
            effectiveDate: userData?.effectiveDate
              ? moment(userData?.effectiveDate).format("x")
              : "",
            contactName: userData?.contactName || "",
            companyName: userData?.companyName || "",
            comment: userData?.comment || "",
            domain: userData?.domain,
            visitCount: userData?.visitCount || 0,
            step,
            latestCampaign: userData?.latestCampaign,
            email_specific: userData?.email_specific,
            messageID: userData?.messageID,
            dashboardFiles: userData?.dashboardFiles,
            preUwFiles: userData?.preUwFiles,
            fileListToStore: userData?.fileListToStore,
            zohoDate: userData?.zywave_uploaded_date
              ? moment(userData?.zywave_uploaded_date, "YYYY-MM-DD").format(
                  "M/D/YYYY"
                )
              : "-",
            currProspectDetails,
            salesID: userData?.domain,
            formStage: userData?.formStage,
            address: userData?.address,
            uploadedDate: userData?.uploadedDate,
            quoteData: userData?.quoteData,
            state: userData?.state || "-",
            total_emp: userData?.total_emp || 0,
            isPreUnderwritten: userData?.preUwStatus ? true : false,
            salespersonName:
              userData?.salespersonNameAttr || userData?.salespersonName,
            uploadedTimeStamp: userData?.uploadedTimeStamp
              ? moment(userData?.uploadedTimeStamp, "x").format("M/D/YYYY")
              : userData?.zywave_uploaded_date
              ? moment(userData?.zywave_uploaded_date, "YYYY-MM-DD").format(
                  "M/D/YYYY"
                )
              : "-",
            source: userData.source,
            salescomment: userData.salescomment,
            admincomment: userData.admincomment,
            tableName: userData.tableName,
            smcomment: userData.smcomment,
            dealFlag:
              userData?.dealData?.status === "true"
                ? "closed"
                : userData?.dealData?.status === "false"
                ? "lost"
                : "",
            dealFlagFilter:
              userData?.dealData?.status === "true"
                ? "won"
                : userData?.dealData?.status === "false"
                ? "lost"
                : "",
            dealConfirmed: userData?.dealConfirmed,
            recordDetails: {
              domain: userData.domain,
            },
            tab: this.props.tabName,
          };
          //console.log("deal confirmed", rowData.dealConfirmed);
          if (rowData?.email_specific) {
            Object.values(rowData.email_specific).map((email) => {
              if (
                email.companyProfile &&
                email.companyProfile.contact_number &&
                email.companyProfile.contact_number.value
              ) {
                email.companyProfile.contact_number.value = this.appendRemaining(
                  email.companyProfile.contact_number.value.toString()
                );
              }
            });
          } else {
            if (rowData.phoneNumber)
              rowData.phoneNumber = this.appendRemaining(
                rowData.phoneNumber.toString()
              );
          }

          // rowDataList[firstFilter][secondFilter].push(rowData);
          rowDataList.push(rowData);

          domain_messageid_files[rowData.domain] = {};
          if (rowData.messageID)
            domain_messageid_files[rowData.domain]["messageID"] =
              rowData.messageID;
          if (rowData.dashboardFiles)
            domain_messageid_files[rowData.domain]["dashboardFiles"] =
              rowData.dashboardFiles;
          if (rowData.preUwFiles) {
            let _preUwFiles = [];
            rowData.preUwFiles.forEach((ele) => {
              _preUwFiles.push(ele.key);
            });
            domain_messageid_files[rowData.domain]["preUwFiles"] = _preUwFiles;
          }

          if (rowData.fileListToStore) {
            let _fileListToStore = [];
            rowData.fileListToStore.forEach((ele) => {
              _fileListToStore.push(ele.key);
            });
            domain_messageid_files[rowData.domain][
              "fileListToStore"
            ] = _fileListToStore;
          }
        }

        this.setState({ domain_messageid_files });
        this.props.setRowDataList("tab1", rowDataList);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateDisplayTab2 = () => {
    let { tabName } = this.props;
    let requestBody = {
      tab: tabName,
    };

    let userDataList = [];
    let tempObj1;

    axios
      .post(awsUrl2 + "/api/getDashboardTableData", {
        tab: "tab2",
      })
      .then((res) => {
        let tempObj;
        let rowData;
        // let rowDataList = {
        //   PEO: {
        //     Affinity: [],
        //     Hotschedules: [],
        //   },
        //   PAYGO: {
        //     Affinity: [],
        //     Hotschedules: [],
        //   },
        // };

        let rowDataList = [];
        let domain_messageid_files = {};
        // let engagedDataOb = {
        //   "All": [],
        //   "HotSchedules": [],
        //   "Affinity": []
        // };

        let dataFromApi = res.data.data;
        for (let key in dataFromApi) {
          tempObj = {};
          for (let dataKey in dataFromApi[key]) {
            tempObj[dataKey] = dataFromApi[key][dataKey];
          }
          userDataList.push(tempObj);
        }

        for (let userData of userDataList) {
          let quote_status;
          let step;
          let excluded_status;

          let firstFilter = userData.onlyPaygo === "true" ? "PAYGO" : "PEO";
          let secondFilter =
            userData.client_source === "Hotschedules"
              ? "Hotschedules"
              : "Affinity";

          let email_specific_status;
          let landing_email_status, landing_email_get_quote_clicked;

          for (let email in userData.email_specific) {
            let db_quote_status = userData.email_specific[email].quote_status;
            let db_excluded = userData.email_specific[email].nonEligibilityList;
            if (db_excluded && db_excluded.length > 0) {
              excluded_status = true;
            }

            if (
              userData.email_specific[email].companyProfile &&
              userData.email_specific[email].companyProfile.status &&
              userData.email_specific[email].companyProfile.status.value
            ) {
              let emailStatus =
                userData.email_specific[email].companyProfile.status.value;
              if (!email_specific_status) email_specific_status = emailStatus;
              else if (
                statusPriorityMap[emailStatus] <
                statusPriorityMap[email_specific_status]
              ) {
                email_specific_status = emailStatus;
              }
            }
            if (!landing_email_status) {
              landing_email_status = userData.email_specific[email].landingPage
                ? userData.email_specific[email].landingPage
                : null;
            }

            if (!landing_email_get_quote_clicked) {
              landing_email_get_quote_clicked = userData.email_specific[email]
                .homeGetQuote
                ? userData.email_specific[email].homeGetQuote
                : null;
            }

            if (
              (!quote_status && db_quote_status) ||
              (quote_status === "reject" && db_quote_status) ||
              db_quote_status === "proceed_uw"
            ) {
              quote_status = db_quote_status;
            }
          }

          if (userData.isSalespersonSubmitted) {
            step = "Submitted";
          } else if (userData.isUserSubmitted) {
            step = "User Submitted";
          } else if (userData.isSubmitted) {
            step = "Submitted";
          } else if (Number(userData.proposalGeneratedCount) > 0) {
            step = "Proposal Generated";
          } else if (quote_status) {
            if (quote_status === "uwportal") {
              step = "UW Portal";
            } else if (quote_status === "proceed_uw") {
              step = "Approved";
            } else if (quote_status === "reject") {
              step = "Rejected";
            }
          } else if (Number(userData.quoteGeneratedCount) > 0) {
            step = "Quote Generated";
          } else if (userData.formStage) {
            if (userData.formStage === "one") {
              step = "Company Profile";
              if (landing_email_get_quote_clicked) {
                step += " (Get Quote)";
              }
            } else {
              if (excluded_status) {
                step = "Not Eligible";
              } else {
                step = "UW Questions";
              }
            }
          } else if (userData.visitCount && Number(userData.visitCount) > 0) {
            step = "Landing Page";
          } else if (landing_email_status) {
            step =
              landing_email_status === "affinityLogo"
                ? "Affinity Logo Clicked"
                : "Fourth Logo Clicked";
          } else {
            step = "-";
          }
          if (email_specific_status) {
            step =
              statusPriorityMap[email_specific_status] < statusPriorityMap[step]
                ? email_specific_status
                : step;
          }

          let currProspectDetails = {
            companyProfile: userData.companyProfile,
            childrenLoc: userData.payrollData,
            emodStatesData: userData.emodStatesData,
            // companyProfileAddQuestions: userData.companyProfileAddQuestions,
            // uwQues: userData.uwQues,
            // requestedPricing: userData.requestedPricing
          };

          if (userData?.brokerDetails) {
            currProspectDetails.brokerDetails = userData.brokerDetails;
          }
          // let total_emp = 0;
          // if (userData.payrollData) {
          //   let childrenLoc = userData.payrollData;
          //   for (let locIndex in childrenLoc) {
          //     let loc = childrenLoc[locIndex];
          //     for (let ccIndex in loc.classCodesInfo) {
          //       let cc = loc.classCodesInfo[ccIndex];
          //       total_emp =
          //         total_emp +
          //         Number(cc?.ft?.value || 0) +
          //         Number(cc?.pt?.value || 0);
          //     }
          //   }
          // }
          rowData = {
            visitDate:
              userData.timestamp &&
              moment(Number(userData.timestamp))
                .utcOffset("-0500")
                .format("M/D/YYYY - hh:mm A"),
            userEmail: userData.email,
            quoteGeneratedCount: userData.quoteGeneratedCount || 0,
            proposalGeneratedCount: userData.proposalGeneratedCount || 0,
            uuid: userData.uuid,
            phoneNumber: userData.phoneNumber,
            payroll: userData.payroll,
            indicationCost: userData.indicationCost,
            emod:
              userData.emod && Number(userData.emod)
                ? parseFloat(userData.emod).toFixed(2)
                : "-",
            uwEmail: userData.uwEmail,
            effectiveDate: userData.effectiveDate
              ? moment(userData.effectiveDate).format("x")
              : "",
            contactName: userData.contactName || "",
            companyName: userData.companyName || "",
            comment: userData.comment || "",
            domain: userData.domain,
            visitCount: userData.visitCount || 0,
            step,
            latestCampaign: userData.latestCampaign,
            email_specific: userData.email_specific,
            messageID: userData.messageID,
            dashboardFiles: userData.dashboardFiles,
            zohoDate: userData.zywave_uploaded_date
              ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                  "M/D/YYYY"
                )
              : "-",
            currProspectDetails,
            salesID: userData.domain,
            formStage: userData.formStage,
            address: userData.address,
            quoteData: userData.quoteData,
            uploadedDate: userData.uploadedDate,
            state: userData?.state || "-",
            total_emp: userData?.total_emp || 0,
            salespersonName:
              userData.salespersonNameAttr || userData.salespersonName,
            uploadedTimeStamp: userData.uploadedTimeStamp
              ? moment(userData.uploadedTimeStamp, "x").format("M/D/YYYY")
              : userData.zywave_uploaded_date
              ? moment(userData.zywave_uploaded_date, "YYYY-MM-DD").format(
                  "M/D/YYYY"
                )
              : "-",
            source: userData.source,
            isPreUnderwritten: userData.preUwStatus ? true : false,
            salescomment: userData.salescomment,
            admincomment: userData.admincomment,
            tableName: userData.tableName,
            smcomment: userData.smcomment,
            dealFlag:
              userData?.dealData?.status === "true"
                ? "closed"
                : userData?.dealData?.status === "false"
                ? "lost"
                : "",
            dealFlagFilter:
              userData?.dealData?.status === "true"
                ? "won"
                : userData?.dealData?.status === "false"
                ? "lost"
                : "",
            dealConfirmed: userData?.dealConfirmed,
            recordDetails: {
              domain: userData.domain,
              isMailCmpn: userData.tableName === "campaignTable" ? true : false,
            },
            tab: this.props.tabName,
          };

          if (rowData.email_specific) {
            Object.values(rowData.email_specific).map((email) => {
              if (
                email.companyProfile &&
                email.companyProfile.contact_number &&
                email.companyProfile.contact_number.value
              ) {
                email.companyProfile.contact_number.value = this.appendRemaining(
                  email.companyProfile.contact_number.value.toString()
                );
              }
            });
          } else {
            if (rowData.phoneNumber)
              rowData.phoneNumber = this.appendRemaining(
                rowData.phoneNumber.toString()
              );
          }

          // rowDataList[firstFilter][secondFilter].push(rowData);
          rowDataList.push(rowData);
          // engagedDataOb.All.push(rowData);

          // if (rowData.source === "affinity") {
          //   engagedDataOb.Affinity.push(rowData);
          // } else if (rowData.source === "hotschedules") {
          //   engagedDataOb.HotSchedules.push(rowData);
          // }

          domain_messageid_files[rowData.domain] = {};
          if (rowData.messageID)
            domain_messageid_files[rowData.domain]["messageID"] =
              rowData.messageID;
          if (rowData.dashboardFiles)
            domain_messageid_files[rowData.domain]["dashboardFiles"] =
              rowData.dashboardFiles;
          if (userData.preUwFiles) {
            let _preUwFiles = [];
            userData.preUwFiles.forEach((ele) => {
              _preUwFiles.push(ele.key);
            });
            domain_messageid_files[userData.domain]["preUwFiles"] = _preUwFiles;
          }

          if (userData.fileListToStore) {
            let _fileListToStore = [];
            userData.fileListToStore.forEach((ele) => {
              _fileListToStore.push(ele.key);
            });
            domain_messageid_files[userData.domain][
              "fileListToStore"
            ] = _fileListToStore;
          }
        }

        this.setState({ domain_messageid_files });
        this.props.setRowDataList("tab2", rowDataList);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    let { selected, tabName } = this.props;
    let {
      showModel,
      modalTitle,
      modalContent,
      sourceSelected,
      popupData,
    } = this.state;
    // console.log("sourceSelected: ", sourceSelected, " tabName: ", tabName);
    return (
      <>
        {selected === tabName ? (
          <div
            className={"admin-dashboard " + `${tabName}`}
            style={this.state.style}
          >
            {tabName === "tab1" && (
              <div className="mt-3 mb-3 dashboard-dropdown-row">
                <select
                  value={sourceSelected}
                  onChange={(e) => {
                    this.setState({ sourceSelected: e.target.value });
                  }}
                >
                  <option value={"All"}>All</option>
                  <option value={"HotSchedules"}>Hot Schedules</option>
                  <option value={"Affinity"}>Affinity</option>
                </select>
              </div>
            )}
            <div ref={this.myRef}>{this.setTableData()}</div>
            <Modal
              show={showModel}
              onHide={() => {
                this.setState({
                  showModel: false,
                  modalTitle: null,
                  modalContent: null,
                });
              }}
              dialogClassName="model-download modal-dialog-centered"
            >
              <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{modalContent}</Modal.Body>
            </Modal>
          </div>
        ) : (
          ""
        )}
        {popupData.show && (
          <Popup popupData={popupData} updatePopUpData={this.updatePopUpData} />
        )}
      </>
    );
  }
}

export default DashboardAdmin;
