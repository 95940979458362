import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { Form } from "react-bootstrap";

let isMobile = window.innerWidth < 700;
class Allques extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quesList: this.props.quesList,
    };
  }

  componentDidUpdate() {
    if (this.state.quesList !== this.props.quesList) {
      this.setState({ quesList: this.props.quesList });
    }
  }

  createTable = () => {
    const { quesList } = this.props;
    const { updateQuesList } = this.props;
    let table = [];

    // Outer loop to create parent
    for (let i = 0; i < quesList.length; i++) {
      let checkedYes = false,
        checkedNo = false;
      if (quesList[i].response === true) {
        checkedYes = true;
      } else if (quesList[i].response === false) {
        checkedNo = true;
      }

      let questionContent = quesList[i].ques;
      if (quesList[i].name === "q23") {
        // Modify the question content for index 24
        questionContent = (
          <div className="mandatory">
            <p>
              {quesList[i].ques}
              {<span style={{ color: "red" }}>&#42;</span>}
            </p>
          </div>
        );
      }

      table.push(
        <tr className="bringcolumn " key={i}>
          <td className={"responsivecolumn" + "tableQues" + " " + `trow${i}`}>
            {questionContent}
          </td>
          {quesList[i].type !== "input" ? (
            <>
              <td className="tableAns d-flex">
                <Form.Check
                  inline
                  id={`inputY-${i}`}
                  value="Y"
                  name={quesList[i].name}
                  checked={checkedYes}
                  label="Yes"
                  type="radio"
                  onChange={(e) => updateQuesList(e, "response", true, i)}
                />
                <Form.Check
                  inline
                  id={`inputN-${i}`}
                  value="N"
                  name={quesList[i].name}
                  checked={checkedNo}
                  label="No"
                  type="radio"
                  onChange={(e) => updateQuesList(e, "response", false, i)}
                />
              </td>
              <td className="tableRemarks">
                {quesList[i].type === "percent" ? (
                  <NumberFormat
                    id={"qr" + (i + 1)}
                    className={
                      !quesList[i].response ? "hidden ques-input" : "ques-input"
                    }
                    maxLength="3"
                    suffix={"%"}
                  />
                ) : (
                  <input
                    id={`remarks-${i}`}
                    value={
                      quesList[i].remarks && quesList[i].remarks !== "none"
                        ? quesList[i].remarks
                        : ""
                    }
                    type="text"
                    className={
                      !quesList[i].response ? "hidden ques-input" : "ques-input"
                    }
                    disabled={!quesList[i].response}
                    onChange={(e) =>
                      updateQuesList(e, "remarks", e.target.value, i)
                    }
                  />
                )}
              </td>
            </>
          ) : quesList[i].name === "q23" ? (
            <>
              <td className="tableInputs" colspan="2">
                <NumberFormat
                  id={`remarks-${i}`}
                  value={quesList[i].remarks ? quesList[i].remarks : ""}
                  type="text"
                  prefix={"$"}
                  onKeyDown={(event) => {
                    if (event.key === "-" || event.key === "−") {
                      event.preventDefault(); // Prevent typing the '-' character
                    }
                  }}
                  style={{ width: "100%" }}
                  className="ques-input"
                  onChange={(e) =>
                    updateQuesList(e, "remarks", e.target.value, i)
                  }
                />{" "}
                <td></td>
              </td>
            </>
          ) : (
            <>
              <td className="tableInputs" colspan="2">
                <input
                  id={`remarks-${i}`}
                  value={quesList[i].remarks ? quesList[i].remarks : ""}
                  type="text"
                  style={{ width: "100%" }}
                  className="ques-input"
                  onChange={(e) =>
                    updateQuesList(e, "remarks", e.target.value, i)
                  }
                />{" "}
                <td></td>
              </td>
            </>
          )}
        </tr>
      );
    }
    return table;
  };

  render() {
    return (
      <table className="table table-striped table-borderless table-undrques">
        <thead>
          {!isMobile ? (
            <tr>
              <th className="tableQues">General Information</th>
              <th className="tableAns">Response</th>
              <th className="tableRem">Remarks</th>
            </tr>
          ) : (
            " "
          )}
          {isMobile ? (
            <tr>
              <th className="tableQues">General Information</th>
            </tr>
          ) : (
            " "
          )}
        </thead>
        <tbody> {this.props.quesList && this.createTable()}</tbody>
      </table>
    );
  }
}
export default Allques;
