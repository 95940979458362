import React, { Component, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import Header from "../common/Header";
import { awsUrl2 } from "../../config";
import { Auth } from "aws-amplify";
import LoadingOverlay from "react-loading-overlay";
import { blobPdfFromBase64String } from "../../utils/common";
import SmallLoader from "../common/SmallLoader";
import Loader from "../common/Loader";
import { getHeader } from "../../utils/common";
import moment from "moment";
import AsyncSelect from "react-select/async";
import $ from "jquery";
import { extraQues } from "../../utils/ques_list";
import {
  histClaimCalcTI,
  histClaimCalcTP,
  histClaimCalcTR,
} from "../../utils/form_cmprRates";
import { clearErrHistoricalClaims } from "../../utils/common";
import { object } from "react-dom-factories";
const stateCity = require("../../utils/state_city.json");
let isMobile = window.innerWidth < 319;

function GenerateAcord() {
  const [containerCloseOpen, setContainerCloseOpen] = useState({
    showCompanyProfile: true,
    showContactDetails: true,
    showAddtionalContactDetails: false,
    showLocationAndPayroll: true,
    showUwQuestions: true,
  });
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [acordData, setAcordData] = useState({});
  const [agentData, setAgentData] = useState({});
  const [loggedInUserData, setLoggedInUserData] = useState({});
  const [acordFileURL, setAcordFileURL] = useState();
  const [loadingAcord, setLoadingAcord] = useState();
  const [cliamZeroCheckAmountPaid, setCliamZeroCheckAmountPaid] = useState({});
  const [cliamZeroCheckReserve, setCliamZeroCheckReserve] = useState({});
  const [claimOneWithTotalPayroll, setClaimOneWithTotalPayroll] = useState({});
  const [validCheck, setValidCheck] = useState();
  const acordDisplayRef = useRef(null);
  const clearErr = clearErrHistoricalClaims.bind(this);
  // on mount function
  useEffect(() => {
    const fetchAgentData = async () => {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      let id = urlParams.get("id");

      if (id) {
        $("#loader").css("display", "block");
        let response = await axios
          .get(awsUrl2 + "/api/getAgentDBData/" + id)
          .catch((error) =>
            console.log("Error in fetching agent data from DB", error)
          )
          .finally(() => {
            $("#loader").css("display", "none");
          });

        let _agentData = response?.data || {};

        if (
          Object.keys(_agentData).length > 0 &&
          _agentData.formStage === "three"
        ) {
          let _acordData = _agentData.acordOverrideData;
          if (!_acordData) {
            _acordData = {
              companyProfile: _agentData?.currProspect?.companyProfile || {},
              childrenLoc: _agentData?.currProspect?.childrenLoc || {},
              includedExcludedObject:
                _agentData?.currProspect?.includedExcludedObject || {},
              uwQues: _agentData?.currProspect?.uwQues || {},
              historicalClaimsCheck:
                _agentData?.currProspect?.historicalClaimsCheck || {},
              historicalClaims:
                _agentData?.currProspect?.historicalClaims || {},
            };
          }
          setAgentData(_agentData);
          setAcordData(_acordData);

          console.log(_agentData);
        } else {
          setIsDataAvailable(false);
        }
      }
    };
    const fetchLoggedInDetails = async () => {
      let userData = await Auth.currentAuthenticatedUser();
      setLoggedInUserData(userData);
    };

    fetchAgentData();
    fetchLoggedInDetails();
  }, []);

  // function for updation the uw question
  const updateQuesList = (e, key, value, currIndex) => {
    let _acordData = JSON.parse(JSON.stringify(acordData));
    let index = e.target.id.split("-")[1];
    _acordData.uwQues[index][key] = value;
    if (key === "remarks" && _acordData.uwQues[index]["type"] === "input") {
      console.log({ value });
      _acordData.uwQues[index]["response"] = value.length > 0 ? true : "";
    }
    if (
      key === "response" &&
      _acordData.uwQues[currIndex]["childrenQues"] &&
      _acordData.uwQues[currIndex]?.type !== "input"
    ) {
      const extraQuestionsToRemove = value
        ? _acordData.uwQues[currIndex]["childrenQues"]["ifNo"] || []
        : _acordData.uwQues[currIndex]["childrenQues"]["ifYes"] || [];
      const extraQuestionsToAdd = value
        ? _acordData.uwQues[currIndex]["childrenQues"]["ifYes"] || []
        : _acordData.uwQues[currIndex]["childrenQues"]["ifNo"] || [];
      const indexToRemove = [];
      for (let i = 0; i < _acordData.uwQues.length; i++) {
        if (extraQuestionsToRemove.includes(_acordData.uwQues[i].name))
          indexToRemove.push(i);
      }

      indexToRemove.reverse();
      for (let deleteIndex of indexToRemove) {
        _acordData.uwQues.splice(Number(deleteIndex), 1);
      }
      for (let ques of extraQuestionsToAdd) {
        _acordData.uwQues.splice(Number(currIndex) + 1, 0, extraQues[ques]);
      }
    }
    setAcordData(_acordData);
  };

  // function to create table in uw ques
  const createTable = () => {
    return acordData.uwQues.map((ques, i) => {
      const { response, type, name, remarks } = ques;
      const checkedYes = response === true;
      const checkedNo = response === false;

      return (
        <tr className="bringcolumn" key={"row-" + ques.name}>
          <td className={`responsivecolumn tableQues trow${i}`}>{ques.ques}</td>
          {type !== "input" ? (
            <>
              <td className="tableAns d-flex">
                <Form.Check
                  inline
                  id={`inputY-${i}`}
                  value="Y"
                  name={name}
                  checked={checkedYes}
                  label="Yes"
                  type="radio"
                  onChange={(e) => updateQuesList(e, "response", true, i)}
                />
                <Form.Check
                  inline
                  id={`inputN-${i}`}
                  value="N"
                  name={name}
                  checked={checkedNo}
                  label="No"
                  type="radio"
                  onChange={(e) => updateQuesList(e, "response", false, i)}
                />
              </td>
              <td className="tableRemarks">
                {type === "percent" ? (
                  <NumberFormat
                    id={`qr${i + 1}`}
                    className={!response ? "hidden ques-input" : "ques-input"}
                    maxLength="3"
                    suffix="%"
                  />
                ) : (
                  <input
                    id={`remarks-${i}`}
                    value={remarks && remarks !== "none" ? remarks : ""}
                    type="text"
                    className={!response ? "hidden ques-input" : "ques-input"}
                    disabled={!response}
                    onChange={(e) =>
                      updateQuesList(e, "remarks", e.target.value, i)
                    }
                  />
                )}
              </td>
            </>
          ) : (
            <>
              <td className="tableInputs" colSpan="2">
                <input
                  id={`remarks-${i}`}
                  value={remarks ? remarks : ""}
                  type="text"
                  style={{ width: "100%" }}
                  className="ques-input"
                  onChange={(e) =>
                    updateQuesList(e, "remarks", e.target.value, i)
                  }
                />
              </td>
            </>
          )}
        </tr>
      );
    });
  };

  //function for updating company profile
  const updateCompanyProfile = (event, fieldName) => {
    let _acordData = JSON.parse(JSON.stringify(acordData));
    if (fieldName === "state1" || fieldName === "state2") {
      _acordData.companyProfile[fieldName] = {
        value: event.target.value.toLowerCase(),
      };
    } else {
      _acordData.companyProfile[fieldName] = { value: event.target.value };
    }

    if (
      event.target.value === "" ||
      event.target.value === null ||
      event.target.value === undefined
    ) {
      _acordData.companyProfile[fieldName].error = true;
    }

    setAcordData(_acordData);
  };

  //function for setting states in employee number & anuual premium
  const updateWorkerCompRow = (event, parentLocation, childLocation, name) => {
    let _acordData = JSON.parse(JSON.stringify(acordData));
    let value = event.target.value;
    let childrenLoc = _acordData.childrenLoc;

    childrenLoc[parentLocation] = {
      ...childrenLoc[parentLocation],
      classCodesInfo: {
        ...childrenLoc[parentLocation].classCodesInfo,
        [childLocation]: {
          ...childrenLoc[parentLocation].classCodesInfo[childLocation],
          [name]: {
            value,
          },
        },
      },
    };

    setAcordData(_acordData);
  };

  //function for setting the effective date
  const handleDateChangeEff = (date) => {
    let _acordData = JSON.parse(JSON.stringify(acordData));

    if (date) {
      _acordData.companyProfile.effectiveDate.value = date;

      setAcordData(_acordData);
    }
  };

  //function for setting the location
  const setStateAndCity = (zipCode, state, type) => {
    let _acordData = JSON.parse(JSON.stringify(acordData));
    if (!state) state = "all";
    if (!zipCode) return;
    axios
      .get(
        awsUrl2 +
        "/api/getStateAndCity/" +
        zipCode +
        `/${state ? state : "all"}`
      )
      .then((res) => {
        const data = res.data;
        if (data !== `"Data not found"`) {
          if (type === "primary") {
            _acordData.companyProfile.state1 = { value: data.state };
            _acordData.companyProfile.cityName1 = { value: data.city };
          } else if (type === "mailing") {
            _acordData.companyProfile.state2 = { value: data.state };
            _acordData.companyProfile.cityName2 = { value: data.city };
          }
          setAcordData(_acordData);
        }
      })
      .catch((err) => {
        console.log("Error in get state with zip code:", err);
      });
  };

  // function for displaying accord & converting into blob
  const generateAcordUrl = (acordParams) => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(awsUrl2 + "/api/generateAcord/fl", JSON.stringify(acordParams))
          .then((res) => {
            let pdf = res.data.data;
            let file = blobPdfFromBase64String(pdf);
            let _acordFileURL = URL.createObjectURL(file);

            setAcordFileURL(_acordFileURL);
            acordDisplayRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            resolve();
          })
          .catch((err) => {
            console.log("error", err);
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  //function updating the db accorddata
  const updateAgentData = () => {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(
            awsUrl2 + `/api/agentPersonData/update`,
            JSON.stringify({
              id: agentData.id.toLowerCase(),
              acordOverrideData: acordData,
            })
          )
          .then((res) => {
            resolve();
          })
          .catch((error) => {
            console.log("error", error);
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  function hasEmptyValues(obj) {
    for (const key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        (obj[key]?.value === null ||
          obj[key]?.value === undefined ||
          obj[key]?.value === "")
      ) {
        return true;
      }
    }
    return false;
  }

  let entireValidation = () => {
    let officerCheck = validateIncludeExcludedHandler();
    let includeExcludedCheck = officerCheck["includeExcludeError"];
    let _acordData = JSON.parse(JSON.stringify(acordData));
    let companyProfile = _acordData?.companyProfile;
    delete companyProfile.emailId;
    delete companyProfile.lastName;
    delete companyProfile.phoneNumber;
    delete companyProfile.notes;
    let companyProfileCheck = hasEmptyValues(companyProfile);
    if (includeExcludedCheck && companyProfileCheck) {
      setValidCheck(false);
    } else if (!includeExcludedCheck && companyProfileCheck) {
      setValidCheck(false);
    } else if (includeExcludedCheck && !companyProfileCheck) {
      setValidCheck(false);
    } else if (!includeExcludedCheck && !companyProfileCheck) {
      setValidCheck(true);
    }
  };

  //function for submit all button
  const handleGenerateAcord = async (event) => {
    entireValidation();
    try {
      event.preventDefault();
      setLoadingAcord(true);
      let promiseList = [];
      let acordParams = {
        email: agentData.id,
        timestamp: agentData.quoteData.date,
        carrier: `carrier_ax`,
        mail_address: "",
        address: agentData.address,
        state: ["fl"],
        netRateMap: {},
        agent_name: loggedInUserData?.attributes.name || " ",
        agent_email: loggedInUserData?.attributes.email || " ",
        agent_agency: loggedInUserData?.attributes["custom:agency"] || " ",
        uw_name: loggedInUserData?.attributes["custom:uw"] || " ",
        agency_code: loggedInUserData?.attributes["custom:agency_code"] || "",
        acordOverrideData: acordData,
      };

      promiseList.push(generateAcordUrl(acordParams), updateAgentData());

      await Promise.allSettled(promiseList).then((res) => {
        setLoadingAcord(false);
      });
    } catch (error) {
      setLoadingAcord(false);
    }
  };

  //function for setting the class code.
  const updateWorkerCompDesc = (event, parentLocation, childLocation, name) => {
    let value = event.value;
    let label = event.label;
    let _acordData = JSON.parse(JSON.stringify(acordData));
    let childrenLoc = _acordData.childrenLoc;
    // let parentLocation = locationNumber;

    childrenLoc[parentLocation] = {
      ...childrenLoc[parentLocation],
      classCodesInfo: {
        ...childrenLoc[parentLocation].classCodesInfo,
        [childLocation]: {
          ...childrenLoc[parentLocation].classCodesInfo[childLocation],
          [name]: {
            value,
          },
        },
      },
    };

    setAcordData(_acordData);
  };

  let allStates1 = [
    <option key={1} value={"FL".toLowerCase()}>
      FL
    </option>,
  ];

  let allStates2 = [
    <option key={0} value="">
      State
    </option>,
  ];
  for (var item of stateCity) {
    if (
      acordData?.companyProfile &&
      item ===
      (acordData?.companyProfile?.state2 &&
        acordData?.companyProfile?.state2.value
        ? acordData?.companyProfile?.state2.value
        : "")
    ) {
      allStates2.push(
        <option key={item.Code} value={item.Code.toLowerCase()} selected>
          {item.Code}
        </option>
      );
    } else {
      allStates2.push(
        <option key={item.Code} value={item.Code.toLowerCase()}>
          {item.Code}
        </option>
      );
    }
  }

  // addign the additional classcode row on specific location
  const addNewClassCodeHandler = (locationNumber) => {
    const _acordData = JSON.parse(JSON.stringify(acordData));
    let childrenLoc = _acordData.childrenLoc;
    const keys = Object.keys(
      acordData.childrenLoc[locationNumber]["classCodesInfo"]
    );
    let index;

    if (keys.length === 0) {
      index = 0; // If there are no keys in the classCodesInfo object, set the index to 0
    } else {
      index = Math.max(...keys.map((key) => parseInt(key))) + 1; // Calculate the next available index by finding the highest key value and adding 1 to it
    }

    let parentLocation = locationNumber;

    childrenLoc[parentLocation] = {
      ...childrenLoc[parentLocation],
      classCodesInfo: {
        ...childrenLoc[parentLocation].classCodesInfo,
        [index]: {
          classCodeDescription: {},
          ft: {},
          pt: {},
          preuwPhone: {},
          payroll: {},
        },
      },
    };

    setAcordData(_acordData);
  };

  // loading the wokers's comp descriptions
  const loadOptions = async (value, callback, state) => {
    state = "fl";
    let minLength = isNaN(value) ? 3 : 1;
    if (value.length >= minLength) {
      let returnList = [];

      // const state = "fl";
      const header = await getHeader();
      await axios
        .get(awsUrl2 + `/api/getCcodes/${state}/${value}`, header)
        .then((res) => {
          let dbObjsList = res.data.data;
          if (dbObjsList.length === 0) {
          }
          dbObjsList.forEach((element) => {
            returnList.push({
              value: `${element.class_code}: ${element.description}`,
              label: `${element.class_code}: ${element.description.trim()}`,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      return callback(returnList);
    } else {
      this.setState({ noOptionsMessage: "Enter 3 characters to start" });
    }
  };

  //delete location payroll row
  const deleteClasscodeHandler = (locationNumber, addressLocation) => {
    const _acordData = JSON.parse(JSON.stringify(acordData));
    let childrenLoc = _acordData.childrenLoc;

    delete childrenLoc[locationNumber]["classCodesInfo"][addressLocation];

    const resetState = { ...childrenLoc[locationNumber] };
    resetState.classCodesInfo = {};
    let i = 0;
    for (const key in childrenLoc[locationNumber]["classCodesInfo"]) {
      resetState.classCodesInfo[i] =
        childrenLoc[locationNumber]["classCodesInfo"][key];
      i++;
    }

    childrenLoc[locationNumber] = resetState;

    setAcordData(_acordData);
  };

  // Size of includedobject
  let includedExcludeSize =
    acordData?.includedExcludedObject?.includeExcludeList?.length;

  // remove the include exclude row
  let removeIncludeExcludeHandler = (index) => {
    const _acordData = JSON.parse(JSON.stringify(acordData));
    let includedExcludedObject = _acordData?.includedExcludedObject;
    includedExcludedObject.includeExcludeList.splice(index, 1);
    includedExcludedObject.includeCodeCodeError = {};
    setAcordData(_acordData);
  };

  // add include and exclude new row
  let addIncludeExcludeHandler = () => {
    const _acordData = JSON.parse(JSON.stringify(acordData));
    let includedExcludedObject = _acordData?.includedExcludedObject;
    includedExcludedObject.includeExcludeList.push({
      officerName: { value: "", error: "" },
      ownerShip: { value: "", error: "" },
      includExclude: { value: "", error: "" },
      classCode: { value: "", error: "" },
    });
    setAcordData(_acordData);
  };

  // setting the value of include exclude field
  let setincludeExcludeValueHandler = (e, values, index) => {
    const _acordData = JSON.parse(JSON.stringify(acordData));
    let includedExcludedObject = _acordData?.includedExcludedObject;
    includedExcludedObject.includeExcludeList[index][values][
      "value"
    ] = e.target.value.trim();
    if (e.target.value !== "") {
      includedExcludedObject.includeExcludeList[index][values]["error"] = false;
    }

    setAcordData(_acordData);
  };

  //function for handling the include & exclude error
  let validateIncludeExcludedHandler = () => {
    const _acordData = JSON.parse(JSON.stringify(acordData));
    let childrenLoc = _acordData?.childrenLoc;
    let includedExcludedObject = _acordData?.includedExcludedObject;
    let includeExcludeError = false;

    // collecting the class code for check of include and matching the classcode
    let classCodeToInlcude = [];

    for (let keyValue in childrenLoc[0]["classCodesInfo"]) {
      let includeClasscodeCheck = childrenLoc[0]["classCodesInfo"][keyValue][
        "classCodeDescription"
      ]["value"].split(":")[0];

      classCodeToInlcude.push(
        includeClasscodeCheck.toString().padStart(4, "0")
      );
    }
    console.log(classCodeToInlcude, "classCodeToInlcude");

    // validating for null part or empty part
    for (const [
      index,
      key,
    ] of includedExcludedObject.includeExcludeList.entries()) {
      // Your validation and processing logic here
      if (key?.["includExclude"]?.["value"] === "Included") {
        let classCode = key?.["classCode"]?.["value"]
          .trim()
          .toString()
          .padStart("0", 4);

        console.log("classCodeToInlcude", classCodeToInlcude);

        if (!classCodeToInlcude.includes(classCode)) {
          key["classCode"]["error"] = true;
          includeExcludeError = true;
          if (!includedExcludedObject["includeCodeCodeError"]) {
            includedExcludedObject["includeCodeCodeError"] = {};
          }
          includedExcludedObject["includeCodeCodeError"][index] = true;
        } else {
          if (!includedExcludedObject["includeCodeCodeError"]) {
            includedExcludedObject["includeCodeCodeError"] = {};
          }
          includedExcludedObject["includeCodeCodeError"][index] = false;
        }
      }
      if (key?.["includExclude"]?.["value"] === "Excluded") {
        if (!includedExcludedObject["includeCodeCodeError"]) {
          includedExcludedObject["includeCodeCodeError"] = {};
        }
        includedExcludedObject["includeCodeCodeError"][index] = false;
        key["classCode"]["error"] = false;
      }
    }

    setAcordData(_acordData);
    return { includeExcludeError };
  };

  //function to create table for histrocial table
  let createTableLossRun = () => {
    let _acordData = JSON.parse(JSON.stringify(acordData));
    let table = [];
    let j = 0;
    let historicalClaims = _acordData?.historicalClaims;
    let historicalClaimsCheck = _acordData?.historicalClaimsCheck;
    let historicalClaimsErrorStore = _acordData?.historicalClaimsErrorStore;

    historicalClaimsErrorStore = !historicalClaimsErrorStore
      ? {}
      : historicalClaimsErrorStore;
    historicalClaims = !historicalClaims ? {} : historicalClaims;
    historicalClaimsCheck = !historicalClaimsCheck ? {} : historicalClaimsCheck;

    let loss_data = _acordData ? _acordData?.historicalClaims : null;
    let loss_data_index;
    let historyLimitYear = 0;
    let backgroundColor = "";
    let absYear;

    let rowId, rowIdDup, rowIdYear, rowIdDupYear;

    rowIdYear =
      new Date(_acordData?.companyProfile?.effectiveDate.value).getFullYear() -
      1 || new Date().getFullYear();

    rowIdDupYear =
      new Date(_acordData?.companyProfile?.effectiveDate.value).getFullYear() -
      1 || new Date().getFullYear();

    let currYear =
      new Date(_acordData?.companyProfile?.effectiveDate.value).getFullYear() -
      1 || new Date().getFullYear();

    historyLimitYear =
      currYear - _acordData?.companyProfile?.yearsInBusiness?.value;

    //  console.log("historyLimitYear:in ", historyLimitYear);

    for (let i = 0; i < 10; i++) {
      rowId = rowIdYear - j;
      rowIdDup = rowIdDupYear - i;
      absYear = !absYear ? rowIdDup : absYear;
      if (historicalClaimsCheck[absYear]) {
        backgroundColor = "#dddddd";
      } else {
        backgroundColor = "";
      }
      rowId = rowId.toString();
      if (i % 2 === 0) {
        j++;
        let yearId = Number(rowId);
        // loss_data_index = loss_data
        //   ? loss_data.findIndex((e) => e.year === rowId)
        //   : null;
        table.push(
          <tr id={rowIdDup} key={rowIdDup} className=" historialRow">
            <td className="per100">
              <Form.Group>
                <label for={`carrier-${absYear}`} className="mandatory">
                  Carrier
                </label>

                <input
                  autoComplete="off"
                  id={`carrier-${absYear}`}
                  type="text"
                  name="carText"
                  onChange={(e) => handleChange(e, "carrier")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].carrier
                        ? "red"
                        : "",
                  }}
                  disabled={
                    historyLimitYear > yearId || historicalClaimsCheck[absYear]
                  }
                  onKeyPress={(e) => {
                    clearErr(e, historicalClaimsErrorStore, "carrier");
                  }}
                  value={
                    historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["carrier"]
                      ? historicalClaims[absYear]["carrier"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className="per100">
              <Form.Group>
                <label
                  for={`annPre-${absYear}`}
                  className="mandatory"
                  title="Annual Premium"
                >
                  Annual Premium
                </label>
                <NumberFormat
                  name="anualPremium"
                  id={`annPre-${absYear}`}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    clearErr(e, historicalClaimsErrorStore, "annPre");
                  }}
                  thousandSeparator={true}
                  onChange={(e) => handleChange(e, "annPre")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].annPre
                        ? "red"
                        : "",
                  }}
                  disabled={
                    historyLimitYear > yearId || historicalClaimsCheck[absYear]
                  }
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  value={
                    historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["annPre"]
                      ? historicalClaims[absYear]["annPre"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className="per50">
              <Form.Group>
                <label
                  title="Number of claims"
                  for={`claims-${absYear}`}
                  className="mandatory"
                >
                  # of Claims
                </label>
                <NumberFormat
                  name="claims"
                  id={`claims-${absYear}`}
                  maxLength="2"
                  autoComplete="off"
                  onKeyPress={(e) => {
                    clearErr(e, historicalClaimsErrorStore, "claim");
                  }}
                  onChange={(e) => handleChange(e, "claim")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].claim
                        ? "red"
                        : "",
                  }}
                  disabled={
                    historyLimitYear > yearId || historicalClaimsCheck[absYear]
                  }
                  className="text-center"
                  type="text"
                  value={
                    historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["claim"]
                      ? historicalClaims[absYear]["claim"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className="per100">
              <Form.Group>
                <label
                  for={`totInc-${absYear}`}
                  className="mandatory"
                  title="Amount Paid"
                >
                  Amount Paid
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    clearErr(e, historicalClaimsErrorStore, "totInc");
                  }}
                  id={`totInc-${absYear}`}
                  autoComplete="off"
                  name="totInc"
                  thousandSeparator={true}
                  onChange={(e) => handleChange(e, "totInc")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].totInc
                        ? "red"
                        : "",
                  }}
                  disabled={
                    historyLimitYear > yearId || historicalClaimsCheck[absYear]
                  }
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  // onBlur={(e) => this.zeroDefault(e, "totInc")}
                  value={
                    historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["totInc"]
                      ? historicalClaims[absYear]["totInc"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className="per100">
              <Form.Group>
                <label for={`totRes-${absYear}`} title="Total Reserves">
                  Reserves
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    clearErr(e, historicalClaimsErrorStore, "totRes");
                  }}
                  id={`totRes-${absYear}`}
                  autoComplete="off"
                  name="totRes"
                  thousandSeparator={true}
                  onChange={(e) => handleChange(e, "totRes")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                        historicalClaimsErrorStore[absYear].totRes
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center "
                  type="text"
                  // onBlur={zeroDefault}
                  value={
                    historicalClaims &&
                      historicalClaims[absYear] &&
                      historicalClaims[absYear]["totRes"]
                      ? historicalClaims[absYear]["totRes"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
          </tr>
        );
      } else {
        let yearId = Number(rowId) + 1;
        table.push(
          <tr id={rowIdDup} key={rowIdDup} className="row-no-coverage my-auto">
            <td className="no-coverage">
              <input
                type="checkbox"
                id={`check-${absYear}`}
                disabled={historyLimitYear >= yearId}
                checked={
                  historyLimitYear > yearId ||
                  (historicalClaimsCheck[absYear]
                    ? historicalClaimsCheck[absYear]
                    : false)
                }
                onChange={(e) => handleCheck(e)}
              />
              {`No coverage for the year
               ${yearId}`}
            </td>
            {/* <td className="per5 text-left">coverage for the {yearId}</td> */}
          </tr>
        );
        absYear--;
      }
    }
    return table;
  };

  // setting state for histrical claims
  let handleChange = (e, key) => {
    const _acordData = JSON.parse(JSON.stringify(acordData));
    let historicalClaims = _acordData?.historicalClaims;
    let year = e.target.id.split("-")[1];

    if (!historicalClaims?.[year]) {
      historicalClaims[year] = {};
    }
    historicalClaims[year][key] = e.target.value;

    if (key === "totInc") {
      historicalClaims = histClaimCalcTI(historicalClaims, year);
    }
    if (key === "totPaid") {
      historicalClaims = histClaimCalcTP(historicalClaims, year);
    }
    if (key === "totRes") {
      historicalClaims = histClaimCalcTR(historicalClaims, year);
    }

    if (key === "claim") {
      if (!historicalClaims[year]["totInc"]) {
        historicalClaims[year]["totInc"] = "$0";
      }

      if (!historicalClaims[year]["totRes"]) {
        historicalClaims[year]["totRes"] = "$0";
      }
    }
    setAcordData(_acordData);
  };

  //handling the checkbox of Historical claims
  let handleCheck = (e) => {
    const _acordData = JSON.parse(JSON.stringify(acordData));
    let historicalClaimsCheck = _acordData?.historicalClaimsCheck;
    let historicalClaimsErrorStore =
      _acordData?.historicalClaimsErrorStore || {};
    let historicalClaims = _acordData?.historicalClaims;

    let checkbox = e.target;
    let year = checkbox.id.split("-")[1];
    let checked = checkbox.checked;
    historicalClaimsCheck[year] = checked;
    delete historicalClaims[year];

    for (let key in historicalClaimsErrorStore[year]) {
      historicalClaimsErrorStore[year][key] = false;
    }
    setAcordData(_acordData);
  };

  return (
    <div className="newquote-mainbody">
      <div
        className="d-flex justify-content-between"
        style={{ backgroundColor: "white" }}
      >
        <Header />
      </div>
      {isDataAvailable ? (
        <>
          <div className="editquote-content w-100">
            <div id="compProfile">
              <form autoComplete="none" onSubmit={handleGenerateAcord}>
                <div className="formTitle py-4">
                  <b className="font-family-montserrat-bold">
                    Please add/update your details
                  </b>
                </div>
                <hr />
                <section className="pt-2">
                  <div className="pink-header">
                    <div className="font-family-montserrat-bold">
                      Company Profile
                    </div>
                    {containerCloseOpen["showCompanyProfile"] ? (
                      <span
                        className="pink-sign noselect"
                        onClick={() => {
                          if (containerCloseOpen["showCompanyProfile"])
                            setContainerCloseOpen((prevState) => {
                              return {
                                ...prevState,
                                showCompanyProfile: false,
                              };
                            });
                          else return;
                        }}
                      >
                        &#8722;
                      </span>
                    ) : (
                      <span
                        className="pink-sign noselect"
                        onClick={() => {
                          if (!containerCloseOpen["showCompanyProfile"])
                            setContainerCloseOpen((prevState) => {
                              return { ...prevState, showCompanyProfile: true };
                            });
                          else return;
                        }}
                      >
                        &#43;
                      </span>
                    )}
                  </div>
                  {containerCloseOpen["showCompanyProfile"] && (
                    <div className="company-profile-container">
                      <div className="row" id="company-profile-row1">
                        <div className="col-lg-6 col-md-12 grey">
                          <Form.Group>
                            <label htmlFor="companyName" className="mandatory">
                              <b className="font-family-montserrat-semi-bold">
                                Company Name
                              </b>
                            </label>
                            <input
                              value={
                                acordData?.companyProfile?.companyName &&
                                  acordData?.companyProfile?.companyName.value
                                  ? acordData?.companyProfile?.companyName.value
                                  : ""
                              }
                              id="companyName"
                              type="text"
                              style={
                                acordData?.companyProfile?.companyName &&
                                  acordData?.companyProfile?.companyName.error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                              onChange={(event) =>
                                updateCompanyProfile(event, "companyName")
                              }
                              placeholder="Company Name"
                              autoComplete="off"
                              className="rectangle1"
                            />
                            <p className="errMsg" />
                          </Form.Group>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <Form.Group>
                            <label
                              htmlFor="descriptionOfOperations"
                              title="Description of Operations"
                              className="mandatory"
                            >
                              <b className="font-family-montserrat-semi-bold">
                                Description of Operations
                              </b>
                            </label>

                            <input
                              value={
                                acordData?.companyProfile
                                  ?.descriptionOfOperations &&
                                  acordData?.companyProfile
                                    ?.descriptionOfOperations.value
                                  ? acordData?.companyProfile
                                    ?.descriptionOfOperations.value
                                  : ""
                              }
                              onChange={(event) =>
                                updateCompanyProfile(
                                  event,
                                  "descriptionOfOperations"
                                )
                              }
                              style={
                                acordData?.companyProfile
                                  ?.descriptionOfOperations &&
                                  acordData?.companyProfile
                                    ?.descriptionOfOperations.error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                              placeholder="Description of Operations"
                              id="descriptionOfOperations"
                              type="text"
                              // onKeyUp={onlyAlpha}
                              autoComplete="off"
                            />
                            <p className="errMsg" />
                            <span></span>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row" id="company-profile-row2">
                        <div className="col-lg-3 col-md-12">
                          <Form.Group>
                            <label htmlFor="fein" className="mandatory">
                              <b className="font-family-montserrat-semi-bold">
                                FEIN
                              </b>
                            </label>
                            <input
                              placeholder="FEIN"
                              value={
                                acordData?.companyProfile?.fein
                                  ? acordData?.companyProfile?.fein.value
                                  : ""
                              }
                              onChange={(event) => {
                                updateCompanyProfile(event, "fein");
                              }}
                              style={
                                acordData?.companyProfile?.fein &&
                                  acordData?.companyProfile?.fein.error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                              id="fein"
                              type="text"
                              autoComplete="off"
                              onInput={(event) => {
                                event.target.value = event.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*)\./g, "$1");
                              }}
                              onBlur={(event) => {
                                let _acordData = JSON.parse(
                                  JSON.stringify(acordData)
                                );
                                var value = event.target.value
                                  .toString()
                                  .trim();
                                value = value.split("-").join("");
                                var len = value.length,
                                  dummystr = "000000000";

                                if (len > 0 && len < 9) {
                                  value =
                                    dummystr.slice(0, 9 - len) + "" + value;
                                  _acordData.companyProfile.fein.value =
                                    value[0] + value[1] + "-" + value.slice(2);
                                  _acordData.companyProfile.fein.errMsg = "";
                                } else if (len > 9) {
                                  _acordData.companyProfile.fein.errMsg =
                                    "FEIN needs to be 9 digits";
                                  _acordData.companyProfile.fein.error = true;
                                } else if (len === 0) {
                                  _acordData.companyProfile.fein.value = undefined;
                                  _acordData.companyProfile.fein.errMsg = "";
                                } else {
                                  _acordData.companyProfile.fein.value =
                                    value[0] + value[1] + "-" + value.slice(2);
                                  _acordData.companyProfile.fein.errMsg = "";
                                }
                                setAcordData(_acordData);
                              }}
                            />
                            <p className="errMsg">
                              {acordData?.companyProfile?.fein &&
                                acordData?.companyProfile?.fein.errMsg &&
                                acordData?.companyProfile?.fein.errMsg !== ""
                                ? acordData?.companyProfile?.fein.errMsg
                                : ""}
                            </p>
                          </Form.Group>
                        </div>
                        <div className="col-lg-5 col-md-12">
                          <Form.Group>
                            <label htmlFor="firstName" title="First Name">
                              <b className="font-family-montserrat-semi-bold">
                                Contact Name
                              </b>
                            </label>
                            <input
                              placeholder="Contact Name"
                              value={
                                acordData?.companyProfile?.firstName &&
                                  acordData?.companyProfile?.firstName.value
                                  ? acordData?.companyProfile?.firstName.value
                                  : ""
                              }
                              onChange={(event) =>
                                updateCompanyProfile(event, "firstName")
                              }
                              style={
                                acordData?.companyProfile?.firstName &&
                                  acordData?.companyProfile?.firstName.error
                                  ? { borderColor: "" }
                                  : {}
                              }
                              id="firstName"
                              type="text"
                              // onKeyUp={onlyAlpha}
                              autoComplete="off"
                            />
                            <p className="errMsg" />
                          </Form.Group>
                        </div>
                        <div className="col-lg-4 col-md-12">
                          <Form.Group>
                            <label htmlFor="phoneNumber">
                              <b className="font-family-montserrat-semi-bold">
                                Contact Phone
                              </b>
                            </label>
                            <NumberFormat
                              value={
                                acordData?.companyProfile?.phoneNumber &&
                                  acordData?.companyProfile?.phoneNumber.value
                                  ? acordData?.companyProfile?.phoneNumber.value
                                  : ""
                              }
                              onChange={(event) =>
                                updateCompanyProfile(event, "phoneNumber")
                              }
                              style={
                                acordData?.companyProfile?.phoneNumber &&
                                  acordData?.companyProfile?.phoneNumber.error
                                  ? { borderColor: "" }
                                  : {}
                              }
                              placeholder="(000) 000-0000"
                              autoComplete="off"
                              id="phoneNumber"
                              format="(###) ###-####"
                              type="text"
                            />
                            <p className="errMsg" />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row" id="company-profile-row3">
                        <div
                          id="entity-type"
                          style={{ width: "30%", paddingInline: "15px" }}
                        >
                          <Form.Group>
                            <label htmlFor="entityType" className="mandatory">
                              <b className="font-family-montserrat-semi-bold">
                                Entity Type
                              </b>
                            </label>
                            <select
                              value={
                                acordData?.companyProfile?.entityType &&
                                  acordData?.companyProfile?.entityType.value
                                  ? acordData?.companyProfile?.entityType.value
                                  : ""
                              }
                              id="entityType"
                              type="text"
                              style={
                                acordData?.companyProfile?.entityType &&
                                  acordData?.companyProfile?.entityType.error
                                  ? { borderColor: "red" }
                                  : { fontSize: "16px" }
                              }
                              onChange={(event) =>
                                updateCompanyProfile(event, "entityType")
                              }
                              autoComplete="off"
                            >
                              <option key={1} value={""}>
                                {" "}
                                Please Select{" "}
                              </option>
                              <option key={2} value={"Corporation"}>
                                {" "}
                                Corporation{" "}
                              </option>

                              <option key={3} value={"LLC"}>
                                {" "}
                                LLC{" "}
                              </option>

                              <option key={4} value={"Partnership"}>
                                {" "}
                                Partnership{" "}
                              </option>
                              <option key={5} value={"Sole Proprietor"}>
                                {" "}
                                Sole Proprietor{" "}
                              </option>
                            </select>
                            <p className="errMsg" />
                          </Form.Group>
                        </div>
                        <div
                          id="years-in-business"
                          style={{ width: "20%", paddingInline: "15px" }}
                        >
                          <Form.Group>
                            <label
                              htmlFor="yearsInBusiness"
                              title="Years in Business"
                              className="mandatory"
                            >
                              <b className="font-family-montserrat-semi-bold text-nowrap ">
                                Years in Business
                              </b>
                            </label>
                            <span id="yearsInBusiness-span">
                              <NumberFormat
                                value={
                                  acordData?.companyProfile?.yearsInBusiness &&
                                    acordData?.companyProfile?.yearsInBusiness
                                      .value
                                    ? acordData?.companyProfile?.yearsInBusiness
                                      .value
                                    : ""
                                }
                                onChange={(event) =>
                                  updateCompanyProfile(event, "yearsInBusiness")
                                }
                                style={
                                  acordData?.companyProfile?.yearsInBusiness &&
                                    acordData?.companyProfile?.yearsInBusiness
                                      .error
                                    ? {
                                      borderColor: "red",
                                      textAlign: "center",
                                    }
                                    : { textAlign: "center" }
                                }
                                autoComplete="off"
                                id="yearsInBusiness"
                                format="####"
                                type="text"
                              />
                            </span>
                            <p className="errMsg" />
                          </Form.Group>
                        </div>

                        <div
                          id="entity-type"
                          style={{ width: "30%", paddingInline: "15px" }}
                        >
                          <Form.Group>
                            <Form.Label
                              htmlFor="Employer_Liability_Limit"
                              className="mandatory"
                            >
                              <b className="font-family-montserrat-semi-bold">
                                Employer's Liability Limit
                              </b>
                            </Form.Label>
                            <select
                              value={
                                acordData?.companyProfile?.empLiabilityLimit &&
                                  acordData?.companyProfile?.empLiabilityLimit
                                    .value
                                  ? acordData?.companyProfile?.empLiabilityLimit
                                    .value
                                  : ""
                              }
                              id="empLiabilityLimit"
                              type="text"
                              style={
                                acordData?.companyProfile?.empLiabilityLimit &&
                                  acordData?.companyProfile?.empLiabilityLimit
                                    .error
                                  ? { borderColor: "red" }
                                  : { fontSize: "16px" }
                              }
                              onChange={(event) =>
                                updateCompanyProfile(event, "empLiabilityLimit")
                              }
                              autoComplete="off"
                            >
                              <option key={1} value={""}>
                                Please Select
                              </option>
                              <option key={2} value={"100-100-500"}>
                                {" "}
                                100/500/100{" "}
                              </option>
                              <option key={3} value={"500-500-500"}>
                                {" "}
                                500/500/500{" "}
                              </option>
                              <option key={4} value={"1000-1000-1000"}>
                                {" "}
                                1000/1000/1000{" "}
                              </option>
                            </select>
                            <p className="errMsg" />
                          </Form.Group>
                        </div>

                        <div
                          id="expected-start-date"
                          style={{ width: "20%", paddingInline: "15px" }}
                        >
                          <Form.Group>
                            <label
                              htmlFor="effectiveDate"
                              title="Expected Start Date"
                              className="mandatory"
                            >
                              <b className="font-family-montserrat-semi-bold">
                                Proposed Eff.Date
                              </b>
                            </label>
                            <span id="effectiveDate">
                              <DatePicker
                                selected={
                                  moment(
                                    acordData?.companyProfile?.effectiveDate
                                      ?.value
                                  ) || moment()
                                }
                                onChange={handleDateChangeEff}
                                showYearDropdown
                                showMonthDropdown
                                useShortMonthInDropdown
                                // style={
                                //   companyProfile?.effectiveDate?.error
                                //     ? { border: "1px solid red !important", textAlign: "center" }
                                //     : { border: "1px solid red !important", textAlign: "center" }
                                // }
                                id="expectedStartDate"
                              />
                            </span>
                            <p className="errMsg" />
                          </Form.Group>
                        </div>
                      </div>

                      <div className="row-col-2 mb-0">
                        <Form.Group style={{ marginBottom: "19.95px" }}>
                          <b className="font-family-montserrat-semi-bold">
                            Primary Location
                          </b>
                        </Form.Group>
                      </div>
                      <div className="row">
                        <div
                          id="street-1"
                          style={{ width: "51%", paddingInline: "15px" }}
                        >
                          <Form.Group>
                            <label htmlFor="street1" className="mandatory">
                              <b className="font-family-montserrat-semi-bold">
                                Street Address
                              </b>
                            </label>
                            <input
                              value={
                                acordData?.companyProfile?.street1 &&
                                  acordData?.companyProfile?.street1.value
                                  ? acordData?.companyProfile?.street1.value
                                  : ""
                              }
                              id="street1"
                              type="text"
                              style={
                                acordData?.companyProfile?.street1 &&
                                  acordData?.companyProfile?.street1.error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                              onChange={(event) =>
                                updateCompanyProfile(event, "street1")
                              }
                              placeholder="Street Address"
                              autoComplete="off"
                              className="rectangle1"
                            />
                            <p className="errMsg" />
                          </Form.Group>
                        </div>
                        <div
                          id="zip-1"
                          style={{ width: "11%", paddingInline: "15px" }}
                        >
                          <Form.Group>
                            <label
                              htmlFor={"zipCode1"}
                              className="mandatory"
                              title="Zip Code"
                            >
                              <b className="font-family-montserrat-semi-bold">
                                Zip
                              </b>
                            </label>
                            <NumberFormat
                              name="zipCode1"
                              id={"zipCode1"}
                              format="#####"
                              type="text"
                              onChange={(event) =>
                                updateCompanyProfile(event, "zipCode1")
                              }
                              value={
                                acordData?.companyProfile?.zipCode1
                                  ? acordData?.companyProfile?.zipCode1.value
                                  : ""
                              }
                              onBlur={(event) =>
                                setStateAndCity(
                                  event.target.value,
                                  "FL",
                                  "primary"
                                )
                              }
                              style={
                                acordData?.companyProfile?.zipCode1 &&
                                  acordData?.companyProfile?.zipCode1.error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                              placeholder="Zip"
                              autoComplete="off"
                            />
                            <p className="errMsg addressErrs" />
                          </Form.Group>
                        </div>
                        <div
                          id="city-1"
                          style={{ width: "27%", paddingInline: "15px" }}
                        >
                          <Form.Group>
                            <label
                              htmlFor={"cityName1"}
                              className="mandatory"
                              title="City"
                            >
                              <b className="font-family-montserrat-semi-bold">
                                City
                              </b>
                            </label>
                            <input
                              id={"cityName1"}
                              name="cityName1"
                              type="text"
                              onChange={(event) =>
                                updateCompanyProfile(event, "cityName1")
                              }
                              value={
                                acordData?.companyProfile?.cityName1
                                  ? acordData?.companyProfile?.cityName1.value
                                  : ""
                              }
                              style={
                                acordData?.companyProfile?.cityName1 &&
                                  acordData?.companyProfile?.cityName1.error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                              placeholder="City Name"
                              autoComplete="off"
                            />
                            <p className="errMsg addressErrs" />
                          </Form.Group>
                        </div>
                        <div
                          style={{ width: "11%", paddingInline: "15px" }}
                          id="state-1"
                        >
                          <Form.Group>
                            <label className="mandatory" title="State">
                              <b className="font-family-montserrat-semi-bold">
                                State
                              </b>
                            </label>
                            <select
                              id={"state1"}
                              name="state1"
                              type="text"
                              onChange={(event) =>
                                updateCompanyProfile(event, "state1")
                              }
                              value={
                                acordData?.companyProfile?.state1 &&
                                  acordData?.companyProfile?.state1.value
                                  ? acordData?.companyProfile?.state1.value.toLowerCase()
                                  : ""
                              }
                              style={
                                acordData?.companyProfile?.state1 &&
                                  acordData?.companyProfile?.state1.error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                              autoComplete="off"
                            >
                              {allStates1}
                            </select>
                            <p className="errMsg addressErrs" />
                          </Form.Group>
                        </div>
                        <div className="col-sm-1"></div>
                      </div>
                      <div className="row-col-2">
                        <Form.Group style={{ marginBottom: "19.95px" }}>
                          <div className="row">
                            <b
                              className="font-family-montserrat-semi-bolder"
                              style={{
                                paddingInline: "15px",
                                fontSize: "large",
                              }}
                            >
                              Mailing Address
                            </b>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="row">
                        <div
                          id="street-2"
                          style={{ width: "51%", paddingInline: "15px" }}
                        >
                          <Form.Group>
                            <label htmlFor="street2" className="mandatory">
                              <b className="font-family-montserrat-semi-bold">
                                Mailing Address
                              </b>
                            </label>
                            <input
                              value={
                                acordData?.companyProfile?.street2 &&
                                  acordData?.companyProfile?.street2.value
                                  ? acordData?.companyProfile?.street2.value
                                  : ""
                              }
                              id="street2"
                              type="text"
                              style={
                                acordData?.companyProfile?.street2 &&
                                  acordData?.companyProfile?.street2.error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                              onChange={(event) =>
                                updateCompanyProfile(event, "street2")
                              }
                              placeholder="Street Address"
                              autoComplete="off"
                              className="rectangle1"
                            />
                            <p className="errMsg" />
                          </Form.Group>
                        </div>
                        <div
                          style={{ width: "11%", paddingInline: "15px" }}
                          id="zip-2"
                        >
                          <Form.Group>
                            <label
                              htmlFor={"zipCode2"}
                              className="mandatory"
                              title="Zip Code"
                            >
                              <b className="font-family-montserrat-semi-bold">
                                Zip
                              </b>
                            </label>
                            <NumberFormat
                              name="zipCode2"
                              id={"zipCode2"}
                              format="#####"
                              type="text"
                              onChange={(event) =>
                                updateCompanyProfile(event, "zipCode2")
                              }
                              value={
                                acordData?.companyProfile?.zipCode2
                                  ? acordData?.companyProfile?.zipCode2.value
                                  : ""
                              }
                              style={
                                acordData?.companyProfile?.zipCode2 &&
                                  acordData?.companyProfile?.zipCode2.error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                              onBlur={(event) =>
                                setStateAndCity(
                                  event.target.value,
                                  undefined,
                                  "mailing"
                                )
                              }
                              placeholder="Zip"
                              autoComplete="off"
                            />
                            <p className="errMsg addressErrs" />
                          </Form.Group>
                        </div>
                        <div
                          id="city-2"
                          style={{ width: "27%", paddingInline: "15px" }}
                        >
                          <Form.Group>
                            <label
                              htmlFor={"cityName2"}
                              className="mandatory"
                              title="City"
                            >
                              <b className="font-family-montserrat-semi-bold">
                                City
                              </b>
                            </label>
                            <input
                              id={"cityName2"}
                              name="cityName2"
                              type="text"
                              onChange={(event) =>
                                updateCompanyProfile(event, "cityName2")
                              }
                              value={
                                acordData?.companyProfile?.cityName2 &&
                                  acordData?.companyProfile?.cityName2.value
                                  ? acordData?.companyProfile?.cityName2.value
                                  : ""
                              }
                              style={
                                acordData?.companyProfile?.cityName2 &&
                                  acordData?.companyProfile?.cityName2.error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                              placeholder="City Name"
                              autoComplete="off"
                            />
                            <p className="errMsg addressErrs" />
                          </Form.Group>
                        </div>
                        <div
                          style={{ width: "11%", paddingInline: "15px" }}
                          id="state-2"
                        >
                          <Form.Group>
                            <label className="mandatory" title="State">
                              <b className="font-family-montserrat-semi-bold">
                                State
                              </b>
                            </label>
                            <select
                              id={"state2"}
                              name="state2"
                              type="text"
                              onChange={(event) =>
                                updateCompanyProfile(event, "state2")
                              }
                              value={
                                acordData?.companyProfile?.state2 &&
                                  acordData?.companyProfile?.state2.value
                                  ? acordData?.companyProfile?.state2.value.toLowerCase()
                                  : ""
                              }
                              style={
                                acordData?.companyProfile?.state2 &&
                                  acordData?.companyProfile?.state2.error
                                  ? { borderColor: "red" }
                                  : {}
                              }
                              autoComplete="off"
                            >
                              {allStates2}
                            </select>
                            <p className="errMsg addressErrs" />
                          </Form.Group>
                        </div>
                        <div className="col-sm-1"></div>
                      </div>
                    </div>
                  )}
                </section>
                <hr />
                {acordData?.childrenLoc && (
                  <>
                    <section className="pt-2">
                      <div className="pink-header">
                        <div className="font-family-montserrat-bold">
                          <b>Locations &amp; Payroll</b>
                        </div>
                        {containerCloseOpen["showLocationAndPayroll"] ? (
                          <span
                            className="pink-sign noselect"
                            onClick={() => {
                              if (containerCloseOpen["showLocationAndPayroll"])
                                setContainerCloseOpen((prevState) => {
                                  return {
                                    ...prevState,
                                    showLocationAndPayroll: false,
                                  };
                                });
                              else return;
                            }}
                          >
                            &#8722;
                          </span>
                        ) : (
                          <span
                            className="pink-sign noselect"
                            onClick={() => {
                              if (!containerCloseOpen["showLocationAndPayroll"])
                                setContainerCloseOpen((prevState) => {
                                  return {
                                    ...prevState,
                                    showLocationAndPayroll: true,
                                  };
                                });
                              else return;
                            }}
                          >
                            &#43;
                          </span>
                        )}
                      </div>
                      {containerCloseOpen["showLocationAndPayroll"] &&
                        Object.entries(acordData?.childrenLoc).map(
                          ([locatioNumber, childrenLocs]) => (
                            <>
                              <div id="addressDiv">
                                {Object.entries(
                                  childrenLocs.classCodesInfo
                                ).map(([addressLocation, classCodesInfo]) => (
                                  <div className="location-payroll-container">
                                    <div className="row" id="location-payroll">
                                      <div className=" col-lg-5 col-md-12">
                                        <label
                                          title="Workers’ Comp Class Code or Description"
                                          for="edit_accord_cc"
                                        >
                                          <b className="font-family-montserrat-semi-bold text-nowrap">
                                            Class Code
                                          </b>
                                        </label>
                                        <AsyncSelect
                                          id="edit_accord_cc"
                                          defaultOptions
                                          placeholder="Enter 3 characters"
                                          value={
                                            classCodesInfo &&
                                              classCodesInfo?.classCodeDescription &&
                                              classCodesInfo?.classCodeDescription
                                                .value
                                              ? {
                                                label:
                                                  classCodesInfo
                                                    .classCodeDescription
                                                    .value,
                                                value:
                                                  classCodesInfo
                                                    .classCodeDescription
                                                    .value,
                                              }
                                              : undefined
                                          }
                                          onChange={(event) => {
                                            updateWorkerCompDesc(
                                              event,
                                              locatioNumber,
                                              addressLocation,
                                              "classCodeDescription"
                                            );
                                          }}
                                          loadOptions={(inputValue, callback) =>
                                            loadOptions(
                                              inputValue,
                                              callback,
                                              childrenLocs?.state?.value
                                            )
                                          }
                                        ></AsyncSelect>
                                      </div>
                                      <div
                                        className=" col-lg-2 col-md-12"
                                        style={{ marginTop: "0.5%" }}
                                      >
                                        <Form.Group>
                                          <label
                                            title="Number of full time employees"
                                            for="preuw_FullTime"
                                          >
                                            <b className="font-family-montserrat-semi-bold text-nowrap">
                                              # of employees
                                            </b>
                                          </label>
                                          <NumberFormat
                                            title="Number of full time employees"
                                            id="preuw_FullTime"
                                            isNumericString={true}
                                            name="FT"
                                            className="text-center"
                                            format="#####"
                                            placeholder="# FT"
                                            type="text"
                                            autoComplete="off"
                                            value={
                                              classCodesInfo &&
                                              classCodesInfo?.ft &&
                                              classCodesInfo?.ft?.value
                                            }
                                            onChange={(event) => {
                                              updateWorkerCompRow(
                                                event,
                                                locatioNumber,
                                                addressLocation,
                                                "ft"
                                              );
                                            }}
                                          />
                                        </Form.Group>
                                      </div>
                                      <div
                                        className=" col-lg-2 col-md-12"
                                        style={{ marginTop: "0.5%" }}
                                      >
                                        <Form.Group>
                                          <label
                                            for="preuw_AnnualPayroll"
                                            title="Annual Payroll"
                                          >
                                            <b className="font-family-montserrat-semi-bold">
                                              Annual Payroll *
                                            </b>
                                          </label>
                                          <NumberFormat
                                            title="Annual Payroll"
                                            id="preuw_AnnualPayroll"
                                            disabled={false}
                                            name="payroll"
                                            placeholder="Annual Payroll"
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            className="text-center"
                                            type="text"
                                            autoComplete="off"
                                            value={
                                              classCodesInfo &&
                                              classCodesInfo?.payroll &&
                                              classCodesInfo?.payroll?.value
                                            }
                                            onChange={(event) => {
                                              updateWorkerCompRow(
                                                event,
                                                locatioNumber,
                                                addressLocation,
                                                "payroll"
                                              );
                                            }}
                                          />
                                        </Form.Group>
                                      </div>
                                      {Object.keys(childrenLocs.classCodesInfo)
                                        .length > 1 && (
                                          <div className=" col-lg-1 col-md-12">
                                            <button
                                              type="button"
                                              className="rmvLoc btnInvisible transpButton mt-3 btn "
                                              onClick={() => {
                                                deleteClasscodeHandler(
                                                  locatioNumber,
                                                  addressLocation
                                                );
                                              }}
                                            >
                                              <img
                                                src={require("../../images/delete4.png")}
                                                alt=""
                                              />
                                            </button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className="row no-gutters">
                                <div className="calculator ml-3 mt-3 mb-3">
                                  <div>
                                    <img
                                      src={require("../../images/plus4.png")}
                                    />
                                    <span>&nbsp;&nbsp;</span>
                                    <button
                                      type="button"
                                      className="btnInvisible addLoc transpButton p-0 btn"
                                      onClick={() =>
                                        addNewClassCodeHandler(locatioNumber)
                                      }
                                    >
                                      <b>Add class code</b>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        )}
                    </section>
                    <hr />
                  </>
                )}

                {acordData?.includedExcludedObject && (
                  <>
                    <div
                      id="historical-cliams-container"
                      className="font-family-montserrat-semi-bold d-flex align-items-center mt-3 mb-3"
                      style={{ color: "#c0224d" }}
                    >
                      <h5 className="pr-4 mt-4 mb-4">
                        Individuals Included / Excluded
                      </h5>
                    </div>
                    {acordData?.includedExcludedObject?.[
                      "includeCodeCodeError"
                    ] &&
                      Object.values(
                        acordData?.includedExcludedObject?.[
                        "includeCodeCodeError"
                        ]
                      ).some((value) => value === true) && (
                        <p id="claims-error" className="text-danger">
                        Please enter class code which includes officer's payroll.
                        </p>
                      )}

                    <div id="IncludeExcludeContainer">
                      {acordData?.includedExcludedObject?.includeExcludeList.map(
                        (values, index) => {
                          return (
                            <div
                              className="row-include-exclude row no-gutter"
                              key={index}
                            >
                              <div className="col-lg-2">
                                <Form.Group>
                                  <label
                                    htmlFor="includ_Exclude_Name"
                                    className="mandatory"
                                  >
                                    <b className="font-family-montserrat-semi-bold">
                                      Name
                                    </b>
                                  </label>
                                  <input
                                    id="includ_Exclude_Name"
                                    type="text"
                                    placeholder="Name"
                                    autoComplete="off"
                                    onChange={(e) =>
                                      setincludeExcludeValueHandler(
                                        e,
                                        "officerName",
                                        index
                                      )
                                    }
                                    style={
                                      acordData?.includedExcludedObject
                                        .includeExcludeList[index][
                                        "officerName"
                                      ] &&
                                        acordData?.includedExcludedObject
                                          .includeExcludeList[index][
                                        "officerName"
                                        ]["error"]
                                        ? { borderColor: "red" }
                                        : {}
                                    }
                                    value={`${values?.["officerName"]?.["value"]}`}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-lg-2">
                                <Form.Group>
                                  <label
                                    htmlFor="includ_Exclude_ownerShip"
                                    className="mandatory"
                                  >
                                    <b className="font-family-montserrat-semi-bold">
                                      Ownership &#37;
                                    </b>
                                  </label>

                                  <NumberFormat
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    suffix="%"
                                    placeholder="0.00%"
                                    autoComplete="off"
                                    id="includ_Exclude_ownerShip"
                                    type="text"
                                    onChange={(e) =>
                                      setincludeExcludeValueHandler(
                                        e,
                                        "ownerShip",
                                        index
                                      )
                                    }
                                    style={
                                      acordData?.includedExcludedObject
                                        .includeExcludeList[index][
                                        "ownerShip"
                                      ] &&
                                        acordData?.includedExcludedObject
                                          .includeExcludeList[index]["ownerShip"][
                                        "error"
                                        ]
                                        ? { borderColor: "red" }
                                        : {}
                                    }
                                    value={`${values?.["ownerShip"]?.["value"]}`}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-lg-3">
                                <Form.Group>
                                  <label
                                    htmlFor="included_Excluded"
                                    className="mandatory"
                                  >
                                    <b className="font-family-montserrat-semi-bold">
                                      Included / Excluded
                                    </b>
                                  </label>
                                  <select
                                    id="included_Excluded"
                                    type="text"
                                    placeholder="Included / Excluded"
                                    autoComplete="off"
                                    onChange={(e) =>
                                      setincludeExcludeValueHandler(
                                        e,
                                        "includExclude",
                                        index
                                      )
                                    }
                                    style={
                                      acordData?.includedExcludedObject
                                        .includeExcludeList[index][
                                        "includExclude"
                                      ] &&
                                        acordData?.includedExcludedObject
                                          .includeExcludeList[index][
                                        "includExclude"
                                        ]["error"]
                                        ? { borderColor: "red" }
                                        : {}
                                    }
                                    value={`${values?.["includExclude"]?.["value"]}`}
                                  >
                                    <option key={1} value={undefined}>
                                      Please Select
                                    </option>
                                    <option key={2} value={"Included"}>
                                      Included
                                    </option>
                                    <option key={3} value={"Excluded"}>
                                      Excluded
                                    </option>
                                  </select>
                                </Form.Group>
                              </div>
                              <div className="col-lg-2">
                                <Form.Group>
                                  <label
                                    htmlFor="includ_Exclude_classCode"
                                    className="mandatory"
                                  >
                                    <b className="font-family-montserrat-semi-bold">
                                      Class Code
                                    </b>
                                  </label>
                                  <NumberFormat
                                    id="includ_Exclude_classCode"
                                    type="text"
                                    placeholder="Class Code"
                                    autoComplete="off"
                                    format="####"
                                    onChange={(e) =>
                                      setincludeExcludeValueHandler(
                                        e,
                                        "classCode",
                                        index
                                      )
                                    }
                                    style={
                                      acordData?.includedExcludedObject
                                        .includeExcludeList[index] &&
                                        acordData?.includedExcludedObject
                                          .includeExcludeList[index]["classCode"][
                                        "error"
                                        ]
                                        ? { borderColor: "red" }
                                        : {}
                                    }
                                    value={`${values?.["classCode"]?.["value"]}`}
                                  />
                                </Form.Group>
                              </div>
                              {includedExcludeSize >= 0 && (
                                <div className="col-1 d-flex">
                                  <button
                                    type="button"
                                    className="rmvLoc btnInvisible transpButton"
                                    onClick={() =>
                                      removeIncludeExcludeHandler(index)
                                    }
                                  >
                                    <img
                                      src={require("../../images/delete4.png")}
                                    />
                                  </button>
                                </div>
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>

                    <div>
                      <button
                        type="button"
                        className="btnInvisible addLoc transpButton p-0"
                        onClick={addIncludeExcludeHandler}
                      >
                        <img src={require("../../images/plus4.png")} />{" "}
                        <b>Add Officers</b>
                      </button>
                    </div>
                  </>
                )}

                <hr></hr>
                {acordData?.historicalClaims && (
                  <div id="historical_Claims">
                    <div
                      id="include-exclude-container "
                      className="font-family-montserrat-semi-bold d-flex align-items-center mt-3 mb-3"
                      style={{ color: "#c0224d" }}
                    >
                      <h5 className="pr-4 mt-4 mb-4">
                        Prior Carrier Information/Loss History
                      </h5>
                    </div>
                    {acordData?.historicalClaimsError && (
                      <p id="claims-error" className="text-danger">
                        Claims submitted should be for consecutive years!
                      </p>
                    )}
                    {Object.values(cliamZeroCheckAmountPaid).some(
                      (value) => value === true
                    ) ||
                      Object.values(cliamZeroCheckReserve).some(
                        (value) => value === true
                      ) ? (
                      <p id="claims-error" className="text-danger">
                        The Amount paid and reserve should be 0 if there are no
                        claims!
                      </p>
                    ) : null}

                    {Object.values(claimOneWithTotalPayroll).some(
                      (value) => value === true
                    ) && (
                        <p id="claims-error" className="text-danger">
                          Amount paid cannot be 0 if claims are more than zero!
                        </p>
                      )}
                    {/* <hr /> */}
                    <div className="row grey-table-row ">
                      <table
                        id="claimTable"
                        className="grey-table table table-responsive"
                      >
                        <thead>
                          <tr></tr>
                        </thead>
                        <tbody>{createTableLossRun()}</tbody>
                      </table>
                    </div>
                  </div>
                )}
                <hr></hr>

                {acordData?.uwQues && (
                  <>
                    <section className="pt-2">
                      <div className="pink-header">
                        <div className="font-family-montserrat-bold">
                          Underwriting Questions
                        </div>
                        {containerCloseOpen["showUwQuestions"] ? (
                          <span
                            className="pink-sign noselect"
                            onClick={() => {
                              if (containerCloseOpen["showUwQuestions"])
                                setContainerCloseOpen((prevState) => {
                                  return {
                                    ...prevState,
                                    showUwQuestions: false,
                                  };
                                });
                              else return;
                            }}
                          >
                            &#8722;
                          </span>
                        ) : (
                          <span
                            className="pink-sign noselect"
                            onClick={() => {
                              if (!containerCloseOpen["showUwQuestions"])
                                setContainerCloseOpen((prevState) => {
                                  return {
                                    ...prevState,
                                    showUwQuestions: true,
                                  };
                                });
                              else return;
                            }}
                          >
                            &#43;
                          </span>
                        )}
                      </div>

                      {containerCloseOpen["showUwQuestions"] && (
                        <table className="table table-striped table-borderless table-undrques">
                          <thead>
                            {!isMobile ? (
                              <tr>
                                <th className="tableQues">
                                  General Information
                                </th>
                                <th className="tableAns">Response</th>
                                <th className="tableRem">Remarks</th>
                              </tr>
                            ) : (
                              " "
                            )}
                          </thead>
                          <tbody> {createTable()}</tbody>
                        </table>
                      )}
                    </section>
                    <hr />
                  </>
                )}
                <div className="d-flex justify-content-center align-items-center">
                  <button type="submit" className="btn btn-primary">
                    Generate Acord
                  </button>
                  {loadingAcord && <SmallLoader className="ml-2" />}
                </div>
              </form>
            </div>
          </div>
          {acordFileURL && validCheck ? (
            <div ref={acordDisplayRef} className="w-75 m-auto">
              <LoadingOverlay active={loadingAcord ? true : false} spinner>
                <div className="text-left mt-3">
                  <b> Please find below your repopulated Acord App </b>
                </div>
                <div className="accord-container">
                  <div className="accord-toolbar">
                    <a
                      href={acordFileURL}
                      download={"Acord"}
                      title={`Download: Acord`}
                    >
                      <img
                        height="30"
                        src={require("../../images/download-file.png")}
                      ></img>
                    </a>
                  </div>

                  <iframe
                    id={`iframeAccord`}
                    src={
                      (acordFileURL || "about:blank") + "#toolbar=0&view=FitH"
                    }
                    style={{ border: "none", height: "800px" }}
                    width="100%"
                  ></iframe>
                </div>
              </LoadingOverlay>
            </div>
          ) : (
            <div
              style={{
                color: "red",
                textAlign: "center",
                paddingBottom: "2%",
                background: "#e2f2ff",
              }}
            >
              <b>{acordFileURL ? "Please fill all mandatory fields" : ""}</b>
            </div>
          )}
        </>
      ) : (
        <div>
          <div>Unable to fetch quote details</div>
        </div>
      )}
      <Loader />
    </div>
  );
}

export default GenerateAcord;
