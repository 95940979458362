//component for description code mapping
import React, { Component } from "react";
import { awsUrl2 } from "../../config";
import { getHeader } from "../../utils/common";
import AsyncSelect from "react-select/async";
import { Form } from "react-bootstrap";
import { checkMailCampaignUser } from "../../utils/check_mail_campaign_user";
import debounce from "../../utils/optimize_api";

const axios = require("axios");
class GoverningClassCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noOptionsMessage: "Enter 3 characters to start",
      key: Math.random(),
      initialDescApiCalled: false,
      placeholderText: "Enter the class code that best describes the business",
    };
  }

  componentDidUpdate = () => {
    const { descriptionInfo } = this.props;
    const { updateLocationError } = this.props;
    if (
      JSON.stringify(descriptionInfo) !==
      JSON.stringify(this.state.descriptionInfo)
    ) {
      let description;
      let returnList = [];
      if (descriptionInfo && descriptionInfo.value) {
        description = descriptionInfo.value;
        let desc = description.split(":")[1].trim();
        this.setState({ descriptionInfo });
        if (
          desc === "no description found" &&
          !this.state.initialDescApiCalled
        ) {
          this.setState({ initialDescApiCalled: true });
          // const header = await getHeader();
          let value = description.split(":")[0];
          axios
            .get(awsUrl2 + `/api/getCcodes/fl/${value}`)
            .then((res) => {
              let dbObjsList = res.data.data;
              if (dbObjsList.length === 0) {
                this.setState({ noOptionsMessage: "No data found" });
              }
              dbObjsList.forEach((element) => {
                returnList.push({
                  value: `${element.class_code}: ${element.description}`,
                  label: `${element.class_code
                    .toString()
                    .padStart(4, "0")}: ${element.description.trim()} `,
                  status: `${element.eligibility}`,
                });
              });
              this.setState({ defaultOptionsList: returnList });
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              updateLocationError("descriptionInfo", true);
            });
        }
      }
    }
    if (this.props.state !== this.state.state) {
      this.setState({
        key: Math.random(),
        state: this.props.state,
        noOptionsMessage: "Enter 3 characters to start",
      });
    }
  };

  handleSelectChange = (selectedOption) => {
    console.log(selectedOption, "selectedoptions");
    const { descriptionInfo, setdescriptionHandler } = this.props;

    setdescriptionHandler(selectedOption);
    this.setState({ prevDescription: selectedOption });
  };

  loadOptions = async (value, callback) => {
    let typeof_value = Number(value);
    if (!isNaN(typeof_value)) {
      value = Number(value);
      value = value.toString();
    }
    let minLength = isNaN(value) ? 3 : 1;
    if (value.length >= minLength) {
      let returnList = [];
      await axios
        .get(awsUrl2 + `/api/getCcodes/fl/${value}`)
        .then((res) => {
          let dbObjsList = res.data.data;
          if (dbObjsList.length === 0) {
            this.setState({ noOptionsMessage: "No data found" });
          }

          dbObjsList.forEach((element) => {
            returnList.push({
              value: `${element.class_code}: ${element.description}`,
              label: `${element.class_code
                .toString()
                .padStart(4, "0")}: ${element.description.trim()} `,
              status: `${element.eligibility}`,
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
      return callback(returnList);
    } else {
      this.setState({ noOptionsMessage: "Enter 3 characters to start" });
    }
  };

  customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "35px",
      border: state.isFocused ? "2px solid black" : "1.5px solid black",
      background: "transparent",
      borderRadius: "4px",
      boxShadow: "0.5px 1px 4px grey",
      "&:hover": {
        border: state.isFocused ? "2px solid black" : "1.5px solid black",
      },
    }),
    input: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#2b2d31",
      fontWeight: "normal",
    }),
  };

  customStylesErr = {
    control: (provided) => ({
      ...provided,
      minHeight: "35px",
      border: "1px solid red",
      background: "transparent",
      borderRadius: "4px",
      boxShadow: "none",
    }),
    input: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#2b2d31",
      fontWeight: "normal",
    }),
  };

  render() {
    const {
      noOptionsMessage,
      descriptionInfo,
      key,
      defaultOptionsList,
      placeholderText,
      isLoggedIn,
      prevDescription,
    } = this.state;

    let isSalespersonMailCampaign = sessionStorage.getItem(
      "salespersonMailCampaign"
    )
      ? true
      : false;

    let mailCampaignUserCheck =
      checkMailCampaignUser() && !isSalespersonMailCampaign;

    // let salespersonMailCampaign = sessionStorage.getItem("salespersonMailCampaign") ? true : false;
    let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;

    let descValue;
    let dropDownValue;
    let description;
    let errorMessage = "Invalid class code. Please check.";

    if (descriptionInfo && descriptionInfo.value) {
      description = descriptionInfo.value;
      let desc = description.split(":")[1].trim();
      let code = description.split(":")[0].trim();
      if (desc === "no description found") {
        errorMessage = "Please select the description.";
        descValue = [
          {
            label: code.toString().padStart(4, "0"),
            value: code,
          },
        ];
        dropDownValue = defaultOptionsList;
      } else {
        descValue = [
          {
            label: `${code.toString().padStart(4, "0")}: ${desc}`,
            value: description,
          },
        ];
        dropDownValue = descValue;
      }
    }

    return (
      <div id={`class-code-desc`} className="workerscompcode">
        <Form.Group
          style={
            descriptionInfo && descriptionInfo.error ? { marginBottom: 0 } : {}
          }
        >
          <label
            className="d-flex"
            title="Governing Class Code or Key Word Description"
          >
            <b className="font-family-montserrat">
              {`Governing Class Code or Key Word Description`}
            </b>
          </label>
          <div
            title={`${
              description ? description : "Enter 3 characters to start"
            }`}
          >
            <AsyncSelect
              key={key}
              value={descValue ? descValue : []}
              className="class-desc-select"
              classNamePrefix="class-desc-select"
              cacheOptions
              defaultOptions={dropDownValue ? dropDownValue : []}
              onClick={debounce(this.loadOptions)}
              loadOptions={debounce(this.loadOptions)}
              noOptionsMessage={() => noOptionsMessage}
              // placeholder="Enter 3 characters...."
              onFocus={() => {
                this.setState({ placeholderText: "" });
                this.setState({ prevDescription: description });
              }}
              onBlur={() => {
                this.setState({ placeholderText: "Enter 3 characters" });
                if (!prevDescription) return;
                if (prevDescription !== "no description found") {
                }
              }}
              isDisabled={
                this.props.finalEligibility === "declined" ? true : false
              }
              onChange={this.handleSelectChange}
              placeholder={placeholderText}
              styles={
                descriptionInfo && descriptionInfo.error
                  ? this.customStylesErr
                  : this.customStyles
              }
            />
          </div>
        </Form.Group>
        {descriptionInfo && descriptionInfo.error && (
          <div style={{ color: "red", fontSize: "11px" }}>{errorMessage}</div>
        )}
      </div>
    );
  }
}

export default GoverningClassCode;
