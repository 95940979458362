import React, { Component } from "react";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import { Form } from "react-bootstrap";
import NumberFormat from "react-number-format";
import toast, { Toaster } from "react-hot-toast";
import { checkMailCampaignUser } from "../../../utils/check_mail_campaign_user";
import DetailedEligibilityData from "./DetailedEligibilityData";
import {
  deleteAllCookies,
  showErrAlert,
  blobPdfFromBase64String,
  clearErr,
  validEmail,
  onlyAlpha,
  clearDelete,
  initializeComponents,
  clearErrHistoricalClaims,
} from "../../../utils/common";
import numeral from "numeral";
import {
  histClaimCalcTI,
  histClaimCalcTP,
  histClaimCalcTR,
  handleCheck,
} from "../../../utils/form_cmprRates";
import {
  amtrustResponse,
  fubaQuotePdfGenerator,
  downloadFubaDeclinationQuotePdf,
  convertToBuffer,
} from "../../../utils/form_ques";
import { awsUrl, awsUrl2 } from "../../../config";
import { Auth } from "aws-amplify";
import $ from "jquery";
import QuoteTable from "../../common/QuoteTable";
import DropZones from "../../common/DropZones";
import Tabs from "../../common/Tabs";
import { mergePdf } from "../../common/MergePdf";
import FubaQuoteDetails from "../../common/FubaQuoteDetails";
import NewProgress from "../../../newcomponent/common/NewProgress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import EmodQuestions from "./EmodQuestions";
import Context from "../../../contexts/Context";
import { roundValues } from "../../../utils/math_functions";
import { startLoader, stopLoader } from "../../../utils/loader";
import {
  getFinalEligibility,
  getDeclinedStatementList,
} from "../../../utils/eligibility_helpers";

let isMobile = window.innerWidth < 1024;
class FormCmprQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        peo: [],
        paygo: [],
      },
      visible: false,
      uuid_carrier: [],
      fileURL: undefined,
      peoPaygo: {
        peo: "",
        paygo: "",
      },
      fileURL2: undefined,
      loadingAccord: false,
      isNoLossChckd: false,
      loadingNoLoss: false,
      selectedCarrier: "peo",
      historicalClaimsError: false,
      historicalClaims: {},
      historicalClaimsCheck: {},
      historicalClaimsErrorStore: {},
      emodStatesData: {},
      loading: true,
      salespersonName: "",
      salespersonCheck: false,
      loggedIn: false,
      industry_type: "",
      docUploaded: false,
      quoteSubmitted: false,
      carrierSubmittedWith: null,
      amtrustBound: false,
      netRateMap: {},
      salespersonQuoteSubmitted: false,
      salesManagerCheck: false,
      salesManagerPersonName: "",
      carrierStateMap: undefined,
      acordloadtab: {},
      finalEligibility: "",
      declineEligibilityStmtList: [],
      loadingEAcord: false,
    };
    this.getAllDataWRTCarrier = this.getAllDataWRTCarrier.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.QuoteView = this.QuoteView.bind(this);
    this.handleCheck = handleCheck.bind(this);
    this.clearErr = clearErrHistoricalClaims.bind(this);
  }
  componentWillMount() {
    if (!window.location.hash) {
      window.location = window.location + "#loaded";
      window.location.reload();
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.quoteSubmitted !== prevProps.quoteSubmitted) {
      this.setState({ quoteSubmitted: this.props.quoteSubmitted });
    }

    if (this.props.carrierSubmittedWith !== prevProps.carrierSubmittedWith) {
      this.setState({ carrierSubmittedWith: this.props.carrierSubmittedWith });
    }

    if (this.props.amtrustBound !== prevProps.amtrustBound) {
      this.setState({ amtrustBound: this.props.amtrustBound });
    }

    if (
      this.props.reCalculateData !== prevProps.reCalculateData &&
      this.props.reCalculateData === true
    ) {
      await this.onMountFunction();
      this.props.setReCalculateData(false);
    }
  }

  createNetRateMap = (netRatesResponse) => {
    let response = {};
    for (let data of netRatesResponse?.Data) {
      if (!(data?.State in response)) {
        response[data?.State] = {};
      }
      response[data?.State][String(data?.ClassCode).substring(0, 4)] = {
        rate: data?.NetRate || 0,
        description: data?.ClassDescription,
      };
    }

    return response;
  };

  createPayoData = (
    quoteDetailsResponse,
    netRateMap,
    peoStateClassCodeMap,
    industry_type
  ) => {
    let payoData = [];
    let payoEligibility;
    for (let data of quoteDetailsResponse.ClassCodes) {
      let classCode = String(data.ClassCode).substring(0, 4);
      let state = data.State.toLowerCase();
      let obj = {
        state: data.State,
        wc_code: classCode,
        wc_desc:
          netRateMap[data.State] && netRateMap[data.State][classCode]
            ? netRateMap[data.State][classCode].description
            : "-",
        fica:
          peoStateClassCodeMap[state] && peoStateClassCodeMap[state][classCode]
            ? peoStateClassCodeMap[state][classCode].fica
            : 0,
        futa:
          peoStateClassCodeMap[state] && peoStateClassCodeMap[state][classCode]
            ? peoStateClassCodeMap[state][classCode].futa
            : 0,
        suta:
          industry_type === "hospitality"
            ? peoStateClassCodeMap[state] &&
              peoStateClassCodeMap[state][classCode]
              ? peoStateClassCodeMap[state][classCode].suta
              : 0
            : "TBD",
        work_comp: netRateMap[data.State]
          ? netRateMap[data.State][classCode].rate * 100
          : "UW",
        net_rate: netRateMap[data.State]
          ? netRateMap[data.State][classCode].rate * 100
          : "UW",
        admin:
          industry_type === "hospitality"
            ? peoStateClassCodeMap[state] &&
              peoStateClassCodeMap[state][classCode]
              ? peoStateClassCodeMap[state][classCode].admin
              : 0
            : "TBD",
        base_rate: 0,
        limit: 0,
      };
      for (let key of ["fica", "futa", "suta", "work_comp", "admin"]) {
        if (obj[key] && !isNaN(obj[key])) obj.base_rate += Number(obj[key]);
      }
      for (let key of ["fica", "work_comp", "admin"]) {
        if (obj[key] && !isNaN(obj[key])) obj.limit += Number(obj[key]);
      }
      payoData.push(obj);
    }

    payoEligibility = quoteDetailsResponse?.Eligibility;

    return { payoData, payoEligibility };
  };

  sortQuoteTableData = (a, b) => {
    if (a.state > b.state) {
      return 1;
    } else if (a.state < b.state) {
      return -1;
    } else if (a.wc_code > b.wc_code) {
      return 1;
    } else if (a.wc_code < b.wc_code) {
      return -1;
    } else {
      return 0;
    }
  };

  onMountFunction = async () => {
    try {
      let {
        data,
        historicalClaims,
        historicalClaimsCheck,
        startDate,
        fileURL,
      } = this.state;

      let { setEligibilityCheck } = this.props;

      let fileList = {
        first: {},
        second: {},
      };

      let declineEligibilityStmtList = [],
        finalEligibility;

      let currProspectDetails = JSON.parse(
        sessionStorage.getItem("currProspect")
      );

      let agentID = sessionStorage.getItem("agentID");

      if (currProspectDetails?.emodStatesData) {
        let { emodStatesData } = currProspectDetails;
        this.setState({ emodStatesData });
      }
      var formStage = sessionStorage.getItem("formStage");

      if (
        formStage === "five" ||
        formStage === "six" ||
        formStage === "three" ||
        formStage === "four"
      ) {
        $("#loader").css("display", "block");

        let sortKeyList = [],
          address = [];

        if (currProspectDetails !== null) {
          axios
            .get(awsUrl + "/api/getUserDataStatus/" + agentID + "/quote")
            .then(async (response) => {
              let dataFromAPI = response.data;
              let fileListToStore;

              if (dataFromAPI.carrierBasedData) {
                for (let carr in dataFromAPI.carrierBasedData) {
                  for (let line in dataFromAPI.carrierBasedData[carr]) {
                    address.push(line);
                    sortKeyList.push(
                      dataFromAPI.carrierBasedData[carr][line].uuid_carrier
                    );
                  }
                }

                sessionStorage.setItem(
                  "sortKeyList",
                  JSON.stringify(sortKeyList)
                );
                sessionStorage.setItem("address", JSON.stringify(address));

                let firstCarrierObject =
                  dataFromAPI.carrierBasedData[
                  Object.keys(dataFromAPI.carrierBasedData)[0]
                  ];
                try {
                  if (firstCarrierObject) {
                    let firstLineObject =
                      firstCarrierObject[Object.keys(firstCarrierObject)[0]];

                    if (firstLineObject) {
                      if (firstLineObject.date && firstLineObject.uuid) {
                        let quoteData = {
                          uuid: firstLineObject.uuid,
                          date: firstLineObject.date,
                        };
                        sessionStorage.setItem(
                          "quoteData",
                          JSON.stringify(quoteData)
                        );
                      }

                      if (firstLineObject.quotePdfUuid) {
                        sessionStorage.setItem(
                          "quotePdfUuid",
                          firstLineObject.quotePdfUuid
                        );
                      }
                      if (firstLineObject.eligibilityCheck) {
                        setEligibilityCheck(firstLineObject.eligibilityCheck);
                        finalEligibility = getFinalEligibility(
                          firstLineObject.eligibilityCheck
                        );
                        if (finalEligibility === "declined")
                          declineEligibilityStmtList = await getDeclinedStatementList(
                            firstLineObject.eligibilityCheck
                          );
                      }
                      // finalEligibility = "approved";
                      // declineEligibilityStmtList = ["This is to test something over here.", "This is to test something over here."]

                      if (firstLineObject.preUwFiles)
                        sessionStorage.setItem(
                          "preUwFiles",
                          JSON.stringify(firstLineObject.preUwFiles)
                        );

                      if (firstLineObject.dashboardFiles)
                        sessionStorage.setItem(
                          "dashboardFiles",
                          JSON.stringify(firstLineObject.dashboardFiles)
                        );

                      if (firstLineObject.uwQuesList)
                        sessionStorage.setItem(
                          "uwQuesList",
                          JSON.stringify(firstLineObject.uwQuesList)
                        );

                      if (firstLineObject.historicalClaims) {
                        historicalClaims = firstLineObject.historicalClaims;
                      }
                      if (firstLineObject.historicalClaimsCheck) {
                        historicalClaimsCheck =
                          firstLineObject.historicalClaimsCheck;
                      }

                      if (firstLineObject.startDate) {
                        startDate = moment(
                          firstLineObject.startDate,
                          "YYYY-MM-DD"
                        );
                      }
                    }
                  }
                } catch (error) {
                  console.log("++error", error);
                }
              }

              if (!fileURL) this.generateAccordAndStore();

              if (fileListToStore) {
                for (let row in fileListToStore) {
                  if (!fileList[fileListToStore[row].keyToFile])
                    fileList[fileListToStore[row].keyToFile] = {};
                  fileList[fileListToStore[row].keyToFile][
                    fileListToStore[row].key.split("/").reverse()[0]
                  ] = "#empty";
                }
              }

              this.setState({
                data,
                historicalClaims,
                historicalClaimsCheck,
                startDate,
                fileList,
                declineEligibilityStmtList,
                finalEligibility,
              });

              $("#loader").css("display", "none");
            })
            .catch((err) => {
              console.log("error: ", err);
              // showErrAlert(err);
              $("#loader").css("display", "none");
            })
            .finally(() => {
              this.setState({ loading: false });
            });
        } else {
          sessionStorage.setItem("formStage", "gov_cc");
          deleteAllCookies();
          sessionStorage.clear();
          window.location.reload();
        }
      }
    } catch (error) {
      console.log("HERE IS THE ERROR", error);
    }
  };

  async componentDidMount() {
    await this.onMountFunction();
  }

  open() {
    this.setState({
      visible: true,
    });
  }
  close() {
    this.setState({
      visible: false,
    });
  }
  QuoteView(event) {
    let carrierTemplate = event.target.id.split("#+");
    let carrier = carrierTemplate[0];
    let timestamp = carrierTemplate[1];
    let getQuoteDetails = {};
    let email = JSON.parse(sessionStorage.getItem("currProspect")).emailAddress;
    getQuoteDetails.email = email;
    getQuoteDetails.timestamp = timestamp;
    getQuoteDetails.carrier = carrier;
    Auth.currentSession().then((res) => {
      const USER_TOKEN = res.idToken.jwtToken;
      const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
      axios
        .post(
          awsUrl + "/api/generateQuotePDF",
          JSON.stringify(getQuoteDetails),
          header
        )
        .then((res) => {
          let pdf = res.data.data;
          let fileName = "Quote.pdf";
          let file = blobPdfFromBase64String(pdf);
          var fileURL = URL.createObjectURL(file);
          let newWindow = window.open("./QuotePDF", "_blank");
          newWindow.onload = () => {
            newWindow.location = fileURL;
          };
        })
        .catch((err) => {
          console.log("Err", err);
          // showErrAlert("View Quote is not available due to error");
        });
    });
  }

  updateAcordSelectedState = (tabName, state) => {
    let { acordloadtab } = JSON.parse(JSON.stringify(this.state));
    let selectedtabName = tabName.split(" ")[0].toLowerCase();
    acordloadtab[selectedtabName] = state;
    this.setState({ acordloadtab });
  };

  generateAccordAndStore = async () => {
    try {
      let currProspect = sessionStorage.getItem("currProspect")
        ? JSON.parse(sessionStorage.getItem("currProspect"))
        : undefined;
      let address = sessionStorage.getItem("address")
        ? JSON.parse(sessionStorage.getItem("address"))
        : undefined;
      let quoteData = sessionStorage.getItem("quoteData")
        ? JSON.parse(sessionStorage.getItem("quoteData"))
        : undefined;

      let agentID = sessionStorage.getItem("agentID");

      let childrenLoc = currProspect.childrenLoc;
      let stateList = [];
      for (let childrenLocIndx in childrenLoc) {
        let childrenLocObj = childrenLoc[childrenLocIndx];
        if (childrenLocObj.state && childrenLocObj.state.value) {
          stateList.push(childrenLocObj.state.value.toLowerCase());
        }
      }
      let isLoggedIn;
      try {
        isLoggedIn = await Auth.currentAuthenticatedUser();
      } catch (error) {
        console.log("Authentication error here", error);
      }

      if (currProspect && address && quoteData) {
        let etQuoteDetails = {
          email: agentID,
          timestamp: quoteData.date,
          carrier: `carrier_ax`,
          mail_address: "",
          address: address,
          state: stateList,
          netRateMap: {},
          agent_name: isLoggedIn?.attributes.name || " ",
          agent_email: isLoggedIn?.attributes.email || " ",
          agent_agency: isLoggedIn?.attributes["custom:agency"] || " ",
          uw_name: isLoggedIn?.attributes["custom:uw"] || " ",
          agency_code: isLoggedIn?.attributes["custom:agency_code"] || "",
        };

        axios
          .post(
            awsUrl2 + "/api/generateAcord/fl",
            JSON.stringify(etQuoteDetails)
          )
          .then((res) => {
            let pdf = res.data.data;
            let file = blobPdfFromBase64String(pdf);

            let fileURL = URL.createObjectURL(file);

            this.setState({ fileURL });
          })
          .catch((err) => {
            console.log("Err", err);
          });
      }
    } catch (error) {
      console.log("Error in generating acord");
    }
  };

  generateNoLoss = async (date) => {
    let currProspect = sessionStorage.getItem("currProspect")
      ? JSON.parse(sessionStorage.getItem("currProspect"))
      : undefined;
    let sortKeyList = sessionStorage.getItem("sortKeyList")
      ? JSON.parse(sessionStorage.getItem("sortKeyList"))
      : undefined;

    if (currProspect) {
      this.setState({ loadingNoLoss: true });
      let cName = `${currProspect.companyProfile.firstName.value} ${currProspect.companyProfile.lastName.value}`;
      let dFrom = "";

      try {
        dFrom = moment(date).format("YYYY-MM-DD");
      } catch (error) { }

      await axios
        .post(
          awsUrl2 + "/api/getNoLossData",
          JSON.stringify({
            company: currProspect.companyProfile.companyName.value,
            name: cName,
            dFrom,
            fein: currProspect.companyProfile.fein.value,
          })
        )
        .then(async (res) => {
          let pdf = res.data.data;
          let file = blobPdfFromBase64String(pdf);

          var fileURL2 = URL.createObjectURL(file);

          // let dataToSend = {
          //   user_email_id: currProspect.companyProfile.emailId.value,
          //   uuid_carrier: sortKeyList[0],
          //   nolossGenerated: true,
          //   dFrom: date,
          // };
          // await axios
          //   .post(awsUrl2 + "/api/updateSolveUserStatusTable", dataToSend)
          //   .then((res) => {})
          //   .catch((error) => {
          //     console.log("error in API-updateSolveUserStatusTable", error);
          //   });
          this.setState({ fileURL2 });
          this.setState({ loadingNoLoss: false });
        })
        .catch((err) => {
          console.log("Err", err);
          // showErrAlert("View Quote is not available due to error.");
          this.setState({ loadingNoLoss: false });
        });
    }
  };

  getAllDataWRTCarrier(carrier, data) {
    let calculated_data = "";
    let requireData = {};
    let places = [];
    let teapSum = 0;
    for (let addres in data) {
      requireData["status"] = data[addres].quote_status;
      requireData["timestamp"] = data[addres].date;
      places.push(addres);
      teapSum += Number(data[addres].teap);
      calculated_data = data[addres].calculated_data;
    }

    requireData["places"] = places;
    requireData["teapSum"] = teapSum;
    requireData["calculated_data"] = calculated_data;
    return requireData;
  }

  handleBind = async () => {
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
    let domain = sessionStorage.getItem("domain");

    let body = {
      companyName: currProspect.companyProfile.companyName.value,
      date: quoteData.date,
      uuid: quoteData.uuid,
      email: currProspect.companyProfile.emailId.value,
    };

    try {
      let requestBody = {
        domain,
        email: currProspect.companyProfile.emailId.value,
      };

      await axios
        .post(
          awsUrl2 + "/api/updateSolveTrackingDataGeneric/updateProposalStatus",
          JSON.stringify(requestBody)
        )
        .then((res) => {
          console.log("updated proposal count");
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.log("error", error);
    }

    sessionStorage.setItem("formStage", "four");
    window.location.reload();

    // axios
    //   .post(awsUrl2 + "/api/sendBindMail", JSON.stringify(body))
    //   .then((response) => {
    //     console.log("bind mail sent successfully");
    //   })
    //   .catch((error) => {
    //     console.log("error while sending bind email.", error);
    //   })
    //   .finally(() => {
    //     try {
    //       let sortKeyList = JSON.parse(sessionStorage.getItem("sortKeyList"));
    //       let email = JSON.parse(sessionStorage.getItem("currProspect"))
    //         .companyProfile.emailId.value;

    //       let updateBody = {
    //         sortKeyList,
    //         email,
    //       };

    //       axios
    //         .post(
    //           awsUrl2 + "/api/updateSolveUsersData",
    //           JSON.stringify(updateBody)
    //         )
    //         .then((res) => {
    //           console.log("updated table data successfully");
    //         })
    //         .catch((error) => {
    //           console.log("error", error);
    //         })
    //         .finally(() => {
    //           sessionStorage.setItem("formStage", "four");
    //           window.location.reload();
    //         });
    //     } catch (error) {
    //       console.log("error", error);
    //       sessionStorage.setItem("formStage", "four");
    //       window.location.reload();
    //     }
    //   });
  };

  handleCheckClicked = async (event) => {
    let { isNoLossChckd } = this.state;
    let currProspect = sessionStorage.getItem("currProspect")
      ? JSON.parse(sessionStorage.getItem("currProspect"))
      : undefined;
    let sortKeyList = sessionStorage.getItem("sortKeyList")
      ? JSON.parse(sessionStorage.getItem("sortKeyList"))
      : undefined;
    let domain = sessionStorage.getItem("domain");
    let salesID = sessionStorage.getItem("salesID");
    let user_email_id = domain
      ? domain
      : salesID
        ? salesID.toLowerCase()
        : currProspect?.companyProfile?.companyName?.value
          ? currProspect.companyProfile.companyName.value.toLowerCase()
          : "";

    for (let sortkey of sortKeyList) {
      let dataToSend = {
        user_email_id,
        uuid_carrier: sortkey,
        nolossGenerated: !isNoLossChckd,
        dFrom: "",
      };
      this.setState({
        isNoLossChckd: !isNoLossChckd,
      });
      await axios
        .post(awsUrl2 + "/api/updateFourthUserStatusTable", dataToSend)
        .then((res) => { })
        .catch((error) => {
          console.log("error in API-updateFourthUserStatusTable", error);
        });
    }
  };

  handleDateSelected = (date) => {
    this.setState({ startDate: date });
    this.generateNoLoss(date);
  };

  handleTotPaidBlur = (e) => {
    // zeroDefault(e);
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];
    historicalClaims = histClaimCalcTP(historicalClaims, year);
    this.setState({ historicalClaims });
  };

  handleChange = (e, key) => {
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];

    if (!historicalClaims[year]) {
      historicalClaims[year] = {};
    }
    historicalClaims[year][key] = e.target.value;

    if (key === "totInc") {
      historicalClaims = histClaimCalcTI(historicalClaims, year);
    }
    if (key === "totPaid") {
      historicalClaims = histClaimCalcTP(historicalClaims, year);
    }
    if (key === "totRes") {
      historicalClaims = histClaimCalcTR(historicalClaims, year);
    }
    this.setState({ historicalClaims });
  };

  createTable = () => {
    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );

    if (!currProspectDetails) return [];
    let yearsInBusiness;

    try {
      if (
        currProspectDetails &&
        currProspectDetails.companyProfile &&
        currProspectDetails.companyProfile.yearsInBusiness &&
        currProspectDetails.companyProfile.yearsInBusiness.value
      ) {
        yearsInBusiness = Number(
          currProspectDetails.companyProfile.yearsInBusiness.value
        );
      }
    } catch (error) {
      console.log(error);
    }

    let table = [];
    let j = 0;
    let {
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsErrorStore,
    } = this.state;

    historicalClaimsErrorStore = !historicalClaimsErrorStore
      ? {}
      : historicalClaimsErrorStore;
    historicalClaims = !historicalClaims ? {} : historicalClaims;
    historicalClaimsCheck = !historicalClaimsCheck ? {} : historicalClaimsCheck;

    // let yearBusinessEstablished = moment(
    //   currProspectDetails.companyProfile.effectiveDate.value
    // );
    // let effectiveDate = moment(
    //   currProspectDetails.companyProfile.effectiveDate.value
    // );
    let historyLimitYear = new Date().getFullYear() - (yearsInBusiness || 0);
    let absYear;

    let rowId, rowIdDup, rowIdYear, rowIdDupYear;

    rowIdYear = new Date().getFullYear() - 1;
    rowIdDupYear = new Date().getFullYear() - 1;

    for (let i = 0; i < 10; i++) {
      rowId = rowIdYear - j;
      rowIdDup = rowIdDupYear - i;
      absYear = !absYear ? rowIdDup : absYear;
      // if (historicalClaimsCheck[absYear]) {
      //   backgroundColor = "#dddddd";
      // } else {
      //   backgroundColor = "";
      // }
      rowId = rowId.toString();
      if (i % 2 === 0) {
        j++;
        let yearId = Number(rowId);
        // loss_data_index = loss_data
        //   ? loss_data.findIndex((e) => e.year === rowId)
        //   : null;
        table.push(
          <div className="row">
            <tr id={rowIdDup} key={rowIdDup}>
              <td className="col-4" id="carrier">
                <Form.Group>
                  <label
                    htmlFor={`carrier-${absYear}`}
                    className="font-family-montserrat-semi-bold text-nowrap"
                  >
                    Carrier
                  </label>
                  <input
                    autoComplete="off"
                    id={`carrier-${absYear}`}
                    type="text"
                    name="carText"
                    onChange={(e) => this.handleChange(e, "carrier")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                          historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                          historicalClaimsErrorStore[absYear].carrier
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    onKeyPress={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "carrier");
                    }}
                    value={
                      historicalClaims &&
                        historicalClaims[absYear] &&
                        historicalClaims[absYear]["carrier"]
                        ? historicalClaims[absYear]["carrier"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>

              <td className="col-3" id="Annaulprem">
                <Form.Group>
                  <label
                    htmlFor={`annPre-${absYear}`}
                    title="Annual Premium"
                    style={{ fontSize: "12px" }}
                    className="font-family-montserrat-semi-bold text-nowrap"
                  >
                    Annual Premium
                  </label>
                  <NumberFormat
                    name="anualPremium"
                    id={`annPre-${absYear}`}
                    autoComplete="off"
                    onKeyPress={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "annPre");
                    }}
                    thousandSeparator={true}
                    onChange={(e) => this.handleChange(e, "annPre")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                          historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                          historicalClaimsErrorStore[absYear].annPre
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    onKeyDown={(event) => {
                      if (event.key === "-" || event.key === "−") {
                        event.preventDefault(); // Prevent typing the '-' character
                      }
                    }}
                    prefix={"$"}
                    className="text-center"
                    type="text"
                    value={
                      historicalClaims &&
                        historicalClaims[absYear] &&
                        historicalClaims[absYear]["annPre"]
                        ? historicalClaims[absYear]["annPre"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>

              <td className="col-3" id="claims">
                <Form.Group>
                  <label
                    title="Number of claims"
                    htmlFor={`claims-${absYear}`}
                    className="font-family-montserrat-semi-bold text-nowrap "
                  >
                    # Claims
                  </label>
                  <NumberFormat
                    name="claims"
                    id={`claims-${absYear}`}
                    maxLength="2"
                    autoComplete="off"
                    onKeyPress={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "claim");
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "-" || event.key === "−") {
                        event.preventDefault(); // Prevent typing the '-' character
                      }
                    }}
                    onChange={(e) => this.handleChange(e, "claim")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                          historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                          historicalClaimsErrorStore[absYear].claim
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    className="text-center"
                    type="text"
                    value={
                      historicalClaims &&
                        historicalClaims[absYear] &&
                        historicalClaims[absYear]["claim"]
                        ? historicalClaims[absYear]["claim"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>

              <td className="col-4" id="totalincurred">
                <Form.Group>
                  <label
                    htmlFor={`totInc-${absYear}`}
                    title="Total Incurred"
                    className="font-family-montserrat-semi-bold text-nowrap"
                  >
                    Total Incurred
                  </label>
                  <NumberFormat
                    onFocus={(e) => {
                      this.clearErr(e, historicalClaimsErrorStore, "totInc");
                    }}
                    id={`totInc-${absYear}`}
                    autoComplete="off"
                    name="totInc"
                    thousandSeparator={true}
                    onChange={(e) => this.handleChange(e, "totInc")}
                    style={{
                      backgroundColor:
                        historyLimitYear > rowId ||
                          historicalClaimsCheck[absYear]
                          ? "#dddddd"
                          : "",
                      // backgroundColor: backgroundColor,
                      borderColor:
                        // submitClicked &&
                        historicalClaimsErrorStore[absYear] &&
                          historicalClaimsErrorStore[absYear].totInc
                          ? "red"
                          : "",
                    }}
                    disabled={historyLimitYear > rowId}
                    prefix={"$"}
                    className="text-center"
                    type="text"
                    // onBlur={(e) => this.zeroDefault(e, "totInc")}
                    value={
                      historicalClaims &&
                        historicalClaims[absYear] &&
                        historicalClaims[absYear]["totInc"]
                        ? historicalClaims[absYear]["totInc"]
                        : ""
                    }
                  />
                </Form.Group>
              </td>

              {/* <td className="per10">
            <Form.Group>
              <label
                htmlFor={`totPaid-${absYear}`}
                title="Total Paid"
                className="font-family-montserrat-semi-bold"
              >
                Total Paid
              </label>
              <NumberFormat
                onFocus={(e) => {
                  this.clearErr(e, historicalClaimsErrorStore, "totPaid");
                }}
                onBlur={this.handleTotPaidBlur}
                id={`totPaid-${absYear}`}
                autoComplete="off"
                name="totPaid"
                thousandSeparator={true}
                onChange={(e) => this.handleChange(e, "totPaid")}
                style={{
                  backgroundColor:
                    historyLimitYear > rowId || historicalClaimsCheck[absYear]
                      ? "#dddddd"
                      : "",
                  // backgroundColor: backgroundColor,
                  borderColor:
                    // submitClicked &&
                    historicalClaimsErrorStore[absYear] &&
                    historicalClaimsErrorStore[absYear].totPaid
                      ? "red"
                      : "",
                }}
                disabled={historyLimitYear > rowId}
                prefix={"$"}
                className="text-center"
                type="text"
                // onBlur={zeroDefault}
                value={
                  historicalClaims &&
                  historicalClaims[absYear] &&
                  historicalClaims[absYear]["totPaid"]
                    ? historicalClaims[absYear]["totPaid"]
                    : ""
                }
              />
            </Form.Group>
          </td>
          <td className="per10">
            <Form.Group>
              <label
                htmlFor={`totRes-${absYear}`}
                title="Total Reserves"
                className="font-family-montserrat-semi-bold"
              >
                Total Reserves
              </label>
              <NumberFormat
                onFocus={(e) => {
                  this.clearErr(e, historicalClaimsErrorStore, "totRes");
                }}
                id={`totRes-${absYear}`}
                autoComplete="off"
                name="totRes"
                thousandSeparator={true}
                onChange={(e) => this.handleChange(e, "totRes")}
                style={{
                  backgroundColor:
                    historyLimitYear > rowId || historicalClaimsCheck[absYear]
                      ? "#dddddd"
                      : "",
                  // backgroundColor: backgroundColor,
                  borderColor:
                    // submitClicked &&
                    historicalClaimsErrorStore[absYear] &&
                    historicalClaimsErrorStore[absYear].totRes
                      ? "red"
                      : "",
                }}
                disabled={historyLimitYear > rowId}
                prefix={"$"}
                className="text-center "
                type="text"
                // onBlur={zeroDefault}
                value={
                  historicalClaims &&
                  historicalClaims[absYear] &&
                  historicalClaims[absYear]["totRes"]
                    ? historicalClaims[absYear]["totRes"]
                    : ""
                }
              />
            </Form.Group>
          </td> */}
            </tr>{" "}
          </div>
        );
      } else {
        let yearId = Number(rowId) + 1;
        table.push(
          <tr id={rowIdDup} key={rowIdDup} className="row-no-coverage my-auto">
            <td className="no-coverage">
              <input
                type="checkbox"
                id={`check-${absYear}`}
                disabled={historyLimitYear > yearId}
                checked={
                  historyLimitYear > yearId || historicalClaimsCheck[absYear]
                    ? historicalClaimsCheck[absYear] || true
                    : false
                }
                onChange={(e) => this.handleCheck(e)}
              />
              No coverage in {yearId}
            </td>
            {/* <td className="per5 text-left">coverage for the {yearId}</td> */}
          </tr>
        );
        absYear--;
      }
    }
    return table;
  };

  setSelectedCarrier = (val) => {
    let { selectedCarrier, peoPaygo } = this.state;
    if (selectedCarrier !== val) {
      this.setState({ selectedCarrier: val }, () => {
        if (peoPaygo[val]) this.setState({ fileURL: peoPaygo[val] });
        else this.generateAccordAndStore();
      });
    }
  };

  updateEmodInfo = (state, fieldName, value) => {
    let { emodStatesData } = this.state;
    let dataFieldNames = ["rate1", "rate2", "date1", "date2"];
    if (dataFieldNames.includes(fieldName)) {
      emodStatesData[state][fieldName] = {
        value: value,
      };
    } else {
      emodStatesData[state][fieldName] = value;
    }

    this.setState({ emodStatesData });
  };

  updateSalesPerson = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + `/api/salesPersonData/update`, JSON.stringify(data))
        .then((res) => resolve())
        .catch((err) => reject());
    });
  };

  updateSubmitUserTracking = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          awsUrl2 + "/api/updateSolveTrackingDataGeneric/updateSubmitStatus",
          JSON.stringify(data)
        )
        .then((res) => resolve())
        .catch((err) => reject());
    });
  };

  handleSubmit = async (
    salespersonCheck,
    mailCampaignUserCheck,
    bindCarrier,
    bindOnline
  ) => {
    $("#loader").css("display", "block");
    let { updatePopUpData } = this.props;
    let {
      historicalClaims,
      historicalClaimsCheck,
      isNoLossChckd,
      startDate,
    } = this.state;
    let currProspect = sessionStorage.getItem("currProspect");
    let sortKeyList = sessionStorage.getItem("sortKeyList");
    let landingEmail = sessionStorage.getItem("landingEmail");

    let amtrustAccountDetails = sessionStorage.getItem("amtrustAccountDetails");

    try {
      amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
    } catch (error) { }

    try {
      currProspect = JSON.parse(currProspect);
      sortKeyList = JSON.parse(sortKeyList);

      let domain = sessionStorage.getItem("domain");
      let salesID = sessionStorage.getItem("salesID");

      let user_email_id = domain
        ? domain
        : salespersonCheck && salesID
          ? salesID.toLowerCase()
          : currProspect.companyProfile.companyName &&
            currProspect.companyProfile.companyName.value
            ? currProspect.companyProfile.companyName.value.toLowerCase()
            : "";

      try {
        if (startDate) {
          startDate = moment(startDate).format("YYYY-MM-DD");
        } else {
          throw "invalid date";
        }
      } catch (error) {
        startDate = "";
      }

      let requestBody = {
        user_email_id,
        sortKeyList,
        historicalClaims,
        historicalClaimsCheck,
        nolossGenerated: isNoLossChckd,
        startDate,
        quoteSubmitted: "true",
        submissionDate: moment().format("x"),
      };

      let trackBody = {
        domain: domain,
        email: landingEmail,
        isSubmitted: "true",
      };

      let salesBody = {
        id: salesID?.toLowerCase(),
        isSubmitted: "true",
      };

      requestBody.bindCarrier = bindCarrier;
      salesBody.bindCarrier = bindCarrier;
      trackBody.bindCarrier = bindCarrier;

      if (salespersonCheck) {
        requestBody.salespersonQuoteSubmitted = "true";
        trackBody.isSalespersonSubmitted = "true";
        salesBody.isSalespersonSubmitted = "true";
        sessionStorage.setItem("salespersonQuoteSubmitted", "true");
        this.setState({ salespersonQuoteSubmitted: true });
      } else {
        requestBody.userQuoteSubmitted = "true";
        trackBody.isUserSubmitted = "true";
        salesBody.isUserSubmitted = "true";
      }

      if (amtrustAccountDetails) {
        requestBody.amtrustAccountDetails = amtrustAccountDetails;
        salesBody.amtrustAccountDetails = amtrustAccountDetails;
        trackBody.amtrustAccountDetails = amtrustAccountDetails;
      }

      await axios
        .post(awsUrl2 + "/api/underWriterData/update", requestBody)
        .then(async (res) => {
          if (salespersonCheck && salesID && !landingEmail) {
            await this.updateSalesPerson(salesBody);
          } else {
            await this.updateSubmitUserTracking(trackBody);
          }
          this.props.setQuoteSubmittedStatus(true);
          this.props.setCarrierSubmittedWith(bindCarrier);
          if (bindCarrier === "paygo" && bindOnline) {
            this.props.setAmtrustBoundStatus(true);
          }
          updatePopUpData({
            show: true,
            title: "Thank You for the Opportunity",
            children: (
              <>
                <br></br>
                <span style={{ fontWeight: "bold" }}>
                  Our Team will get back to you in the next 24 Hours.
                </span>
              </>
            ),
            acceptBtn: "Okay",
            disableNoButton: true,
          });
        })
        .catch((error) => {
          console.log("error in API-updateFourthUserStatusTable", error);
        });
      $("#loader").css("display", "none");
    } catch (error) {
      console.log("error while saving claims info", error);
      $("#loader").css("display", "none");
    }
  };

  isPeoRequired = () => {
    let response = true;
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      let companyProfileAddQuestions = currProspect.companyProfileAddQuestions;
      if (
        companyProfileAddQuestions &&
        companyProfileAddQuestions[2].response === "b"
      ) {
        response = false;
      }
    } catch (error) {
      response = true;
    }

    return response;
  };

  isPaygoRequired = () => {
    let response = true;
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      let companyProfileAddQuestions = currProspect.companyProfileAddQuestions;
      if (
        companyProfileAddQuestions &&
        companyProfileAddQuestions[2].response === "a"
      ) {
        response = false;
      }
    } catch (error) {
      response = true;
    }

    return response;
  };

  handleDocUploaded = (value) => {
    this.setState({ docUploaded: value });
  };

  amtrustQuoteBind = async () => {
    //amtrust bind api call
    let response = true;
    let { salespersonCheck } = this.state;
    try {
      let amtrustAccountDetails = sessionStorage.getItem(
        "amtrustAccountDetails"
      );

      amtrustAccountDetails = JSON.parse(amtrustAccountDetails);

      let domain = sessionStorage.getItem("domain");
      let salesID = sessionStorage.getItem("salesID");
      let currProspect = sessionStorage.getItem("currProspect");

      let amtrustTriggerResponse = await axios.post(
        awsUrl2 + `/api/triggerAmtrustBindQuote`,
        JSON.stringify({
          quoteId: amtrustAccountDetails.QuoteId,
          amtrustPaygoEligibility: this.state.payoEligibility,
          user_email_id: domain
            ? domain
            : salespersonCheck && salesID
              ? salesID.toLowerCase()
              : currProspect.companyProfile.companyName &&
                currProspect.companyProfile.companyName.value
                ? currProspect.companyProfile.companyName.value.toLowerCase()
                : "",
          sortKeyList: sessionStorage.getItem("sortKeyList")
            ? JSON.parse(sessionStorage.getItem("sortKeyList"))
            : undefined,
        })
      );

      let uuid;
      if (amtrustTriggerResponse.data.uuid) {
        uuid = amtrustTriggerResponse.data.uuid;
      } else {
        throw "error in amtrust create quote trigger";
      }

      let amtrustQuoteBindResponse;
      do {
        amtrustQuoteBindResponse = await amtrustResponse(
          uuid,
          "getBindQuoteResponse"
        );

        if (amtrustQuoteBindResponse === "error") {
          throw "error in amtrust quote binding";
        } else if (
          amtrustQuoteBindResponse &&
          amtrustQuoteBindResponse !== "processing"
        ) {
          amtrustAccountDetails = {
            ...amtrustAccountDetails,
            BindData: amtrustQuoteBindResponse,
          };
        }
      } while (amtrustQuoteBindResponse === "processing");
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(amtrustAccountDetails)
      );
    } catch (error) {
      console.log(error);
      response = false;
    }
    return response;
  };

  onClickProceedToBind = (carrier, bindOnline) => {
    let { updatePopUpData } = this.props;
    let { salespersonCheck } = this.state;

    try {
      startLoader();
      let mailCampaignUserCheck = checkMailCampaignUser();

      let salespersonMailCampaign = sessionStorage.getItem(
        "salespersonMailCampaign"
      );

      let bindWithAmtrust = carrier === "paygo" && bindOnline;
      updatePopUpData({
        show: true,
        title: "Do you wish to continue?",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              {`${bindWithAmtrust
                  ? "This will bind the quote with"
                  : "This will submit to the UW desk of"
                } ${carrier.toUpperCase()}`}
            </span>
          </>
        ),
        acceptBtn: "Yes",
        acceptBtnCallback: async () => {
          if (bindWithAmtrust) {
            await this.amtrustQuoteBind();
          }
          await this.handleSubmit(
            salespersonCheck,
            mailCampaignUserCheck && !salespersonMailCampaign,
            carrier,
            bindOnline
          );
          stopLoader();
        },
        rejectBtnCallback: () => {
          stopLoader();
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  toastSuccess = (text) =>
    toast.success(text, {
      duration: 3000,
    });

  toastError = (text) =>
    toast.error(text, {
      duration: 3000,
    });

  downloadEligibilityWisePdf = async (quotePdfUuid, finalEligibility) => {
    return new Promise((resolve, reject) => {
      console.log("hello world");
      axios
        .post(awsUrl2 + "/api/downloadQuoteFile", {
          quotePdfUuid,
          finalEligibility,
        })
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          console.log("lossSignedURL", lossSignedURL);
          // let options = { header: { accept: "application/pdf" } };
          axios
            .get(lossSignedURL, { responseType: "blob" })
            .then((result) => {
              const newBlob = new Blob([result.data], {
                type: "application/pdf",
              });
              let url = URL.createObjectURL(newBlob);
              const link = document.createElement("a");

              // create a blobURI pointing to our Blob
              link.href = url;
              let fileName;
              if (finalEligibility === "approved") fileName = "Quote.pdf";
              if (finalEligibility === "declined")
                fileName = "DeclinationQuote.pdf";
              link.download = fileName;

              // some browser needs the anchor to be in the doc
              document.body.append(link);
              link.click();
              link.remove();

              // in case the Blob uses a lot of memory
              setTimeout(() => URL.revokeObjectURL(link.href), 7000);
              $("#loader").css("display", "none");

              resolve();
            })
            .catch((error) => {
              console.log("error in downloading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in downloading quote/declination file  ", error);
          reject();
        });
    });
  };
  downloadEAcord = async () => {
    try {
      let eAcordPdf;
      let currProspect = sessionStorage.getItem("currProspect")
        ? JSON.parse(sessionStorage.getItem("currProspect"))
        : undefined;
      let address = sessionStorage.getItem("address")
        ? JSON.parse(sessionStorage.getItem("address"))
        : undefined;
      let quoteData = sessionStorage.getItem("quoteData")
        ? JSON.parse(sessionStorage.getItem("quoteData"))
        : undefined;

      let agentID = sessionStorage.getItem("agentID");

      let childrenLoc = currProspect.childrenLoc;
      let stateList = [];
      for (let childrenLocIndx in childrenLoc) {
        let childrenLocObj = childrenLoc[childrenLocIndx];
        if (childrenLocObj.state && childrenLocObj.state.value) {
          stateList.push(childrenLocObj.state.value.toLowerCase());
        }
      }
      let isLoggedIn;
      try {
        isLoggedIn = await Auth.currentAuthenticatedUser();
      } catch (error) {
        console.log("Authentication error here", error);
      }

      if (currProspect && address && quoteData) {
        let etQuoteDetails = {
          email: agentID,
          timestamp: quoteData.date,
          carrier: `carrier_ax`,
          mail_address: "",
          address: address,
          state: stateList,
          netRateMap: {},
          agent_name: isLoggedIn?.attributes.name || " ",
          agent_email: isLoggedIn?.attributes.email || " ",
          agent_agency: isLoggedIn?.attributes["custom:agency"] || " ",
          uw_name: isLoggedIn?.attributes["custom:uw"] || " ",
          agency_code: isLoggedIn?.attributes["custom:agency_code"] || ""
        };
        axios
          .post(
            awsUrl2 + "/api/generateEAcord/fl",
            JSON.stringify(etQuoteDetails)
          )
          .then((res) => {
            eAcordPdf = res.data;
            const uint8Array = new Uint8Array(Object.values(eAcordPdf));
            const blob = new Blob([uint8Array], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "editable_acord.pdf";

            // Append link to body and trigger download
            document.body.appendChild(link);
            link.click();

            // Cleanup
            document.body.removeChild(link);
            this.setState({ loadingEAcord: false });
          });
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to download editable Acord. Please try again later.");
    }
  };

  render() {
    let { fileURL, finalEligibility, declineEligibilityStmtList } = this.state;
    let quotePdfUuid = sessionStorage.getItem("quotePdfUuid");

    let zeroYearsInBusiness = false;

    let currProspect,
      yearsInBusiness = 100000;
    try {
      currProspect = sessionStorage.getItem("currProspect")
        ? JSON.parse(sessionStorage.getItem("currProspect"))
        : null;

      if (
        currProspect &&
        currProspect.companyProfile &&
        currProspect.companyProfile.yearsInBusiness &&
        currProspect.companyProfile.yearsInBusiness.value
      ) {
        yearsInBusiness = Number(
          currProspect.companyProfile.yearsInBusiness.value
        );
      }
    } catch (error) {
      console.log("error on indication page : fetching currProspected", error);
    }

    zeroYearsInBusiness = yearsInBusiness === 0;

    return (
      <div id="cmprQts" className="container-fluid per98">
        <NewProgress />
        <div id="compareQuote">
          {finalEligibility && (
            <FubaQuoteDetails
              eligibility={finalEligibility}
              statements={declineEligibilityStmtList}
              callback={() =>
                this.downloadEligibilityWisePdf(quotePdfUuid, finalEligibility)
              }
            />
          )}
          {/* <LoadingOverlay
            active={fileURL ? false : true}
            spinner
            className={isMobile ? "spinner" : ""}
          >
            <div className="acord-text text-left mt-3">
              <b></b>
            </div>
            <div
              className={
                isMobile ? "accord-container-mobile" : "accord-container"
              }
            >
              <div
                className={
                  isMobile ? "accord-toolbar-mobile" : "accord-toolbar"
                }
              >
                <a href={fileURL} download={"Acord"} title={`Download: Acord`}>
                  <img
                    height="30"
                    src={require("../../../images/download-file.png")}
                  ></img>
                  <span style={{ color: "black" }}>
                    {isMobile ? `Download Acord` : ""}
                  </span>
                </a>
              </div>

              {isMobile ? (
                ""
              ) : (
                <iframe
                  id={`iframeAccord`}
                  src={(fileURL || "about:blank") + "#toolbar=0&view=FitH"}
                  style={{ border: "none", height: "800px" }}
                  width="100%"
                ></iframe>
              )}
            </div>
          </LoadingOverlay> */}
          {process.env.REACT_APP_MODE === "dev" && (
            <div className="row no-gutters body-row">
              <div className="d-flex w-100">
                <div className="quote-detailed-data">
                  <h5>Detailed Eligibility Checks</h5>
                  <DetailedEligibilityData
                    detailedData={this.props.eligibilityCheck}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Toaster />
      </div>
    );
  }
}

export default function FormCmprQuoteWrapper(props) {
  return (
    <Context.Consumer>
      {(context) => (
        <FormCmprQuote
          updatePopUpData={props.updatePopUpData}
          quoteSubmitted={context.quoteSubmitted}
          setQuoteSubmittedStatus={context.setQuoteSubmittedStatus}
          carrierSubmittedWith={context.carrierSubmittedWith}
          setCarrierSubmittedWith={context.setCarrierSubmittedWith}
          amtrustBound={context.amtrustBound}
          setAmtrustBoundStatus={context.setAmtrustBoundStatus}
          reCalculateData={context.reCalculateData}
          setReCalculateData={context.setReCalculateData}
          setEligibilityCheck={context.setEligibilityCheck}
          eligibilityCheck={context.eligibilityCheck}
        />
      )}
    </Context.Consumer>
  );
}
