import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home2 from "./component/pages/Home2";
import Home3 from "./component/pages/Home3";
import ProgramDetails from "./component/pages/ProgramDetails";
import NewQuote from "./newcomponent/NewQuote";
import Login from "./component/pages/Login";
import PanelUW from "./component/pages/PanelUW";
import Tabs from "./component/pages/Tabs";
import DashboardSales from "./component/pages/DashboardSales";
import AntiPrivateRoute from "./component/common/AntiPrivateRoute";
import PrivateGroupRoute from "./component/common/PrivateGroupRoute";
import DemoRouteHandler from "./component/pages/DemoRouteHandler";
import ContextProvider from "./contexts/ContextProvider";
import DashboardAdmin from "./component/pages/DashboardAdmin";
import FubaLoginQuote from "./component/common/FubaLoginQuote";
import UWDashboard from "./component/pages/UWDashboard";
import GenerateAcord from "./component/pages/GenerateAcord";
import { Auth } from "aws-amplify";

class App extends Component {
  componentDidMount() {
    this.setTitle();
  }

  // setTitle = () => {
  //   process.env.REACT_APP_MODE === "beta"
  //     ? (document.title = "Underwriter")
  //     : (document.title = "FUBA");
  // };

  setTitle = async () => {
    try {
      let demoUwLogin = false;
      let demoAgentLogin = false;

      // Await the session to get the result
      const res = await Auth.currentSession();

      // Check if the user belongs to the specified groups
      demoUwLogin = res.accessToken.payload["cognito:groups"].includes(
        process.env.REACT_APP_UW_GROUP
      );
      demoAgentLogin = res.accessToken.payload["cognito:groups"].includes(
        process.env.REACT_APP_AGENT_GROUP
      );

      console.log(demoAgentLogin, demoUwLogin, "logins");

      // Set the document title based on conditions
      if (process.env.REACT_APP_MODE === "beta") {
        document.title = demoUwLogin
          ? "Underwriter"
          : demoAgentLogin
          ? "Agent"
          : "Underwriter";
      } else {
        document.title = "FUBA";
      }
    } catch (error) {
      console.log("Authentication error here", error);
    }
  };

  render() {
    return (
      <ContextProvider>
        <Router>
          <Switch>
            <Route path="/demo" component={DemoRouteHandler} />
            <Route exact path="/FubaLogin" component={FubaLoginQuote} />
            <PrivateGroupRoute
              allowedGroup={[
                process.env.REACT_APP_AGENT_GROUP,
                process.env.REACT_APP_ADMIN_GROUP,
              ]}
              exact
              path="/AgentDashboard"
              component={DashboardSales}
            />
            <PrivateGroupRoute
              allowedGroup={[
                process.env.REACT_APP_AGENT_GROUP,
                process.env.REACT_APP_ADMIN_GROUP,
              ]}
              exact
              path="/Quote"
              component={NewQuote}
            />

            {
              <PrivateGroupRoute
                allowedGroup={process.env.REACT_APP_ADMIN_GROUP}
                exact
                path="/AdminDashboard"
                component={DashboardAdmin}
              />
            }
            {
              <PrivateGroupRoute
                allowedGroup={process.env.REACT_APP_UW_GROUP}
                exact
                path="/UWDashboard"
                component={UWDashboard}
              />
            }
            <PrivateGroupRoute
              allowedGroup={[process.env.REACT_APP_AGENT_GROUP]}
              exact
              path="/generate-acord"
              component={GenerateAcord}
            />

            <AntiPrivateRoute path="/" component={Login} />

            {/*
            {/* <PrivateGroupRoute
            allowedGroup={process.env.REACT_APP_CARRIER_GROUP}
            exact
            path="/PanelUW"
            component={PanelUW}
          /> 
          <Route path="/program-details" component={ProgramDetails} />
        <PrivateGroupRoute
              allowedGroup={process.env.REACT_APP_PREUW_GROUP}
              exact
              path="/PanelUW"
              component={PanelUW}
            />
        */}

            {/* <Route path="/PanelUW" component={PanelUW} /> */}

            {/* <Route path="/Home" component={Home3} /> */}
            {/* <Route path="/" component={Home2} /> */}
          </Switch>
        </Router>
      </ContextProvider>
    );
  }
}

export default App;
