import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import GoverningClassCode from "../GovernCC";
import { fubaGoverningCcQues } from "../../../utils/ques_list";
import { Table, Badge } from "react-bootstrap";
import NewProgress from "../../../newcomponent/common/NewProgress";

import {
  agentPersonLoginCheck,
  getUwQuestionsEligibility,
} from "../../../utils/form_ques";

import { adminPersonLoginCheck } from "../../../utils/form_ques";
import { startNewQuote } from "../../../utils/common";
import axios from "axios";
import { awsUrl, awsUrl2 } from "../../../config";
import { Container, Row, Col } from "react-bootstrap";

import {
  getFinalEligibility,
  getDeclinedStatementList,
  resetEligibility,
} from "../../../utils/eligibility_helpers";

import Context from "../../../contexts/Context";
import { Auth } from "aws-amplify";
const uuidv4 = require("uuid/v4");
let isMobile = window.innerWidth <= 1080;

function GoverningCCEligibility(props) {
  const [selectedOption, setSelectedOption] = useState({
    q1: { value: "", ifyes: "", ifNo: "" },
    q2: { value: "", ifyes: "", ifNo: "" },
    q3: { value: "", ifyes: "", ifNo: "" },
  });

  const [descriptionInfo, setDescriptionInfo] = useState({
    value: "",
    label: "",
    status: "",
  });

  const [agentpersonCheck, setAgentpersonCheck] = useState(false);
  const [agentusername, setagentusername] = useState("");
  const [agentpersonName, setagentpersonName] = useState("");
  const [finalEligibility, setFinalEligibility] = useState(false);
  const [declinedStatementList, setDeclinedStatementList] = useState([]);
  const [validationFailed, setValidationFailed] = useState(false);
  const formStage = sessionStorage.getItem("formStage");

  // set the user input data from curr prospect
  useEffect(() => {
    let currProspect = sessionStorage.getItem("currProspect");
    (async () => {
      let {
        agentpersonName,
        agentpersonCheck,
        agentpersonNameAttr,
        agentusername,
      } = await agentPersonLoginCheck();

      if (agentpersonCheck) {
        setAgentpersonCheck(agentpersonCheck);
        setagentusername(agentusername);
        setagentpersonName(agentpersonName);
      }

      // ADMIN PERSON CHECK
      let {
        adminName,
        adminNameAttr,
        adminpersonCheck,
        username,
      } = await adminPersonLoginCheck();
    })();

    (async () => {
      await axios.get(awsUrl2 + "/api/feinAgencyMap")
        .then(response => {
          console.log("FeinApi", response.data);
          sessionStorage.setItem("feinAgencyMap", JSON.stringify(response.data))
          props.setFeinAgencyMap(response.data)
        })
        .catch(error => {
          // Handle error
          console.error('There was a problem with the request:', error);
        });
    })();

    try {
      currProspect = JSON.parse(currProspect);
      if (currProspect?.govClassCodeObject) {
        setDescriptionInfo({
          value: currProspect.govClassCodeObject.govClassCode,
          label: currProspect.govClassCodeObject.govClassCode,
          status: currProspect.govClassCodeObject.govClassCodeEligibility,
        });
        setSelectedOption(currProspect.govClassCodeObject.gccQues_res);
      }
    } catch (err) {
      console.log("error in setting gov cc states", err);
    }
  }, []);

  useEffect(() => {
    // set the final eligibility to the state
    setFinalEligibility(
      getFinalEligibility(props.eligibilityCheck, "governing_cc")
    );

    // Create list of declined statement codes
    (async () => {
      let statements = await getDeclinedStatementList(
        props.eligibilityCheck,
        "governing_cc"
      );
      setDeclinedStatementList(statements);
    })();
  }, [props.eligibilityCheck]);

  // function to define eligibilty check for Governing Class Code & SELECTED QUESTION
  const getGoverningClassCodeEligibility = (status) => {
    let obj = {
      approved: "approved",
      excluded: "declined",
      restricted: "referred",
    };
    return obj[status];
  };

  //eligibilty Map check
  const getQuestionsEligibility = (eligibilityCheck, selectedOptions) => {
    let questionsCheckMapping = {
      q1: "eligibility_check_1_2",
      q2: "eligibility_check_1_3",
      q3: "eligibility_check_1_4",
    };

    for (let questionName in selectedOptions) {
      eligibilityCheck.governing_cc[
        questionsCheckMapping[questionName]
      ].eligibility =
        selectedOptions[questionName].value === "true"
          ? selectedOptions[questionName].ifYes
          : selectedOptions[questionName].ifNo;
    }

    return eligibilityCheck;
  };

  const getFormValidationFailed = () => {
    let response = false;
    try {
      // Validation check logic
      const isSelecteddOptionsInvalid = Object.values(selectedOption).some(
        (option) => !option.value
      );

      if (!descriptionInfo.value || isSelecteddOptionsInvalid) {
        response = true;
      }
    } catch (error) { }

    return response;
  };

  // Handle Submit Button function of insert/update Data
  const handleSubmit = async () => {
    let eligibility_check = JSON.parse(JSON.stringify(props.eligibilityCheck));
    let agentID = sessionStorage.getItem("agentID");
    let dateNow = Math.floor(Date.now()).toString();
    let formValidationFailed = getFormValidationFailed();
    setValidationFailed(formValidationFailed);

    if (!formValidationFailed) {
      // //AGENT PERSON CHECK
      let uuid_sp = uuidv4();

      let govClassCodedescription = descriptionInfo.value;
      let governingClassCodeEligibility = getGoverningClassCodeEligibility(
        descriptionInfo.status
      );
      let dateNow = Math.floor(Date.now()).toString();

      let gcQues_res = selectedOption;
      let govClassCodeObject = {
        updated_timestamp: dateNow,
        govClassCode: govClassCodedescription,
        govClassCodeEligibility: descriptionInfo.status,
        gccQues_res: gcQues_res,
        origin: "Agent",
      };

      try {
        let currProspect = {};
        if (agentpersonCheck) {
          let agentPersonData = {};
          let type = "insert";
          let isLoggedIn;
          let agent_name;
          let agency_name;
          let uw_name;
          let agency_code;
          try {
            isLoggedIn = await Auth.currentAuthenticatedUser();
            agent_name = isLoggedIn?.attributes.name
              ? isLoggedIn?.attributes.name
              : "";
            agency_name = isLoggedIn?.attributes["custom:agency"]
              ? isLoggedIn?.attributes["custom:agency"]
              : "";
            uw_name = isLoggedIn?.attributes["custom:uw"]
              ? isLoggedIn?.attributes["custom:uw"]
              : "";
            agency_code = isLoggedIn?.attributes["custom:agency_code"] || ""
          } catch (error) {
            console.log("Authentication error here", error);
          }

          if (agentID) {
            type = "update";
          } else {
            type = "insert";
            agentID = `fuba_${uuid_sp}_${dateNow}`;
            sessionStorage.setItem("agentID", agentID.toLowerCase());
            agentPersonData["created_timestamp"] = dateNow;
          }
          //reseting the all eligibilties:

          resetEligibility(eligibility_check, []);

          //setting of Eligibilty Check
          eligibility_check["governing_cc"][
            "eligibility_check_1_1"
          ].eligibility = governingClassCodeEligibility;

          eligibility_check = getQuestionsEligibility(
            eligibility_check,
            selectedOption
          );
          props.setEligibilityCheck(eligibility_check);

          currProspect = {
            govClassCodeObject: govClassCodeObject,
            agency_name,
            agent_name,
            uw_name,
            agency_code
          };

          let finalEligibility = getFinalEligibility(
            eligibility_check,
            "governing_cc"
          );
          let formStage = finalEligibility === "declined" ? "gov_cc" : "one";

          agentPersonData = {
            ...agentPersonData,
            currProspect,
            id: agentID.toLowerCase(),
            agentName: agentusername,
            eligibilityCheck: eligibility_check,
            updated_timestamp: dateNow,
            formStage,
            agentEmail: agentpersonName,
          };
          await axios
            .post(
              awsUrl2 + `/api/agentPersonData/${type}`,
              JSON.stringify(agentPersonData)
            )
            .then((res) => {
              sessionStorage.setItem(
                "currProspect",
                JSON.stringify(currProspect)
              );
              sessionStorage.setItem("formStage", formStage);
              window.location.reload();
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
      } catch (error) {
        console.log("Agent person flow error: ", error);
      }
    }
  };

  const setdescriptionHandler = (selectedValue) => {
    setDescriptionInfo(selectedValue);
  };

  //Set the response of all three question
  const handleOptionChange = (event, questionData) => {
    const { value } = event.target;
    const { name, ifYes, ifNo } = questionData;
    setSelectedOption((prevFormData) => ({
      ...prevFormData,
      [name]: { value, ifYes, ifNo },
    }));
  };

  return (
    <>
      <NewProgress />
      <div>
        <h3>
          <b>Eligibility Determination</b>
        </h3>
        <div
          className="container d-flex mt-3 ml-4"
          style={{ padding: "81px 0px 0px 0px" }}
        >
          <div className="row no-gutter" style={{ display: "contents" }}>
            <div className="col-1">
              <div>
                <p>
                  <b>State</b>
                </p>
              </div>
              <div style={{ "margin-top": "-16%" }}>
                <button
                  style={{ "line-height": "1.8" }}
                  className="GCC-state-btn"
                >
                  {" "}
                  FL{" "}
                </button>
              </div>
            </div>
            <div className={isMobile ? "col-9" : "col-8"}>
              <GoverningClassCode
                descriptionInfo={descriptionInfo}
                setdescriptionHandler={setdescriptionHandler}
                id="gov_cc"
                finalEligibility={finalEligibility}
              />
            </div>
          </div>
        </div>
        <Table
          style={{ "margin-top": "60px", width: "80%" }}
          id="govCCTableContainer"
        >
          <tbody>
            {fubaGoverningCcQues.map((data) => {
              const isFilled = selectedOption?.[data.name]?.value !== undefined;
              const noResponse = !isFilled;
              return (
                <tr>
                  <td style={{ "border-top": "0px solid #a7d3ff" }}>
                    <b>{`${data.id}.\t\t${data.ques}`}</b>
                  </td>
                  <td style={{ "border-top": "0px solid #a7d3ff" }}>
                    <Form.Check
                      inline
                      label="Yes"
                      name={`${data.name}-y`}
                      value={true}
                      type="radio"
                      id={`inline-radio-1`}
                      checked={selectedOption?.[data.name]?.value === "true"}
                      onChange={(e) => handleOptionChange(e, data)}
                    />
                  </td>
                  <td style={{ "border-top": "0px solid #a7d3ff" }}>
                    <Form.Check
                      inline
                      label="No"
                      name={`${data.name}-n`}
                      type="radio"
                      value={false}
                      checked={selectedOption?.[data.name]?.value === "false"}
                      id={`inline-radio-2`}
                      onChange={(e) => handleOptionChange(e, data)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {validationFailed && (
          <p style={{ right: "40px", color: "red" }}>
            Please fill all the required fields
          </p>
        )}
        <Container
          className="d-flex justify-content-center align-items-center"
          style={{ height: "15vh" }}
        >
          <button
            type="button"
            onClick={handleSubmit}
            disabled={
              finalEligibility === "declined" ||
              (formStage !== "one" && formStage !== "gov_cc") ||
              !agentpersonCheck
            }
            id="profileSaveButton"
            className="btn mt-0  button_color btn-lg btn-warning-shadow btnSubmits text-center"
          >
            Submit
          </button>
          {finalEligibility === "declined" ? (
            <button
              className="btn ml-4 btn-md btn-success"
              onClick={startNewQuote}
            >
              Refresh
            </button>
          ) : (
            ""
          )}
        </Container>
        {declinedStatementList.length > 0 && (
          <div style={{ display: "flex" }}>
            <div>
              <p>
                <b>Eligibility:</b>
              </p>
            </div>
            <div>
              <ul style={{ listStyleType: "none" }}>
                {declinedStatementList.map((check, index) => (
                  <li key={index} style={{ color: "red" }}>
                    {check}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

// export default GoverningCCEligibility;

export default function FormGovCCWrapper(props) {
  return (
    <Context.Consumer>
      {(context) => (
        <GoverningCCEligibility
          updatePopUpData={props.updatePopUpData}
          quoteSubmitted={context.quoteSubmitted}
          setQuoteSubmittedStatus={context.setQuoteSubmittedStatus}
          setEligibilityCheck={context.setEligibilityCheck}
          eligibilityCheck={context.eligibilityCheck}
          feinAgnecyMap={context.feinAgnecyMap}
          setFeinAgencyMap={context.setFeinAgencyMap}
        />
      )}
    </Context.Consumer>
  );
}
