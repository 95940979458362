import React from "react";
import CustomDropZone from "./CustomDropZone";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../../config";
import LoadingOverlay from "react-loading-overlay";

export default class DropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: this.props.fileList || {},
      uploadingInProgress: false,
      uploadMsg: undefined,
      errMsg: undefined,
      docUploaded: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.fileList) !== JSON.stringify(prevProps.fileList)
    ) {
      this.setState({ fileList: this.props.fileList });
    }
  }

  updateFileList = (newFile) => {
    let { fileList } = this.state;
    if (fileList[newFile.name] === "#empty") {
      this.setState({ errMsg: "File already exist", uploadMsg: undefined });
      return;
    }
    fileList[newFile.name] = newFile;
    this.setState({ fileList, errMsg: undefined, uploadMsg: undefined });
  };

  removeFile = (fileName) => {
    let { fileList } = this.state;
    delete fileList[fileName];
    this.setState({ fileList });
  };

  uploadFile = (fileData, postDataS3) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl + "/api/uploadLossForm", postDataS3)
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          let options = { header: { "Content-Type": postDataS3.fileType } };
          axios
            .put(lossSignedURL, fileData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve();
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploadLossForm ", error);
          reject();
        });
    });
  };

  updateFourthUserCampaignTable = (dataToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/preUwData/updateFileDetails", dataToSend)
        .then((res) => {
          resolve();
        })
        .catch((error) => {
          console.log("error in API-preUwFlow-file_update", error);
          reject();
        });
    });
  };

  updateFourthUserTrackingTable = (dataToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/preUwData/updateTrackingFileDetails", dataToSend)
        .then((res) => {
          resolve();
        })
        .catch((error) => {
          console.log("error in API-preUwFlow-file_update", error);
          reject();
        });
    });
  };

  handleUpload = () => {
    let { updatePopUpData } = this.props;
    let preUwEngagedFlag = sessionStorage.getItem("preUwEngagedFlag");

    updatePopUpData({
      show: true,
      title: "Do you wish to continue?",
      children: (
        <>
          <br></br>
          <span style={{ fontWeight: "bold" }}>
            Files once uploaded cannot be deleted
          </span>
        </>
      ),
      acceptBtn: "Yes",
      acceptBtnCallback: async () => {
        try {
          let { fileList } = this.state;
          this.setState({ uploadingInProgress: true });
          let domain = sessionStorage.getItem("domain");

          if (!domain) return;

          const folder1 = `fourth/${domain}`;
          let dataToSendPromise = [];

          let promiseList = [],
            fileListToStore = [];


          for (let fileName in fileList) {

            let fileType = fileName.split(".").reverse()[0];
            const folder2 = "preUwUpload";

            fileListToStore.push({
              key: `${folder1}/${folder2}/${fileName}`,
              fileType,
              keyToFile: fileName,
            });

            const postDataS3 = {
              email: "john@doe.com",
              folder1,
              folder2,
              fileName,
              fileType,
            };
            if (fileList[fileName] !== "#empty")
              promiseList.push(
                this.uploadFile(fileList[fileName], postDataS3)
              );
          }


          Promise.all(promiseList)
            .then((res) => {
              if (!preUwEngagedFlag) {
                dataToSendPromise.push(
                  this.updateFourthUserCampaignTable({
                    domain: domain,
                    preUwFileToUpload: fileListToStore
                  })
                );
              } else {
                dataToSendPromise.push(
                  this.updateFourthUserTrackingTable({
                    domain: domain,
                    preUwFileToUpload: fileListToStore
                  })
                );
              }

              Promise.all(dataToSendPromise)
                .then((res) => {
                  for (let fileName in fileList) {
                    fileList[fileName] = "#empty";
                  }
                  this.setState({
                    uploadingInProgress: false, docUploaded: true,
                    uploadMsg: "files uploaded",
                    errMsg: undefined,
                    fileList
                  });
                })
                .catch((error) => console.log("error in API", error));
            })
            .catch((error) => {
              console.log("error: ", error);
              this.setState({ errMsg: "Error", uploadMsg: undefined, uploadingInProgress: false });
            });
        } catch (error) {
          console.log("error: ", error);
          this.setState({ errMsg: "Error", uploadMsg: undefined, uploadingInProgress: false });
        }
      },
    });
  };

  render() {
    let { fileList, uploadingInProgress, uploadMsg, errMsg, docUploaded } = this.state;
    let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;
    return (
      <LoadingOverlay active={uploadingInProgress} spinner>
        <div className="dropZonesContainer">
          <div className="row mb-3">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <CustomDropZone
                name="first"
                fileList={fileList}
                updateFileList={this.updateFileList}
                removeFile={this.removeFile}
                uploadMsg={uploadMsg}
              />
            </div>
          </div>
          <div className="d-flex flex-column">
            <pre
              className={
                "text-center text-capitalize " +
                (uploadMsg ? "text-success" : "text-danger")
              }
            >
              {uploadMsg || errMsg}
            </pre>
            <button
              className="btn btn-warning btn-sm"
              style={{
                width: "min-content",
                color: "white",
                alignSelf: "center",
                backgroundColor: "#feae01",
                boxShadow: "0px 5px 12px grey",
              }}
              onClick={(event) => {
                if (hideButtons) return;
                event.preventDefault();
                this.handleUpload();
              }}
              disabled={uploadingInProgress}
            >
              Upload
            </button>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
