import React from "react";
import { awsUrl2 } from "../../config";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import LoadingOverlay from "react-loading-overlay";
let deletImg = require("../../images/trash.png");
let downloadIcon = require("../../images/downloadIcon.svg");
export class DownloadDashboardFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardFiles: this.props.dashboardFiles || [],
      // preUwFiles: this.props.preUwFiles || [],
      fileListToStore: this.props.fileListToStore || [],
      uwDashboardFiles: this.props.uwDashboardFiles || [],
      downloadInProgress: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      dashboardFiles: nextProps.dashboardFiles || [],
      // preUwFiles: nextProps.preUwFiles || [],
      fileListToStore: nextProps.fileListToStore || [],
      uwDashboardFiles: nextProps.uwDashboardFiles || [],
    });
  }

  downloadFile = async (file) => {
    try {
      this.setState({ downloadInProgress: true });
      await axios
        .post(
          awsUrl2 + "/api/dashboardFileDownload",
          JSON.stringify({
            fileName: file,
          })
        )
        .then(async (res) => {
          let downloadURL = res.data;

          let a = document.createElement("a");
          a.download = file.split("/").reverse()[0];
          a.href = downloadURL;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.setState({ downloadInProgress: false });
        });
    } catch (error) {
      this.setState({ downloadInProgress: false });
    }
  };

  downloadAllFiles = async (e) => {
    e.preventDefault();
    try {
      this.setState({ downloadInProgress: true });
      let { dashboardFiles, preUwFiles, fileListToStore } = this.state;
      var zip = new JSZip();
      const folder = zip.folder("files");
      for (let file of dashboardFiles) {
        await axios
          .post(
            awsUrl2 + "/api/dashboardFileDownload",
            JSON.stringify({
              fileName: file,
            })
          )
          .then(async (res) => {
            let downloadURL = res.data;
            let responseFileData = await axios.get(downloadURL, {
              responseType: "blob",
            });

            folder.file(file.split("/").reverse()[0], responseFileData.data);
          });
      }

      for (let file of fileListToStore) {
        console.log("file: ", file);

        await axios
          .post(
            awsUrl2 + "/api/uWDashboardFileDownload",
            JSON.stringify({
              fileName: file["key"],
            })
          )
          .then(async (res) => {
            let downloadURL = res.data;
            let responseFileData = await axios.get(downloadURL, {
              responseType: "blob",
            });

            folder.file(
              file["key"].split("/").reverse()[0],
              responseFileData.data
            );
          });
      }
      zip
        .generateAsync({ type: "blob" })
        .then((blob) => {
          saveAs(blob, "InsurecompFiles.zip");
          this.setState({ downloadInProgress: false });
        })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log("erorr: ", error);
      this.setState({ downloadInProgress: false });
    }
  };

  downloadUwFiles = async (e) => {
    e.preventDefault();
    try {
      this.setState({ downloadInProgress: true });
      let { uwDashboardFiles } = this.state;
      var zip = new JSZip();
      const folder = zip.folder("files");
      for (let file of uwDashboardFiles) {
        await axios
          .post(
            awsUrl2 + "/api/dashboardFileDownload",
            JSON.stringify({
              fileName: file,
            })
          )
          .then(async (res) => {
            let downloadURL = res.data;
            let responseFileData = await axios.get(downloadURL, {
              responseType: "blob",
            });
            folder.file(file.split("/").reverse()[0], responseFileData.data);
          });
      }
      zip
        .generateAsync({ type: "blob" })
        .then((blob) => {
          saveAs(blob, "InsurecompFiles.zip");
          this.setState({ downloadInProgress: false });
        })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log("erorr: ", error);
      this.setState({ downloadInProgress: false });
    }
  };

  //function to download files from fourth specific bucket
  downloadFileFourth = async (file) => {
    try {
      this.setState({ downloadInProgress: true });
      await axios
        .post(
          awsUrl2 + "/api/uWDashboardFileDownload",
          JSON.stringify({
            fileName: file["key"],
          })
        )
        .then(async (res) => {
          let downloadURL = res.data;

          let a = document.createElement("a");
          a.download = file["key"].split("/").reverse()[0];
          a.href = downloadURL;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.setState({ downloadInProgress: false });
        });
    } catch (error) {
      this.setState({ downloadInProgress: false });
    }
  };
  deleteFile = async (indx, key, tabName) => {
    try {
      let { dashboardFiles } = JSON.parse(JSON.stringify(this.state));
      this.setState({ downloadInProgress: true });
      let removedFile = dashboardFiles?.[indx];
      dashboardFiles.splice(indx, 1);
      //api call to update fileList in DB.
      await axios
        .post(awsUrl2 + "/api/deleteFilefromS3", JSON.stringify({ key: key }))
        .then(async (res) => {
          await axios
            .post(
              awsUrl2 + `/api/updateSolveTrackingDataGeneric/generic`,
              JSON.stringify({
                id: this.props.domainName,
                dashboardFiles: dashboardFiles,
              })
            )
            .then((res) => {
              if (res.status == 200) {
                // Update the condition to check res.data instead of just res

                this.props.updateDomainMessageidFiles(
                  this.props.domainName,
                  "dashboardFiles",
                  dashboardFiles
                );
              }

              this.setState({ dashboardFiles, downloadInProgress: false });
            });
        })
        .catch((err) => {
          console.log("error in delete file from S3 API", err); // Log the error object
        });
    } catch (error) {
      console.log("error in deleteFile:", error);
    } finally {
      this.setState({ downloadInProgress: false });
    }
  };

  // delete the uw dashboard file
  uwDeleteFile = async (indx, key) => {
    try {
      let { uwDashboardFiles } = JSON.parse(JSON.stringify(this.state));
      this.setState({ downloadInProgress: true });
      let removedFile = uwDashboardFiles?.[indx];
      uwDashboardFiles.splice(indx, 1);
      //api call to update fileList in DB.
      await axios
        .post(awsUrl2 + "/api/deleteFilefromS3", JSON.stringify({ key: key }))
        .then(async (res) => {
          await axios
            .post(
              awsUrl2 + `/api/updateSolveTrackingDataGeneric/generic`,
              JSON.stringify({
                id: this.props.domainName,
                uwDashboardFiles: uwDashboardFiles,
              })
            )
            .then((res) => {
              if (res.status == 200) {
                // Update the condition to check res.data instead of just res

                this.props.updateDomainMessageidFiles(
                  this.props.domainName,
                  "uwDashboardFiles",
                  uwDashboardFiles
                );
              }

              this.setState({ uwDashboardFiles, downloadInProgress: false });
            });
        })
        .catch((err) => {
          console.log("error in delete file from S3 API", err); // Log the error object
        });
    } catch (error) {
      console.log("error in deleteFile:", error);
    } finally {
      this.setState({ downloadInProgress: false });
    }
  };

  deleteOnlyUWFile = async (indx, key) => {
    try {
      let { fileListToStore } = JSON.parse(JSON.stringify(this.state));
      this.setState({ downloadInProgress: true });
      let removedFile = fileListToStore?.[indx];
      fileListToStore.splice(indx, 1);
      //api call to update fileList in DB.
      await axios
        .post(
          awsUrl2 + "/api/deleteFilefromS3",
          JSON.stringify({ key: key["key"] })
        )
        .then(async (res) => {
          await axios
            .post(
              awsUrl2 + `/api/updateSolveTrackingDataGeneric/generic`,
              JSON.stringify({
                id: this.props.domainName,
                fileListToStore: fileListToStore,
              })
            )
            .then((res) => {
              if (res.status == 200) {
                // Update the condition to check res.data instead of just res

                this.props.updateDomainMessageidFiles(
                  this.props.domainName,
                  "fileListToStore",
                  fileListToStore
                );
              }

              this.setState({ fileListToStore, downloadInProgress: false });
            });
        })
        .catch((err) => {
          console.log("error in delete file from S3 API", err); // Log the error object
        });
    } catch (error) {
      console.log("error in deleteFile:", error);
    } finally {
      this.setState({ downloadInProgress: false });
    }
  };

  render() {
    let {
      preUwFiles,
      fileListToStore,
      dashboardFiles,
      uwDashboardFiles,
      downloadInProgress,
    } = this.state;

    let filesTotal =
      (fileListToStore?.length || 0) +
      (dashboardFiles?.length || 0) +
      (uwDashboardFiles?.length || 0);

    return (
      <>
        {filesTotal > 0 ? (
          <div className="download-files">
            <div className="upload-heading my-3 mx-auto text-center">
              <h3 className="m-0">Download Files</h3>
            </div>
            <LoadingOverlay active={downloadInProgress} spinner>
              <div className="my-0 mx-auto d-flex justify-content-center">
                {filesTotal > 0 && (
                  <>
                    <table style={{ width: "50%", marginInline: "auto" }}>
                      <thead>
                        <th
                          className=" fw-bold  text-decoration-underline"
                          style={{ textAlign: "center" }}
                        >
                          <b style={{ textDecoration: "underline" }}>Agent</b>
                          <img
                            className="ml-3 mr-3"
                            style={{ cursor: "pointer" }}
                            src={downloadIcon}
                            alt="downloadIcon"
                            onClick={(e) => this.downloadAllFiles(e)}
                          />
                        </th>
                      </thead>
                      <tbody>
                        {dashboardFiles?.length > 0 &&
                          dashboardFiles.map((file, indx) => {
                            return (
                              <tr>
                                <td>
                                  <button
                                    className="btn btn-link"
                                    onClick={() => this.downloadFile(file)}
                                  >
                                    {file.split("/").pop()}
                                  </button>
                                </td>
                                {
                                  <td>
                                    <button
                                      className="btn btn-link"
                                      onClick={() =>
                                        this.deleteFile(indx, file)
                                      }
                                    >
                                      <img
                                        src={deletImg}
                                        alt="delete button"
                                        style={{
                                          width: "25px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </button>
                                  </td>
                                }
                              </tr>
                            );
                          })}
                        {fileListToStore?.length > 0 &&
                          fileListToStore.map((file, indx) => {
                            return (
                              <tr>
                                <td>
                                  <button
                                    className="btn btn-link"
                                    onClick={() =>
                                      this.downloadFileFourth(file)
                                    }
                                  >
                                    {file["key"].split("/").pop()}
                                  </button>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-link"
                                    onClick={() =>
                                      this.deleteOnlyUWFile(indx, file)
                                    }
                                  >
                                    <img
                                      src={deletImg}
                                      alt="delete button"
                                      style={{
                                        width: "25px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <table style={{ width: "50%", marginInline: "auto" }}>
                      <thead>
                        <th style={{ textAlign: "center" }}>
                          <b style={{ textDecoration: "underline" }}>
                            Underwriter
                          </b>
                          <img
                            className="ml-3 mr-3 mb"
                            style={{ cursor: "pointer", marginBottom: "5px" }}
                            src={downloadIcon}
                            alt="downloadIcon"
                            onClick={(e) => this.downloadUwFiles(e)}
                          />
                        </th>
                      </thead>
                      <tbody>
                        {uwDashboardFiles?.length > 0 &&
                          uwDashboardFiles.map((file, indx) => {
                            return (
                              <tr>
                                <td>
                                  <button
                                    className="btn btn-link"
                                    onClick={() => this.downloadFile(file)}
                                  >
                                    {file.split("/").pop()}
                                  </button>
                                </td>
                                {
                                  <td>
                                    <button
                                      className="btn btn-link"
                                      onClick={() =>
                                        this.uwDeleteFile(indx, file)
                                      }
                                    >
                                      <img
                                        src={deletImg}
                                        alt="delete button"
                                        style={{ width: "25px" }}
                                      />
                                    </button>
                                  </td>
                                }
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </LoadingOverlay>
          </div>
        ) : (
          <div className="upload-heading my-3 mx-auto text-center">
            <h3>No Files Uploaded!</h3>
          </div>
        )}
      </>
    );
  }
}
