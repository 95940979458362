import React from "react";
import CustomDropZone from "./CustomDropZone";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../config";
import LoadingOverlay from "react-loading-overlay";

const headingMap = {
  first: "Upload Loss Runs",
  second: "Latest Signed No Loss Letter, Loss Runs, and Other Documents",
};

const folderMap = {
  first: "LossRun",
  second: "OtherDocuments",
};

export default class DropZones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: this.props.fileList,
      uploadingInProgress: false,
      uploadMsg: undefined,
      errMsg: undefined,
      docUploaded: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.fileList) !== JSON.stringify(prevProps.fileList)
    ) {
      this.setState({ fileList: this.props.fileList });
    }
  }

  updateFileList = (name, newFile) => {
    let { fileList } = this.state;
    if (fileList[name][newFile.name] === "#empty") {
      this.setState({ errMsg: "File already exist", uploadMsg: undefined });
      return;
    }
    fileList[name][newFile.name] = newFile;
    this.setState({ fileList, errMsg: undefined, uploadMsg: undefined });
  };

  removeFile = (name, fileName) => {
    let { fileList } = this.state;
    delete fileList[name][fileName];
    this.setState({ fileList });
  };

  uploadFile = (fileData, postDataS3) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl + "/api/uploadLossForm", postDataS3)
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          let options = { header: { "Content-Type": postDataS3.fileType } };
          axios
            .put(lossSignedURL, fileData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve();
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploadLossForm ", error);
          reject();
        });
    });
  };

  updateAgentTable = (dataToSend) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          awsUrl2 + `/api/agentPersonData/update`,
          JSON.stringify({
            id: dataToSend.agentID,
            fileListToStore: dataToSend.fileListToStore,
          })
        )
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          reject();
        });
    });
  };

  handleUpload = () => {
    let { updatePopUpData } = this.props;
    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );

    updatePopUpData({
      show: true,
      title: "Do you wish to continue?",
      children: (
        <>
          <br></br>
          <span style={{ fontWeight: "bold" }}>
            Files once uploaded cannot be deleted
          </span>
        </>
      ),
      acceptBtn: "Yes",
      acceptBtnCallback: async () => {
        try {
          let { fileList } = this.state;
          if (Object.keys(fileList["second"]).length !== 0) {
            let fileListToStore = [];
            this.setState({ uploadingInProgress: true });
            let agentID = JSON.parse(
              JSON.stringify(sessionStorage.getItem("agentID"))
            );

            let primay_key = agentID;

            let email = primay_key;
            let email2 = email
              .split(" ")
              .join("")
              .toLowerCase();
            const folder1 = `${email2}`;
            let dataToSendPromise = [];

            let promiseList = [];
            for (let key in fileList) {
              if (Object.keys(fileList[key]).length > 0) {
                for (let fileName in fileList[key]) {
                  let fileType = fileName.split(".").reverse()[0];
                  const folder2 = folderMap[key];
                  fileListToStore.push({
                    key: `${folder1}/${folder2}/${fileName}`,
                    fileType,
                    keyToFile: key,
                  });

                  const postDataS3 = {
                    email,
                    folder1,
                    folder2,
                    fileName,
                    fileType,
                  };
                  if (fileList[key][fileName] !== "#empty")
                    promiseList.push(
                      this.uploadFile(fileList[key][fileName], postDataS3)
                    );
                }
              }
            }

            Promise.all(promiseList)
              .then((res) => {
                if (agentID) {
                  dataToSendPromise.push(
                    this.updateAgentTable({
                      agentID,
                      fileListToStore,
                    }).catch((err) =>
                      console.log("can't update user tracking data")
                    )
                  );
                }
                Promise.all(dataToSendPromise)
                  .then((res) => {
                    for (let key in fileList) {
                      if (fileList[key]) {
                        for (let fileName in fileList[key])
                          fileList[key][fileName] = "#empty";
                      }
                    }
                    this.setState({
                      uploadingInProgress: false,
                      docUploaded: true,
                      fileList,
                      uploadMsg: "files uploaded",
                      errMsg: undefined,
                    });
                    this.props.handleFileListToStoreSetter(fileListToStore);
                    sessionStorage.setItem(
                      "fileListToStore",
                      JSON.stringify(fileListToStore)
                    );
                  })
                  .catch((error) => console.log("error in API", error));
              })
              .catch((error) => {
                console.log("error: ", error);
                this.setState({ uploadingInProgress: false });
                this.setState({ errMsg: "Error", uploadMsg: undefined });
              });
          } else {
            this.setState({
              errMsg: "No files uploaded",
            });
          }
        } catch (error) {
          console.log("error: ", error);
        }
      },
    });
  };

  render() {
    let {
      fileList,
      uploadingInProgress,
      uploadMsg,
      errMsg,
      docUploaded,
    } = this.state;
    let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;
    return (
      <LoadingOverlay active={uploadingInProgress} spinner>
        <div className="dropZonesContainer">
          <div className="row mb-3">
            {/* <div className="col-sm-12 col-md-12 col-lg-6 mb-3">
              <CustomDropZone
                name="first"
                fileList={fileList}
                updateFileList={this.updateFileList}
                removeFile={this.removeFile}
                headingMap={headingMap}
                uploadMsg={uploadMsg}
              />
            </div> */}
            <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
              <CustomDropZone
                name="second"
                fileList={fileList}
                updateFileList={this.updateFileList}
                removeFile={this.removeFile}
                headingMap={headingMap}
                uploadMsg={uploadMsg}
              />
              <p>
                Note: Loss runs valued within the last 30 days prior to the
                proposed effective date must be provided in order to bind this
                risk.
              </p>
            </div>
          </div>
          <div className="d-flex flex-column">
            <pre
              className={
                "text-center text-capitalize " +
                (uploadMsg ? "text-success" : "text-danger")
              }
            >
              {uploadMsg || errMsg}
            </pre>
            <button
              className="btn btn-warning btn-sm"
              style={{
                width: "min-content",
                color: "white",
                alignSelf: "center",
                backgroundColor: "#feae01",
                boxShadow: "0px 5px 12px grey",
              }}
              onClick={(event) => {
                if (hideButtons) return;
                event.preventDefault();
                this.handleUpload();
              }}
              disabled={
                !this.props.agentpersonCheck ||
                uploadingInProgress ||
                hideButtons
              }
            >
              Upload
            </button>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}
