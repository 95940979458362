"use strict";
import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import UnderWriterTab from "./UnderWriterTab";

const UnderWriterFlow = () => {
  const [selected, setSelected] = useState("tab1");
  const [dealMap, setDealMap] = useState({});

  const tabsMap = {
    tab1: "PEO",
    tab2: "PAYGO",
  };

  const updateDealMap = (key, value) => {
    let dealMapCopy = JSON.parse(JSON.stringify(dealMap));
    dealMapCopy[key] = value;
    setDealMap(dealMapCopy);
  }

  return (
    <div className={"Submission-uw-dashboard"} style={{ width: "100%" }}>
      <Nav
        variant="pills"
        defaultActiveKey={selected}
        style={{ fontSize: "16px", margin: "5px", fontFamily: "Montserrat" }}
      >
        {Object.keys(tabsMap).map((tab) => (
          <Nav.Item>
            <Nav.Link eventKey={tab} onClick={() => setSelected(tab)}>
              {tabsMap[tab]}
            </Nav.Link>
          </Nav.Item>
        ))}
        <h1
          className="mt-1 mb-1 d-flex"
          style={{
            textAlign: "center",
            margin: "0px auto",
            fontFamily: "Montserrat",
          }}
        >
          Underwriter Dashboard
        </h1>
      </Nav>
      {Object.keys(tabsMap).map((tab) => (
        <div key={tab}>
          {<UnderWriterTab tabName={tab} selected={selected} dealMap={dealMap} updateDealMap={updateDealMap} />}
        </div>
      ))}
    </div>
  );
};

export default UnderWriterFlow;
