import { style } from "d3";
import React from "react";
import { Row, Col, Carousel } from "react-bootstrap";
import styles from "./QuotePageBanner.module.scss";
let calendarImage = require("../../../images/calendar.svg");
let clipboardImage = require("../../../images/clipboard.svg");
let constructionImage = require("../../../images/construction-and-tools.svg");
let humanImage = require("../../../images/human.svg");
let notesImage = require("../../../images/notes.svg");
let toDoImage = require("../../../images/to-do-list.svg");
let payrollImage = require("../../../images/caro_img_1.jpeg");
let bmImage = require("../../../images/caro_img_6.webp");
let complianceImage = require("../../../images/caro_img_4.webp");
let hrImage = require("../../../images/caro_img_2.webp");
let rmImage = require("../../../images/caro_img_5.webp");
let tAndAImage = require("../../../images/caro_img_3.jpeg");
let firstSlideImage = require("../../../images/caro_img_1.jpeg");

export default function QuotePageBanner() {
  const services = [
    {
      name: "Employee Benefit Programs",
      content: (
        <>
          <h5>Employee Benefit Programs</h5>
          <div style={{ textAlign: "left" }}>
            Our programs allow you to reduce costs while still providing
            employees with quality benefits. We’ll work with you to define the
            plans that work best for you and your team.
          </div>
        </>
      ),
      icon: notesImage,
      image: payrollImage,
    },
    {
      name: "Employee Discount",
      icon: humanImage,
      image: hrImage,
      content: (
        <>
          <h5>Employee Discount</h5>
          <div style={{ textAlign: "left" }}>
            {`    ${
              process.env.REACT_APP_MODE == "beta"
                ? "We"
                : "With LifeMart&reg; you"
            } can give employees easy access to discounts
            of up to 40% on more than five million products and services.`}
          </div>
        </>
      ),
    },
    {
      name: "Unemployment Claims Administration",
      icon: calendarImage,
      image: tAndAImage,
      content: (
        <>
          <h5>Unemployment Claims Administration</h5>
          <ul>
            <li>Consulting on employee problems as they arise</li>
            <li>Claims Administration</li>
            <li>Appeals Representation</li>
            <li>Auditing</li>
            <li>Education and Training</li>
          </ul>
        </>
      ),
    },
    {
      name: "Compliance and Healthcare Reform Consulting",
      icon: toDoImage,
      image: complianceImage,
      content: (
        <>
          <h5>Compliance and Healthcare Reform Consulting</h5>
          <ul>
            <li>
              Our experts help you understand the true implications of
              healthcare reform, so you stay compliant.
            </li>
            <li>
              This includes, but is not limited to, changes surrounding The
              Affordable Care Act, or ACA
            </li>
          </ul>
        </>
      ),
    },
    {
      name: "Worker's Compensation Risk Management",
      icon: constructionImage,
      image: rmImage,
      content: (
        <>
          <h5>Workers’ Compensation &amp; Risk Management</h5>
          <ul>
            <li>Injury loss analysis</li>
            <li>Risk control action plans to improve safety</li>
            <li>Engineering controls training programs to mitigate risk</li>
            <li>Management safety</li>
            <li>Safety programs and employee training</li>
          </ul>
        </>
      ),
    },
    {
      name: "Employee Benefit Administration",
      icon: clipboardImage,
      image: bmImage,
      content: (
        <>
          <h5>Employee Benefit Administration</h5>
          <div style={{ textAlign: "left" }}>
            We offer a Competitive Benefits Package without the Administrative
            Burden.
          </div>
          <ul>
            <li>Provide Benefits, Reduce Costs</li>
            <li>Reduce Administrivia</li>
            <li>Stay Compliant</li>
          </ul>
        </>
      ),
    },
  ];
  return (
    <Row noGutters className={styles.statsBannerContainer}>
      <Col className={styles.bannerColumn}>
        <div className={styles.colContainer}>
          <div>
            Helping{" "}
            <span className={styles.orangeText + " " + styles.boldText}>
              hundreds
            </span>{" "}
            of business owners control their employee-related costs and
            liabilities for{" "}
            <span className={styles.orangeText + " " + styles.boldText}>
              over 15 years.
            </span>
          </div>
          <br />
          <div>
            Our combined team provides a unique{" "}
            <span className={styles.orangeText + " " + styles.italicText}>
              combination of technology and services to restaurants and
              hospitality
            </span>{" "}
            businesses across the country
          </div>
        </div>
      </Col>
      <Col className={styles.sliderColumn}>
        <Carousel
        // data-interval="false"
        // data-ride="carousel"
        >
          {services.map((service, index) => (
            <Carousel.Item key={index}>
              <img
                className={styles.carouselImage}
                src={service.image}
                alt="First slide"
              />
              <Carousel.Caption className={`${styles.caption} pl-5 pr-5`}>
                {service.content}
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </Col>
    </Row>
  );
}
