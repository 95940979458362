import React, { Component } from "react";
import axios from "axios";
import { awsAcordUpload } from "../../config";
import { acordQues, extraQues } from "../../utils/ques_list";
import moment from "moment";
import SmallLoader from "../../component/common/SmallLoader";
import { color } from "d3";

class UploadFormAcord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadingFile: false,
      awaitingFile: false,
      parseError: false,
      parseMesssage: "",
      urlSuccess: false,
      signedUrl: "",
    };
  }

  createFormFieldData = (data) => {
    try {
      let includeExcludeList = [];
      let entityTypeMap = {
        llc: "LLC",
        corporation: "Corporation",
        partnership: "Partnership",
        sole_proprietor: "Sole Proprietor",
      };

      // remove the default responses from the first 24 acord questions
      let uw_questions = acordQues;
      try {
        for (let i = 0; i < 24; i++) {
          delete uw_questions[i].response;
        }
      } catch (error) {}

      let { childrenLoc: childrenLocProps } = JSON.parse(
        JSON.stringify(this.props)
      );
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));

      // prepare the included excluded details
      try {
        for (let item of data?.included_excluded_details || []) {
          if (
            item?.NAME ||
            item?.["INC/EXC"] ||
            item?.CLASSCODE ||
            item?.OWNERSHIP
          ) {
            includeExcludeList.push({
              classCode: { error: "", value: item?.CLASSCODE || "" },
              includExclude: { error: "", value: item?.["INC/EXC"] || "" },
              officerName: { error: "", value: item?.NAME || "" },
              ownerShip: { error: "", value: item?.OWNERSHIP || "" },
            });
          }
        }
      } catch (error) {
        includeExcludeList = [];
      }

      // prepare the mailing address
      let street2, state2, zipCode2, city2;
      try {
        if (data?.basic_information?.mailing_address?.location_details) {
          street2 =
            data?.basic_information.mailing_address.location_details?.address ||
            "";
          state2 =
            data?.basic_information.mailing_address.location_details?.state ||
            "";
          zipCode2 =
            data?.basic_information.mailing_address.location_details?.pincode ||
            "";
          city2 =
            data?.basic_information.mailing_address.location_details?.city ||
            "";
        } else if (data?.basic_information?.mailing_address?.address) {
          street2 = data?.basic_information.mailing_address.address;
        }
      } catch (error) {}

      // prepare the primary address
      let street1, state1, zipCode1, city1;
      try {
        if (data?.basic_information?.primary_address?.location_details) {
          street1 =
            data?.basic_information.primary_address.location_details?.address ||
            "";
          state1 =
            data?.basic_information.primary_address.location_details?.state ||
            "";
          zipCode1 =
            data?.basic_information.primary_address.location_details?.pincode ||
            "";
          city1 =
            data?.basic_information.primary_address.location_details?.city ||
            "";
        } else if (data?.basic_information?.primary_address?.address) {
          street1 = data?.basic_information.primary_address.address;
        }
      } catch (error) {}

      // prepare the company profile
      let phone_number =
        data?.basic_information.phone_number ||
        data?.basic_information.mobile_number;

      let extracted_number = phone_number?.trim().match(/\d+/g)?.join("");

      let companyProfile = {
        lastName: {
          value: data?.basic_information.contact_information?.last_name || "",
        },
        effectiveDate: {
          value: new moment(data?.basic_information.eff_date || ""),
        },
        cityName1: { value: "" },
        zipCode1: { value: "" },
        cityName2: { value: city2 || "" },
        zipCode2: { value: zipCode2 || "" },
        entityType: {
          value: entityTypeMap[data?.basic_information?.entity_type] || "",
        },
        companyName: { value: data?.basic_information.company_name || "" },
        fein: { value: data?.basic_information.fein || "" },
        state2: { value: state2 || "" },
        state1: { value: "" },
        yearsInBusiness: {
          value: data?.basic_information.years_in_business || "",
        },
        firstName: {
          value:
            data?.basic_information.contact_information?.first_name +
              " " +
              data?.basic_information.contact_information?.last_name || "",
        },
        phoneNumber: {
          value: extracted_number || "",
        },
        descriptionOfOperations: { value: data?.basic_information.desc || "" },
        street1: { value: "" },
        street2: { value: street2 || "" },
        emailId: {},
        empLiabilityLimit: { value: data?.basic_information.el_limits || "" },
      };

      if (state1?.toLowerCase() === "fl") {
        companyProfile = {
          ...companyProfile,
          cityName1: { value: city1 || "" },
          zipCode1: { value: zipCode1 || "" },
          state1: { value: state1 || "" },
          street1: { value: street1 || "" },
        };
      }

      // prepare children loc
      let locationClassCodeCurrentIndex = {};
      let childrenLoc = {};
      let governingClassCode;
      try {
        childrenLoc = JSON.parse(JSON.stringify(childrenLocProps));
        childrenLoc[0].classCodesInfo = {
          0: childrenLocProps[0].classCodesInfo[0],
        };
        locationClassCodeCurrentIndex[0] = 0;
        governingClassCode =
          childrenLocProps[0].classCodesInfo[0]?.classCodeDescription?.value?.split(
            ":"
          )[0];
      } catch (error) {}

      for (let ccIndex in data.state_details) {
        let state = data.state_details[ccIndex].location_details?.state;

        if (state && state?.toLowerCase() !== "fl") continue;
        // let locNumber = data.state_details[ccIndex].LOC || Number(ccIndex) + 1;
        let locNumber = 0;
        let payroll;

        try {
          let remuneration = data.state_details[ccIndex].REMUNERATION;

          let isNumber = !isNaN(
            remuneration?.trim()?.replace("$", "")?.replace(",", "")
          );
          if (isNumber) {
            payroll = remuneration?.trim()?.replace("$", "");
          }
        } catch (error) {}

        // check if the class code from acord is the same as the governing class code
        try {
          if (data.state_details[ccIndex].CLASS === governingClassCode) {
            childrenLoc[locNumber].classCodesInfo[0] = {
              ...childrenLoc[locNumber].classCodesInfo[0],
              payroll: {
                value: payroll ? "$" + payroll : "$ ",
              },
              pt: {
                value: data.state_details[ccIndex].PART || "",
              },
              ft: {
                value: data.state_details[ccIndex].FULL || "",
              },
              fromAcord: true,
            };
            continue;
          }
        } catch (error) {}

        // initialize/update the current index for the class code for this location
        if (!(locNumber in locationClassCodeCurrentIndex)) {
          locationClassCodeCurrentIndex[locNumber] = -1;
        }
        locationClassCodeCurrentIndex[locNumber]++;

        if (!childrenLoc[locNumber]) {
          let cityName = "";
          let street = "";
          try {
            cityName = data.state_details[ccIndex].location_details?.city;
            street = data.state_details[ccIndex].location_details?.address;
          } catch (error) {}
          childrenLoc[locNumber] = {
            number: locNumber,
            zipCode: {
              value:
                data.state_details[ccIndex].location_details?.pincode || "",
            },
            cityName: {
              value: cityName || "",
            },
            street: {
              value: street || "",
            },
            state: {
              value: state || "",
            },
            classCodesInfo: { 0: {} },
          };
        }
        childrenLoc[locNumber].classCodesInfo[
          locationClassCodeCurrentIndex[locNumber]
        ] = {
          classCodeDescription: {
            error: false,
            value:
              data.state_details[ccIndex].CLASS +
                ": " +
                data.state_details[ccIndex].DESCR || " ",
          },
          payroll: {
            value: payroll ? "$" + payroll : "$ ",
          },
          pt: {
            value: data.state_details[ccIndex].PART || "",
          },
          ft: {
            value: data.state_details[ccIndex].FULL || "",
          },
          fromAcord: true,
        };
      }

      // prepare the uw questions
      let response_received = false;
      let globalIndex = 0;
      try {
        for (let localIndex in data.uw_questions) {
          let question = data.uw_questions[localIndex];
          let response =
            question.response?.includes("Y") || question.yes
              ? true
              : question.response?.includes("N") || question.no
              ? false
              : undefined;
          if (response !== undefined) {
            response_received = true;
            uw_questions[globalIndex].response = response;

            if (
              uw_questions[globalIndex].childrenQues &&
              uw_questions[globalIndex]?.type !== "input"
            ) {
              // add the hidden questions based on the responses
              let extraQuestionsToAdd = response
                ? uw_questions[globalIndex]["childrenQues"]["ifYes"] || []
                : uw_questions[globalIndex]["childrenQues"]["ifNo"] || [];

              for (let ques of extraQuestionsToAdd) {
                uw_questions.splice(
                  Number(globalIndex) + 1,
                  0,
                  extraQues[ques]
                );
                globalIndex++;
              }
            }
          }
          globalIndex++;
        }

        // store the acord questions only if we get atleast one answer prefilled
        if (response_received) {
          currProspect.acordUwQues = uw_questions;
          sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
        }
      } catch (error) {
        console.log(error);
      }

      // prepare emod
      let emod = { emodValue: "" };
      try {
        if (
          data?.basic_information?.emod &&
          !isNaN(data.basic_information.emod)
        ) {
          emod.emodValue = String(data.basic_information.emod);
        }
      } catch (error) {
        console.log(error);
      }

      // prepare historical claims
      try {
        if (data?.historical_claims) {
          currProspect.acordHistoricalClaims = data.historical_claims;
          sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
        }
      } catch (error) {}

      let result = {
        companyProfile,
        childrenLoc,
        includedExcludedObject: { includeExcludeList },
        emod,
      };

      console.log(result);
      this.props.updateFormFields(result);
    } catch (error) {
      console.log("error while parsing acord results", error);
    }
  };

  handleLossOcrParse = (data) => {
    this.setState({ awaitingFile: false, disableBind: false });
    if (data.fileType === "success") {
      this.setState({
        parseMesssage: "Upload complete!",
        parseError: false,
        urlSuccess: true,
        signedUrl: data.url,
      });
      console.log("here");
      this.createFormFieldData(data.data);
    } else if (data.fileType === "error") {
      this.setState({
        parseMesssage:
          "Unable to process the loss file. Please contact admin@insurecomp.com for further clarification.",
        parseError: true,
      });
    } else {
      this.setState({
        parseMesssage:
          "Unable to generate the download link. Please contact admin@insurecomp.com for further clarification.",
        parseError: true,
      });
    }
  };

  s3Poller = (fileName) => {
    axios
      .get(
        awsAcordUpload +
          `/api/s3PollerJson/ocr-doc-demo-acord/${fileName}/us-east-1`
      )
      .then((result) => {
        if (result.data.fileType) {
          console.log("here in");
          this.handleLossOcrParse(result.data);
        } else {
          this.s3Poller(fileName);
        }
      })
      .catch((err) => {
        console.log("Error", err);
        this.setState({
          parseError: true,
          awaitingFile: false,
          parseMesssage:
            "Unable to process the loss file. Please contact admin@insurecomp.com for further clarification.",
        });
      });
  };
  uploadFile = (event) => {
    this.setState({ uploadingFile: true, parseError: false });
    this.setState({
      error: false,
      urlSuccess: false,
      signedUrl: "",
      awaitingFile: false,
    });
    const that = this;
    event.preventDefault();

    const formData = new FormData();
    formData.append("file", this.state.file[0]);

    axios
      .post(awsAcordUpload + "/api/fileUploadAcord", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        that.setState({ uploadingFile: false });
        that.setState({ awaitingFile: true });
        const fileName = response.data.fileName;
        const folder = fileName.split(".")[0];
        console.log("file uploaded:", folder);
        this.s3Poller(folder);
      })
      .catch((error) => {
        this.setState({ uploadingFile: false });
        console.log("error", error);
      });
  };

  handleFileUpload = (event) => {
    this.setState({ file: event.target.files });
  };

  render() {
    const {
      uploadingFile,
      awaitingFile,
      urlSuccess,
      signedUrl,
      parseError,
      parseMesssage,
    } = this.state;
    return (
      <div className="">
        <form className="">
          <div className="form-group">
            <p style={{ color: "#00308F" }}>
              <b>
                Complete fields below or upload an Acord 130 FL (no
                attachments.)
              </b>
            </p>
            <input
              label="upload file"
              type="file"
              onChange={this.handleFileUpload}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.uploadFile}
          >
            Submit
          </button>
        </form>
        <div className="">
          {uploadingFile && (
            <span>
              Uploading file <SmallLoader />
            </span>
          )}
          {awaitingFile && (
            <span>
              Waiting for file creation <SmallLoader />
            </span>
          )}
          {urlSuccess && (
            <div>
              <a href={signedUrl}>
                {parseMesssage} Please update the information below. Field with
                asterisk are required
              </a>
            </div>
          )}
          {parseError && <span>{parseMesssage}</span>}
        </div>
      </div>
    );
  }
}

export default UploadFormAcord;
