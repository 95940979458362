import { awsUrl } from "../config";
import axios from "axios";
import $ from "jquery";
import { Auth } from "aws-amplify";
import { showErrAlert, getHeader } from "./common";
const uuidv4 = require("uuid/v4");
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");
var global_manual_pre_data = [];

export async function submitComrRate(
  locInfo,
  droppedFile,
  uploadError,
  historicalClaims,
  historicalClaimsCheck
) {
  this.setState({ submitClicked: true });
  let validLocInfo = validateLocInfo(this, locInfo);
  let validHistoricalClaims = validateHistoricalClaims(
    this,
    historicalClaims,
    historicalClaimsCheck
  );
  let currProspectDetails = JSON.parse(sessionStorage.getItem("currProspect"));
  if (droppedFile === "") {
    this.setState({ lossFileMissingError: true });
  }

  if (validHistoricalClaims && validLocInfo && droppedFile && !uploadError) {
    $("#loader").css("display", "block");
    let classCodesList, classCodeObj;
    for (let addressBlockNo in locInfo) {
      classCodesList = [];
      for (let addressChildNo in locInfo[addressBlockNo]) {
        classCodeObj = {
          code: locInfo[addressBlockNo][
            addressChildNo
          ].classCodeDescription.split(":")[0],
          desc: locInfo[addressBlockNo][
            addressChildNo
          ].classCodeDescription.split(":")[1],
          ft: locInfo[addressBlockNo][addressChildNo].ft,
          pt: locInfo[addressBlockNo][addressChildNo].pt,
          payroll: locInfo[addressBlockNo][addressChildNo].payroll
            .split("$")[1]
            .split(",")
            .join(""),
        };
        classCodesList.push(classCodeObj);
      }
      currProspectDetails.prospectAddresses[
        addressBlockNo
      ].classCodes = classCodesList;
    }

    let historicalClaimsList = [];
    for (let year in historicalClaims) {
      if (!historicalClaimsCheck[year]) {
        let objRow = {
          year: year,
          carrier: historicalClaims[year].carrier,
          annPre: historicalClaims[year].annPre,
          claim: historicalClaims[year].claim,
          totInc: historicalClaims[year].totInc,
          totPaid: historicalClaims[year].totPaid,
          totRes: historicalClaims[year].totRes,
        };
        historicalClaimsList.unshift(objRow);
      }
    }

    currProspectDetails.historicalClaims = historicalClaimsList;
    if (this.state.lossData) {
      currProspectDetails.lossData = this.state.lossData;
    }

    let lossRatioObj = {};
    let totalIncurred = 0;
    let totalPremium = 0;
    historicalClaimsList.forEach((element, count) => {
      let incurred = parseFloat(element.totInc.replace(/\$|,/g, ""));
      let premium = parseFloat(element.annPre.replace(/\$|,/g, ""));
      totalIncurred += incurred;
      totalPremium += premium;
      lossRatioObj[(count + 1).toString()] =
        Math.round((totalIncurred / totalPremium) * 100 * 100) / 100;
    });

    currProspectDetails.lossRatioData = lossRatioObj;

    sessionStorage.setItem("currProspect", JSON.stringify(currProspectDetails));
    let tableName = "ProspectTable";
    let prospectData = JSON.parse(sessionStorage.getItem("currProspect"));
    const header = await getHeader();
    prospectData.formStage = "three";
    sessionStorage.removeItem("acordData");
    axios
      .post(
        awsUrl + "/api/postTablesData/" + tableName,
        JSON.stringify(prospectData),
        header
      )
      .then(function(response) {
        try {
          sessionStorage.setItem("formStage", "three");
          sessionStorage.setItem("isLoggedIn", true);
          window.location.reload();
          $("#loader").css("display", "none");
        } catch (err) {
          $("#loader").css("display", "none");
          alert(err.message);
        }
      })
      .catch((err) => {
        $("#loader").css("display", "none");
        showErrAlert(err.response.data);
      });
  }
}

function validateLocInfo(self, locInfo) {
  let keys = ["classCodeDescription", "ft", "pt", "payroll", "manPremRange"];
  let locInfoErrorStore = {};
  let validFlag = true;
  for (let addressBlockNo in locInfo) {
    if (!locInfoErrorStore[addressBlockNo]) {
      locInfoErrorStore[addressBlockNo] = {};
    }
    for (let addressChildNo in locInfo[addressBlockNo]) {
      if (!locInfoErrorStore[addressBlockNo][addressChildNo]) {
        locInfoErrorStore[addressBlockNo][addressChildNo] = {};
      }
      for (let key of keys) {
        if (
          key in locInfo[addressBlockNo][addressChildNo] &&
          locInfo[addressBlockNo][addressChildNo][key] !== ""
        ) {
          if (key === "manPremRange") {
            if (
              locInfo[addressBlockNo][addressChildNo][key].min === "N/A" ||
              locInfo[addressBlockNo][addressChildNo][key].max === "N/A"
            ) {
              locInfoErrorStore[addressBlockNo][addressChildNo][key] = true;
              validFlag = false;
            } else {
              locInfoErrorStore[addressBlockNo][addressChildNo][key] = false;
            }
          } else {
            locInfoErrorStore[addressBlockNo][addressChildNo][key] = false;
          }
        } else {
          locInfoErrorStore[addressBlockNo][addressChildNo][key] = true;
          validFlag = false;
        }
      }
    }
  }
  self.setState({ locInfoErrorStore });

  return validFlag;
}

export function validateHistoricalClaims(
  self,
  historicalClaims,
  historicalClaimsCheck
) {
  let checkMap = {
    falseOne: false,
    trueMid: false,
    falseTwo: false,
  };

  let flag;

  for (let year in historicalClaimsCheck) {
    if (historicalClaimsCheck[year] === false) {
      if (!checkMap.falseOne) {
        checkMap.falseOne = true;
      } else if (checkMap.trueMid) {
        checkMap.falseTwo = true;
      }
    } else {
      if (checkMap.falseOne) {
        checkMap.trueMid = true;
      }
    }
  }
  let tempFlag = !(checkMap.falseOne && checkMap.trueMid && checkMap.falseTwo);
  let consecutiveYearErrorFlag = !tempFlag;

  let historicalClaimsErrorStore = {};
  let keys = ["carrier", "claim", "annPre", "totInc", "totPaid", "totRes"];
  let emptyFieldFlag = false;
  for (let year in historicalClaimsCheck) {
    if (!historicalClaimsErrorStore[year]) {
      historicalClaimsErrorStore[year] = {};
    }
    for (let key of keys) {
      if (
        historicalClaims[year] &&
        historicalClaims[year][key] &&
        historicalClaims[year][key].trim() !== ""
      ) {
        historicalClaimsErrorStore[year][key] = false;
      } else {
        if (!historicalClaimsCheck[year]) {
          historicalClaimsErrorStore[year][key] = true;
          emptyFieldFlag = true;
        }
      }
    }
  }

  flag = !consecutiveYearErrorFlag && !emptyFieldFlag;

  self.setState({
    historicalClaimsError: consecutiveYearErrorFlag,
    historicalClaimsErrorStore,
  });

  return flag;
}

export function dropReadFile(file) {
  if (file.length > 0) {
    this.setState({ lossFileMissingError: false });
    let fileData = file[0];
    let fileName = fileData.name;
    let fileParts = fileData.name.split(".");
    let fileType = fileParts.reverse()[0];
    this.setState({
      showUploadProgress: true,
      uploadError: false,
      droppedFile: fileName,
    });

    Auth.currentSession().then((res) => {
      let email = res.idToken.payload.email;
      let lId = uuidv4();
      let prospectData = JSON.parse(sessionStorage.getItem("currProspect"));
      let postDataS3 = {
        user: email,
        fileType: fileType,
        lId: lId,
      };
      let postLossData = {
        user: email,
        fileName: lId,
        bucket: "loss-run-ocr",
      };
      const USER_TOKEN = res.idToken.jwtToken;
      const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
      axios
        .post(awsUrl + "/api/uploadLossForm", postDataS3, header)
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          let ocrSignedURL = returnData.ocrSignedURL;
          let url = returnData.url;
          let lossData = {};
          let options = { header: { "Content-Type": fileType } };
          axios
            .put(lossSignedURL, fileData, options)
            .then((result) => {
              axios
                .put(ocrSignedURL, fileData, options)
                .then((result) => {
                  axios
                    .post(
                      awsUrl + "/api/postTablesData/loss-run-ocr",
                      JSON.stringify(postLossData),
                      header
                    )
                    .then((result) => {
                      lossData = { url: url, fileType: fileType };
                      this.setState({ lossData });
                      prospectData.lId = lId;
                      prospectData.lossFileName = fileName;
                      axios
                        .post(
                          awsUrl + "/api/postTablesData/ProspectTable",
                          JSON.stringify(prospectData),
                          header
                        )
                        .then((response) => {
                          this.setState({ showUploadProgress: false });
                          sessionStorage.setItem(
                            "currProspect",
                            JSON.stringify(prospectData)
                          );
                        })
                        .catch((err) => {
                          this.setState({ showUploadProgress: false });
                          this.setState({ uploadError: true });
                          console.log("Error", err);
                        });
                    })
                    .catch((error) => {
                      this.setState({ showUploadProgress: false });
                      this.setState({ uploadError: true });
                      console.log("Error", error);
                    });
                })
                .catch((error) => {
                  this.setState({ showUploadProgress: false });
                  this.setState({ uploadError: true });
                  console.log("Error", error);
                });
            })
            .catch((error) => {
              this.setState({ showUploadProgress: false });
              this.setState({ uploadError: true });
              console.log("Error", error);
            });
        })
        .catch((err) => {
          this.setState({ showUploadProgress: false });
          this.setState({ uploadError: true });
          console.log("Error", err);
        });
    });
  }
}

export function setManualPremium(self, stateblock) {
  let { stateAggregate } = self.state;
  let minSum = 0;
  let maxSum = 0;
  let minFlage = true;
  let maxFlage = true;
  let StateWiseData = {
    min: {},
    max: {},
  };
  let minValueTemp = global_manual_pre_data[stateblock].min;
  let maxValueTemp = global_manual_pre_data[stateblock].max;

  for (let carr in minValueTemp) {
    if (StateWiseData.min[carr] === undefined) {
      StateWiseData.min[carr] = [];
    }
    StateWiseData.min[carr].push(
      Object.values(global_manual_pre_data[stateblock].min[carr].value)
    );
  }
  for (let carr in maxValueTemp) {
    if (StateWiseData.max[carr] === undefined) {
      StateWiseData.max[carr] = [];
    }
    StateWiseData.max[carr].push(
      Object.values(global_manual_pre_data[stateblock].max[carr].value)
    );
  }
  let minValue = StateWiseData.min;
  let maxValue = StateWiseData.max;
  for (let carr in minValue) {
    let sum = 0;
    let values = minValue[carr];
    for (let arrValue of values) {
      for (let val of arrValue) {
        sum += Number(val.min);
      }
    }
    if (minFlage) {
      minFlage = false;
      minSum = sum;
    } else {
      if (sum < minSum) {
        minSum = sum;
      }
    }
  }
  for (let carr in maxValue) {
    let values = maxValue[carr];
    let sum = 0;

    for (let arrValue of values) {
      for (let val of arrValue) {
        sum += Number(val.max);
      }
    }
    if (maxFlage) {
      maxFlage = false;
      maxSum = sum;
    } else {
      if (sum > maxSum) {
        maxSum = sum;
      }
    }
  }
  if (!stateAggregate) {
    stateAggregate = {};
  }
  stateAggregate[stateblock] = {
    min: minSum,
    max: maxSum,
  };

  self.setState({ stateAggregate });
}

export function removeStateAggregateData(self, addressBlockNo, addressChildNo) {
  try {
    for (let carrier in global_manual_pre_data[addressBlockNo].max) {
      delete global_manual_pre_data[addressBlockNo].max[carrier].value[
        addressChildNo
      ];
    }
    for (let carrier in global_manual_pre_data[addressBlockNo].min) {
      delete global_manual_pre_data[addressBlockNo].min[carrier].value[
        addressChildNo
      ];
    }
    if (!this) {
      setManualPremium(self, addressBlockNo);
    } else {
      setManualPremium(this, addressBlockNo);
    }
  } catch (e) {
    console.log(e);
  }
}

export async function getMinMax(locInfoBlock, addressBlockNo, addressChildNo) {
  const { ft, pt, payroll, classCodeDescription } = locInfoBlock;
  let { locInfo } = this.state;

  let classCode = classCodeDescription.split(":")[0];
  let data = {
    code: classCode,
    pt: pt.trim(),
    ft: ft.trim(),
    payroll: payroll
      .split("$")[1]
      .split(",")
      .join(""),
  };

  let classNo = addressChildNo;

  let currProspectDetails = JSON.parse(sessionStorage.getItem("currProspect"));

  let date_from = currProspectDetails.effectiveDate.split("/");
  date_from = date_from[2] + "-" + date_from[0] + "-" + date_from[1];

  let manual_pre = {
    employees: [],
    state: "",
    date_from: date_from,
  };

  manual_pre.employees.push(data);
  manual_pre.state = currProspectDetails.prospectAddresses[
    addressBlockNo
  ].stateCode.toLowerCase();
  let tempBlock = currProspectDetails.prospectAddresses[addressBlockNo];
  manual_pre.location = `${tempBlock.streetLineOne}_${tempBlock.streetLine2}_${tempBlock.city}_${tempBlock.stateCode}_${tempBlock.zipCode}`;

  if (
    global_manual_pre_data[addressBlockNo] === null ||
    global_manual_pre_data[addressBlockNo] === undefined
  ) {
    global_manual_pre_data[addressBlockNo] = {};
    if (
      global_manual_pre_data[addressBlockNo].min === null ||
      (global_manual_pre_data[addressBlockNo].min === undefined &&
        global_manual_pre_data[addressBlockNo].max === null) ||
      global_manual_pre_data[addressBlockNo].max === undefined
    ) {
      global_manual_pre_data[addressBlockNo].max = [];
      global_manual_pre_data[addressBlockNo].min = [];
    }
  }
  manual_pre.date_from = date_from;
  const header = await getHeader();
  axios
    .post(awsUrl + "/api/getMinMaxPremium", JSON.stringify(manual_pre), header)
    .then((responce) => {
      if (responce.data !== "Not eligible") {
        let min = Math.round(
          Number(responce.data.man_prem_values["min"].value)
        );
        let max = Math.round(
          Number(responce.data.man_prem_values["max"].value)
        );

        if (min === -1 && max === 0) {
          min = 0;
          max = 0;
        }
        locInfoBlock["manPremRange"] = {
          min: min,
          max: max,
        };
        let minWithClassId = {
          min: min,
          class_num: classNo,
        };
        let maxWithClassId = {
          max: max,
          class_num: classNo,
        };
        var minCarrier = responce.data.man_prem_values.min["carrier"];
        var maxCarrier = responce.data.man_prem_values.max["carrier"];
        if (
          global_manual_pre_data[addressBlockNo].min[minCarrier] === null ||
          (global_manual_pre_data[addressBlockNo].min[minCarrier] ===
            undefined &&
            global_manual_pre_data[addressBlockNo].max[maxCarrier] === null) ||
          global_manual_pre_data[addressBlockNo].max[maxCarrier] === undefined
        ) {
          global_manual_pre_data[addressBlockNo].max[maxCarrier] = {};
          global_manual_pre_data[addressBlockNo].min[minCarrier] = {};
        }
        if (
          global_manual_pre_data[addressBlockNo].min[minCarrier].value ===
            undefined ||
          (global_manual_pre_data[addressBlockNo].min[minCarrier] === null &&
            global_manual_pre_data[addressBlockNo].max[maxCarrier].value ===
              undefined) ||
          global_manual_pre_data[addressBlockNo].max[maxCarrier] === null
        ) {
          global_manual_pre_data[addressBlockNo].min[minCarrier].value = {};
          global_manual_pre_data[addressBlockNo].max[maxCarrier].value = {};
        }
        global_manual_pre_data[addressBlockNo].min[minCarrier].value[
          classNo
        ] = minWithClassId;
        global_manual_pre_data[addressBlockNo].max[maxCarrier].value[
          classNo
        ] = maxWithClassId;
        setManualPremium(this, addressBlockNo);
      } else {
        locInfoBlock["manPremRange"] = {
          min: "N/A",
          max: "N/A",
        };
        removeStateAggregateData(this, addressBlockNo, addressChildNo);
      }
      locInfo[addressBlockNo][addressChildNo] = locInfoBlock;
      this.setState({ locInfo });
    })
    .catch((err) => {
      console.log("Error while calling getMinMax: ", err);
    });
}

export function histClaimCalcTI(historicalClaims, year) {
  let TI, TR, TP;
  if (!historicalClaims[year].totInc || historicalClaims[year].totInc === "") {
    TI = 0;
  } else {
    TI = parseInt(
      historicalClaims[year].totInc.replace("$", "").replace(",", "")
    );
  }
  if (!historicalClaims[year].totRes || historicalClaims[year].totRes === "") {
    TR = 0;
  } else {
    TR = parseInt(
      historicalClaims[year].totRes.replace("$", "").replace(",", "")
    );
  }
  TP = "$" + formatAmount(TI - TR);

  historicalClaims[year].totPaid = TP;
  return historicalClaims;
}

export function histClaimCalcTP(historicalClaims, year) {
  let TI, TP, TR;
  if (historicalClaims[year]) {
    if (historicalClaims[year].totInc) {
      TI = parseInt(
        historicalClaims[year].totInc.replace("$", "").replace(",", "")
      );
      TP = parseInt(
        historicalClaims[year].totPaid.replace("$", "").replace(",", "")
      );
      TR = "$" + formatAmount(TI - TP);
    }
    if (
      historicalClaims[year].totPaid === "" ||
      historicalClaims[year].totPaid === "-"
    ) {
      TR = "$0";
    }

    historicalClaims[year].totRes = TR;
  }
  return historicalClaims;
}

export function histClaimCalcTR(historicalClaims, year) {
  let TI, TR, TP;
  if (historicalClaims[year].totInc) {
    TI = parseInt(
      historicalClaims[year].totInc.replace("$", "").replace(",", "")
    );
    TR = parseInt(
      historicalClaims[year].totRes.replace("$", "").replace(",", "")
    );
    TP = "$" + formatAmount(TI - TR);
  }
  if (
    historicalClaims[year].totRes === "" ||
    historicalClaims[year].totRes === "-"
  ) {
    TP = "$0";
  }

  historicalClaims[year].totPaid = TP;
  return historicalClaims;
}

function formatAmount(nStr) {
  nStr += "";
  var x = nStr.split(".");
  var x1 = x[0];
  var x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
}

export function zeroDefault(e, key) {
  let { historicalClaims } = this.state;
  let year = e.target.id;
  if (!historicalClaims[year]) {
    historicalClaims[year] = {};
  }
  if (!historicalClaims[year][key]) {
    historicalClaims[year][key] = "$0";
  }

  this.setState({ historicalClaims });
}

export function handleCheck(e) {
  let {
    historicalClaimsCheck,
    historicalClaimsErrorStore,
    historicalClaims,
  } = this.state;
  let checkbox = e.target;
  let year = checkbox.id.split("-")[1];
  let checked = checkbox.checked;
  historicalClaimsCheck[year] = checked;
  delete historicalClaims[year];

  for (let key in historicalClaimsErrorStore[year]) {
    historicalClaimsErrorStore[year][key] = false;
  }
  this.setState({
    historicalClaimsCheck,
    historicalClaimsErrorStore,
    historicalClaims,
  });
}
