import React from "react";
import { Form, Button } from "react-bootstrap";
import NumberFormat from "react-number-format";

let isMobile = window.innerWidth < 993;
const IncludeExclude = ({
  includedExcludedObject,
  addIncludeExcludeHandler,
  removeIncludeExcludeHandler,
  setincludeExcludeValueHandler,
  handleKeyDown,
  checkEligibleForClasscodeHandler,
}) => {
  let includedExcludeSize = includedExcludedObject?.includeExcludeList?.length;

  return (
    <>
      <div
        id="include-exclude-container "
        className="font-family-montserrat-semi-bold d-flex align-items-center mt-3 mb-3"
      >
        <h5 className="pr-4 mt-4 mb-4"> Individuals Included / Excluded</h5>
      </div>
      {includedExcludedObject?.["includeCodeCodeError"] &&
        Object.values(includedExcludedObject?.["includeCodeCodeError"]).some(
          (value) => value === true
        ) && (
          <p id="claims-error" className="text-danger">
           Please enter class code which includes officer's payroll.
          </p>
        )}

      <div id="IncludeExcludeContainer">
        {includedExcludedObject?.includeExcludeList.map((values, index) => {
          return (
            <div className="row-include-exclude row no-gutter" key={index}>
              <div className="col-lg-2">
                <Form.Group>
                  <label htmlFor="includ_Exclude_Name" className="mandatory">
                    <b className="font-family-montserrat-semi-bold">Name</b>
                  </label>
                  <input
                    id="includ_Exclude_Name"
                    type="text"
                    placeholder="Name"
                    autoComplete="off"
                    onChange={(e) =>
                      setincludeExcludeValueHandler(e, "officerName", index)
                    }
                    onKeyDown={(event) => handleKeyDown(event)}
                    style={
                      includedExcludedObject.includeExcludeList[index][
                        "officerName"
                      ] &&
                      includedExcludedObject.includeExcludeList[index][
                        "officerName"
                      ]["error"]
                        ? { borderColor: "red" }
                        : {}
                    }
                    value={`${values?.["officerName"]?.["value"]}`}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-2">
                <Form.Group>
                  <label
                    htmlFor="includ_Exclude_ownerShip"
                    className="mandatory"
                  >
                    <b className="font-family-montserrat-semi-bold">
                      Ownership &#37;
                    </b>
                  </label>

                  <NumberFormat
                    fixedDecimalScale={true}
                    decimalScale={2}
                    suffix="%"
                    placeholder="0.00%"
                    autoComplete="off"
                    id="includ_Exclude_ownerShip"
                    type="text"
                    onChange={(e) =>
                      setincludeExcludeValueHandler(e, "ownerShip", index)
                    }
                    onKeyDown={(event) => {
                      if (event.key === "-" || event.key === "−") {
                        event.preventDefault(); // Prevent typing the '-' character
                      }
                    }}
                    style={
                      includedExcludedObject.includeExcludeList[index][
                        "ownerShip"
                      ] &&
                      includedExcludedObject.includeExcludeList[index][
                        "ownerShip"
                      ]["error"]
                        ? { borderColor: "red" }
                        : {}
                    }
                    value={`${values?.["ownerShip"]?.["value"]}`}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-3">
                <Form.Group>
                  <label htmlFor="included_Excluded" className="mandatory">
                    <b className="font-family-montserrat-semi-bold">
                      Included / Excluded
                    </b>
                  </label>
                  <select
                    id="included_Excluded"
                    type="text"
                    placeholder="Included / Excluded"
                    autoComplete="off"
                    onChange={(e) =>
                      setincludeExcludeValueHandler(e, "includExclude", index)
                    }
                    style={
                      includedExcludedObject.includeExcludeList[index][
                        "includExclude"
                      ] &&
                      includedExcludedObject.includeExcludeList[index][
                        "includExclude"
                      ]["error"]
                        ? { borderColor: "red" }
                        : {}
                    }
                    value={`${values?.["includExclude"]?.["value"]}`}
                  >
                    <option key={1} value={undefined}>
                      Please Select
                    </option>
                    <option key={2} value={"Included"}>
                      Included
                    </option>
                    <option key={3} value={"Excluded"}>
                      Excluded
                    </option>
                  </select>
                </Form.Group>
              </div>
              <div className="col-lg-2">
                <Form.Group>
                  <label
                    htmlFor="includ_Exclude_classCode"
                    className="mandatory"
                  >
                    <b className="font-family-montserrat-semi-bold">
                      Class Code
                    </b>
                  </label>
                  <NumberFormat
                    id="includ_Exclude_classCode"
                    type="text"
                    placeholder="Class Code"
                    autoComplete="off"
                    format="####"
                    onChange={(e) =>
                      setincludeExcludeValueHandler(e, "classCode", index)
                    }
                    onBlur={(e) => {
                      checkEligibleForClasscodeHandler(e, index);
                    }}
                    style={
                      (includedExcludedObject.includeExcludeList[index] &&
                        includedExcludedObject.includeExcludeList[index][
                          "classCode"
                        ]["error"]) ||
                      includedExcludedObject?.[
                        "includeExcludeEligibilityCheck"
                      ]?.[index] ||
                      ""
                        ? { borderColor: "red" }
                        : {}
                    }
                    value={`${values?.["classCode"]?.["value"]}`}
                  />
                  {includedExcludedObject?.["includeExcludeEligibilityCheck"]?.[
                    index
                  ] ? (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      {" "}
                      No Class code found
                    </p>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </div>
              {includedExcludeSize > 1 && (
                <div className="col-lg-1 ">
                  <Form.Group>
                  <label htmlFor="included_Excluded" >
                    <b className="font-family-montserrat-semi-bold"> 
                      &nbsp;</b>
                  </label>
                    <button
                      type="button"
                      className="rmvLoc btnInvisible transpButton"
                      onClick={() => removeIncludeExcludeHandler(index)}
                    >
                      <img
                        src={require("../../../images/delete4.png")}
                        className={
                          isMobile ? "inc_ex_del_btn-mobile" : "inc_ex_del_btn"
                        }
                      />
                    </button>
                  </Form.Group>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div>
        <button
          type="button"
          className="btnInvisible addLoc transpButton p-0"
          onClick={addIncludeExcludeHandler}
        >
          <img src={require("../../../images/plus4.png")} /> <b>Add Officers</b>
        </button>
      </div>
    </>
  );
};

export default IncludeExclude;
