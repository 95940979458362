import React from "react";

class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleTabClick = () => {
    const { tabName, handleTabClick } = this.props;
    handleTabClick(tabName);
  };

  render() {
    let {
      activeTab,
      tabName,
      updateAcordSelectedState,
      carrierStateMap,
      acordloadtab,
    } = this.props;

    let stateList = ["Ncci", "FL", "CA"];

    let mainSelectedTab = tabName.split(" ")[0];
    let selectedState = acordloadtab[mainSelectedTab.toLowerCase()];

    let statelistMap =
      carrierStateMap["carrier_at"] ||
      carrierStateMap["carrier_ar"] ||
      carrierStateMap["carrier_ax"];

    let nccistateCheck = false;
    for (let states of statelistMap) {
      if (states !== "fl" && states !== "ca") {
        nccistateCheck = true;
        break;
      }
    }

    let className = "tab-list-item";

    if (activeTab === tabName) {
      className += " tab-list-active";
    }

    return (
      <li onClick={this.handleTabClick} className={className} key={tabName}>
        {
          <ol
            className="acordSelectedList tab-list-item pl-0"
            style={{ listStyleType: "none", display: "flex" }}
          >
            {tabName.toLowerCase() === "no loss" ? (
              <li
                onClick={() => {
                  updateAcordSelectedState(tabName, "no loss");
                }}
                key={tabName + "no loss"}
                className={selectedState === "no loss" ? "TabNameSelected" : ""}
                style={{
                  margin: "0px",
                  padding: "0px",
                }}
              >
                No Loss
              </li>
            ) : (
              stateList.map((state) => {
                let stateCheck = false;
                if (state === "Ncci") {
                  stateCheck = nccistateCheck;
                } else {
                  stateCheck = statelistMap.includes(state.toLowerCase());
                }
                if (stateCheck) {
                  return (
                    <li
                      onClick={() => {
                        updateAcordSelectedState(tabName, state);
                      }}
                      key={tabName + state}
                      className={
                        state === selectedState ? "TabNameSelected" : ""
                      }
                      style={{
                        margin: "0px",
                        padding: "0px",
                      }}
                    >
                      {state}
                    </li>
                  );
                }
              })
            )}
          </ol>
        }
      </li>
    );
  }
}

export default Tab;
