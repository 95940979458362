import React from "react";
import DashboardAdmin from "./DashboardAdminReference";
import Loader from "../common/Loader";
import { Nav } from "react-bootstrap";
import Header from "../common/Header";
import { logout } from "../../utils/common";
import { Check } from "@material-ui/icons";
import { Prev } from "react-bootstrap/esm/PageItem";
class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "tab3",
      program: "PEO",
      source: "Affinity",
      dealMap: {},
      confirmDealMap: {},
      engagedList: [],
      campaignedList: [],
      salesForceList: [],
      wonList: [],
      lostList: [],
      allTabCheck: {
        tab1: false,
        tab2: false,
        tab3: false,
      },
    };
  }

  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      this.setState({ selected: tab });
    }
  };

  updateDealMap = (key, value) => {
    let { dealMap } = this.state;
    dealMap[key] = value;
    this.setState({ dealMap });
  };

  updateConfirmDealMap = (key, value) => {
    let { confirmDealMap } = this.state;
    confirmDealMap[key] = value;
    this.setState({ confirmDealMap });
  };

  componentDidMount = () => {
    sessionStorage.clear();
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      allTabCheck,
      engagedList,
      wonList,
      lostList,
      campaignedList,
      salesForceList,
    } = JSON.parse(JSON.stringify(this.state));
    if (JSON.stringify(allTabCheck) !== JSON.stringify(prevState.allTabCheck)) {
      if (
        allTabCheck["tab1"] === true &&
        allTabCheck["tab2"] === true &&
        allTabCheck["tab3"] === true
      ) {
        let engagedIndicesToRemove = [];
        let campaignedIndicesToRemove = [];
        let salesforceIndicesToRemove = [];

        // shift engaged data to won/lost tabs
        let engagedDomainSet = new Set();
        for (let index in engagedList) {
          index = Number(index);
          let item = engagedList[index];
          if (item.dealConfirmed === "true") {
            if (item.dealFlag === "closed") {
              wonList.push(item);
            } else {
              lostList.push(item);
            }
            engagedIndicesToRemove.unshift(index);
            engagedDomainSet.add(item.domain);
          }
        }

        for (let index of engagedIndicesToRemove) {
          engagedList.splice(index, 1);
        }

        // shift campaigned data to won/lost tabs
        for (let index in campaignedList) {
          index = Number(index);
          let item = campaignedList[index];
          if (item.dealConfirmed === "true") {
            if (!engagedDomainSet.has(item.domain)) {
              if (item.dealFlag === "closed") {
                wonList.push(item);
              } else {
                lostList.push(item);
              }
            }
            campaignedIndicesToRemove.unshift(index);
          }
        }

        for (let index of campaignedIndicesToRemove) {
          campaignedList.splice(index, 1);
        }

        // shift salesforce data to won/lost tabs
        for (let index in salesForceList) {
          index = Number(index);
          let item = salesForceList[index];
          if (item.dealConfirmed === "true") {
            if (item.dealFlag === "closed") {
              wonList.push(item);
            } else {
              lostList.push(item);
            }
            salesforceIndicesToRemove.unshift(index);
          }
        }

        for (let index of salesforceIndicesToRemove) {
          salesForceList.splice(index, 1);
        }

        this.setState({
          wonList,
          lostList,
          engagedList,
          campaignedList,
          salesForceList,
        });
      }
    }
  }

  handleDealConfirmAll = (toMoveList) => {
    let {
      wonList,
      lostList,
      engagedList,
      campaignedList,
      salesForceList,
    } = JSON.parse(JSON.stringify(this.state));

    toMoveList.sort((a, b) => a.index - b.index).reverse();

    for (let data of toMoveList) {
      let { index, dealFlag, rowData, tab } = data;

      delete rowData?.tableData?.checked;

      if (dealFlag === "closed") wonList.unshift(rowData);
      else if (dealFlag === "lost") lostList.unshift(rowData);

      if (tab === "tab1") {
        engagedList.splice(index, 1);
      } else if (tab === "tab2") {
        campaignedList.splice(index, 1);
      } else if (tab === "tab3") {
        salesForceList.splice(index, 1);
      }
    }

    this.setState({
      wonList,
      lostList,
      engagedList,
      campaignedList,
      salesForceList,
    });
  };

  handleDealConfirm = (tab, index, dealFlag, rowData) => {
    console.log(tab, index, dealFlag, rowData);
    let {
      wonList,
      lostList,
      engagedList,
      campaignedList,
      salesForceList,
    } = JSON.parse(JSON.stringify(this.state));
    if (dealFlag === "closed") wonList.unshift(rowData);
    else if (dealFlag === "lost") lostList.unshift(rowData);
    this.setState({ wonList, lostList });

    if (tab === "tab1") {
      engagedList.splice(index, 1);
      this.setState({ engagedList });
    } else if (tab === "tab2") {
      campaignedList.splice(index, 1);
      this.setState({ campaignedList });
    } else if (tab === "tab3") {
      salesForceList.splice(index, 1);
      this.setState({ salesForceList });
    }
  };

  setRowDataList = (tab, list) => {
    let { allTabCheck } = JSON.parse(JSON.stringify(this.state));
    if (tab === "tab1") this.setState({ engagedList: list });
    else if (tab === "tab2") this.setState({ campaignedList: list });
    else if (tab === "tab3") this.setState({ salesForceList: list });
    allTabCheck[tab] = true;
    this.setState({ allTabCheck });
  };

  prepareEngagedList = () => {
    let { engagedList } = this.state;
    let engagedFilterList = {
      All: [],
      HotSchedules: [],
      Affinity: [],
    };

    for (let rowData of engagedList) {
      engagedFilterList.All.push(rowData);
      if (rowData.source === "affinity") {
        engagedFilterList.Affinity.push(rowData);
      } else if (rowData.source === "hotschedules") {
        engagedFilterList.HotSchedules.push(rowData);
      }
    }

    return engagedFilterList;
  };

  getList = (tab) => {
    let { campaignedList, salesForceList, wonList, lostList } = this.state;

    let tableDataByTab = {
      tab1: () => this.prepareEngagedList(),
      tab2: () => campaignedList,
      tab3: () => salesForceList,
      tab4: () => wonList,
      tab5: () => lostList,
    };

    return tableDataByTab[tab]() || [];
  };

  render() {
    let {
      selected,
      program,
      source,
      dealMap,
      confirmDealMap,
      allTabCheck,
    } = this.state;
    const tabsMap = {
      tab1: { name: "Engaged", enabled: true, visible: true },
      tab2: { name: "Campaigned", enabled: true, visible: true },
      tab3: { name: "Salesforce", enabled: true, visible: true },
      tab4: { name: "Won", enabled: true, visible: true },
      tab5: { name: "Lost", enabled: true, visible: true },
    };

    return (
      <div className="dashboard-container">
        <div className="d-flex" style={{ borderBottomStyle: "groove" }}>
          <Header />
        </div>
        <div className="tabs p-4">
          <Nav
            variant="pills"
            defaultActiveKey={selected}
            style={{ fontSize: "16px", fontFamily: "Montserrat" }}
          >
            {Object.keys(tabsMap).map((tab) =>
              tabsMap[tab].visible ? (
                <Nav.Item className={tabsMap[tab].enabled ? "" : "pl-1"}>
                  <Nav.Link
                    className={tabsMap[tab].enabled ? "" : "btn  text-grey"}
                    eventKey={tab}
                    onClick={() => this.setSelected(tab)}
                    disabled={!tabsMap[tab].enabled}
                  >
                    {tabsMap[tab].name}
                  </Nav.Link>
                </Nav.Item>
              ) : null
            )}
          </Nav>
          {Object.keys(tabsMap).map((tab) => (
            <div key={tab}>
              {
                <DashboardAdmin
                  tabName={tab}
                  selected={selected}
                  program={program}
                  source={source}
                  dealMap={dealMap}
                  updateDealMap={this.updateDealMap}
                  confirmDealMap={confirmDealMap}
                  updateConfirmDealMap={this.updateConfirmDealMap}
                  setRowDataList={this.setRowDataList}
                  rowDataList={this.getList(tab)}
                  allTabCheck={allTabCheck}
                  handleDealConfirm={this.handleDealConfirm}
                  handleDealConfirmAll={this.handleDealConfirmAll}
                />
              }
            </div>
          ))}
        </div>
        <Loader />
      </div>
    );
  }
}

export default Tabs;
