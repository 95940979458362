import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { iframe } from "react-dom-factories";
import axios from "axios";
import { apiURL, awsUrl, awsUrl2, localURL } from "../../../config";
let ismobile = window.innerWidth < 1100;

const checkBrokerPercentageValue = (e) => {
  let percentageValue = e.target.value.slice(0, -1);
  // console.log(percentageValue, "++++++++++++++++++++");
  if (percentageValue >= 0 && percentageValue <= 100) {
    e.target.style.border = "none";
  } else {
    e.target.style.border = "2px solid red";
  }
};
const BrokerQues = (props) => {
  const {
    brokerQuestionValue,
    brokerdropdownSelected,
    brokerPercentage,
    brokerQuestionResponse,
    brokerDropdownResponse,
    brokerPercentageResponse,
  } = props;
  let data = [];
  const [brokerList, setBrokerList] = useState(data);

  useEffect(() => {
    loadBrokeroptions();
  }, []);

  useEffect(() => {
    for (let brokerObj of brokerList) {
      if (brokerObj?.value === brokerdropdownSelected?.value) {
        brokerPercentageResponse({ target: { value: brokerObj.rate } });
        break;
      }
    }
  }, [brokerdropdownSelected?.value]);

  const loadBrokeroptions = async () => {
    let Brokerlist = await axios.get(awsUrl2 + "/api/getallBrokerList");

    let brokerMappinglist = Brokerlist.data;

    for (let key in brokerMappinglist) {
      data.push({
        label: key ? brokerMappinglist[key]["broker_name"] : key,
        value: brokerMappinglist[key]["broker_name"],
        rate: brokerMappinglist[key]["commission_rate"]
      });
    }
    setBrokerList(data);
  };
  brokerList.sort(function (a, b) {
    const nameA = a.value;
    const nameB = b.value;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  return (
    <div
      id="brokerFlow"
      className={`${brokerQuestionValue ? "Brokerflow-row " : ""}`}
    >
      <div className={`${ismobile ? "row" : " align-items-end mb-4"}`}>
        <div
          className={`${
            ismobile
              ? "col-12 d-flex commisonQuestion"
              : "d-flex commisonQuestion"
          }`}
        >
          <p style={{ marginBottom: "0px" }} className="pl-0">
            <b>Is there a broker in this deal?</b>
          </p>

          <Form.Check
            className="pl-5"
            inline
            value="Y"
            checked={brokerQuestionValue === true}
            label="Yes"
            type="radio"
            onChange={(e) => brokerQuestionResponse(e, true)}
          />
          <Form.Check
            className="pl-3"
            inline
            value="N"
            checked={brokerQuestionValue === false}
            label="No"
            type="radio"
            onChange={(e) => brokerQuestionResponse(e, false)}
          />
        </div>
        {brokerQuestionValue ? (
          <>
            <div
              className={`${
                ismobile
                  ? "col-12 d-flex broker_dropdown row"
                  : "d-flex  select_broker_dropdown"
              }`}
            >
              <div className={`${ismobile ? " col-md-12" : "pl-0 "}`}>
                <label className="font-weight-bold ">Select Broker</label>
                <div>
                  <Select
                    placeholder={"Please Select"}
                    value={brokerdropdownSelected}
                    className="carrier-dropdown dropdown"
                    classNamePrefix="broker-dropdown"
                    options={brokerList}
                    onChange={(selectOption) => {
                      brokerDropdownResponse(selectOption);
                    }}
                  />
                </div>
              </div>

              <div className={`${ismobile ? " col-md-12" : "pl-2 "}`}>
                <label className="font-weight-bold ">Broker Comm %</label>
                <div>
                  <NumberFormat
                    name="brokerPercent"
                    id={"BrokerId"}
                    fixedDecimalScale={true}
                    disabled={brokerdropdownSelected ? false : true}
                    className={`${brokerdropdownSelected ? " " : "greyout"}`}
                    decimalScale={2}
                    suffix="%"
                    type="text"
                    value={brokerPercentage}
                    placeholder="0.00%"
                    autoComplete="off"
                    style={{ width: "60%" }}
                    onChange={(e) => {
                      brokerPercentageResponse(e);
                    }}
                    onBlur={checkBrokerPercentageValue}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          " "
        )}
      </div>
    </div>
  );
};

export default BrokerQues;
