import React, { Component } from "react";
import Allques from "../../subcompo/sections-quote/Allques";
import NumberFormat from "react-number-format";
import { Form, Button, FormGroup } from "react-bootstrap";
import { awsUrl, awsUrl2 } from "../../../config";
import {
  updateQuesList,
  updateAmtrustQuesList,
  sendAcordQuoteEmail,
} from "../../../utils/form_ques";
import { clearErrHistoricalClaims } from "../../../utils/common";
import { resetEligibility } from "../../../utils/eligibility_helpers";

import {
  submitComrRate,
  dropReadFile,
  histClaimCalcTI,
  histClaimCalcTP,
  histClaimCalcTR,
  zeroDefault,
  handleCheck,
  getMinMax,
  removeStateAggregateData,
} from "../../../utils/form_cmprRates";
import { setUWQues, setHistoricalClaims } from "../../../utils/session_data";
import {
  fourthQues,
  acordQues,
  fourthAmtrustGenericQues,
} from "../../../utils/ques_list";
import {
  salesManagerLoginCheck,
  fubaQuotePdfGenerator,
  downloadFubaDeclinationQuotePdf,
} from "../../../utils/form_ques";
import {
  // submitQues,
  amtrustResponse,
  agentPersonLoginCheck,
  salesPersonLoginCheck,
  checkQuestionsValidity,
  getClassCodeEligibility,
  getUwQuestionsEligibility,
  getHospitalityEligibility,
} from "../../../utils/form_ques";
import moment from "moment";
import $ from "jquery";
import NewProgress from "../../../newcomponent/common/NewProgress";
import axios from "axios";
import { Auth } from "aws-amplify";
import DropZones from "../../common/DropZones";

import Context from "../../../contexts/Context";
import {
  getFinalEligibility,
  getDeclinedStatementList,
} from "../../../utils/eligibility_helpers";
import DropZone from "../PreUwUpload/DropZone";
import { checkMailCampaignUser } from "../../../utils/check_mail_campaign_user";
const uuidv4 = require("uuid/v4");
const numeral = require("numeral");

let isMobile = window.innerWidth < 993;

const eligibilityMap = {
  approved: 1,
  referred: 2,
  declined: 3,
};
class FormUnderQues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quesList: [],
      amtrustQuesList: [],
      preUwStatus: "approved",
      clientEligibility: "",
      quoteSubmitted: false,
      salesManagerCheck: false,
      salesManagerPersonName: "",

      historicalClaimsError: false,
      historicalClaims: {},
      historicalClaimsCheck: {},
      historicalClaimsErrorStore: {},
      uwDataConfirmed: false,
      fileList: { first: {}, second: {} },
      fileListToStore: [],
      cliamZeroCheckAmountPaid: {},
      cliamZeroCheckReserve: {},
      claimOneWithTotalPayroll: {},
    };
    this.setUWQues = setUWQues.bind(this);
    this.updateQuesList = updateQuesList.bind(this);
    this.updateAmtrustQuesList = updateAmtrustQuesList.bind(this);
    // this.submitQues = submitQues.bind(this);

    this.setHistoricalClaims = setHistoricalClaims.bind(this);
    this.handleCheck = handleCheck.bind(this);
    this.clearErr = clearErrHistoricalClaims.bind(this);
    this.zeroDefault = zeroDefault.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.quoteSubmitted !== prevProps.quoteSubmitted)
      this.setState({ quoteSubmitted: this.props.quoteSubmitted });
  }

  handleFileListToStoreSetter = (list) => {
    console.log(list);
    this.setState({ fileListToStore: list });
  };

  uploadQuoteFileToS3 = async (uuid, binaryData, eligibility) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/uploadQuoteFile", { uuid, eligibility })
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          console.log("lossSignedURL", lossSignedURL);
          let options = { header: { ContentType: "application/pdf" } };
          axios
            .put(lossSignedURL, binaryData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve();
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploading quote/declination file  ", error);
          reject();
        });
    });
  };

  lossHistoryEligibilityChecker = async (
    historicalClaims,
    historicalClaimsCheck,
    eligibility_check,
    childrenLoc,
    lossRatioObj,
    histClaimsLatestYear
  ) => {
    try {
      const currYear = histClaimsLatestYear; //most recent year as per expected date
      const mostRecentThreeYears = [currYear, currYear - 1, currYear - 2];
      let recentThreeYearClaims = 0;
      const lossRatioCodes = ["5022", "5403", "5645", "5651"];
      const priorCoverageCodes = [
        "5",
        "8",
        "2585",
        "5348",
        "5478",
        "8006",
        "8209",
        "8232",
        "8380",
        "5022",
        "5403",
        "5651",
        "5645",
        "8393",
        "9012",
        "9014",
        "9015",
      ];
      const childLocLossRatioCodes = [];
      let coverage = 0;
      const governingCC = JSON.parse(sessionStorage.getItem("currProspect"))?.[
        "govClassCodeObject"
      ]?.["govClassCode"].split(":")[0];
      console.log({ governingCC });

      for (let year in historicalClaims) {
        if (!historicalClaimsCheck?.[year]) coverage++;
      }
      for (let year in historicalClaims) {
        if (mostRecentThreeYears.includes(Number(year)))
          recentThreeYearClaims += Number(historicalClaims?.[year].claim);
      }
      if (recentThreeYearClaims > 2)
        eligibility_check["loss_history"]["eligibility_check_3_1"][
          "eligibility"
        ] = "referred";
      else
        eligibility_check["loss_history"]["eligibility_check_3_1"][
          "eligibility"
        ] = "approved";

      for (let index in childrenLoc) {
        const classCodesInfo = childrenLoc[index]["classCodesInfo"];
        for (let classCode in classCodesInfo) {
          let code = classCodesInfo[classCode]["classCodeDescription"][
            "value"
          ].split(":")[0];
          if (
            lossRatioCodes.includes(code) &&
            !childLocLossRatioCodes.includes(code)
          )
            childLocLossRatioCodes.push(code);
        }
      }
      if (childLocLossRatioCodes.length === 0)
        eligibility_check["loss_history"]["eligibility_check_3_2"][
          "eligibility"
        ] = "";
      else if (childLocLossRatioCodes.length > 0) {
        try {
          const eligibility_check_3_2 = await axios.post(
            awsUrl2 + "/api/getLossRatioEligibility",
            JSON.stringify({
              classCodes: childLocLossRatioCodes,
              lossRatio: lossRatioObj[3],
            })
          );
          eligibility_check["loss_history"]["eligibility_check_3_2"][
            "eligibility"
          ] = eligibility_check_3_2?.data || "";
        } catch (error) {
          console.log("error inside eligibility_check_3_2 ", error);
        }
      }
      if (priorCoverageCodes.includes(governingCC)) {
        try {
          const eligibility_check_3_3 = await axios.post(
            awsUrl2 + "/api/getPriorCoverageEligibility",
            JSON.stringify({
              governingCC,
              coverage,
            })
          );
          console.log("eligibility_check_3_3", eligibility_check_3_3);
          eligibility_check["loss_history"]["eligibility_check_3_3"][
            "eligibility"
          ] = eligibility_check_3_3?.data || "";
        } catch (error) {
          console.log("error inside eligibility_check_3_3 ", error);
        }
      } else if (!priorCoverageCodes.includes(governingCC)) {
        eligibility_check["loss_history"]["eligibility_check_3_3"][
          "eligibility"
        ] = "";
      }
      console.log("lossRatioObj", lossRatioObj);
      if (lossRatioObj[3] < 55) {
        eligibility_check["loss_history"]["eligibility_check_3_4"][
          "eligibility"
        ] = "approved";
        eligibility_check["loss_history"]["eligibility_check_3_5"][
          "eligibility"
        ] = "";
      }
    } catch (error) {
      console.log("error inside loss history eligibility_check", error);
    }
  };
  submitQues = async (
    amtrustQuesList,
    updatePopUpData,
    acordQuesAvailable,
    uwExpectedResponses
  ) => {
    let eligibility_check = JSON.parse(
      JSON.stringify(this.props.eligibilityCheck)
    );

    let {
      historicalClaims,
      historicalClaimsCheck,
      quesList,
      uwDataConfirmed,
      fileListToStore,
    } = this.state;
    $("#loader").css("display", "block");

    let quotePdfUuid = uuidv4();

    let historicalClaimsList = [];
    let currProspectDetails;
    try {
      currProspectDetails = JSON.parse(sessionStorage.getItem("currProspect"));
    } catch (error) {
      console.log(error);
    }

    let { companyProfile, childrenLoc, emod } = currProspectDetails;
    let histClaimsLatestYear =
      new Date(companyProfile?.effectiveDate?.value).getFullYear() - 1 ||
      new Date().getFullYear();

    // historical Claims calculation begins here
    for (let i = 0; i < 5; i++) {
      let year = histClaimsLatestYear - i;
      let objRow = {
        year: year,
        carrier: historicalClaims?.[year]?.carrier || "",
        annPre: historicalClaims?.[year]?.annPre || 0,
        claim: historicalClaims?.[year]?.claim || 0,
        totInc: historicalClaims?.[year]?.totInc || 0,
        totPaid: historicalClaims?.[year]?.totPaid || 0,
        totRes: historicalClaims?.[year]?.totRes || 0,
        pyrlExp: historicalClaims?.[year]?.pyrlExp || 0,
      };
      historicalClaimsList.push(objRow);
    }

    // currProspectDetails.historicalClaims = historicalClaimsList;
    // if (this.state.lossData) {
    //   currProspectDetails.lossData = this.state.lossData;
    // }

    console.log("historicalClaimsList", historicalClaimsList);

    let lossRatioObj = {};
    let claimsFrequencyPayroll = {};
    let claimsFrequencyPremium = {};
    let totalIncurred = 0;
    let totalPremium = 0;
    let totalClaims = 0;
    let totalPayrollExposure = 0;

    historicalClaimsList.forEach((element, count) => {
      let incurred = numeral(element.totInc).value();
      let reserve = numeral(element.totRes).value();
      let premium = numeral(element.annPre).value();
      let claims = numeral(element.claim).value();
      let payrollExposure = numeral(element.pyrlExp).value();

      // if (!payrollExposure) {
      //   payrollExposure = aggregatePayroll;
      // }

      // if (!premium) {
      //   premium = aggregatePremium;
      // }

      totalIncurred += incurred + reserve;
      totalPremium += premium;
      totalClaims += claims;
      totalPayrollExposure += payrollExposure;

      lossRatioObj[(count + 1).toString()] =
        Math.round((totalIncurred / totalPremium) * 100 * 100) / 100 || 0;
    });
    // currProspectDetails.lossRatioData = lossRatioObj;
    // currProspectDetails.claimsFrequencyPayrollData = claimsFrequencyPayroll;
    // currProspectDetails.claimsFrequencyPremiumData = claimsFrequencyPremium;

    let validQuestions = checkQuestionsValidity(quesList, amtrustQuesList);
    let {
      validHistoricalClaims,
      historicalClaimsError,
      historicalClaimsErrorStore,
    } = this.validateHistoricalClaims(
      historicalClaims,
      historicalClaimsCheck,
      histClaimsLatestYear
    );

    if (!validHistoricalClaims) {
      $("#loader").css("display", "none");
      try {
        document
          .getElementById("historical_Claims")
          .scrollIntoView({ behavior: "smooth" });
      } catch (error) {}
      return;
    }
    if (!validQuestions) {
      $("#loader").css("display", "none");
      this.setState({ incompleteQuestions: true });
      return;
    }

    resetEligibility(eligibility_check, [
      "loss_history",
      "uw_questions",
      "quote_data",
    ]);

    await this.lossHistoryEligibilityChecker(
      historicalClaims,
      historicalClaimsCheck,
      eligibility_check,
      childrenLoc,
      lossRatioObj,
      histClaimsLatestYear
    );

    try {
      // let currProspectDetails = JSON.parse(
      //   sessionStorage.getItem("currProspect")
      // );

      // let isSalesforce = sessionStorage.getItem("isSalesforce");
      // let sfQuoteId = sessionStorage.getItem("sfQuoteId");

      // let uwAmtrustQuesList = sessionStorage.getItem("uwAmtrustQuesList");
      // if (uwAmtrustQuesList) {
      //   uwAmtrustQuesList = JSON.parse(uwAmtrustQuesList);
      // }

      // let uwQuesList = sessionStorage.getItem("uwQuesList");
      // if (uwQuesList) {
      //   uwQuesList = JSON.parse(uwQuesList);
      // }

      // let preUwData;
      // try {
      //   preUwData = JSON.parse(sessionStorage.getItem("preUwData"));
      // } catch (error) {}

      // let salespersonMailCampaign = sessionStorage.getItem(
      //   "salespersonMailCampaign"
      // );

      // let amtrustAccountDetails = sessionStorage.getItem(
      //   "amtrustAccountDetails"
      // );
      // try {
      //   amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
      // } catch (error) {
      //   amtrustAccountDetails = {};
      // }

      // let amtrustAccountDetailsCopy = sessionStorage.getItem(
      //   "amtrustAccountDetailsCopy"
      // );
      // try {
      //   amtrustAccountDetailsCopy = JSON.parse(amtrustAccountDetailsCopy);
      // } catch (error) {}

      let {
        agentpersonName,
        agentpersonCheck,
        agentpersonNameAttr,
      } = await agentPersonLoginCheck();
      // let {
      //   salespersonName,
      //   salespersonCheck,
      //   salespersonNameAttr,
      // } = await salesPersonLoginCheck();

      let agentID;
      try {
        agentID = sessionStorage.getItem("agentID");
      } catch (error) {
        console.log(error);
      }

      let uwQuesData = [];
      let acordQuesData = [];
      let carrierUserData = [];
      let quoteQuestionsList = [0, 0, 0];

      for (let question of quesList) {
        let dataQues = {
          response: question.response ? "Y" : "N",
          name: question.name,
          ques: question.ques,
          ifYes: question.ifYes,
          ifNo: question.ifNo,
          type: question?.type,
        };
        uwQuesData.push(dataQues);
        if (question.statementCode)
          eligibility_check["uw_questions"][question.statementCode][
            "eligibility"
          ] = question.response ? question.ifYes : question.ifNo;

        if (question.name === "q28" && question.response) {
          quoteQuestionsList[0] = 1;
        }
        if (question.name === "q27" && question.response) {
          quoteQuestionsList[1] = 1;
        }
        if (question.name === "q29" && question.response) {
          quoteQuestionsList[2] = 1;
        }
      }
      this.props.setEligibilityCheck(eligibility_check);
      for (let question of acordQues) {
        let acordDataQues = {
          response: "",
          name: question.name,
          ques: question.ques,
        };
        let answerObj = uwQuesData?.find((e) => e.name === question.name);
        if (answerObj?.response) {
          acordDataQues.response = answerObj.response;
        }
        acordQuesData.push(acordDataQues);
      }

      // currProspectDetails.uwQues = uwQuesData;
      let isLoggedIn;
      let agent_name;
      let agency_name;
      let uw_name;
      let agency_code;
      try {
        isLoggedIn = await Auth.currentAuthenticatedUser();
        agent_name = isLoggedIn?.attributes.name
          ? isLoggedIn?.attributes.name
          : "";
        agency_name = isLoggedIn?.attributes["custom:agency"]
          ? isLoggedIn?.attributes["custom:agency"]
          : "";
        uw_name = isLoggedIn?.attributes["custom:uw"]
          ? isLoggedIn?.attributes["custom:uw"]
          : "";
        agency_code = isLoggedIn?.attributes["custom:agency_code"] || "";
      } catch (error) {
        console.log("Authentication error here", error);
      }

      currProspectDetails = {
        ...currProspectDetails,
        companyProfile,
        agency_name,
        agent_name,
        uw_name,
        agency_code,
        childrenLoc,
        historicalClaims: historicalClaims,
        historicalClaimsCheck: historicalClaimsCheck,
        uwQues: quesList,
        claimsFrequencyPayrollData: claimsFrequencyPayroll,
        claimsFrequencyPremiumData: claimsFrequencyPremium,
        lossRatioData: lossRatioObj,
        uwDataConfirmed,
      };

      sessionStorage.setItem(
        "currProspect",
        JSON.stringify(currProspectDetails)
      );

      // let admin_coefficient = {
      //   a: 52,
      //   b: 26,
      //   c: 24,
      //   d: 12,
      // };

      // if (childrenLoc && companyProfile) {
      // let { salespersonName, salespersonCheck } = await salesPersonLoginCheck();

      // let requested_admin = {};
      // if (requestedPricing?.checked && requestedPricing?.adminPercent) {
      //   requested_admin = {
      //     value: requestedPricing.adminPercent,
      //     type: "percent",
      //   };
      // } else if (!requestedPricing?.checked && requestedPricing?.perCKdollar) {
      //   requested_admin = {
      //     value: requestedPricing.perCKdollar.split("$")[1],
      //     type: "percheck",
      //   };
      // }

      let dataBody = {
        common: {
          el_limits: companyProfile?.empLiabilityLimit?.value,
          date_from: moment(companyProfile.effectiveDate.value).format(
            "YYYY-MM-DD"
          ),
          date_to: moment(companyProfile.effectiveDate.value)
            .add(1, "y")
            .format("YYYY-MM-DD"),
          emod: [],
          questions: quoteQuestionsList || [],
          sr_carriers: {},
          // requested_admin,
        },
        offices: [],
      };

      if (emod?.emodValue) {
        dataBody.common.emod.push({
          value_1: emod.emodValue,
          effective_date_1: moment(companyProfile.effectiveDate.value).format(
            "YYYY-MM-DD"
          ),
          stateList: ["fl"],
        });
      }

      // let emodForMail;

      // for (let state in emodStatesData) {
      //   let emodObj = {};
      //   if (emodStatesData[state].response) {
      //     emodObj = {
      //       value_1: emodStatesData[state].rate1.value,
      //       effective_date_1: moment(emodStatesData[state].date1.value).format(
      //         "YYYY-MM-DD"
      //       ),
      //     };

      //     if (emodForMail == null && emodStatesData[state].rate1.value)
      //       emodForMail = emodStatesData[state].rate1.value;

      //     emodObj.stateList =
      //       state === "ncci" ? emodStatesData[state].stateList : [state];
      //     if (emodStatesData[state].displayMore) {
      //       emodObj.value_2 = emodStatesData[state].rate2.value;
      //       emodObj.effective_date_2 = moment(
      //         emodStatesData[state].date2.value
      //       ).format("YYYY-MM-DD");
      //     }

      //     dataBody.common.emod.push(emodObj);
      //   }
      // }
      let carrierEligBody = {};
      for (let addressBlockNo in childrenLoc) {
        let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${
          childrenLoc[addressBlockNo].state.value
        }_${
          (childrenLoc[addressBlockNo].zipCode ||
            childrenLoc[addressBlockNo].zipcode)["value"]
        }`;
        let officesObj = {
          name,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          requested_suta: "",
          client_suta: "",
          employees: [],
          carriers: ["carrier_ax"],
        };
        let key = `${name}_${addressBlockNo}`;
        if (!(key in carrierEligBody)) {
          carrierEligBody[key] = [];
        }
        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray = childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].classCodeDescription.value.split(":");

          let payroll =
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
              .value;

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();
          let state = childrenLoc[addressBlockNo].state.value.toLowerCase();

          if (
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
              .govClassCodeCheck
          ) {
            carrierEligBody[key].push({
              code,
              description: desc,
              state,
              governingCC: "true",
            });
          } else {
            carrierEligBody[key].push({
              code,
              description: desc,
              state,
            });
          }

          officesObj.requested_suta =
            childrenLoc[addressBlockNo].suta &&
            childrenLoc[addressBlockNo].suta.value
              ? childrenLoc[addressBlockNo].suta.value.split("%")[0]
              : "";
          officesObj.client_suta =
            childrenLoc[addressBlockNo].clientsuta &&
            childrenLoc[addressBlockNo].clientsuta.value
              ? childrenLoc[addressBlockNo].clientsuta.value.split("%")[0]
              : "";

          let payroll_value = childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].payroll.value.includes("$")
            ? childrenLoc[addressBlockNo].classCodesInfo[
                addressChildNo
              ].payroll.value.split("$")[1]
            : childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].payroll
                .value;
          let classCodeObj = {
            code,
            payroll: payroll_value.split(",").join(""),
            ft: childrenLoc?.[addressBlockNo]?.classCodesInfo?.[addressChildNo]
              ?.ft?.value
              ? String(
                  childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].ft
                    .value
                ).trim()
              : "0",
            pt: childrenLoc?.[addressBlockNo]?.classCodesInfo?.[addressChildNo]
              ?.pt?.value
              ? String(
                  childrenLoc?.[addressBlockNo]?.classCodesInfo?.[
                    addressChildNo
                  ]?.pt?.value
                ).trim()
              : "0",
            desc: desc.toLowerCase(),
          };
          officesObj.employees.push(classCodeObj);
        }
        dataBody.offices.push(officesObj);
      }

      // let quoteFactors = {
      //   teap: 0,
      //   total: 0,
      //   futaCharge: 0,
      //   ficaCharge: 0,
      //   sutaCharge: 0,
      //   payroll: 0,
      //   adminFee: 0,
      // };

      // let eligibilityMap = {
      //   approved: 1,
      //   referred: 2,
      //   declined: 3,
      // };

      // try {
      //   clientEligibility = eligibilityList.reduce((a, b) => {
      //     if (eligibilityMap[a] > eligibilityMap[b]) {
      //       return a;
      //     }
      //     return b;
      //   });
      // } catch (error) {
      //   clientEligibility = "approved";
      // }
      const eligibilityMapAsPerAPI = {
        yes: "approved",
        uw: "referred",
        no: "declined",
      };

      await axios
        .post(awsUrl + "/api/getCarrierElig", JSON.stringify(carrierEligBody))
        .then((res) => {
          let data = res.data;
          let ccEligibility;
          for (let loc in data) {
            ccEligibility = data[loc]["carrier_ax"]["value"];
          }
          eligibility_check["company_profile"]["eligibility_check_2_9"][
            "eligibility"
          ] = eligibilityMapAsPerAPI?.[ccEligibility];
        });

      let res;
      try {
        res = await axios.post(
          awsUrl + "/api/getQuote",
          JSON.stringify(dataBody)
        );
      } catch (error) {
        console.log("error inside getQuote API", error);
        //sessionStorage.setItem("quoteFactors", JSON.stringify(quoteFactors));

        //mail for failed quote generation
        // let sessionData;
        // try {
        //   sessionData = await Auth.currentAuthenticatedUser();
        // } catch (error) {}

        // let userDetails = {
        //   companyName: companyProfile.companyName.value,
        //   loggedInUser: sessionData
        //     ? `${sessionData.attributes.name} (${sessionData.attributes.email})`
        //     : "",
        // };
        // await sendFailedQuoteMail(userDetails);

        //     $("#loader").css("display", "none");
        //     window.location.reload();
      }
      let dropped = true;
      let data = res.data;
      for (let location in data) {
        if (data[location]["carrier_ax"] !== "Dropped") {
          dropped = false;
        }
      }

      if (!dropped) {
        let prospectAddresses = {};

        // create the prospect address object
        for (let stateIndex in childrenLoc) {
          prospectAddresses[stateIndex] = {
            stateCode: childrenLoc[stateIndex].state.value,
            addressId: childrenLoc[stateIndex].number,
            classCodes: [],
          };
          for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
            let classCodeDescArray = childrenLoc[stateIndex].classCodesInfo[
              classCodeIndex
            ].classCodeDescription.value.split(":");

            let code = classCodeDescArray.shift();
            let desc = classCodeDescArray.join(":").trim();

            prospectAddresses[stateIndex].classCodes.push({
              code,
              payroll: numeral(
                childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll
                  .value
              )
                .value()
                .toString(),
              pt: childrenLoc?.[stateIndex]?.classCodesInfo?.[classCodeIndex]
                ?.pt?.value
                ? String(
                    childrenLoc?.[stateIndex]?.classCodesInfo?.[classCodeIndex]
                      ?.pt?.value
                  ).trim()
                : "0",
              ft: childrenLoc?.[stateIndex]?.classCodesInfo?.[classCodeIndex]
                ?.ft?.value
                ? String(
                    childrenLoc[stateIndex].classCodesInfo[classCodeIndex].ft
                      .value
                  ).trim()
                : "0",
              description: desc,
            });
            // quoteFactors.payroll += numeral(
            //   childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll
            //     .value
            // ).value();
          }
        }

        let currentDate = Math.floor(Date.now()).toString();
        let uuid = sessionStorage.getItem("agentID");
        if (!uuid) {
          uuid = uuidv4();
        }
        let sortKeyList = [];
        let address = [];
        let quoteData;

        let primay_key = agentID.toLowerCase();
        let generate_id;

        try {
          let generate_id_response = await axios.get(
            awsUrl + "/api/getUniqueID"
          );
          generate_id = generate_id_response.data.id;
        } catch (error) {
          console.log(error);
        }

        let subNo = uuid.split("_");
        subNo.reverse();
        let sub_digit = subNo[0].substring(subNo[0].length - 6);

        // let QUOTE_REFERENCE_ID = generate_id
        //   ? `IC-${moment().format("YY")}${generate_id}`
        //   : "";

        let QUOTE_REFERENCE_ID = `IC - ${sub_digit}`;

        // let dashboardFiles = sessionStorage.getItem("dashboardFiles");
        // try {
        //   if (dashboardFiles) dashboardFiles = JSON.parse(dashboardFiles);
        //   else dashboardFiles = [];
        // } catch (err) {}

        // let preUwFiles = sessionStorage.getItem("preUwFiles");
        // try {
        //   if (preUwFiles) preUwFiles = JSON.parse(preUwFiles);
        //   else preUwFiles = [];
        // } catch (err) {}
        let totalEstAnnualPremium = 0;
        let manualRates;
        let manualRateMap = {};
        let manualRateGovCC;
        const governingCC = JSON.parse(
          sessionStorage.getItem("currProspect")
        )?.["govClassCodeObject"]?.["govClassCode"].split(":")[0];
        for (let place in data) {
          if (!(place in manualRateMap)) manualRateMap[place] = {};
          address.push(place);
          for (let carrier in data[place]) {
            console.log(data[place][carrier]);
            if (data[place][carrier] !== "Dropped") {
              totalEstAnnualPremium +=
                data[place][carrier].total_estimated_annual_premium;
              manualRates = data[place][carrier].manual_rate_values;
              manualRateMap[place][carrier] = manualRates;
              if (!manualRateGovCC) {
                for (let code in manualRates) {
                  if (code === governingCC) {
                    manualRateGovCC = manualRates[code];
                    break;
                  }
                }
              }
              let tempData = {
                user_email_id: `${primay_key}`,
                quotePdfUuid,
                uuid_carrier: `${currentDate}@${uuid}_${carrier}+${place}`,
                date: currentDate,
                effective_date: moment(
                  companyProfile.effectiveDate.value
                ).format("YYYY-MM-DD"),
                expiration_date: moment(companyProfile.effectiveDate.value)
                  .add(1, "y")
                  .format("YYYY-MM-DD"),
                prospect_addresses: prospectAddresses,
                phone_number: companyProfile.phoneNumber.value,
                company_name: companyProfile.companyName.value,
                fein: companyProfile.fein.value,
                liability_limitId: companyProfile?.empLiabilityLimit?.value,
                uuid: uuid,
                carrier: `${carrier}+${place}`,
                carrier_email_id: data[place][carrier].carrier_email,
                carrier_location_data: data[place][carrier],
                currProspectDetails,
                uw_ques: quesList,
                fileListToStore: fileListToStore,
                quote_reference_id: QUOTE_REFERENCE_ID,
              };

              // when working on login
              // if (salespersonName) tempData.salespersonName = salespersonName;

              // if (salespersonNameAttr)
              //   tempData.salespersonNameAttr = salespersonNameAttr;

              sortKeyList.push(tempData.uuid_carrier);
              carrierUserData.push(tempData);
            }
          }
        }
        if (lossRatioObj[3] >= 55 && totalEstAnnualPremium < 6000) {
          eligibility_check["loss_history"]["eligibility_check_3_5"][
            "eligibility"
          ] = "declined";
        } else if (lossRatioObj[3] >= 55 && totalEstAnnualPremium >= 6000) {
          eligibility_check["loss_history"]["eligibility_check_3_5"][
            "eligibility"
          ] = "referred";
        }
        if (totalEstAnnualPremium < 1000) {
          eligibility_check["quote_data"]["eligibility_check_5_1"][
            "eligibility"
          ] = "declined";
        } else {
          eligibility_check["quote_data"]["eligibility_check_5_1"][
            "eligibility"
          ] = "approved";
        }
        console.log("totalEstAnnualPremium", totalEstAnnualPremium);
        //if any change is happening here with 5_2, please check getEligibility function as well.
        if (totalEstAnnualPremium > 15000) {
          eligibility_check["quote_data"]["eligibility_check_5_2"][
            "eligibility"
          ] = "referred";
        } else {
          eligibility_check["quote_data"]["eligibility_check_5_2"][
            "eligibility"
          ] = "approved";
        }

        eligibility_check["quote_data"]["eligibility_check_5_3"][
          "eligibility"
        ] = "approved";

        for (let place in manualRateMap) {
          for (let carrier in manualRateMap[place]) {
            for (let code in manualRateMap[place][carrier]) {
              if (manualRateMap[place][carrier][code] > manualRateGovCC) {
                eligibility_check["quote_data"]["eligibility_check_5_3"][
                  "eligibility"
                ] = "referred";
                break;
              }
            }
          }
        }

        this.props.setEligibilityCheck(eligibility_check);
        for (let tempData of carrierUserData) {
          tempData["eligibilityCheck"] = eligibility_check;
        }
        quoteData = {
          uuid,
          date: currentDate,
        };

        // sessionStorage.setItem("sortKeyList", JSON.stringify(sortKeyList));
        sessionStorage.setItem("quoteData", JSON.stringify(quoteData));
        // sessionStorage.setItem("address", JSON.stringify(address));

        // if (amtrustAccountDetails && amtrustAccountDetails.QuoteId) {
        //   try {
        //     let amtrustSaveQuestionsRequestBody = [];
        //     for (let question of amtrustQuesList) {
        //       amtrustSaveQuestionsRequestBody.push({
        //         QuestionId: question.name,
        //         AnswerValue: question.response ? "yes" : "no",
        //       });
        //     }

        //     let amtrustSaveQuestionsTriggerResponse = await axios.post(
        //       awsUrl2 +
        //         `/api/triggerAmtrustSaveQuestions/${amtrustAccountDetails.QuoteId}`,
        //       amtrustSaveQuestionsRequestBody
        //     );

        //     let saveQuestionsUuid, savePaymentPlansUuid;
        //     if (
        //       amtrustSaveQuestionsTriggerResponse.data.uuid
        //       // &&amtrustSavePaymentPlansTriggerResponse.data.uuid
        //     ) {
        //       saveQuestionsUuid =
        //         amtrustSaveQuestionsTriggerResponse.data.uuid;
        //       // savePaymentPlansUuid =
        //       //   amtrustSavePaymentPlansTriggerResponse.data.uuid;
        //     } else {
        //       throw "error in amtrust create quote trigger";
        //     }

        //     let amtrustQuestionsResponse, amtrustSavePaymentPlansResponse;
        //     do {
        //       let promiseList = [
        //         amtrustResponse(saveQuestionsUuid, "saveQuestions"),
        //         // amtrustResponse(savePaymentPlansUuid, "savePaymentPlans"),
        //       ];

        //       await Promise.all(promiseList)
        //         .then((res) => {
        //           console.log("++res", res);
        //           amtrustQuestionsResponse = res[0];
        //           // amtrustSavePaymentPlansResponse = res[1];
        //         })
        //         .catch((err) => {
        //           console.log("error", err);
        //           throw "error in amtrust quote creation";
        //         });
        //       if (
        //         amtrustQuestionsResponse === "error"
        //         // ||amtrustSavePaymentPlansResponse === "error"
        //       ) {
        //         throw "error in amtrust quote generation";
        //       }
        //     } while (
        //       amtrustQuestionsResponse === "processing"
        //       // ||amtrustSavePaymentPlansResponse === "processing"
        //     );

        //     let amtrustSavePaymentPlansTriggerResponse = await axios.get(
        //       awsUrl2 +
        //         `/api/triggerAmtrustSavePaymentPlans/${amtrustAccountDetails.QuoteId}`
        //     );

        //     if (
        //       // amtrustSaveQuestionsTriggerResponse.data.uuid &&
        //       amtrustSavePaymentPlansTriggerResponse.data.uuid
        //     ) {
        //       // saveQuestionsUuid = amtrustSaveQuestionsTriggerResponse.data.uuid;
        //       savePaymentPlansUuid =
        //         amtrustSavePaymentPlansTriggerResponse.data.uuid;
        //     } else {
        //       throw "error in amtrust create quote trigger";
        //     }

        //     do {
        //       let promiseList = [
        //         // amtrustResponse(saveQuestionsUuid, "saveQuestions"),
        //         amtrustResponse(savePaymentPlansUuid, "savePaymentPlans"),
        //       ];

        //       await Promise.all(promiseList)
        //         .then((res) => {
        //           console.log("++res", res);
        //           // amtrustQuestionsResponse = res[0];
        //           amtrustSavePaymentPlansResponse = res[0];
        //         })
        //         .catch((err) => {
        //           console.log("error", err);
        //           throw "error in amtrust quote creation";
        //         });
        //       if (
        //         // amtrustQuestionsResponse === "error" ||
        //         amtrustSavePaymentPlansResponse === "error"
        //       ) {
        //         throw "error in amtrust quote generation";
        //       }
        //     } while (
        //       // amtrustQuestionsResponse === "processing" ||
        //       amtrustSavePaymentPlansResponse === "processing"
        //     );
        //   } catch (error) {
        //     console.log("error in amtrust quote generation", error);
        //   }
        // }

        // if (sessionStorage.getItem("landingEmail")) {
        //   let uploadDataa = [];
        //   let userTrackingData = {};
        //   userTrackingData.email = sessionStorage.getItem("landingEmail");
        //   let currProspct = JSON.parse(sessionStorage.getItem("currProspect"));
        //   userTrackingData.timestamp = visitTimestamp;
        //   userTrackingData.domain = sessionStorage.getItem("domain");
        //   userTrackingData.companyProfile = currProspct.companyProfile;
        //   userTrackingData.emodStatesData = currProspct.emodStatesData;
        //   userTrackingData.indicationCost = quoteFactors.total;
        //   userTrackingData.payrollData = currProspct.childrenLoc;
        //   userTrackingData.uuid = sessionStorage.getItem("uuid");
        //   userTrackingData.address = address;
        //   userTrackingData.quoteData = quoteData;
        //   userTrackingData.uwQues = uwQues;

        //   if (salespersonMailCampaign) {
        //     userTrackingData.salespersonName = salespersonName;
        //     userTrackingData.salespersonNameAttr = salespersonNameAttr;
        //     userTrackingData.salesEngaged = "true";
        //   }

        //   uploadDataa.push(userTrackingData);
        //   // console.log("*************************");
        //   // console.log("********uploadDataa******");
        //   // console.log(uploadDataa);
        //   await axios
        //     .post(awsUrl2 + "/api/updateUsersTrackingData", uploadDataa)
        //     .then((res) => {
        //       console.log("posted data", res);
        //     })
        //     .catch((error) => {
        //       console.log("error in posting data to user tracking", error);
        //     });
        // }
        try {
          if (agentpersonCheck) {
            let agentPersonData = {};
            let type = "update";
            let dateNow = Math.floor(Date.now()).toString();

            agentPersonData.id = agentID;
            agentPersonData.updated_timestamp = dateNow;
            agentPersonData.formStage = "three";
            agentPersonData.agentName = agentpersonName;
            agentPersonData.uwQues = quesList;
            agentPersonData.address = address;
            agentPersonData.quoteData = quoteData;
            agentPersonData.sortKeyList = sortKeyList;
            agentPersonData.currProspect = currProspectDetails;
            agentPersonData.eligibilityCheck = eligibility_check;
            agentPersonData.totalPremium = totalEstAnnualPremium;
            agentPersonData.quoteGeneratedTimestamp = currentDate;
            agentPersonData.UWeligibilityCheckStatus = "";
            agentPersonData.quote_reference_id = QUOTE_REFERENCE_ID;

            await axios
              .post(
                awsUrl2 + `/api/agentPersonData/${type}`,
                JSON.stringify(agentPersonData)
              )
              .then(() => {})
              .catch((error) => {
                console.log("error", error);
              });
          }
        } catch (error) {
          console.log("sales person flow error: ", error);
        }

        let prevIndex = 0;
        let nextIndex = 100;
        let uploadData;
        for (let i = 0; i < Math.floor(carrierUserData.length / 100) + 1; i++) {
          uploadData = carrierUserData.slice(prevIndex, nextIndex);
          axios
            .post(awsUrl + "/api/postUsersData", uploadData)
            .then(async (res) => {
              let sessionData;
              let quoteGenerate = true;
              let declineEligibilityStmtList = [],
                finalEligibility;
              try {
                sessionData = await Auth.currentAuthenticatedUser();

                let currProspect = sessionStorage.getItem("currProspect")
                  ? JSON.parse(sessionStorage.getItem("currProspect"))
                  : undefined;
                let addressSession = sessionStorage.getItem("address")
                  ? JSON.parse(sessionStorage.getItem("address"))
                  : address;
                quoteData = sessionStorage.getItem("quoteData")
                  ? JSON.parse(sessionStorage.getItem("quoteData"))
                  : quoteData;

                // let agentID = sessionStorage.getItem("agentID");

                let childrenLoc = currProspect.childrenLoc;
                let stateList = [];
                for (let childrenLocIndx in childrenLoc) {
                  let childrenLocObj = childrenLoc[childrenLocIndx];
                  if (childrenLocObj.state && childrenLocObj.state.value) {
                    stateList.push(childrenLocObj.state.value.toLowerCase());
                  }
                }
    
                try {
                  const response = await axios.get(
                    `${awsUrl}/api/getUserDataStatus/${agentID}/quote`
                  );
                  const dataFromAPI = response.data;

                  if (dataFromAPI.carrierBasedData) {
                    const firstCarrierObject =
                      dataFromAPI.carrierBasedData[
                        Object.keys(dataFromAPI.carrierBasedData)[0]
                      ];

                    let firstLineObject =
                      firstCarrierObject[Object.keys(firstCarrierObject)[0]];

                    if (firstLineObject.date && firstLineObject.uuid) {
                      quoteData = {
                        uuid: firstLineObject.uuid,
                        date: firstLineObject.date,
                      };
                    }
                    if (firstCarrierObject) {
                      const firstLineObject =
                        firstCarrierObject[Object.keys(firstCarrierObject)[0]];

                      if (firstLineObject.eligibilityCheck) {
                        finalEligibility = getFinalEligibility(
                          firstLineObject.eligibilityCheck
                        );
                        if (finalEligibility === "declined") {
                          declineEligibilityStmtList = await getDeclinedStatementList(
                            firstLineObject.eligibilityCheck
                          );
                        }
                      }
                    }
                  }
                } catch (error) {
                  console.log("Error:", error);
                }

                let agency_codeApi;
                try {
                  agency_codeApi = await axios.post(
                    awsUrl2 + "/api/getAgencyCode",
                    { userId: agentID }
                  );
                  
                } catch (error) {
                  console.log("erorr:", error);
                }

                // finalEligibility = "decline";
                let isLoggedIn;
                try {
                  isLoggedIn = await Auth.currentAuthenticatedUser();
                } catch (error) {
                  console.log("Authentication error here", error);
                }
                let etQuoteDetails = {
                  email: agentID,
                  timestamp: quoteData.date,
                  carrier: `carrier_ax`,
                  mail_address: "",
                  address: addressSession,
                  state: stateList,
                  netRateMap: {},
                  agent_name: isLoggedIn?.attributes.name || " ",
                  agent_email: isLoggedIn?.attributes.email || " ",
                  agent_agency: isLoggedIn?.attributes["custom:agency"] || " ",
                  uw_name: isLoggedIn?.attributes["custom:uw"] || " ",
                  agency_code:
                    agency_codeApi?.data?.agency_code ||
                    isLoggedIn?.attributes["custom:agency_code"] ||
                    "",
                };

                let userDetails = {
                  companyName: companyProfile.companyName.value,
                  loggedInUser: sessionData
                    ? `${sessionData.attributes.name} (${sessionData.attributes.email})`
                    : "",
                  agentID: agentID,
                };

                let responseBufferApprove = await fubaQuotePdfGenerator(
                  quoteGenerate
                );

                let responseBufferDecline = await downloadFubaDeclinationQuotePdf(
                  declineEligibilityStmtList,
                  quoteData,
                  quoteGenerate
                );

                // console.log("quote uuid", quotePdfUuid);
                // console.log("responseBufferApprove", responseBufferApprove);
                // console.log("responseBufferDecline", responseBufferDecline);

                let dashboardFiles =
                  JSON.parse(sessionStorage.getItem("dashboardFiles")) || [];
                let fileListToStore =
                  JSON.parse(sessionStorage.getItem("fileListToStore")) || [];

                await this.uploadQuoteFileToS3(
                  quotePdfUuid,
                  responseBufferDecline,
                  "declined"
                );
                await this.uploadQuoteFileToS3(
                  quotePdfUuid,
                  responseBufferApprove,
                  "approved"
                );
                await sendAcordQuoteEmail(
                  userDetails,
                  etQuoteDetails,
                  fileListToStore,
                  dashboardFiles,
                  quotePdfUuid,
                  finalEligibility
                );
                // $("#loader").css("display", "none");
                console.log("posted data", res);
              } catch (error) {}
            })
            .catch((error) => {
              console.log("error in posting data", error);
            })
            .finally(() => {
              sessionStorage.setItem("formStage", "three");
              // sessionStorage.setItem(
              //   "quoteFactors",
              //   JSON.stringify(quoteFactors)
              // );
              $("#loader").css("display", "none");
              window.location.reload();
            });
        }
      } else {
        sessionStorage.setItem("formStage", "two");
        $("#loader").css("display", "none");
        // window.location.reload();
      }
      // }
    } catch (error) {
      console.log("error", error);
      $("#loader").css("display", "none");
    }
  };

  validateHistoricalClaims = (
    historicalClaims,
    historicalClaimsCheck,
    currYear
  ) => {
    let {
      historicalClaimsError,
      cliamZeroCheckAmountPaid,
      cliamZeroCheckReserve,
      claimOneWithTotalPayroll,
    } = this.state;
    let historicalClaimsCheckCopy = JSON.parse(
      JSON.stringify(historicalClaimsCheck)
    );
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let historyLimitYear =
      currYear - currProspect?.companyProfile?.yearsInBusiness?.value;
    //console.log("historyLimitYear", historyLimitYear);
    let checkMap = {
      falseOne: false,
      trueMid: false,
      falseTwo: false,
    };

    let validHistoricalClaims;

    for (let i = currYear; i > currYear - 5; i--) {
      if (!historicalClaimsCheckCopy[i]) historicalClaimsCheckCopy[i] = false;
    }

    for (let year in historicalClaimsCheckCopy) {
      if (historicalClaimsCheckCopy[year] === false) {
        if (!checkMap.falseOne) {
          checkMap.falseOne = true;
        } else if (checkMap.trueMid) {
          checkMap.falseTwo = true;
        }
      } else {
        if (checkMap.falseOne) {
          checkMap.trueMid = true;
        }
      }
    }
    let tempFlag = !(
      checkMap.falseOne &&
      checkMap.trueMid &&
      checkMap.falseTwo
    );
    // if you want the consecutive functionality to work make use below line and comment the consecutiveYearErrorFlag = false;
    // let consecutiveYearErrorFlag = !tempFlag;

    let consecutiveYearErrorFlag = false;

    let historicalClaimsErrorStore = {};
    let keys = ["carrier", "claim", "annPre", "totInc"];
    let emptyFieldFlag = false;
    // console.log("historicalClaimsCheckCopy", historicalClaimsCheckCopy);
    // console.log("historicalClaims", historicalClaims);
    for (let year in historicalClaimsCheckCopy) {
      if (!historicalClaimsErrorStore[year]) {
        historicalClaimsErrorStore[year] = {};
      }
      for (let key of keys) {
        if (
          historicalClaims?.[year] &&
          historicalClaims?.[year]?.[key] &&
          historicalClaims?.[year]?.[key].trim() !== ""
        ) {
          historicalClaimsErrorStore[year][key] = false;
        } else {
          if (!historicalClaimsCheckCopy[year] && year >= historyLimitYear) {
            historicalClaimsErrorStore[year][key] = true;
            emptyFieldFlag = true;
          }
        }

        if (
          historicalClaims?.[year]?.hasOwnProperty("claim") &&
          historicalClaims?.[year]?.["claim"].trim() !== ""
        ) {
          if (historicalClaims?.[year]?.["claim"].trim() !== "0") {
            if (historicalClaims?.[year]?.hasOwnProperty("totInc")) {
              if (historicalClaims?.[year]?.["totInc"] === "$0") {
                historicalClaimsErrorStore[year] = {
                  totInc: true,
                };
                emptyFieldFlag = true;
                claimOneWithTotalPayroll[year] = true;
              } else {
                claimOneWithTotalPayroll[year] = false;
              }
            }
          } else {
            claimOneWithTotalPayroll[year] = false;
          }

          if (
            historicalClaims?.[year]?.hasOwnProperty("claim") &&
            historicalClaims?.[year]?.["claim"].trim() === "0"
          ) {
            if (historicalClaims?.[year]?.hasOwnProperty("totInc")) {
              if (
                parseInt(
                  historicalClaims?.[year]?.["totInc"]?.replace("$", "")
                ) !== 0
              ) {
                historicalClaimsErrorStore[year]["totInc"] = true;
                emptyFieldFlag = true;
                cliamZeroCheckAmountPaid[year] = true;
              } else {
                cliamZeroCheckAmountPaid[year] = false;
              }
            }

            if (historicalClaims?.[year]?.hasOwnProperty("totRes")) {
              if (
                parseInt(
                  historicalClaims?.[year]?.["totRes"]?.replace("$", "")
                ) !== 0
              ) {
                historicalClaimsErrorStore[year]["totRes"] = true;
                emptyFieldFlag = true;
                cliamZeroCheckReserve[year] = true;
              } else {
                cliamZeroCheckReserve[year] = false;
              }
            }
          } else {
            cliamZeroCheckAmountPaid[year] = false;
            cliamZeroCheckReserve[year] = false;
          }
        }
      }
    }

    validHistoricalClaims = !consecutiveYearErrorFlag && !emptyFieldFlag;
    historicalClaimsError = consecutiveYearErrorFlag;
    this.setState({
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsError,
      historicalClaimsErrorStore,
      cliamZeroCheckAmountPaid,
      claimOneWithTotalPayroll,
      cliamZeroCheckReserve,
    });

    return {
      validHistoricalClaims,
      historicalClaimsError: consecutiveYearErrorFlag,
      historicalClaimsErrorStore,
    };
  };

  createQuestionsData = () => {
    let { quesList, amtrustQuesList } = JSON.parse(JSON.stringify(this.state));
    for (let question of [...quesList, ...amtrustQuesList]) {
      if (question.response === true) {
        question.response = "Y";
      } else if (question.response === false) {
        question.response = "N";
      }
    }

    return { quesList, amtrustQuesList };
  };

  saveQuestionsResponse = async () => {
    try {
      $("#loader").css("display", "block");

      let { quesList, amtrustQuesList } = this.createQuestionsData();

      let userTrackingData = {
        domain: sessionStorage.getItem("domain"),
        quesList,
        amtrustQuesList,
      };

      await axios
        .post(awsUrl2 + "/api/updatePreUnderwriter/generic", userTrackingData)
        .then((res) => {
          console.log("posted data", res);
          let staticQuestions = this.setQuestions(quesList);
          let amtrustQuestions = this.setQuestions(amtrustQuesList);
          sessionStorage.setItem("quesList", JSON.stringify(staticQuestions));
          sessionStorage.setItem(
            "amtrustQuesList",
            JSON.stringify(amtrustQuestions)
          );
          this.setState({
            preUwFlowMessage: "Questions saved successfully!",
          });
        })
        .catch((error) => {
          console.log("error in saving questions", error);
          this.setState({
            preUwFlowMessage: "Error in saving questions!",
          });
        })
        .finally(() => {
          $("#loader").css("display", "none");
        });
    } catch (error) {
      console.log("error in saving question responses", error);
      this.setState({
        preUwFlowMessage: "Error in saving questions!",
      });
      $("#loader").css("display", "none");
    }
  };

  clearAllQuestions = () => {
    let { quesList, amtrustQuesList } = JSON.parse(JSON.stringify(this.state));

    for (let question of quesList) {
      delete question.response;
    }

    try {
      for (let question of amtrustQuesList) {
        delete question.response;
      }
    } catch (error) {
      console.log("unabe to delete paygo question responses");
      amtrustQuesList = [];
    }

    this.setState({ quesList, amtrustQuesList });
  };

  handleChange = (e, key) => {
    let { historicalClaims } = this.state;
    console.log("historicalClaims: ", historicalClaims);
    let year = e.target.id.split("-")[1];

    if (!historicalClaims?.[year]) {
      historicalClaims[year] = {};
    }
    historicalClaims[year][key] = e.target.value.trimStart();

    if (key === "totInc") {
      historicalClaims = histClaimCalcTI(historicalClaims, year);
    }
    if (key === "totPaid") {
      historicalClaims = histClaimCalcTP(historicalClaims, year);
    }
    if (key === "totRes") {
      historicalClaims = histClaimCalcTR(historicalClaims, year);
    }

    if (key === "claim") {
      if (!historicalClaims[year]["totInc"]) {
        historicalClaims[year]["totInc"] = "$0";
      }

      if (!historicalClaims[year]["totRes"]) {
        historicalClaims[year]["totRes"] = "$0";
      }
    }
    this.setState({ historicalClaims });
  };
  // historicalonBlurHandler = (event) => {
  //   let { companyProfile, historicalClaims } = this.state;
  //   let historicalClaimsCheck = {};

  //   let currYear =
  //     new Date(companyProfile.effectiveDate.value).getFullYear() ||
  //     new Date().getFullYear();
  //   console.log("currYear: ", currYear);

  //   let historyLimitYear = currYear - event.target.value;
  //   console.log("historyLimitYear: ", historyLimitYear);
  //   for (let i = 0; i < 5; i++) {
  //     if (currYear >= historyLimitYear) {
  //       historicalClaimsCheck[currYear] = false;
  //     } else {
  //       historicalClaimsCheck[currYear] = true;
  //     }
  //     currYear--;
  //   }

  //   this.setHistoricalClaims();

  //   this.setState({ companyProfile, historicalClaimsCheck, historicalClaims });
  // };

  handleTotPaidBlur = (e) => {
    // zeroDefault(e);
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];
    historicalClaims = histClaimCalcTP(historicalClaims, year);
    this.setState({ historicalClaims });
  };

  createTable = () => {
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));

    let table = [];
    let j = 0;
    let {
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsErrorStore,
      // submitClicked,
    } = this.state;

    historicalClaimsErrorStore = !historicalClaimsErrorStore
      ? {}
      : historicalClaimsErrorStore;
    historicalClaims = !historicalClaims ? {} : historicalClaims;
    historicalClaimsCheck = !historicalClaimsCheck ? {} : historicalClaimsCheck;

    let loss_data = currProspect ? currProspect.historicalClaims : null;
    let loss_data_index;
    let historyLimitYear = 0;
    let backgroundColor = "";
    let absYear;

    let rowId, rowIdDup, rowIdYear, rowIdDupYear;

    rowIdYear =
      new Date(
        currProspect?.companyProfile?.effectiveDate.value
      ).getFullYear() - 1 || new Date().getFullYear();

    rowIdDupYear =
      new Date(
        currProspect?.companyProfile?.effectiveDate.value
      ).getFullYear() - 1 || new Date().getFullYear();

    let currYear =
      new Date(
        currProspect?.companyProfile?.effectiveDate.value
      ).getFullYear() - 1 || new Date().getFullYear();

    historyLimitYear =
      currYear - currProspect?.companyProfile?.yearsInBusiness?.value;

    //  console.log("historyLimitYear:in ", historyLimitYear);

    for (let i = 0; i < 10; i++) {
      rowId = rowIdYear - j;
      rowIdDup = rowIdDupYear - i;
      absYear = !absYear ? rowIdDup : absYear;
      if (historicalClaimsCheck[absYear]) {
        backgroundColor = "#dddddd";
      } else {
        backgroundColor = "";
      }
      rowId = rowId.toString();
      if (i % 2 === 0) {
        j++;
        let yearId = Number(rowId);
        // loss_data_index = loss_data
        //   ? loss_data.findIndex((e) => e.year === rowId)
        //   : null;
        table.push(
          <tr
            id={rowIdDup}
            key={rowIdDup}
            className={isMobile ? `row ` : `historialRow`}
          >
            <td className={isMobile ? `col-sm-4` : `per100`}>
              <Form.Group>
                <label for={`carrier-${absYear}`} className="mandatory">
                  Carrier
                </label>

                <input
                  autoComplete="off"
                  id={`carrier-${absYear}`}
                  type="text"
                  name="carText"
                  onChange={(e) => this.handleChange(e, "carrier")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].carrier
                        ? "red"
                        : "",
                  }}
                  disabled={
                    historyLimitYear > yearId || historicalClaimsCheck[absYear]
                  }
                  onKeyPress={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "carrier");
                  }}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["carrier"]
                      ? historicalClaims[absYear]["carrier"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className={isMobile ? `col-sm-4` : `per100`}>
              <Form.Group>
                <label
                  for={`annPre-${absYear}`}
                  className="mandatory"
                  title="Annual Premium"
                >
                  Annual Premium
                </label>
                <NumberFormat
                  name="anualPremium"
                  id={`annPre-${absYear}`}
                  autoComplete="off"
                  onKeyPress={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "annPre");
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "-" || event.key === "−") {
                      event.preventDefault(); // Prevent typing the '-' character
                    }
                  }}
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "annPre")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].annPre
                        ? "red"
                        : "",
                  }}
                  disabled={
                    historyLimitYear > yearId || historicalClaimsCheck[absYear]
                  }
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["annPre"]
                      ? historicalClaims[absYear]["annPre"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className={isMobile ? `col-sm-4` : `per50`}>
              <Form.Group>
                <label
                  title="Number of claims"
                  for={`claims-${absYear}`}
                  className="mandatory"
                >
                  # of Claims
                </label>
                <NumberFormat
                  name="claims"
                  id={`claims-${absYear}`}
                  format="####"
                  autoComplete="off"
                  onKeyPress={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "claim");
                  }}
                  onChange={(e) => this.handleChange(e, "claim")}
                  onKeyDown={(event) => {
                    if (event.key === "-" || event.key === "−") {
                      event.preventDefault(); // Prevent typing the '-' character
                    }
                  }}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].claim
                        ? "red"
                        : "",
                  }}
                  disabled={
                    historyLimitYear > yearId || historicalClaimsCheck[absYear]
                  }
                  className="text-center"
                  type="text"
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["claim"]
                      ? historicalClaims[absYear]["claim"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            <td className={isMobile ? `col-sm-4` : `per100`}>
              <Form.Group>
                <label
                  for={`totInc-${absYear}`}
                  className="mandatory"
                  title="Amount Paid"
                >
                  Amount Paid
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "totInc");
                  }}
                  id={`totInc-${absYear}`}
                  autoComplete="off"
                  name="totInc"
                  thousandSeparator={true}
                  onKeyDown={(event) => {
                    if (event.key === "-" || event.key === "−") {
                      event.preventDefault(); // Prevent typing the '-' character
                    }
                  }}
                  onChange={(e) => this.handleChange(e, "totInc")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].totInc
                        ? "red"
                        : "",
                  }}
                  disabled={
                    historyLimitYear > yearId || historicalClaimsCheck[absYear]
                  }
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  // onBlur={(e) => this.zeroDefault(e, "totInc")}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["totInc"]
                      ? historicalClaims[absYear]["totInc"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
            {/* <td className="per100">
              <Form.Group>
                <label for={`totPaid-${absYear}`} title="Total Paid">
                  Total Paid
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "totPaid");
                  }}
                  onBlur={this.handleTotPaidBlur}
                  id={`totPaid-${absYear}`}
                  autoComplete="off"
                  name="totPaid"
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "totPaid")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].totPaid
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center"
                  type="text"
                  // onBlur={zeroDefault}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["totPaid"]
                      ? historicalClaims[absYear]["totPaid"]
                      : ""
                  }
                />
              </Form.Group>
            </td> */}
            <td className={isMobile ? `col-sm-4` : `per100`}>
              <Form.Group>
                <label for={`totRes-${absYear}`} title="Total Reserves">
                  Reserves
                </label>
                <NumberFormat
                  onFocus={(e) => {
                    this.clearErr(e, historicalClaimsErrorStore, "totRes");
                  }}
                  id={`totRes-${absYear}`}
                  autoComplete="off"
                  onKeyDown={(event) => {
                    if (event.key === "-" || event.key === "−") {
                      event.preventDefault(); // Prevent typing the '-' character
                    }
                  }}
                  name="totRes"
                  thousandSeparator={true}
                  onChange={(e) => this.handleChange(e, "totRes")}
                  style={{
                    backgroundColor: historyLimitYear > rowId ? "#dddddd" : "",
                    backgroundColor: backgroundColor,
                    borderColor:
                      // submitClicked &&
                      historicalClaimsErrorStore[absYear] &&
                      historicalClaimsErrorStore[absYear].totRes
                        ? "red"
                        : "",
                  }}
                  disabled={historyLimitYear > rowId}
                  prefix={"$"}
                  className="text-center "
                  type="text"
                  // onBlur={zeroDefault}
                  value={
                    historicalClaims &&
                    historicalClaims[absYear] &&
                    historicalClaims[absYear]["totRes"]
                      ? historicalClaims[absYear]["totRes"]
                      : ""
                  }
                />
              </Form.Group>
            </td>
          </tr>
        );
      } else {
        let yearId = Number(rowId) + 1;
        table.push(
          <tr id={rowIdDup} key={rowIdDup} className="row-no-coverage my-auto">
            <td className="no-coverage">
              <input
                type="checkbox"
                id={`check-${absYear}`}
                disabled={historyLimitYear >= yearId}
                checked={
                  historyLimitYear > yearId ||
                  (historicalClaimsCheck[absYear]
                    ? historicalClaimsCheck[absYear]
                    : false)
                }
                onChange={(e) => this.handleCheck(e)}
              />
              {`No coverage for the year
               ${yearId}`}
            </td>
            {/* <td className="per5 text-left">coverage for the {yearId}</td> */}
          </tr>
        );
        absYear--;
      }
    }
    return table;
  };
  // amtrustGetQuestionsResponse = (uuid) => {
  //   let promise = new Promise(async (resolve, reject) => {
  //     try {
  //       let res = await axios.get(
  //         awsUrl2 + `/api/getAmtrustData/${uuid}/getQuestions`
  //       );
  //       resolve(res.data);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });

  //   return promise;
  // };

  // amtrustGetQuestionsPreQuoteResponse = (uuid) => {
  //   let promise = new Promise(async (resolve, reject) => {
  //     try {
  //       let res = await axios.get(
  //         awsUrl2 + `/api/getAmtrustData/${uuid}/getQuestionsPreQuote`
  //       );
  //       resolve(res.data);
  //     } catch (error) {
  //       reject(error);
  //     }
  //   });

  //   return promise;
  // };

  prefillQuestionResponse = (quesList, savedQuestions) => {
    try {
      let quesListCopy = JSON.parse(JSON.stringify(quesList));
      if (savedQuestions) {
        for (let question of quesListCopy) {
          for (let sQues of savedQuestions) {
            if (sQues.name === question.name) {
              question.response = sQues.response;
            }
          }
        }
      }

      return quesListCopy;
    } catch (error) {
      console.log("error while autofilling questions responses", error);
    }
  };

  prefillQuesitons = (questions, uwResponses, questionSetName) => {
    try {
      let savedResponses = {};
      for (let question of uwResponses) {
        savedResponses[question.name] = question;
      }

      for (let question of questions) {
        if (question.name in savedResponses) {
          question.response = savedResponses[question.name].response;
        } else if (question.paygoName in savedResponses) {
          question.response = savedResponses[question.paygoName].response;
        }
      }

      this.setState({ [questionSetName]: questions });
    } catch (error) {
      console.log(error);
    }
  };

  prefillUwResponses = () => {
    try {
      let { quesList, amtrustQuesList } = this.state;
      let uwQuesList, uwAmtrustQuesList;
      let questionLists = [];
      try {
        uwQuesList = JSON.parse(sessionStorage.getItem("uwQuesList"));
      } catch (error) {}
      try {
        uwAmtrustQuesList = JSON.parse(
          sessionStorage.getItem("uwAmtrustQuesList")
        );
      } catch (error) {}

      if (
        quesList &&
        uwQuesList &&
        quesList?.length > 0 &&
        uwQuesList?.length > 0
      ) {
        this.prefillQuesitons(quesList, uwQuesList, "quesList");
      }

      if (
        amtrustQuesList &&
        uwAmtrustQuesList &&
        amtrustQuesList?.length > 0 &&
        uwAmtrustQuesList?.length > 0
      ) {
        this.prefillQuesitons(
          amtrustQuesList,
          uwAmtrustQuesList,
          "amtrustQuesList"
        );
      }
    } catch (error) {
      console.log("++", error);
    }
  };

  async componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);
    $("#loader").css("display", "block");
    // try {
    //   let {
    //     salesManagerPersonName,
    //     salesManagerCheck,
    //     salesManagerPersonNameAttr,
    //   } = await salesManagerLoginCheck();

    //   if (salesManagerCheck) {
    //     this.setState({
    //       salesManagerCheck,
    //       salesManagerPersonName,
    //     });
    //   }
    // } catch (error) {
    //   console.log("checking salesmanager login and failed");
    // }
    try {
      let formStage = sessionStorage.getItem("formStage");
      if (!formStage || formStage === "gov_cc") return;
      // let preUnderwriter = sessionStorage.getItem("preUnderwriter") === "true";
      let currProspect;
      currProspect = sessionStorage.getItem("currProspect");
      let fileListToStoredata;
      fileListToStoredata = sessionStorage.getItem("fileListToStore");
      try {
        currProspect = JSON.parse(currProspect);
        fileListToStoredata = JSON.parse(fileListToStoredata);
      } catch (error) {}
      let historicalClaimsCheck = currProspect?.historicalClaimsCheck || {};
      let historicalClaims = currProspect?.historicalClaims || {};

      // agent check

      let {
        agentpersonName,
        agentpersonCheck,
        agentpersonNameAttr,
        agentusername,
      } = await agentPersonLoginCheck();
      this.setState({ agentpersonCheck, agentpersonName, agentusername });

      // set the static uw question responses

      try {
        if (currProspect) {
          let quesList = [];
          if (currProspect.uwQues) {
            quesList = currProspect.uwQues;
            this.setUWQues(quesList);
          } else if (currProspect.acordUwQues) {
            quesList = currProspect.acordUwQues;
            this.setUWQues(quesList);
          } else {
            try {
              quesList = acordQues;
            } catch (error) {
              console.log("error", error);
            } finally {
              this.setState({ quesList });
            }
            // this.prefillUwResponses();
          }
          if (currProspect.uwDataConfirmed)
            this.setState({
              uwDataConfirmed: currProspect.uwDataConfirmed,
            });
          if (fileListToStoredata) {
            let fileListToStore = fileListToStoredata;
            let fileList = {
              first: {},
              second: {},
            };
            for (let row in fileListToStore) {
              if (!fileList[fileListToStore[row].keyToFile])
                fileList[fileListToStore[row].keyToFile] = {};
              fileList[fileListToStore[row].keyToFile][
                fileListToStore[row].key.split("/").reverse()[0]
              ] = "#empty";
            }
            this.setState({ fileList, fileListToStore });
          }
        }
      } catch (error) {
        console.log("error", error);
      }
      //url login details
      let isLoggedIn;
      let agent_name;
      let agency_name;
      let uw_name;
      let agency_code;
      try {
        isLoggedIn = await Auth.currentAuthenticatedUser();
        agent_name = isLoggedIn?.attributes.name
          ? isLoggedIn?.attributes.name
          : "";
        agency_name = isLoggedIn?.attributes["custom:agency"]
          ? isLoggedIn?.attributes["custom:agency"]
          : "";
        uw_name = isLoggedIn?.attributes["custom:uw"]
          ? isLoggedIn?.attributes["custom:uw"]
          : "";
        agency_code = isLoggedIn?.attributes["custom:agency_code"] || "";
      } catch (error) {
        console.log("Authentication error here", error);
      }

      try {
        if (currProspect) {
          // let historicalClaimsCheck = {};

          if (currProspect.historicalClaimsCheck) {
            this.setState({ historicalClaimsCheck, historicalClaims });
          } else if (currProspect?.companyProfile?.effectiveDate?.value) {
            let currYear =
              new Date(
                currProspect?.companyProfile?.effectiveDate?.value
              ).getFullYear() || new Date().getFullYear();

            let historyLimitYear;
            if (currProspect && currProspect?.companyProfile?.yearsInBusiness) {
              historyLimitYear =
                currYear - currProspect?.companyProfile?.yearsInBusiness?.value;
            }

            currYear = currYear - 1;
            for (let i = 0; i < 5; i++) {
              if (currYear >= historyLimitYear) {
                historicalClaimsCheck[currYear] = false;
              } else {
                historicalClaimsCheck[currYear] = true;
              }
              currYear--;
            }

            this.setHistoricalClaims();

            try {
              let acordHistoricalClaims = currProspect?.acordHistoricalClaims;
              if (formStage === "two" && acordHistoricalClaims) {
                for (let year in historicalClaimsCheck) {
                  if (year in acordHistoricalClaims) {
                    historicalClaims[year] = {
                      carrier: acordHistoricalClaims[year]?.carrier || "",
                      annPre: acordHistoricalClaims[year]?.premium || "",
                      claim: acordHistoricalClaims[year]?.claims || "",
                      totInc: acordHistoricalClaims[year]?.paid || "",
                      totRes: acordHistoricalClaims[year]?.reserve || "",
                    };
                  }
                }
              }
            } catch (error) {}
          }
          this.setState({ historicalClaimsCheck, historicalClaims });
        }
      } catch (error) {}

      //       if (currProspect.uwExpectedResponses) {
      //         this.setState({
      //           uwExpectedResponses: currProspect.uwExpectedResponses,
      //         });
      //       }

      //       // this.getUniqueAmtrustQuestions();
      //     }
      //   } catch (error) {
      //     console.log(
      //       "error while setting static uw question responses",
      //       error
      //     );
      //   }
      // }

      // if (preUnderwriter && currProspect.childrenLoc) {
      //   try {
      //     let ClassCodeList = [];
      //     let amtrustQuesList = [];
      //     let childrenLoc = currProspect.childrenLoc;

      //     for (let locIndx in childrenLoc) {
      //       let state = childrenLoc[locIndx].state.value;
      //       let classCodesInfo = childrenLoc[locIndx].classCodesInfo;
      //       for (let ccIndx in classCodesInfo) {
      //         let cc_desc = classCodesInfo[ccIndx].classCodeDescription.value;
      //         let code = cc_desc.split(":")[0];
      //         ClassCodeList.push({
      //           ClassCode: code,
      //           LocState: state,
      //         });
      //       }
      //     }

      //     let amtrustGetPreQuoteResponse = await axios.post(
      //       awsUrl2 + `/api/triggerAmtrustGetQuestionsPreQuote`,
      //       JSON.stringify({
      //         ClassCodeList,
      //       })
      //     );

      //     let uuid;
      //     if (amtrustGetPreQuoteResponse.data.uuid) {
      //       uuid = amtrustGetPreQuoteResponse.data.uuid;
      //     } else {
      //       throw "error in amtrust create quote trigger";
      //     }

      //     let amtrustQuestionsResponse;
      //     do {
      //       amtrustQuestionsResponse = await this.amtrustGetQuestionsPreQuoteResponse(
      //         uuid
      //       );
      //       if (amtrustQuestionsResponse === "error") {
      //         throw "error in amtrust get questions";
      //       }
      //     } while (amtrustQuestionsResponse === "processing");

      //     if (amtrustQuestionsResponse.StatusCode === "200") {
      //       let idList = [];
      //       for (let question of amtrustQuestionsResponse.Questions) {
      //         // remove duplicate question ids
      //         if (!idList.includes(question.QuestionId)) {
      //           amtrustQuesList.push({
      //             name: question.QuestionId,
      //             ques: question.Question,
      //           });
      //           idList.push(question.QuestionId);
      //         }
      //       }

      //       // prefill the amtrust question responses if saved earlier
      //       try {
      //         let savedQuestions = JSON.parse(
      //           sessionStorage.getItem("currProspect")
      //         ).amtrustQuesList;
      //         if (savedQuestions) {
      //           console.log("inside saved questions");
      //           // savedQuestions = JSON.parse(savedQuestions);
      //           amtrustQuesList = this.prefillQuestionResponse(
      //             amtrustQuesList,
      //             savedQuestions
      //           );
      //           console.log("amtrustQuesList: ", amtrustQuesList);
      //           console.log("saved: ", savedQuestions);
      //         }
      //       } catch (error) {
      //         console.log("error while autofilling amtrust question responses");
      //       }
      //       this.setState({ amtrustQuesList });
      //     }

      //     // prefill the static question responses if saved earlier

      // try {
      // if (currProspect) {
      //   let quesList;
      //   if (currProspect.uwQues) {
      //     quesList = currProspect.uwQues;
      //     this.setState({ quesList });
      //     // this.setUWQues(quesList);
      //   } else {
      //     try {
      //       quesList = await this.getQuesList();
      //     } catch (error) {
      //       console.log("error", error);
      //     } finally {
      //       this.setState({ quesList });
      //     }
      //   }
      // }

      //         if (currProspect.uwExpectedResponses) {
      //           this.setState({
      //             uwExpectedResponses: currProspect.uwExpectedResponses,
      //           });
      //         }
      //       }
      //     } catch (error) {
      //       console.log(
      //         "error while setting static uw question responses",
      //         error
      //       );
      //     }
      //   } catch (error) {
      //     console.log("error in working with preUnderwriter flag", error);
      //   }
      // }

      // try {
      //   if (preUnderwriter) {
      //     let clientEligibility = sessionStorage.getItem("clientEligibility");
      //     if (clientEligibility) {
      //       this.setState({ clientEligibility });
      //     }
      //   }
      //   if (preUnderwriter && currProspect.preUwStatus) {
      //     this.setState({ preUwStatus: currProspect.preUwStatus });
      //   }
      // } catch (error) {}
    } catch (error) {
      console.log(error);
    } finally {
      $("#loader").css("display", "none");
    }
  }

  getUniqueAmtrustQuestions = () => {
    try {
      let { amtrustQuesList, uwExpectedResponses } = this.state;
      // if (uwAmtrustQuesList && uwExpectedResponses) {
      console.log("++", amtrustQuesList, uwExpectedResponses);

      // }
    } catch (error) {
      console.log("++ error in removing duplicate amtrust questions", error);
    }
  };

  putPeoCampaignData = async (item) => {
    return new Promise(async (resolve, reject) => {
      try {
        let currentUserDetails = await Auth.currentAuthenticatedUser();
        let uwEmail = currentUserDetails.attributes.email;
        item.uwEmail = uwEmail;

        await axios
          .post(
            awsUrl2 + "/api/peoCampaignData/putFromUndrWrtr",
            JSON.stringify(item)
          )
          .then((res) => {
            console.log(res);
            resolve({ data: "updated" });
          })
          .catch((err) => {
            console.log(err);
            reject({ error: err });
          });
      } catch (error) {
        reject({ error: error });
      }
    });
  };

  setQuestions = (quesList) => {
    let response = {};
    for (let question of quesList) {
      response[question.name] = {
        ques: question.ques,
        response: question.response,
      };
    }

    return response;
  };

  updatePreunderWriterData = async (pre_underwriter_selection) => {
    return new Promise(async (resolve, reject) => {
      try {
        let { quesList, amtrustQuesList } = this.createQuestionsData();

        let userTrackingData = {
          domain: sessionStorage.getItem("domain"),
          pre_underwriter_status: "completed",
          pre_underwriter_selection,
          quesList,
          amtrustQuesList,
        };

        await axios
          .post(awsUrl2 + "/api/updatePreUnderwriter/generic", userTrackingData)
          .then((res) => {
            console.log("posted data", res);
            let staticQuestions = this.setQuestions(quesList);
            let amtrustQuestions = this.setQuestions(amtrustQuesList);
            sessionStorage.setItem("quesList", JSON.stringify(staticQuestions));
            sessionStorage.setItem(
              "amtrustQuesList",
              JSON.stringify(amtrustQuestions)
            );
            resolve();
          })
          .catch((error) => {
            console.log("error in posting data to user tracking", error);
            reject();
          });
      } catch (error) {
        console.log("error while updating before Pre underwriter ", error);
        reject();
      }
    });
  };

  onClickApprove = async () => {
    try {
      $("#loader").css("display", "block");
      let uwEmail;
      try {
        let currentUserDetails = await Auth.currentAuthenticatedUser();
        uwEmail = currentUserDetails.attributes.email;
      } catch (error) {}

      let preUwEngagedFlag = sessionStorage.getItem("preUwEngagedFlag")
        ? true
        : false;

      let currProspectDetails = JSON.parse(
        sessionStorage.getItem("currProspect")
      );
      let domain = sessionStorage.getItem("domain");

      let { childrenLoc } = currProspectDetails;

      let dataBody = {
        common: {},
        offices: [],
      };

      for (let addressBlockNo in childrenLoc) {
        let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${
          childrenLoc[addressBlockNo].state.value
        }_${
          (childrenLoc[addressBlockNo].zipCode ||
            childrenLoc[addressBlockNo].zipcode)["value"]
        }`;
        let officesObj = {
          name,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          employees: [],
        };

        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray = childrenLoc[addressBlockNo].classCodesInfo[
            addressChildNo
          ].classCodeDescription.value.split(":");

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();

          let classCodeObj = {
            code,
            payroll: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].payroll.value
              .split("$")[1]
              .split(",")
              .join(""),
            ft: childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].ft
              .value
              ? String(
                  childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].ft
                    .value
                ).trim()
              : "0",
            pt: childrenLoc?.[addressBlockNo]?.classCodesInfo?.[addressChildNo]
              ?.pt?.value
              ? String(
                  childrenLoc?.[addressBlockNo]?.classCodesInfo?.[
                    addressChildNo
                  ]?.pt?.value
                ).trim()
              : "0",
            desc: desc.toLowerCase(),
            pricing:
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
                .pricing &&
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo].pricing
                .value
                ? childrenLoc[addressBlockNo].classCodesInfo[
                    addressChildNo
                  ].pricing.value.split("%")[0]
                : "",
          };
          officesObj.employees.push(classCodeObj);
        }
        dataBody.offices.push(officesObj);
      }

      let {
        uwExpectedResponses,
        quesList,
        amtrustQuesList,
        acordQuesAvailable,
        preUwStatus,
      } = this.state;

      let uwQuesData = [];

      for (let question of quesList) {
        let dataQues = {
          response: question.response ? "Y" : "N",
          name: question.name,
          ques: question.ques,
          ifYes: question.ifYes,
          ifNo: question.ifNo,
        };
        uwQuesData.push(dataQues);
      }

      let uwQuestionsEligibility = getUwQuestionsEligibility({
        uwQuesData,
        amtrustQuesList,
        uwExpectedResponses,
        acordQuesAvailable,
      });

      let classCodeEligibility = getClassCodeEligibility(
        currProspectDetails.childrenLoc
      );

      let hospitalityEligibility = await getHospitalityEligibility(dataBody);

      let clientEligibility;
      let eligibilityList = [
        uwQuestionsEligibility || "approved",
        classCodeEligibility || "approved",
        hospitalityEligibility || "approved",
      ];
      try {
        clientEligibility = eligibilityList.reduce((a, b) => {
          if (eligibilityMap[a] > eligibilityMap[b]) {
            return a;
          }
          return b;
        });
      } catch (error) {
        clientEligibility = "approved";
      }

      let preUwData = {
        currProspect: currProspectDetails,
        userId: uwEmail || "",
        preUwStatus,
        preUwDate: Math.floor(Date.now()).toString(),
      };

      let preUwDataToSave = {
        domain,
        clientEligibility,
        classCodeEligibility,
        uwQuestionsEligibility,
        uwExpectedResponses,
        uwQuesList: quesList,
        uwAmtrustQuesList: amtrustQuesList,
        preUwDate: preUwData.preUwDate,
        preUwData,
      };
      try {
        console.log("pre Data: ", preUwDataToSave);
        let apiPath = preUwEngagedFlag ? "updateTrackingData" : "updateData";

        await axios
          .post(awsUrl2 + `/api/preUwData/${apiPath}`, preUwDataToSave)
          .then((res) => {
            console.log("posted data", res);
          })
          .catch((error) => {
            console.log("error in posting data to user tracking", error);
          });

        try {
          if (!preUwEngagedFlag) {
            await axios.post(
              awsUrl2 +
                "/api/updateSolveTrackingDataGeneric/updateUserTrackingPreUw",
              { domain }
            );

            await axios.post(
              awsUrl2 + "/api/updateEngagedWithEmailCampaign/" + domain,
              JSON.stringify(domain)
            );

            sessionStorage.setItem("preUwEngagedFlag", "true");
          }
        } catch (error) {
          console.log("Error in API: ", error);
        }
      } catch (error) {
        console.log("error while updating before Pre underwriter ", error);
      } finally {
        this.setState({ clientEligibility });
        sessionStorage.setItem("clientEligibility", clientEligibility);
        currProspectDetails = {
          ...currProspectDetails,
          amtrustQuesList: amtrustQuesList || [],
          uwQues: quesList || [],
          uwExpectedResponses: uwExpectedResponses || [],
        };
        sessionStorage.setItem(
          "currProspect",
          JSON.stringify(currProspectDetails)
        );
        $("#loader").css("display", "none");
      }
    } catch (error) {
      $("#loader").css("display", "none");
      console.log("error in onClickApprove");
    }
  };

  firstCaps = (str) => {
    if (str) {
      return str[0].toUpperCase() + str.slice(1);
    }
    return str;
  };
  handleConfirmation = (e) => {
    const checkValue = this.state.uwDataConfirmed;
    this.setState({ uwDataConfirmed: !checkValue });
  };

  render() {
    const {
      quesList,
      amtrustQuesList,
      incompleteQuestions,
      preUwFlowMessage,
      acordQuesAvailable,
      uwExpectedResponses,
      onlyPaygoError,
      clientEligibility,
      quoteSubmitted,
      fileList,
      salesManagerCheck,
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsError,
      uwDataConfirmed,
      fileListToStore,
      agentpersonCheck,
      cliamZeroCheckAmountPaid,
      cliamZeroCheckReserve,
      claimOneWithTotalPayroll,
    } = this.state;
    const { updatePopUpData } = this.props;
    const table = this.createTable();

    let hideButtons = sessionStorage.getItem("hideButtons") ? true : false;

    let disableGetQuote = sessionStorage.getItem("disableGetQuote");

    let mailCampaignUserCheck = checkMailCampaignUser();

    let domain = sessionStorage.getItem("domain")
      ? sessionStorage.getItem("domain")
      : "";
    let flagPreUnderWriter = sessionStorage.getItem("preUnderwriter")
      ? true
      : false;
    let compName = "";
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      compName =
        currProspect?.companyProfile &&
        currProspect?.companyProfile?.companyName
          ? currProspect.companyProfile?.companyName?.value
          : "";
    } catch (err) {
      console.log(err);
    }
    return (
      <div id="undrQues" className="container-fluid per98 underwrittingcolor ">
        <NewProgress />
        <div id="historical_Claims">
          <p className="formTitle title3">
            <b>Prior Carrier Information/Loss History</b>
          </p>
          {historicalClaimsError && (
            <p id="claims-error" className="text-danger">
              Claims submitted should be for consecutive years!
            </p>
          )}
          {Object.values(cliamZeroCheckAmountPaid).some(
            (value) => value === true
          ) ||
          Object.values(cliamZeroCheckReserve).some(
            (value) => value === true
          ) ? (
            <p id="claims-error" className="text-danger">
              Claims cannot be 0 if Amount paid is more than zero!
            </p>
          ) : null}

          {Object.values(claimOneWithTotalPayroll).some(
            (value) => value === true
          ) && (
            <p id="claims-error" className="text-danger">
              Amount paid cannot be 0 if claims are more than zero!
            </p>
          )}
          {/* <hr /> */}
          <div className="row grey-table-row ">
            <table
              id="claimTable"
              className="grey-table table table-responsive"
            >
              <thead>
                <tr></tr>
              </thead>
              <tbody>{table}</tbody>
            </table>
          </div>
        </div>
        <div className="m-3 d-flex align-items-center justify-content-center">
          <DropZones
            updatePopUpData={updatePopUpData}
            fileList={fileList}
            handleFileListToStoreSetter={this.handleFileListToStoreSetter}
            agentpersonCheck={agentpersonCheck}
          />
        </div>
        <div id="underWritingQues ">
          <div>
            {onlyPaygoError ? (
              <div>
                The PAYGO API ran into an issue. A representative will reach out
                to you soon.
              </div>
            ) : (
              <div className="table-responsive table-undrques-container ">
                <Allques
                  quesList={quesList}
                  updateQuesList={this.updateQuesList}
                  header={true}
                  clearAll={this.clearAllQuestions}
                  saveQuestionsResponse={this.saveQuestionsResponse}
                />

                {/*amtrustQuesList.length > 0 && (
                  <Allques
                    quesList={amtrustQuesList}
                    updateQuesList={this.updateAmtrustQuesList}
                  />
                )*/}
              </div>
            )}
            {incompleteQuestions && (
              <div className="errMsg text-center mb-4">
                Please answer all the questions!
              </div>
            )}

            {flagPreUnderWriter && (
              <div className="row">
                <div className="col-2">
                  <select
                    value={this.state.preUwStatus || "approved"}
                    id="preUwStatus"
                    type="text"
                    style={{ fontSize: "16px", width: "160px" }}
                    onChange={(event) =>
                      this.setState({ preUwStatus: event.target.value })
                    }
                    autoComplete="off"
                    className="form-control mb-2 mt-2"
                  >
                    <option key={1} value={"approved"}>
                      {" "}
                      Approve{" "}
                    </option>
                    <option key={2} value={"rejected"}>
                      {" "}
                      Reject{" "}
                    </option>
                  </select>
                  <button
                    className="btn btn-md"
                    style={{
                      width: "160px",
                      backgroundColor: "#8ab93f",
                      color: "white",
                    }}
                    disabled={disableGetQuote ? true : false}
                    onClick={() => {
                      if (!disableGetQuote) this.onClickApprove();
                    }}
                  >
                    Submit
                  </button>
                </div>
                {clientEligibility && (
                  <div
                    className="col"
                    style={{
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "left",
                    }}
                  >{`Client Eligibility: ${this.firstCaps(
                    clientEligibility
                  )}`}</div>
                )}
              </div>
            )}
            {flagPreUnderWriter && false && (
              <>
                {preUwFlowMessage && (
                  <div className="text-center mb-4">{preUwFlowMessage}</div>
                )}
                <div
                  className="d-flex"
                  style={{
                    justifyContent: "space-evenly",
                  }}
                >
                  <button
                    className="btn btn-md"
                    style={{
                      width: "160px",
                      backgroundColor: "#8ab93f",
                      color: "white",
                    }}
                    onClick={async () => {
                      try {
                        $("#loader").css("display", "block");
                        let validQuestions = checkQuestionsValidity(
                          quesList,
                          amtrustQuesList
                        );
                        if (validQuestions) {
                          this.setState({ incompleteQuestions: false });
                          await this.putPeoCampaignData({
                            domain,
                            onlyPaygo: "false",
                          });
                          await this.updatePreunderWriterData("peo");
                          this.setState({
                            preUwFlowMessage: "Response saved successfully!",
                          });
                          $("#loader").css("display", "none");
                        } else if (!validQuestions) {
                          this.setState({ incompleteQuestions: true });
                          $("#loader").css("display", "none");
                        }
                      } catch (error) {
                        console.log("error in updating the response", error);
                        this.setState({
                          preUwFlowMessage: "Error in saving response!",
                        });
                        $("#loader").css("display", "none");
                      }
                    }}
                  >
                    Approved for PEO-Master
                  </button>
                  <button
                    className="btn btn-md"
                    style={{
                      width: "160px",
                      backgroundColor: "#c0224d",
                      color: "white",
                    }}
                    onClick={async () => {
                      try {
                        $("#loader").css("display", "block");
                        let validQuestions = checkQuestionsValidity(
                          quesList,
                          amtrustQuesList
                        );
                        if (validQuestions) {
                          this.setState({ incompleteQuestions: false });
                          await this.putPeoCampaignData({
                            domain,
                            onlyPaygo: "true",
                          });
                          await this.updatePreunderWriterData("paygo");
                          this.setState({
                            preUwFlowMessage: "Response saved successfully!",
                          });
                          $("#loader").css("display", "none");
                        } else if (!validQuestions) {
                          this.setState({ incompleteQuestions: true });
                          $("#loader").css("display", "none");
                        }
                      } catch (error) {
                        console.log("error in updating the response", error);
                        this.setState({
                          preUwFlowMessage: "Error in saving response!",
                        });
                        $("#loader").css("display", "none");
                      }
                    }}
                  >
                    Not Approved for PEO-Master
                  </button>
                </div>
              </>
            )}
          </div>
          <div id="tncLabel">
            <Form.Group
              style={{
                display: "flex",
                "justify-content": "space-evenly",
                width: "70vw",
              }}
            >
              <input
                type="checkbox"
                checked={uwDataConfirmed}
                onChange={(e) => this.handleConfirmation(e)}
              />{" "}
              <p
                htmlFor="uwDataConfirmed"
                className="mandatory"
                style={{ width: "65vw" }}
              >
                I certify that all information provided is accurate and complete
                and that I have authority to sign this agreement on behalf of
                the applicant.
              </p>
            </Form.Group>
          </div>
          {!flagPreUnderWriter && !onlyPaygoError && (
            <div className="row">
              <div className="col-12 text-center">
                <button
                  type="button"
                  className="btn button_color btn-lg btn-warning-shadow btnSubmits button_color"
                  onClick={() => {
                    // if (
                    //   hideButtons ||
                    //   quoteSubmitted ||
                    //   disableGetQuote ||
                    //   salesManagerCheck
                    // )
                    //   return;
                    this.submitQues(
                      amtrustQuesList,
                      updatePopUpData,
                      acordQuesAvailable,
                      uwExpectedResponses
                    );
                  }}
                  disabled={
                    !agentpersonCheck ||
                    hideButtons ||
                    quoteSubmitted ||
                    disableGetQuote ||
                    salesManagerCheck ||
                    !uwDataConfirmed
                  }
                  title={
                    !uwDataConfirmed ? "Your acknowledgment is mandatory." : ""
                  }
                >
                  Get Quote
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default function FormUnderQuesWrapper(props) {
  return (
    <Context.Consumer>
      {(context) => (
        <FormUnderQues
          updatePopUpData={props.updatePopUpData}
          quoteSubmitted={context.quoteSubmitted}
          setQuoteSubmittedStatus={context.setQuoteSubmittedStatus}
          setEligibilityCheck={context.setEligibilityCheck}
          eligibilityCheck={context.eligibilityCheck}
        />
      )}
    </Context.Consumer>
  );
}
